import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { FormattedMessage as FM } from 'react-intl'
import { Link } from 'react-router-dom'

import { getTranslation } from '../../../request'
import { getDateStr, prettyDateShort } from '../../../lib/utils'
import indexStyles from '../../../index.css'
import styles from './RelatedObjects.css'
import common from '../common/Body.css'
import ResumoLi from '../modelBlocks/ResumoLi'

class RelatedObjects extends Component {
  render () {
    const { lang, relatedModelLeft, relatedModelRight } = this.props
    return this.buildRelated(relatedModelLeft, relatedModelRight, lang)
  }

  buildRelated (left, right, lang) {
    if (!left && !right) {
      return null
    }
    const leftIsDoc = left !== null && left.type === 'document'
    const rightIsDoc = right !== null && right.type === 'document'
    return (
      <div className={styles.relatedGrid}>
        <div className={styles.relatedTitle}>
          <FM id='global.relatedObjects' defaultMessage='Objectos relacionados' />
        </div>
        {
          !left
            ? null
            : <div className={styles.relatedLeft + ' ' + indexStyles.shadow} key={left.id + '-left'}>
              {
                leftIsDoc
                  ? <div className={common.liShort + ' ' + common.noMargin}>
                    <a className={common.liImg + ' ' + common.download} href={left.downloadLink} target='_blank' />
                    <a className={common.liText + ' ' + common.short} href={left.downloadLink} target='_blank'>
                      <div className={common.textTitle}>
                        {getTranslation(left.titulo, lang)}
                      </div>
                      <div className={common.textDate}>
                        {getDateStr(left.publicationDate, lang)}
                      </div>
                    </a>
                  </div>
                  : <Link
                    className={common.liShort + (!left.image ? ' ' + common.noImage : '') + ' ' + common.noMargin}
                    to={left.link}>
                    {
                      !left.image
                        ? <ResumoLi text={getTranslation(left.resumo, lang)} />
                        : <div className={common.liImg}>
                          <img className={common.imageFrame + ' ' + common.short} src={left.image} />
                        </div>
                    }
                    <div className={common.liText + ' ' + common.short}>
                      <div className={common.textTitle}>
                        {getTranslation(left.titulo, lang)}
                      </div>
                      <div className={common.textDate}>
                        {
                          left.type === 'event'
                            ? prettyDateShort(left.start, lang, left.end)
                            : getDateStr(left.publicationDate, lang)
                        }
                      </div>
                    </div>
                  </Link>
              }
            </div>
        }
        {
          !right
            ? null
            : <div className={styles.relatedRight + ' ' + indexStyles.shadow} key={right.id + '-right'}>
              {
                rightIsDoc
                  ? <div className={common.liShort + ' ' + common.noMargin}>
                    <a className={common.liImg + ' ' + common.download} href={right.downloadLink} target='_blank' />
                    <a className={common.liText + ' ' + common.short} href={right.downloadLink} target='_blank'>
                      <div className={common.textTitle}>
                        {getTranslation(right.titulo, lang)}
                      </div>
                      <div className={common.textDate}>
                        {getDateStr(right.publicationDate, lang)}
                      </div>
                    </a>
                  </div>
                  : <Link
                    className={common.liShort + (!right.image ? ' ' + common.noImage : '') + ' ' + common.noMargin}
                    to={right.link}>
                    {
                      !right.image
                        ? <ResumoLi text={getTranslation(right.resumo, lang)} />
                        : <div className={common.liImg}>
                          <img className={common.imageFrame + ' ' + common.short} src={right.image} />
                        </div>
                    }
                    <div className={common.liText + ' ' + common.short}>
                      <div className={common.textTitle}>
                        {getTranslation(right.titulo, lang)}
                      </div>
                      <div className={common.textDate}>
                        {
                          right.type === 'event'
                            ? prettyDateShort(right.start, lang, right.end)
                            : getDateStr(right.publicationDate, lang)
                        }
                      </div>
                    </div>
                  </Link>
              }
            </div>
        }
      </div >
    )
  }
}

RelatedObjects.propTypes = {
  relatedModelLeft: PropTypes.object,
  relatedModelRight: PropTypes.object,
  lang: PropTypes.string.isRequired
}

const mapStateToProps = state => ({
  relatedModelLeft: state.model.relatedModelLeft,
  relatedModelRight: state.model.relatedModelRight,
  lang: state.linguas.lang
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(RelatedObjects)
