/* eslint-env browser */
import React, { Component } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { IntlProvider } from 'react-intl'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import Routes from '../Routes/Routes'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'
import ScrollTop from '../ScrollTop/ScrollTop'
import messages from './messages'
import { setMobile, setMobileMenu } from '../../modules/global'
import styles from './App.css'
import { fetchCurrentPastaContent } from '../../modules/pastas'
import { fetchDocumentos } from '../../modules/documentos'

class App extends Component {
  constructor(props) {
    super(props)
    const { lang } = props
    this.props.fetchCurrentPastaContent(lang, null, false, 0)
    this.props.fetchDocumentos(lang)
    this.resize = this.resize.bind(this)
  }

  componentDidMount() {
    window.addEventListener('resize', this.resize)
    // const { lang } = this.props
    // this.props.fetchCurrentPastaContent(lang, null, false, 0)
    // this.props.fetchDocumentos(lang)
    this.resize()
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize)
  }

  resize() {
    this.props.setMobile(window.innerWidth < 960)
    this.props.setMobileMenu(window.innerWidth < 960)
  }

  render() {
    const { lang } = this.props
    return (
      <IntlProvider locale={lang} messages={messages[lang]}>
        <Router>
          <div className={styles.mainGrid}>
            <Header />
            <Routes />
            <Footer />
            <ScrollTop />
          </div>
        </Router>
      </IntlProvider>
    )
  }
}

App.propTypes = {
  lang: PropTypes.string.isRequired,
  setMobile: PropTypes.func.isRequired,
  setMobileMenu: PropTypes.func.isRequired,
  fetchCurrentPastaContent: PropTypes.func.isRequired,
  fetchDocumentos: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  lang: state.linguas.lang
})

const mapDispatchToProps = { setMobile, setMobileMenu, fetchCurrentPastaContent, fetchDocumentos }

export default connect(mapStateToProps, mapDispatchToProps)(App)
