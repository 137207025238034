import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { getTranslation } from '../../../request'
import commonStyles from './CommonStyles.css'
import { setFormInstance, saveFormInstance } from '../../../modules/formulario'
import styles from './AutoCompleteField.css'
import Label from './Label'

class AutoCompleteField extends Component {
  componentDidMount () {
    window.addEventListener('click', this.handleWindowClick)
    this.valueChanged = this.valueChanged.bind(this)
    this.selectItem = this.selectItem.bind(this)
    this.handleWindowClick = this.handleWindowClick.bind(this)
    this.handleInputClick = this.handleInputClick.bind(this)
    this.showList = false
    this.myTextValue = React.createRef()
  }

  componentWillUnmount () {
    window.removeEventListener('click', this.handleWindowClick)
  }
  
  render () {
    const { field, lang, formInstance } = this.props
    return (
      <div className={styles.inputGrid}>
        <div className={styles.label + ' ' + commonStyles.labelWrapper} style={{ margin: 0 }}>
          <Label {...this.props} />
        </div>
        <div className={styles.input + '' + styles.width100Percent}>
          {this.buildInput(field, formInstance, lang)}
        </div>
        <div className={styles.menu} style={{ position: 'relative' }}>
          {this.buildList(field, formInstance, lang)}
        </div>
      </div>
    )
  }

  buildInput (field, formInstance, lang) {
    return (
      <input
        ref={this.myTextValue}
        className={commonStyles.inputText + ' ' + styles.width100Percent}
        placeholder={getTranslation(field.placeholder, lang)}
        readOnly={field.readonly}
        onChange={(e) => this.valueChanged(e, field, formInstance, lang)}
        onClick={this.handleInputClick}
        value={this.getValue(field, formInstance)} />
    )
  }

  buildList (field, formInstance, lang) {
    if (!this.showList) {
      return <div />
    }
    let values = this.getValues(field.values, lang)
    let text = this.myTextValue.current.value.toLowerCase()
    values = values.filter(obj => {
      return text === '' || obj.label.toLowerCase().includes(text)
    })
    if (values.length === 0) {
      return <div />
    }
    return (
      <ul className={styles.ul + ' ' + styles.autoCompleteList}>
        {
          values.map(item => (
            <li key={item.value}
              className={styles.li}
              onClick={(e) => this.selectItem(e, field, formInstance, { key: item.value, value: item.label })}>
              {item.label}
            </li>
          ))
        }
      </ul>
    )
  }

  handleInputClick (e) {
    e.stopPropagation()
  }

  handleWindowClick (e) {
    this.showList = false
    // this.forceUpdate()
  }

  selectItem (e, field, formInstance, keyValue) {
    e.stopPropagation()
    if (!formInstance) {
      return
    }
    let formInstanceValue = this.getFormInstanceFieldValue()
    if (formInstanceValue === null) {
      formInstanceValue = this.initNewFormInstanceField()
      formInstance.values.push(formInstanceValue)
    }
    this.setValue(field, formInstance, keyValue)
    this.showList = false
    let values = []
    values.push(formInstanceValue)
    this.props.saveFormInstance(formInstance.id, values)
  }

  getValue (field, formInstance) {
    if (!formInstance) {
      return ''
    }
    for (let index = 0; index < formInstance.values.length; index++) {
      const element = formInstance.values[index]
      if (element.fieldId === field.id) {
        for (let i = 0; i < element.keyValues.length; i++) {
          const keyValue = element.keyValues[i]
          return keyValue.value
        }
      }
    }
    return ''
  }

  valueChanged (e, field, formInstance, lang) {
    const value = e.target.value
    let values = this.getValues(field.values, lang)
    let keyValue = { key: '-1', value: value }
    values.forEach(element => {
      if (element.label.toLowerCase() === value.toLowerCase()) {
        keyValue = { key: element.value, value: element.label }
      }
    })
    let formInstanceValue = this.getFormInstanceFieldValue()
    if (formInstanceValue === null) {
      formInstanceValue = this.initNewFormInstanceField()
      formInstance.values.push(formInstanceValue)
    }
    this.setValue(field, formInstance, keyValue)
    this.showList = true
    values = []
    values.push(formInstanceValue)
    this.props.saveFormInstance(formInstance.id, values)
  }

  setValue (field, formInstance, value) {
    if (!formInstance) {
      return
    }
    for (let index = 0; index < formInstance.values.length; index++) {
      const element = formInstance.values[index]
      if (element.fieldId === field.id) {
        element.keyValues = []
        element.keyValues.push(value)
        this.props.setFormInstance(formInstance)
        return
      }
    }
    formInstance.values.push({ fieldId: field.id, value: value })
    this.props.setFormInstance(formInstance)
  }

  getFormInstanceFieldValue () {
    const { field, formInstance } = this.props
    if (!formInstance) {
      return null
    }
    for (let index = 0; index < formInstance.values.length; index++) {
      const element = formInstance.values[index]
      if (element.fieldId === field.id) {
        return element
      }
    }
    return null
  }

  initNewFormInstanceField () {
    const { field } = this.props
    return { fieldId: field.id, key: null, value: null, keyValues: [] }
  }

  getValues (values, lang) {
    let array = []
    if (!values || values.length === 0) return []
    if (values[0].lang !== undefined) {
      for (let index = 0; index < values.length; index++) {
        const element = values[index]
        if (element.lang === lang) {
          array.push(element)
        }
      }
      return array
    } else {
      for (let index = 0; index < values.length; index++) {
        const element = values[index]
        array.push(element)
      }
      return array
    }
  }
}

AutoCompleteField.propTypes = {
  lang: PropTypes.string.isRequired,
  formInstance: PropTypes.object,
  setFormInstance: PropTypes.func.isRequired,
  saveFormInstance: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  formInstance: state.formulario.formInstance,
  lang: state.linguas.lang
})

const mapDispatchToProps = { setFormInstance, saveFormInstance }

export default connect(mapStateToProps, mapDispatchToProps)(AutoCompleteField)
