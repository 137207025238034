/* eslint-env browser */
import axios from 'axios'

import { host } from '../request'
import { fetchDestaques, fetchHeaderLinks, fetchFooterLinks } from './destaques'
import { fetchHomepageEvents } from './events'
import { setMenuItems } from './menus'

const FETCH_LINGUAS = 'cmlf/linguas/FETCH_LINGUAS'
const SET_LANG = 'cmlf/linguas/SET_LANG'
const TOGGLE_MENU = 'cmlf/linguas/TOGGLE_MENU'

const initialState = {
  linguas: [{
    id: '1',
    sigla: 'pt',
    nome: 'Português'
  }, {
    id: '2',
    sigla: 'en',
    nome: 'English'
  }, {
    id: '3',
    sigla: 'fr',
    nome: 'Français'
  }],
  lang: 'pt',
  showMenu: false
}

export const localeSet = (lang) => ({ type: SET_LANG, lang })
export const setLang = lang => (dispatch) => {
  localStorage.cmlfLang = lang
  dispatch(localeSet(lang))
  dispatch(fetchDestaques(lang))
  dispatch(fetchHeaderLinks(lang))
  dispatch(fetchFooterLinks(lang))
  dispatch(fetchHomepageEvents(lang))
}
export const toggleMenu = () => ({ type: TOGGLE_MENU })
export function fetchLinguas () {
  return (dispatch) => {
    axios.get(host + '/api/public/linguas')
      .then(res => res.data)
      .then(posts => dispatch({
        type: FETCH_LINGUAS,
        payload: posts
      }))
      .then(posts => {
        dispatch(setMenuItems('linguas', posts.payload))
      })
  }
}

export default function reducer (state = initialState, action = {}) {
  switch (action.type) {
    case SET_LANG:
      return { ...state, lang: action.lang }
    case FETCH_LINGUAS:
      return { ...state, linguas: action.payload }
    case TOGGLE_MENU:
      return { ...state, showMenu: !state.showMenu }
    default:
      return state
  }
}
