import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Scrollbars } from 'react-custom-scrollbars'

import styles from './CalendarDays.css'
import { selectDay, setTopDayMonth, updateDays, selectMonth } from '../../../modules/calendar'
import CalendarTopMonth from './CalendarTopMonth'

class CalendarDays extends Component {
  constructor(props) {
    super(props)
    this.renderThumb = this.renderThumb.bind(this)
    this.onScroll = this.onScroll.bind(this)
    this.viewPort = React.createRef()
    this.ulRef = React.createRef()
    this.scrollbar = React.createRef()
    this.skipOnScroll = false
  }

  componentDidMount() {
    const date = new Date()
    date.setHours(0, 0, 0)
    date.setMilliseconds(0)
    this.selectDay({
      date: date,
      day: date.getDay(),
      month: date.getMonth() + 1,
      year: date.getFullYear(),
      selectable: true
    })
  }

  render() {
    const { days, toggleUpdateDays } = this.props
    if (toggleUpdateDays !== null) {
      setTimeout(() => {
        this.scrollToMonth(toggleUpdateDays)
      }, 300)
      setTimeout(() => {
        this.skipOnScroll = false
      }, 1000)
    }
    return (
      <div className={styles.layoutGrid}>
        <CalendarTopMonth />
        <div className={styles.daysContainer} ref={this.viewPort}>
          <Scrollbars
            ref={this.scrollbar}
            autoHeight
            autoHeightMax={380}
            autoHide
            autoHideTimeout={1000}
            autoHideDuration={200}
            onScroll={this.onScroll}
            renderThumbHorizontal={this.renderThumb}
            renderThumbVertical={this.renderThumb}>
            {this.buildDaysList(days)}
          </Scrollbars>
        </div>
        <div className={styles.divGradTop} />
        <div className={styles.divGradBottom} />
      </div>
    )
  }

  buildDaysList(days) {
    let elements = []
    elements.push(
      <ul key='ulDays' className={styles.ul} ref={this.ulRef}>
        {this.buildDaysItems(days)}
      </ul>
    )
    return elements
  }

  buildDaysItems(days) {
    if (!days || days.length === 0) {
      return []
    }
    let elements = []
    let month = days[0].date.getMonth()
    for (let index = 0; index < days.length; index += 2) {
      const day1 = days[index]
      const day2 = index + 1 < days.length ? days[index + 1] : { year: day1.year, month: day1.month, day: null }
      let insertMonthDividor = false
      if (month !== day1.date.getMonth()) {
        month = day1.date.getMonth()
        insertMonthDividor = true
      }
      elements.push(
        <li key={index} className={insertMonthDividor ? styles.monthDividor : styles.twoDaysLine}>
          {this.insertDay(day1, 'l', index)}
          {this.insertDay(day2, 'r', index + 1)}
          {insertMonthDividor
            ? <div className={styles.monthDiv} key={'hr-' + index}>
              {this.getDayMonth(new Date(day1.date.getFullYear(), day1.date.getMonth() + 1, 0))}
            </div>
            : null}
        </li>
      )
    }
    return elements
  }

  insertDay(day, pos, index) {
    const dayPos = pos === 'l' ? 'day1' : 'day2'
    const key = day.year + '-' + day.month + '-' + index + (day.day === null ? 'div' : day.day)
    if (day.day !== null) {
      if (day.selectable) {
        return (
          <div key={key} id={index}
            className={styles[dayPos] + ' ' + styles.dayGrid + ' ' + styles.selectable +
              (this.isSelectedDay(day) ? ' ' + styles.selected : '')}
            onClick={(e) => this.selectDay(day)}>
            <div className={styles.day}>{this.getDayNumber(day.date)}</div>
            <div className={styles.weekDay}>{this.getDayWeek(day.date)}</div>
          </div>
        )
      } else {
        return (
          <div key={key} id={index}
            className={styles[dayPos] + ' ' + styles.dayGrid + ' ' + styles.notSelectable +
              (this.isSelectedDay(day) ? ' ' + styles.selected : '')}>
            <div className={styles.day}>{this.getDayNumber(day.date)}</div>
            <div className={styles.weekDay}>{this.getDayWeek(day.date)}</div>
          </div>
        )
      }
    } else {
      return null
    }
  }

  isSelectedDay(day) {
    const { selectedDay } = this.props
    if (selectedDay !== null &&
      selectedDay.getFullYear() === day.date.getFullYear() &&
      selectedDay.getMonth() === day.date.getMonth() &&
      selectedDay.getDate() === day.date.getDate()) {
      return true
    }
    return false
  }

  selectDay(day) {
    this.props.updateDays(null) // para evitar scroll ao seleccionar o dia e causar render da componente
    let tdm = new Date(day.date)
    tdm.setDate(1)
    this.props.setTopDayMonth(tdm) // scroll do mês se necessário
    this.props.selectDay(day.date)
    this.props.selectMonth({
      year: tdm.getFullYear(),
      month: tdm.getMonth() + 1,
      selectable: true,
      selected: true
    })
  }

  getDayNumber(date) {
    const { lang } = this.props
    return date.toLocaleDateString(lang, { day: '2-digit' })
  }
  getDayWeek(date) {
    const { lang } = this.props
    return date.toLocaleDateString(lang, { weekday: 'short' })
  }
  getDayMonth(date) {
    const { lang } = this.props
    return date.toLocaleDateString(lang, { month: 'long' })
  }

  onScroll(e) {
    if (!this.skipOnScroll) {
      this.setVisibleTopMonth()
    }
  }
  setVisibleTopMonth() {
    const { days } = this.props
    let sb = this.scrollbar.current
    let liItems = this.ulRef.current.children
    let offset = this.ulRef.current.getBoundingClientRect().top
    for (let index = 0; index < liItems.length; index++) {
      const li = liItems[index]
      const liRect = li.getBoundingClientRect()
      if (liRect.top + liRect.height / 3 - offset >= sb.getScrollTop()) {
        let tdy = new Date(days[index * 2].date)
        tdy.setDate(1)
        this.props.setTopDayMonth(tdy)
        break
      }
    }
  }

  scrollToMonth(month) {
    if (!this.ulRef.current) {
      return
    }
    const { days, selectedDay } = this.props
    this.skipOnScroll = true
    let firstDayNode = null
    let lis = this.ulRef.current.children
    for (let index = 0; index < lis.length; index++) {
      const li = lis[index]
      let day1 = days[parseInt(li.children[0].id)]
      if (day1.year === month.year && day1.month === month.month) {
        firstDayNode = li
        if (selectedDay !== null &&
          selectedDay.getMonth() === month.month - 1 &&
          selectedDay.getFullYear() === month.year) {
          let diff = selectedDay.getDate() - day1.date.getDate()
          firstDayNode = lis[index + Math.floor(diff / 2)]
          break
        } else {
          break
        }
      }
    }
    if (firstDayNode !== null) {
      this.scrollToListItem(firstDayNode)
    }
  }

  scrollToListItem(target) {
    let viewPort = this.viewPort.current.getBoundingClientRect()
    let rect = target.getBoundingClientRect()
    let dist = rect.top - viewPort.top
    let sb = this.scrollbar.current
    dist = sb.getScrollTop() + dist
    this.scrollSmooth(sb.getScrollTop(), dist, 300)
  }

  scrollSmooth(oldValue, newValue, dur) {
    const stepDur = 5
    let steps = Math.floor(dur / stepDur)
    let stepSize = (newValue - oldValue) / steps
    for (let index = 0; index < steps; index++) {
      let sum = stepSize * (index + 1)
      setTimeout(() => {
        if (this.scrollbar.current) {
          this.scrollbar.current.scrollTop(oldValue + sum)
        }
      }, index * stepDur)
    }
    setTimeout(() => {
      if (this.scrollbar.current) {
        this.scrollbar.current.scrollTop(newValue)
      }
      // this.skipOnScroll = false
    }, dur + stepDur)
  }

  renderThumb({ style, ...props }) {
    const thumbStyle = {
      minWidth: '8px',
      backgroundColor: '#9ebdd2',
      borderRadius: '4px'
    }
    return (
      <div
        style={{ ...style, ...thumbStyle }}
        {...props} />
    )
  }
}

CalendarDays.propTypes = {
  lang: PropTypes.string.isRequired,
  days: PropTypes.array.isRequired,
  selectedMonth: PropTypes.object,
  selectedDay: PropTypes.object,
  selectDay: PropTypes.func,
  setTopDayMonth: PropTypes.func.isRequired,
  toggleUpdateDays: PropTypes.object,
  updateDays: PropTypes.func.isRequired,
  selectMonth: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  lang: state.linguas.lang,
  days: state.calendar.days,
  selectedDay: state.calendar.selectedDay,
  toggleUpdateDays: state.calendar.toggleUpdateDays
})

const mapDispatchToProps = { selectDay, setTopDayMonth, updateDays, selectMonth }

export default connect(mapStateToProps, mapDispatchToProps)(CalendarDays)
