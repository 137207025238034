import React, { Component } from 'react'
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from 'react-google-maps'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { apiKey } from '../../../lib/utils'
import { setClickedLocal, setBounds } from '../../../modules/locais'
import pinAzul from '../../../img/pinazulclaro.svg'
import pinVermelho from '../../../img/pinvermelho.svg'

class MyListMap extends Component {
  constructor(props) {
    super(props)
    this.map = React.createRef()
    // this.state = { zoom: 12, lat: 39.411705, lng: -31.198887 }
    this.zoom = 12
    this.lat = 39.411705
    this.lng = -31.198887
  }

  render () {
    const { locais, clickedLocal } = this.props
    const onMarkerClick = (e, local) => {
      if (clickedLocal !== null && local.id === clickedLocal.id) {
        this.props.setClickedLocal(null)
      } else {
        this.props.setClickedLocal(local)
      }
    }


    const mapStyles = [
      {
        featureType: 'water',
        elementType: 'geometry.fill',
        stylers: [{ color: '#0c598e' }]
      }
    ]
    const GoogleMapExample = withScriptjs(
      withGoogleMap(props => (
        <GoogleMap
          ref={map => {
            this.map = map;
          }}
          onIdle={props.onMapIdle}
          defaultOptions={{ styles: mapStyles }}
          defaultCenter={{ lat: this.lat, lng: this.lng }}
          defaultZoom={this.zoom}>
          {locais.map((local) => {
            if (!local.latInicio) {
              return null
            }
            if (clickedLocal !== null && local.id === clickedLocal.id) {
              return (
                <Marker
                  key={local.id}
                  position={{ lat: local.latInicio, lng: local.lngInicio }}
                  icon={{
                    url: pinVermelho,
                    strokeColor: 'blue',
                    scaledSize: new google.maps.Size(40, 40)
                  }}
                  onClick={(e) => onMarkerClick(e, local)} />
              )
            } else {
              return (
                <Marker
                  key={local.id}
                  position={{ lat: local.latInicio, lng: local.lngInicio }}
                  icon={{
                    url: pinAzul,
                    strokeColor: 'blue',
                    scaledSize: new google.maps.Size(40, 40)
                  }}
                  onClick={(e) => onMarkerClick(e, local)} />
              )
            }
          })}
        </GoogleMap>
      ))
    )
    const setLocalState = (zoom, lat, lng) => {
      this.zoom = zoom
      this.lat = lat,
        this.lng = lng
    }
    return (
      <div>
        <GoogleMapExample
          onMapIdle={() => {
            if (!this.map || !this.map.getBounds()) {
              return
            }
            let ne = this.map.getBounds().getNorthEast()
            let sw = this.map.getBounds().getSouthWest()
            let zoom = this.map.getZoom()
            let center = this.map.getCenter()
            setLocalState(zoom, center.lat(), center.lng())
            this.props.setBounds({ ne: { lat: ne.lat(), lng: ne.lng() }, sw: { lat: sw.lat(), lng: sw.lng() } })
          }}
          googleMapURL={'https://maps.googleapis.com/maps/api/js?key=' + apiKey + '&v=3.exp&libraries=geometry,drawing,places'}
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={<div style={{ height: `400px` }} />}
          mapElement={<div style={{ height: `100%` }} />}
        />
      </div>
    )
  }
}

MyListMap.propTypes = {
  setClickedLocal: PropTypes.func.isRequired,
  clickedLocal: PropTypes.object,
  setBounds: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  clickedLocal: state.locais.clickedLocal
})

const mapDispatchToProps = { setClickedLocal, setBounds }

export default connect(mapStateToProps, mapDispatchToProps)(MyListMap)
