/* eslint-env browser */
import axios from 'axios'

import { host, computeLink } from '../request'
import { getImage, stringArrToCommaSepString } from '../lib/utils'

const FETCH_NEWS = 'cmlf/news/FETCH_NEWS'
const NEWS_LOADING = 'cmlf/news/NEWS_LOADING'
const NEWS_FETCHED = 'cmlf/news/NEWS_FETCHED'

const initialState = {
  news: [],
  newsAreLoading: false,
  newsFetched: false
}

export const newsLoading = (loading) => ({ type: NEWS_LOADING, loading })
export const newsFetched = () => ({ type: NEWS_FETCHED })

export function fetchNews (lang, categIdArray) {
  return (dispatch) => {
    dispatch(newsLoading(true))
    const cats = stringArrToCommaSepString(categIdArray)
    axios.get(host + '/api/public/noticias?lang=' + lang + (cats !== null ? '&cats=' + cats : ''))
      .then(res => res.data)
      .then(posts => {
        let resp = []
        posts.forEach(post => {
          let item = {
            titulo: post.titulo,
            resumo: post.resumo
          }
          item.image = getImage(post.imagem)
          item.categorias = post.categorias
          item.date = post.data
          item.link = computeLink(post)
          item.id = post.id
          item.obj = post
          resp.push(item)
        })
        dispatch({
          type: FETCH_NEWS,
          payload: resp
          // payload: initialState.news
        })
        dispatch(newsFetched())
        dispatch(newsLoading(false))
      })
  }
}
export default function reducer (state = initialState, action = {}) {
  switch (action.type) {
    case FETCH_NEWS:
      return { ...state, news: action.payload }
    case NEWS_LOADING:
      return { ...state, newsAreLoading: action.loading }
    case NEWS_FETCHED:
      return { ...state, newsFetched: true }
    default:
      return state
  }
}
