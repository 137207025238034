import React, { Component } from 'react'

import { getTranslation } from '../../../request'
import styles from './HeaderField.css'

export default class HeaderField extends Component {
  render () {
    const { field, lang } = this.props
    console.log('field', field)
    return (
      <div>
        {this.buildInterface(field, lang)}
      </div>
    )
  }
  buildInterface (field, lang) {
    switch (field.fieldType) {
      case 1:
        return (<h2 className={styles.h2}>{getTranslation(field.label, lang)}</h2>)
      case 2:
        return (<h3 className={styles.h3}>{getTranslation(field.label, lang)}</h3>)
      case 3:
        return (<h4 className={styles.h4}>{getTranslation(field.label, lang)}</h4>)
      case 4:
        return (<h5 className={styles.h5}>{getTranslation(field.label, lang)}</h5>)
      default:
        return (<h1 className={styles.h1}>{getTranslation(field.label, lang)}</h1>)
    }
  }
}
