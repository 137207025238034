import React, { Component } from 'react'
import { getTranslation } from '../../../request'
import styles from './ParagraphField.css'

export default class HeaderField extends Component {
  render () {
    const { field, lang } = this.props
    return (
      <div>
        {this.buildInterface(field, lang)}
      </div>
    )
  }
  buildInterface (field, lang) {
    let text = getTranslation(field.label, lang)
    let arr = text.split('\n')
    switch (field.fieldType) {
      case 1:
        return (
          arr.map((text, index) => (
            <address key={index}>{text}</address>
          ))
        )
      case 2:
        return (
          arr.map((text, index) => (
            <blockquote key={index}>{text}</blockquote>
          ))
        )
      case 3:
        return (
          arr.map((text, index) => (
            <canvas key={index}>{text}</canvas>
          ))
        )
      case 4:
        return (
          arr.map((text, index) => (
            <output key={index}>{text}</output>
          ))
        )
      default:
        return (
          arr.map((text, index) => (
            <p className={styles.p} key={index}>{text}</p>
          ))
        )
    }
  }
}
