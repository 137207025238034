import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import commonStyles from './CommonStyles.css'
import { setFormInstance, saveFormInstance } from '../../../modules/formulario'
import Label from './Label'
import styles from './RadioButtonGroupField.css'

class RadioButtonGroupField extends Component {
  constructor(props) {
    super(props)
    this.valueChanged = this.valueChanged.bind(this)
    this.otherValueChanged = this.otherValueChanged.bind(this)
    this.otherValueTextChanged = this.otherValueTextChanged.bind(this)
    this.getOtherItemTextValue = this.getOtherItemTextValue.bind(this)
    this.oldOtherValueText = ''
  }

  render () {
    const { field, lang, formInstance } = this.props
    return (
      <div className={commonStyles.labelWrapper}>
        <Label {...this.props} />
        {this.buildInput(field, lang, formInstance)}
        {this.buildOther(field, formInstance)}
      </div>
    )
  }

  buildInput (field, lang, formInstance) {
    const values = this.getValues(field.values, lang)
    let style = { display: 'flex', flexDirection: field.inline ? 'row' : 'column' }
    return (
      <div style={style}>
        {
          values.map(item => (
            <label key={item.value} className={styles.container}>{item.label}
              <input id={item.value} type='radio'
                itemProp={item} value={item.value} checked={this.valueChecked(field, formInstance, item)} readOnly={field.readonly} onChange={this.valueChanged} />
              <span className={styles.checkmark} />
            </label>
          ))
        }
      </div>
    )
  }

  valueChecked (field, formInstance, fieldValue) {
    if (!formInstance) {
      return false
    }
    for (let index = 0; index < formInstance.values.length; index++) {
      const element = formInstance.values[index]
      if (element.fieldId === field.id) {
        for (let i = 0; i < element.keyValues.length; i++) {
          const keyValue = element.keyValues[i]
          if (fieldValue.value === keyValue.key) {
            return true
          }
        }
        break
      }
    }
    return false
  }

  buildOther (field, formInstance) {
    if (!field.allowOther) {
      return null
    }
    const checked = this.valueChecked(field, formInstance, { value: '-1' })
    let inputTextStyle = checked ? {} : { 'display': 'none' }
    return (
      <div key={'outro'} className={styles.outroWrapper}>
        <label className={styles.container}>{'Outro'}
          <input id={0} type='radio'
            className={styles.inputRadio} value={0} checked={checked} readOnly={field.readonly} onChange={this.otherValueChanged} />
          <span className={styles.checkmark} />
        </label>
        <input
          className={styles.inputText}
          style={inputTextStyle} type='text'
          value={this.getOtherItemTextValue()}
          readOnly={field.readonly} onChange={this.otherValueTextChanged} />
      </div>
    )
  }

  valueChanged (e) {
    const { field, formInstance, lang } = this.props
    const value = e.target.value
    const checked = e.target.checked
    const item = this.getFieldValue(field, value, lang)
    let formInstanceValue = this.getFormInstanceFieldValue()
    if (checked) {
      if (formInstanceValue === null) {
        formInstanceValue = this.initNewFormInstanceField()
        formInstance.values.push(formInstanceValue)
      }
      formInstanceValue.keyValues = []
      formInstanceValue.keyValues.push({ key: item.value, value: item.label })
      let values = []
      values.push(formInstanceValue)
      this.props.setFormInstance(formInstance)
      this.props.saveFormInstance(formInstance.id, values)
    } else {
      if (formInstanceValue !== null) {
        this.popKeyValue(formInstanceValue, item.value)
        let values = []
        values.push(formInstanceValue)
        this.props.setFormInstance(formInstance)
        this.props.saveFormInstance(formInstance.id, values)
      }
    }
    this.forceUpdate()
  }

  otherValueChanged (e) {
    const { formInstance } = this.props
    const checked = e.target.checked
    let formInstanceValue = this.getFormInstanceFieldValue()
    if (checked) {
      if (formInstanceValue === null) {
        formInstanceValue = this.initNewFormInstanceField()
        formInstance.push(formInstanceValue)
      }
      formInstanceValue.keyValues = []
      formInstanceValue.keyValues.push({ key: '-1', value: this.oldOtherValueText })
      let values = []
      values.push(formInstanceValue)
      this.props.setFormInstance(formInstance)
      this.props.saveFormInstance(formInstance.id, values)
    } else {
      if (formInstanceValue !== null) {
        this.oldOtherValueText = this.getOtherItemTextValue()
        this.popKeyValue(formInstanceValue, '-1')
        let values = []
        values.push(formInstanceValue)
        this.props.setFormInstance(formInstance)
        this.props.saveFormInstance(formInstance.id, values)
      }
    }
    this.forceUpdate()
  }

  otherValueTextChanged (e) {
    const text = e.target.value
    this.oldOtherValueText = text
    const { formInstance } = this.props
    let formInstanceValue = this.getFormInstanceFieldValue()
    let item = this.getOtherItemKeyValue(formInstanceValue)
    if (!item) {
      return
    }
    item.value = text
    let values = []
    values.push(formInstanceValue)
    this.props.setFormInstance(formInstance)
    this.props.saveFormInstance(formInstance.id, values)
    this.forceUpdate()
  }

  getOtherItemTextValue () {
    let formInstanceValue = this.getFormInstanceFieldValue()
    let item = this.getOtherItemKeyValue(formInstanceValue)
    if (!item) {
      return ''
    }
    return item.value
  }

  getOtherItemKeyValue (formInstanceValue) {
    if (!formInstanceValue) {
      return null
    }
    for (let index = 0; index < formInstanceValue.keyValues.length; index++) {
      const element = formInstanceValue.keyValues[index]
      if (element.key === '-1') {
        return element
      }
    }
    return null
  }

  getFieldValue (field, value, lang) {
    for (let index = 0; index < field.values.length; index++) {
      const item = field.values[index]
      if (item.lang === lang && item.value === value) {
        return item
      }
    }
    return null
  }

  popKeyValue (formInstanceValue, key) {
    for (let index = 0; index < formInstanceValue.keyValues.length; index++) {
      const kv = formInstanceValue.keyValues[index]
      if (kv.key === key) {
        formInstanceValue.keyValues.splice(index, 1)
        return
      }
    }
  }

  initNewFormInstanceField () {
    const { field } = this.props
    return { fieldId: field.id, key: null, value: null, keyValues: [] }
  }

  getFormInstanceFieldValue () {
    const { field, formInstance } = this.props
    if (!formInstance) {
      return null
    }
    for (let index = 0; index < formInstance.values.length; index++) {
      const element = formInstance.values[index]
      if (element.fieldId === field.id) {
        return element
      }
    }
    return null
  }

  getValues (values, lang) {
    let array = []
    if (!values || values.length === 0) return []
    if (values[0].lang !== undefined) {
      for (let index = 0; index < values.length; index++) {
        const element = values[index]
        if (element.lang === lang) {
          array.push(element)
        }
      }
      return array
    } else {
      for (let index = 0; index < values.length; index++) {
        const element = values[index]
        array.push(element)
      }
      return array
    }
  }
}

RadioButtonGroupField.propTypes = {
  lang: PropTypes.string.isRequired,
  formInstance: PropTypes.object,
  setFormInstance: PropTypes.func.isRequired,
  saveFormInstance: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  formInstance: state.formulario.formInstance,
  lang: state.linguas.lang
})

const mapDispatchToProps = { setFormInstance, saveFormInstance }

export default connect(mapStateToProps, mapDispatchToProps)(RadioButtonGroupField)
