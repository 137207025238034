/* eslint-env browser */
import axios from 'axios'
import { notify } from 'react-notify-toast'

import { host, computeLink, notifyFo } from '../request'
import store from '../store'

const SET_FORMULARIO = 'cmlf/formulario/SET_FORMULARIO'
const FETCH_FORMULARIO = 'cmlf/formulario/FETCH_FORMULARIO'
const SET_PAGES = 'cmlf/formulario/SET_PAGES'
const SET_CURRENT_PAGE = 'cmlf/formulario/SET_CURRENT_PAGE'
const FETCH_FORM_INSTANCE = 'cmlf/formulario/FETCH_FORM_INSTANCE'
// const SAVE_FORM_INSTANCE = 'cmlf/formulario/SAVE_FORM_INSTANCE'
const SET_FORM_INSTANCE = 'cmlf/formulario/SET_FORM_INSTANCE'
const FETCH_RESUMO = 'cmlf/formulario/FETCH_RESUMO'
const SET_FORM_INSTANCES = 'cmlf/formulario/SET_FORM_INSTANCES'
const SET_SHOW_ERRORS = 'cmlf/formulario/SET_SHOW_ERRORS'
const SAVING_FORM = 'cmlf/formulario/SAVING_FORM'
const SAVING_SUCCESS = 'cmlf/formulario/SAVING_SUCCESS'
const SET_STUFF_TO_SAVE = 'cmlf/formulario/SET_STUFF_TO_SAVE'

const initialState = {
  formulario: null,
  pages: [],
  currentPage: 0,
  formInstance: null,
  resumo: null,
  formInstances: [],
  showErrors: false,
  showOnlyResumo: false,
  autoSave: false,
  savingForm: false,
  savingSuccess: false,
  stuffToSave: false
}

export const setFormulario = (formulario) => ({ type: SET_FORMULARIO, formulario })
export const setFormInstance = (formInstance) => ({ type: SET_FORM_INSTANCE, formInstance })
export const setCurrenPage = (page) => ({ type: SET_CURRENT_PAGE, page })
export const setShowErrors = (showErrors) => ({ type: SET_SHOW_ERRORS, showErrors })
export const setSavingForm = (value) => ({ type: SAVING_FORM, value })
export const setSavingSuccess = (value) => ({ type: SAVING_SUCCESS, value })
export const setStuffToSave = (value) => ({ type: SET_STUFF_TO_SAVE, value })

export function fetchFormInstances() {
  return (dispatch) => {
    axios.get(host + '/api/user/formInstances/')
      .then(res => res.data)
      .then(post => {
        post.forEach(formInstance => {
          formInstance.formulario.type = 'formulario'
          formInstance.link = computeLink(formInstance.formulario) + '/' + formInstance.id
        })
        dispatch({
          type: SET_FORM_INSTANCES,
          payload: post
        })
      })
      .catch((error) => {
        console.log(error)
        dispatch({
          type: SET_FORM_INSTANCES,
          payload: null
        })
      })
  }
}

export function fetchFormulario(permalink) {
  return (dispatch) => {
    axios.get(host + '/api/public/getObject/formulario/' + permalink)
      .then(res => res.data)
      .then(post => {
        post.link = computeLink(post)
        dispatch({
          type: FETCH_FORMULARIO,
          payload: post
        })
        dispatch({ type: SET_CURRENT_PAGE, page: 0 })
        let pages = buildPages(post)
        dispatch({ type: SET_PAGES, pages })
      })
      .catch((error) => {
        console.log(error)
        dispatch({
          type: FETCH_FORMULARIO,
          payload: null
        })
      })
  }
}

export function fetchFormInstance(id) {
  return (dispatch) => {
    axios.get(host + '/api/user/formInstance/' + id)
      .then(res => res.data)
      .then(post => {
        post.newObs = ''
        dispatch({
          type: FETCH_FORM_INSTANCE,
          payload: post
        })
      })
      .catch((error) => {
        console.log(error)
        dispatch({
          type: FETCH_FORM_INSTANCE,
          payload: null
        })
      })
  }
}

export function saveAndFetchResumo(id) {
  return (dispatch) => {
    let state = store.getState()
    if (state.formulario.stuffToSave) {
      dispatch({
        type: SET_STUFF_TO_SAVE,
        value: false
      })
      const values = state.formulario.formInstance.values
      axios.put(host + '/api/user/formInstance/' + id, { id: id, values: values })
        .then(res => res.data)
        .then(post => {
          axios.get(host + '/api/user/formInstance/' + id + '/resumo')
            .then(res => res.data)
            .then(post => {
              dispatch({
                type: FETCH_RESUMO,
                payload: post
              })
            })
            .catch((error) => {
              console.log(error)
              dispatch({
                type: FETCH_RESUMO,
                payload: null
              })
            })
        })
        .catch((error) => {
          console.log(error)
          dispatch({
            type: SET_STUFF_TO_SAVE,
            value: true
          })
        })
    } else {
      axios.get(host + '/api/user/formInstance/' + id + '/resumo')
        .then(res => res.data)
        .then(post => {
          dispatch({
            type: FETCH_RESUMO,
            payload: post
          })
        })
        .catch((error) => {
          console.log(error)
          dispatch({
            type: FETCH_RESUMO,
            payload: null
          })
        })
    }
  }

}

export function fetchResumo(id) {
  return (dispatch) => {
    axios.get(host + '/api/user/formInstance/' + id + '/resumo')
      .then(res => res.data)
      .then(post => {
        dispatch({
          type: FETCH_RESUMO,
          payload: post
        })
      })
      .catch((error) => {
        console.log(error)
        dispatch({
          type: FETCH_RESUMO,
          payload: null
        })
      })
  }
}

export function saveFormInstance(id, values, forceSave) {
  return (dispatch) => {
    if (!forceSave && !store.getState().formulario.autoSave) {
      dispatch({
        type: SET_STUFF_TO_SAVE,
        value: true
      })
    } else {
      axios.put(host + '/api/user/formInstance/' + id, { id: id, values: values })
        .then(res => res.data)
        .then(post => {
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }
}

export function saveAllValues() {
  return (dispatch) => {
    dispatch({
      type: SET_STUFF_TO_SAVE,
      value: false
    })
    let state = store.getState()
    const id = state.formulario.formInstance.id
    const values = state.formulario.formInstance.values
    axios.put(host + '/api/user/formInstance/' + id, { id: id, values: values })
      .then(res => res.data)
      .then(post => {

      })
      .catch((error) => {
        console.log(error)
        dispatch({
          type: SET_STUFF_TO_SAVE,
          value: true
        })
      })
  }
}

export function saveAndFetchFormInstance(id, values) {
  return (dispatch) => {
    axios.put(host + '/api/user/formInstance/' + id, { id: id, values: values })
      .then(res => res.data)
      .then(post => {
      })
      .then(
        axios.get(host + '/api/user/formInstance/' + id)
          .then(res => res.data)
          .then(post => {
            post.newObs = ''
            dispatch({
              type: FETCH_FORM_INSTANCE,
              payload: post
            })
          })
          .catch((error) => {
            console.log(error)
            dispatch({
              type: FETCH_FORM_INSTANCE,
              payload: null
            })
          })
      )
      .catch((error) => {
        console.log(error)
      })
  }
}

export function addFilesToFormInstance(id, values) {
  return (dispatch) => {
    axios.put(host + '/api/user/formInstance/' + id + '/addFiles', { id: id, values: values })
      .then(res => res.data)
      .then(post => {
      })
      .catch((error) => {
        console.log(error)
      })
  }
}

export function removeFilesFromFormInstance(id, values) {
  return (dispatch) => {
    axios.put(host + '/api/user/formInstance/' + id + '/removeFiles', { id: id, values: values })
      .then(res => res.data)
      .then(post => {
      })
      .catch((error) => {
        console.log(error)
      })
  }
}

export function submitFormInstance(id, obs, lang) {
  return (dispatch) => {
    dispatch({
      type: SET_STUFF_TO_SAVE,
      value: false
    })
    let state = store.getState()
    const values = state.formulario.formInstance.values
    axios.put(host + '/api/user/formInstance/' + id, { id: id, values: values })
      .then(res => res.data)
      .then(post => {
        const langStr = !lang ? '' : '?lang=' + lang
        axios.put(host + '/api/user/formInstance/' + id + '/submit' + langStr, { obs: obs })
          .then(res => res.data)
          .then(post => {
            dispatch({
              type: SET_FORM_INSTANCE,
              payload: null
            })
            // notificação visual formulário submetido com sucesso
            let myColor = { background: 'rgba(8,53,68,0.7)', text: '#FFFFFF' }
            let mensagem = 'Formulário submetido com sucesso\n' +
              'Acompanhe o estado do seu pedido no backoffice da sua conta\n' +
              'Dúvidas/questões, envie email para requerimentos@cmlajesdasflores.pt'
            setTimeout(() => {
              notify.show(mensagem, 'custom', 8000, myColor)
            }, 300)
            axios.get(host + '/api/user/formInstances/')
              .then(res => res.data)
              .then(post => {
                post.forEach(formInstance => {
                  formInstance.formulario.type = 'formulario'
                  formInstance.link = computeLink(formInstance.formulario) + '/' + formInstance.id
                })
                dispatch({
                  type: SET_FORM_INSTANCES,
                  payload: post
                })
              })
          })
      })
      .catch((error) => {
        console.log(error)
        dispatch({
          type: SET_STUFF_TO_SAVE,
          value: true
        })
        dispatch({
          type: SET_FORM_INSTANCES,
          payload: null
        })
      })
    // const langStr = !lang ? '' : '?lang=' + lang
    // axios.put(host + '/api/user/formInstance/' + id + '/submit' + langStr, { obs: obs })
    //   .then(res => res.data)
    //   .then(post => {
    //     dispatch({
    //       type: SET_FORM_INSTANCE,
    //       payload: null
    //     })
    //     axios.get(host + '/api/user/formInstances/')
    //       .then(res => res.data)
    //       .then(post => {
    //         post.forEach(formInstance => {
    //           formInstance.formulario.type = 'formulario'
    //           formInstance.link = computeLink(formInstance.formulario) + '/' + formInstance.id
    //         })
    //         dispatch({
    //           type: SET_FORM_INSTANCES,
    //           payload: post
    //         })
    //       })
    //       .catch((error) => {
    //         console.log(error)
    //         dispatch({
    //           type: SET_FORM_INSTANCES,
    //           payload: null
    //         })
    //       })
    //   })
    //   .catch((error) => {
    //     console.log(error)
    //   })
  }
}

export function removeFormInstance(formInstanceId, formId, props) {
  return (dispatch) => {
    axios.delete(host + '/api/user/formInstance/' + formInstanceId)
      .then(() => {
        props.fetchInfoFormInProgress(formId)
      })
      .catch((error) => {
        if (!error.response.data.error_description) {
          console.log(error)
        } else {
          notifyFo({ text: error.response.data.error_description })
        }
        // console.log(error)
      })
  }
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_FORMULARIO:
      return { ...state, formulario: action.formulario }
    case FETCH_FORMULARIO:
      return { ...state, formulario: action.payload }
    case SET_PAGES:
      return { ...state, pages: action.pages }
    case SET_CURRENT_PAGE:
      return { ...state, currentPage: action.page }
    case FETCH_FORM_INSTANCE:
      return { ...state, formInstance: action.payload }
    case SET_FORM_INSTANCE:
      let newFormInstance = { ...action.formInstance }
      return { ...state, formInstance: newFormInstance }
    case FETCH_RESUMO:
      return { ...state, resumo: action.payload }
    case SET_FORM_INSTANCES:
      return { ...state, formInstances: action.payload }
    case SET_SHOW_ERRORS:
      return { ...state, showErrors: action.showErrors }
    case SET_STUFF_TO_SAVE:
      return { ...state, stuffToSave: action.value }
    default:
      return state
  }
}

export function okToSubmit(formInstance) {
  if (!formInstance) return false
  let result = true
  formInstance.values.forEach(value => {
    const field = getFieldDef(value, formInstance)
    if (field.required) {
      const valor = value.value
      const kvs = value.keyValues
      const files = value.files
      const rows = value.rows
      let hasValue = false
      if (valor !== null && valor.trim() !== '') {
        hasValue = true
      }
      if (kvs && kvs.length > 0) {
        hasValue = true
      }
      if (files && files.length > 0) {
        hasValue = true
      }
      if (rows && rows.length) {
        hasValue = true
      }
      if (!hasValue) result = false
    }
  })
  return result
}

function getFieldDef(value, formInstance) {
  const fieldDefs = formInstance.formulario.campos
  let result = null
  fieldDefs.forEach(field => {
    if (field.id === value.fieldId) {
      result = field
    }
  })
  return result
}

function buildPages(formulario) {
  let pages = []
  let page = []
  for (let index = 0; index < formulario.campos.length; index++) {
    const element = formulario.campos[index]
    if (index === 0 && element.type === 'pageSeparator') {
      continue
    }
    if (element.type === 'pageSeparator') {
      pages.push(page)
      page = []
    } else {
      page.push(element)
    }
  }
  pages.push(page)
  return pages
}
