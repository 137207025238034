import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { getTranslation } from '../../../request'
import commonStyles from './CommonStyles.css'
import { setFormInstance, saveFormInstance } from '../../../modules/formulario'
import Label from './Label'
import ErrorField from './ErrorField'
import msg from '../../App/messages'

class InputTextField extends Component {
  constructor(props) {
    super(props)
    this.valueChanged = this.valueChanged.bind(this)
    this.validPattern = true
    this.errorMsg = null
  }

  render () {
    const { field, lang, formInstance } = this.props
    return (
      <div className={commonStyles.labelWrapper}>
        <Label {...this.props} />
        {this.buildInput(field, formInstance, lang)}
        {
          !this.validPattern
            ? <ErrorField msg={this.errorMsg} />
            : null
        }
      </div>
    )
  }

  buildInput (field, formInstance, lang) {
    return (
      <input
        ref={this.myRef}
        className={commonStyles.inputText}
        type={types[field.fieldType]}
        placeholder={getTranslation(field.placeholder, lang)}
        maxLength={field.maxLength === 0 ? 524288 : field.maxLength}
        readOnly={field.readonly}
        onChange={(e) => this.valueChanged(e, field, formInstance)}
        onBlur={(e) => this.validatePattern(field)}
        value={this.getValue(field, formInstance)} />
    )
  }

  getValue (field, formInstance) {
    if (!formInstance || !formInstance.values) {
      return ''
    }
    for (let index = 0; index < formInstance.values.length; index++) {
      const element = formInstance.values[index]
      if (element.fieldId === field.id) {
        return element.value
      }
    }
    return ''
  }

  valueChanged (e, field, formInstance) {
    this.setValue(field, formInstance, e.target.value)
    this.props.saveFormInstance(formInstance.id, [{ fieldId: field.id, value: e.target.value }])
  }

  setValue (field, formInstance, value) {
    if (!formInstance) {
      return
    }
    for (let index = 0; index < formInstance.values.length; index++) {
      const element = formInstance.values[index]
      if (element.fieldId === field.id) {
        element.value = value
        this.props.setFormInstance(formInstance)
        return
      }
    }
    formInstance.values.push({ fieldId: field.id, value: value })
    this.props.setFormInstance(formInstance)
  }

  validatePattern (field) {
    if (!field.pattern || field.pattern === '') {
      this.validPattern = true
      this.forceUpdate()
      return
    }
    const { formInstance, lang } = this.props
    const value = this.getValue(field, formInstance)
    let regexp = new RegExp(field.pattern)
    if (regexp.test(value)) {
      this.validPattern = true
      this.forceUpdate()
      return
    }
    this.validPattern = false
    this.errorMsg = msg[lang]['global.patternError']
    this.forceUpdate()
  }
}

const types = ['text', 'password', 'email', 'color', 'tel']

InputTextField.propTypes = {
  lang: PropTypes.string.isRequired,
  formInstance: PropTypes.object,
  setFormInstance: PropTypes.func.isRequired,
  saveFormInstance: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  formInstance: state.formulario.formInstance,
  lang: state.linguas.lang
})

const mapDispatchToProps = { setFormInstance, saveFormInstance }

export default connect(mapStateToProps, mapDispatchToProps)(InputTextField)
