/* eslint-env browser */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import commonStyles from './CommonStyles.css'
import { setFormInstance, saveFormInstance, addFilesToFormInstance, saveAndFetchFormInstance } from '../../../modules/formulario'
import Label from './Label'
import styles from './FileUploadField.css'
import { generateUUID } from '../../../request'

class FileUploadField extends Component {
  constructor(props){
    super(props)
    this.valueChanged = this.valueChanged.bind(this)
  }

  render () {
    const { field, lang, formInstance } = this.props
    return (
      <div className={commonStyles.labelWrapper}>
        <Label {...this.props} />
        {this.buildInput(field, formInstance, lang)}
        {this.buildFileList(field, formInstance)}
      </div>
    )
  }

  buildInput (field, formInstance, lang) {
    return (
      <input
        className={commonStyles.fileUpload}
        type='file'
        multiple={field.multipleFiles}
        name='file'
        accept={this.getAcceptedFiles(field, lang)}
        onChange={(e) => this.valueChanged(e, field, formInstance)} />
    )
  }

  buildFileList (field, formInstance) {
    const value = this.getValue(field, formInstance)
    if (!value || !value.files || value.files.length === 0) {
      return null
    }
    return (
      <div className={styles.fileListContainer}>
        {
          value.files.map((file, index) => (
            <div key={index} className={styles.fileContainer}>
              <div className={styles.filenameContainer}>
                {file.filename}
              </div>
              <div className={styles.removeFileIcon}
                onClick={() => this.removeFile(value.files, file)} />
            </div>
          ))
        }
      </div>
    )
  }

  getAcceptedFiles (field, lang) {
    let accept = ''
    field.values.forEach(element => {
      if (element.lang === lang) {
        accept += accept === '' ? element.value : ',' + element.value
      }
    })
    return accept
  }

  getValue (field, formInstance) {
    if (!formInstance || !formInstance.values) {
      return null
    }
    for (let index = 0; index < formInstance.values.length; index++) {
      const element = formInstance.values[index]
      if (element.fieldId === field.id) {
        return element
      }
    }
    return null
  }

  valueChanged (e, field, formInstance) {
    let files = e.target.files
    let filesStruct = {
      count: files.length,
      files: []
    }
    for (let index = 0; index < files.length; index++) {
      const file = files[index]
      let reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = (e) => {
        const mediaFile = {
          id: generateUUID(),
          filename: file.name,
          mediaType: file.type,
          file: e.target.result
        }
        filesStruct.files.push(mediaFile)
        this.processFiles(filesStruct, field, formInstance)
      }
    }
  }

  removeFile (files, file) {
    if (!files || !file) {
      return
    }
    const { field, formInstance } = this.props
    let filesStruct = {
      count: files.length - 1,
      files: []
    }
    for (let index = 0; index < files.length; index++) {
      const element = files[index]
      if (element.id !== file.id) {
        const mediaFile = {
          filename: element.filename,
          mediaType: element.mediaType,
          file: null, // o ficheiro já existe
          id: element.id
        }
        filesStruct.files.push(mediaFile)
      }
    }
    this.processFiles(filesStruct, field, formInstance, true)
  }

  processFiles (filesStruct, field, formInstance, removeFiles = false) {
    if (filesStruct.count === filesStruct.files.length) {
      let formInstanceValue = this.getFormInstanceFieldValue()
      if (formInstanceValue === null) {
        formInstanceValue = this.initNewFormInstanceField()
        formInstance.values.push(formInstanceValue)
      }
      if (!field.multipleFiles) {
        formInstanceValue.files = filesStruct.files
      } else {
        if (!formInstanceValue.files || formInstanceValue.files.length === 0) {
          formInstanceValue.files = filesStruct.files
        } else {
          if (removeFiles) {
            formInstanceValue.files = filesStruct.files
          } else {
            for (let index = 0; index < filesStruct.files.length; index++) {
              const element = filesStruct.files[index]
              formInstanceValue.files.push(element)
            }
          }
        }
      }
      let values = []
      if (!field.multipleFiles || removeFiles) {
        values.push(formInstanceValue)
        this.props.setFormInstance(formInstance)
        this.props.saveFormInstance(formInstance.id, values)
        // this.props.saveAndFetchFormInstance(formInstance.id, values)
      } else {
        let formInstanceValueCopy = { ...formInstanceValue, files: [] }
        formInstanceValueCopy.files = filesStruct.files
        values.push(formInstanceValueCopy)
        this.props.setFormInstance(formInstance)
        this.props.addFilesToFormInstance(formInstance.id, values)
        // this.props.saveAndFetchFormInstance(formInstance.id, values)
      }
    }
  }

  initNewFormInstanceField () {
    const { field } = this.props
    return { fieldId: field.id, key: null, value: null, keyValues: [], mediaFile: null }
  }

  getFormInstanceFieldValue () {
    const { field, formInstance } = this.props
    if (!formInstance) {
      return null
    }
    for (let index = 0; index < formInstance.values.length; index++) {
      const element = formInstance.values[index]
      if (element.fieldId === field.id) {
        return element
      }
    }
    return null
  }
}

FileUploadField.propTypes = {
  lang: PropTypes.string.isRequired,
  formInstance: PropTypes.object,
  setFormInstance: PropTypes.func.isRequired,
  saveFormInstance: PropTypes.func.isRequired,
  addFilesToFormInstance: PropTypes.func.isRequired,
  saveAndFetchFormInstance: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  formInstance: state.formulario.formInstance,
  lang: state.linguas.lang
})

const mapDispatchToProps = { setFormInstance, saveFormInstance, addFilesToFormInstance, saveAndFetchFormInstance }

export default connect(mapStateToProps, mapDispatchToProps)(FileUploadField)
