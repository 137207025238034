import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import styles from './NavResults.css'
import { goFirstPage, goLastPage, goNextPage, goPreviousPage, goPage, setPageListStartPage } from '../../modules/search'

class NavResults extends Component {
  constructor(props) {
    super(props)
    this.goFirstPage = this.goFirstPage.bind(this)
    this.goLastPage = this.goLastPage.bind(this)
    this.goNextPage = this.goNextPage.bind(this)
    this.goPreviousPage = this.goPreviousPage.bind(this)
    this.onChangeCurrentPage = this.onChangeCurrentPage.bind(this)
  }

  render () {
    const { currentPage, totalPages, pageListSize, pageListStartPage } = this.props
    if (totalPages <= 1) {
      return null
    }
    let count = this.getPageCount(pageListSize, totalPages)
    let style = { gridTemplateColumns: '20px 20px repeat(' + count + ', min-content) 20px 20px auto' }
    // style = {}
    return (
      <div className={styles.paginationBlock} style={style}>
        <input
          className={styles.pageCell + ' ' + styles.input + ' ' + styles.goFirstPage}
          type='button'
          onClick={this.goFirstPage}
          disabled={!this.props.canFirstPage} />
        <input
          className={styles.pageCell + ' ' + styles.input + ' ' + styles.goPreviousPage}
          type='button'
          onClick={this.goPreviousPage}
          disabled={!this.props.canPreviousPage} />
        {this.buildPageList(pageListSize, currentPage, totalPages, pageListStartPage)}
        <input
          className={styles.pageCell + ' ' + styles.input + ' ' + styles.goNextPage}
          type='button'
          onClick={this.goNextPage}
          disabled={!this.props.canNextPage} />
        <input
          className={styles.pageCell + ' ' + styles.input + ' ' + styles.goLastPage}
          type='button'
          onClick={this.goLastPage}
          disabled={!this.props.canLastPage} />
      </div>
    )
  }

  getPageCount (pageListSize, totalPages) {
    return totalPages > pageListSize ? pageListSize : totalPages
  }

  buildPageList (pageListSize, currentPage, totalPages, pageListStartPage) {
    let count = this.getPageCount(pageListSize, totalPages)
    let result = []
    for (let index = 0; index < count; index++) {
      let pageNumber = index + pageListStartPage
      result.push(<div
        key={pageNumber}
        className={styles.pageCell + ' ' + styles.pageNumber +
          (pageNumber === currentPage ? ' ' + styles.selected : '')}
        onClick={(e) => this.onChangeCurrentPage(e, pageNumber)}>
        {pageNumber}
      </div>)
    }
    return result
  }

  goFirstPage (e) {
    this.props.goFirstPage()
  }

  goLastPage (e) {
    this.props.goLastPage()
  }

  goNextPage (e) {
    this.props.goNextPage()
  }

  goPreviousPage (e) {
    this.props.goPreviousPage()
  }

  onChangeCurrentPage (e, page) {
    e.stopPropagation()
    const { currentPage } = this.props
    if (page !== currentPage) {
      this.props.goPage(page)
    }
    // let text = e.target.value
    // if (!isNaN(parseInt(text))) {
    // } else {
    //   e.target.value = text
    // }
  }
}

NavResults.propTypes = {
  lang: PropTypes.string.isRequired,
  goFirstPage: PropTypes.func.isRequired,
  goLastPage: PropTypes.func.isRequired,
  goNextPage: PropTypes.func.isRequired,
  goPreviousPage: PropTypes.func.isRequired,
  goPage: PropTypes.func.isRequired,
  pageListSize: PropTypes.number.isRequired,
  pageListStartPage: PropTypes.number.isRequired,
  setPageListStartPage: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  canFirstPage: state.search.canFirstPage,
  canLastPage: state.search.canLastPage,
  canNextPage: state.search.canNextPage,
  canPreviousPage: state.search.canPreviousPage,
  currentPage: state.search.currentPage,
  totalPages: state.search.totalPages,
  pageListSize: state.search.pageListSize,
  pageListStartPage: state.search.pageListStartPage,
  lang: state.linguas.lang
})

const mapDispatchToProps = { goFirstPage, goLastPage, goNextPage, goPreviousPage, goPage, setPageListStartPage }

export default connect(mapStateToProps, mapDispatchToProps)(NavResults)
