import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { FormattedMessage as FM } from 'react-intl'

import { setResultSize } from '../../../modules/navigation'
import { filterCategoriasFromObjectos } from '../../../modules/categorias'
import styles from './Documentos.css'
import { getDateStr, isOdd } from '../../../lib/utils'

class DocumentosList extends Component {
  constructor(props) {
    super(props)
    this.noShadow = true
  }

  componentDidUpdate () {
    const { categorias, documentos, filterCategorias } = this.props
    this.props.setResultSize(documentos.length)
    if (filterCategorias) {
      this.props.filterCategoriasFromObjectos(documentos, categorias)
    }
  }

  render () {
    const { documentos } = this.props
    return (
      <div className={styles.layoutGrid + ' ' + styles.listOnly}>
        <div className={styles.header}><FM id='body.publications' defaultMessage='Publicações' /></div>
        <div className={styles.listContainer}>
          {this.buildDocumentsList(documentos)}
        </div>
      </div>
    )
  }

  filterObjects () {
    const { currentPage, documentos, pageSize } = this.props
    return this.getListSubSet(documentos, currentPage, pageSize)
  }

  buildDocumentsList (docs) {
    if (!docs || docs.length === 0) {
      return null
    }
    const { lang } = this.props
    return (
      <ul className={styles.ul}>
        {
          docs.map((doc, index) => (
            <li key={doc.id}>
              <a className={styles.li + ' ' + styles.file + (isOdd(this.globalIndex++) ? ' ' + styles.odd : '')}
                href={doc.downloadLink} target='_blank'>
                <div className={styles.icon + ' ' + styles.documento} />
                <div className={styles.text}>{doc.obj.titulo}</div>
                <div className={styles.action + ' ' + styles.download} />
                <div className={styles.size}>{this.getSizeString(doc.fileSizeKb, lang)}</div>
                <div className={styles.data}>{getDateStr(doc.obj.publicationDate, lang)}</div>
              </a>
            </li>
          ))
        }
      </ul>
    )
  }

  getSizeString (number, lang) {
    if (!number || !lang) {
      return null
    }
    if (number > 1000) {
      return Math.floor(number / 1024).toLocaleString(lang) + ' MB'
    } else {
      return number.toLocaleString(lang) + ' KB'
    }
  }

  getListSubSet (arr, currentPage, pageSize) {
    if (!arr || arr.length === 0) {
      return []
    }
    const start = (currentPage - 1) * pageSize
    const end = start + pageSize
    if (arr.length < end) {
      return arr.slice(start)
    }
    return arr.slice(start, end)
  }
}

DocumentosList.propTypes = {
  documentos: PropTypes.array.isRequired,
  currentPage: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  lang: PropTypes.string.isRequired,
  setResultSize: PropTypes.func.isRequired,
  filterCategoriasFromObjectos: PropTypes.func.isRequired,
  categorias: PropTypes.array.isRequired
}

const mapStateToProps = state => ({
  documentos: state.documentos.documentos,
  currentPage: state.navigation.currentPage,
  pageSize: state.navigation.pageSize,
  lang: state.linguas.lang,
  categorias: state.categorias.categorias
})

const mapDispatchToProps = { setResultSize, filterCategoriasFromObjectos }

export default connect(mapStateToProps, mapDispatchToProps)(DocumentosList)
