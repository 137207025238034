import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
// import { FormattedMessage as FM } from 'react-intl'

import styles from './Destaques.css'
import spinner from '../../css/Spinner.css'
import indexStyles from '../../index.css'

import { fetchDestaques, setCurrentDestaqueIndex, setAutoPlaySlides } from '../../modules/destaques'
import msg from '../App/messages'

class Destaques extends Component {
  constructor(props) {
    super(props)
    this.selectorsList = []
    this.selectDestaque = this.selectDestaque.bind(this)
    this.autoSelectDestaque = this.autoSelectDestaque.bind(this)
    this.props.fetchDestaques(this.props.lang)
  }

  componentDidUpdate () {
    const { playSlides, currentDestaqueIndex } = this.props
    if (playSlides) {
      this.timer(currentDestaqueIndex)
    }
  }

  timer (index) {
    setTimeout(() => {
      this.autoSelectDestaque(index + 1)
    }, 5000)
  }

  render () {
    const { lang, currentDestaque, currentDestaqueIndex, destaquesList, destaquesAreLoading } = this.props
    if (destaquesAreLoading || !currentDestaque) {
      return (
        <div className={styles.container}>
          <div className={styles.top} />
          <div className={styles.body}>
            <div className={styles.textContainer}>
              <div className={styles.titleText}>{msg[lang]['destaques.highlights']}</div>
            </div>
            <div className={styles.destaqueContainer + ' ' + indexStyles.shadow}>
              <div className={spinner.loader} />
            </div>
          </div>
          <div className={styles.bottom}>
            <div className={styles.selectorWrapper} />
          </div>
        </div>
      )
    }
    this.selectorsList = this.buildSelectorList(currentDestaqueIndex, destaquesList)
    const link = !currentDestaque ? '/' : currentDestaque.permalink
    return (
      <div className={styles.container}>
        <div className={styles.top} />
        <div className={styles.body}>
          <div className={styles.textContainer}>
            <div className={styles.titleText}>{msg[lang]['destaques.highlights']}</div>
          </div>
          <div className={styles.destaqueContainer + ' ' + indexStyles.shadow}>
            <Link className={styles.link} to={link}>
              <img className={styles.destaqueImg} src={currentDestaque.image} alt='' />
              {this.buildInfoWWrapper(currentDestaque)}
            </Link>
          </div>
        </div>
        <div className={styles.bottom}>
          <div className={styles.selectorWrapper}>
            <div className={styles.selectorDiv}>
              <ul className={styles.list}>
                {this.selectorsList}
              </ul>
            </div>
          </div>
        </div>
      </div>
    )
  }

  buildInfoWWrapper (currentDestaque) {
    if (!currentDestaque) {
      return null
    }
    if (currentDestaque.type === 'event' || currentDestaque.type === 'news') {
      return (
        <div className={styles.destaqueInfoWrapper}>
          <div className={styles.destaqueTitle + ' ' + styles.destaqueInfoText}>{currentDestaque.title}</div>
          {/* <div className={styles.destaqueText + ' ' + styles.destaqueInfoText}>{this.props.currentDestaque.text}</div> */}
          <div className={styles.destaqueDate + ' ' + styles.destaqueInfoText}>
            {currentDestaque.date}
          </div>
        </div>
      )
    } else {
      return (
        <div className={styles.destaqueInfoWrapperNoDate}>
          <div className={styles.destaqueTitle + ' ' + styles.destaqueInfoText + ' ' + styles.noDate}>
            {currentDestaque.title}
          </div>
        </div>
      )
    }
  }

  buildSelectorList (index, list) {
    let selList = []
    for (let i = 0; i < list.length; i++) {
      if (i === index) {
        selList.push(<li id={i} key={i} className={styles.li + ' ' + styles.bullet + ' ' + styles.selectedBullet} onClick={this.selectDestaque} />)
      } else {
        selList.push(<li id={i} key={i} className={styles.li + ' ' + styles.bullet + ' ' + styles.otherBullet} onClick={this.selectDestaque} />)
      }
    }
    return selList
  }

  autoSelectDestaque (index) {
    const { destaquesList, playSlides } = this.props
    if (!playSlides) {
      return
    }
    if (!destaquesList) {
      return
    }
    if (index >= destaquesList.length) {
      index = 0
    }
    this.selectorsList = this.buildSelectorList(index, this.props.destaquesList)
    this.props.setCurrentDestaqueIndex(index)
  }

  selectDestaque (e) {
    let index = parseInt(e.target.id, 0)
    this.selectorsList = this.buildSelectorList(index, this.props.destaquesList)
    this.props.setCurrentDestaqueIndex(index)
    clearTimeout(this.timer)
    this.props.setAutoPlaySlides(false)
  }
}

Destaques.propTypes = {
  fetchDestaques: PropTypes.func.isRequired,
  setCurrentDestaqueIndex: PropTypes.func.isRequired,
  destaquesList: PropTypes.array.isRequired,
  // currentDestaque: PropTypes.object.isNotRequired,
  currentDestaqueIndex: PropTypes.number.isRequired,
  lang: PropTypes.string.isRequired,
  playSlides: PropTypes.bool.isRequired,
  setAutoPlaySlides: PropTypes.func.isRequired,
  destaquesAreLoading: PropTypes.bool.isRequired
}

const mapStateToProps = state => ({
  destaquesList: state.destaques.destaquesList,
  currentDestaque: state.destaques.currentDestaque,
  currentDestaqueIndex: state.destaques.currentDestaqueIndex,
  lang: state.linguas.lang,
  playSlides: state.destaques.playSlides,
  destaquesAreLoading: state.destaques.destaquesAreLoading
})

const mapDispatchToProps = { fetchDestaques, setCurrentDestaqueIndex, setAutoPlaySlides }

export default connect(mapStateToProps, mapDispatchToProps)(Destaques)
