import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import styles from './CalendarTopMonth.css'

class CalendarTopMonth extends Component {
  render () {
    const { topDayMonth } = this.props
    return (
      <div className={styles.topDayMonth}>{this.getMonth(topDayMonth)}</div>
    )
  }
  getMonth (date) {
    if (!date) {
      return null
    }
    const { lang } = this.props
    return date.toLocaleDateString(lang, { month: 'long' })
  }
}

CalendarTopMonth.propTypes = {
  lang: PropTypes.string.isRequired,
  topDayMonth: PropTypes.object
}

const mapStateToProps = state => ({
  lang: state.linguas.lang,
  topDayMonth: state.calendar.topDayMonth
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(CalendarTopMonth)
