import store from '../store'
import { host } from '../request'
import axios from 'axios'

const SET_USER = 'cmlf/auth/SET_USER'
const SET_TOKEN = 'cmlf/auth/SET_TOKEN'
const SET_REFRESH_TOKEN = 'cmlf/auth/SET_REFRESH_TOKEN'
const LOGIN_AUTHGOV = 'cmlf/auth/LOGIN_AUTHGOV'
const SET_AUTHGOV_INVALID_CREDENTIALS = 'cmlf/auth/SET_AUTHGOV_INVALID_CREDENTIALS'

const initialState = {
  user: {
    username: null,
    firstName: null,
    lastName: null,
    isAuthGov: false,
    email: null,
    fullName: null,
    roles: null,
    authGovToken: null,
    authGovExpires: null,
    digest: null
  },
  authGovInvalidCredentials: false,
  token: null,
  refreshToken: null
}
export const setUser = (user) => ({ type: SET_USER, user })
export const setToken = (token) => ({ type: SET_TOKEN, token })
export const setRefreshToken = (token) => ({ type: SET_REFRESH_TOKEN, token })
export const setAuthGovInvalidCredentials = (bool) => ({ type: SET_AUTHGOV_INVALID_CREDENTIALS, authGovInvalidCredentials: bool })

export function getCurrentUser () {
  return store.getState().auth.user
}

export function loginAuthGov () {
  return (dispatch) => {
    axios.get(host + '/api/public/userAccount/authgov/login')
      .then(res => res.data)
      .then(data => {
        let user = {
          username: data.username,
          firstName: data.firstName,
          lastName: data.lastName,
          isAuthGov: true,
          email: data.email,
          fullName: data.fullName,
          roles: ['user', 'certified'],
          authGovToken: data.token,
          authGovExpires: data.expires
        }
        localStorage.setItem('cmlf_logout', false)
        localStorage.setItem('cmlf_login', true)
        localStorage.setItem('cmlf_loginIsAuthGov', true)
        localStorage.setItem('cmlf_user', JSON.stringify(user))
        dispatch({
          type: LOGIN_AUTHGOV,
          payload: user
        })
      })
  }
}

export function logoutAuthGov () {
  return (dispatch) => {
    axios.get(host + '/api/public/userAccount/authgov/login')
      .then(res => res.data)
      .then(data => {
        let user = {
          username: data.username,
          firstName: data.firstName,
          lastName: data.lastName,
          isAuthGov: true,
          email: data.email,
          fullName: data.fullName,
          roles: ['user', 'certified'],
          authGovToken: data.token,
          authGovExpires: data.expires
        }
        localStorage.setItem('cmlf_logout', false)
        localStorage.setItem('cmlf_login', true)
        localStorage.setItem('cmlf_loginIsAuthGov', true)
        localStorage.setItem('cmlf_user', JSON.stringify(user))
        dispatch(setUser(null))
        dispatch({
          type: LOGIN_AUTHGOV,
          payload: user
        })
      })
  }
}

export function verifyLoginCredentials (username, name, firstName, lastName, email, nif, niss, token, expires, digest) {
  return (dispatch) => {
    axios.get(host + '/api/public/userAccount/authgov/verifyCredentials?username=' +
      username + '&token=' + token + '&expires=' + expires + '&digest=' + digest)
      .then(res => res.data)
      .then(data => {
        if (!data.ok) {
          dispatch(setAuthGovInvalidCredentials(true))
        }
        let user = {
          username: username,
          firstName: firstName,
          lastName: lastName,
          isAuthGov: true,
          email: email,
          fullName: name,
          nif: nif,
          niss: niss,
          roles: ['user', 'certified'],
          authGovToken: token,
          authGovExpires: expires,
          digest: digest
        }
        localStorage.setItem('cmlf_logout', false)
        localStorage.setItem('cmlf_login', true)
        localStorage.setItem('cmlf_loginIsAuthGov', true)
        localStorage.setItem('cmlf_user', JSON.stringify(user))
        dispatch(setAuthGovInvalidCredentials(false))
        dispatch({
          type: LOGIN_AUTHGOV,
          payload: user
        })
      })
  }
}

export default function reducer (state = initialState, action = {}) {
  switch (action.type) {
    case SET_USER:
      console.log('action.user', action.user)
      if (action.user === null) {
        return { ...state, user: initUser() }
      }
      return { ...state, user: action.user }
    case SET_TOKEN:
      return { ...state, token: action.token }
    case SET_REFRESH_TOKEN:
      return { ...state, refreshToken: action.token }
    case LOGIN_AUTHGOV:
      return { ...state, user: action.payload }
    case SET_AUTHGOV_INVALID_CREDENTIALS:
      return { ...state, authGovInvalidCredentials: action.authGovInvalidCredentials }
    default:
      return state
  }
}

function initUser () {
  return {
    username: null,
    firstName: null,
    lastName: null,
    isAuthGov: false,
    email: null,
    fullName: null,
    roles: null,
    authGovToken: null,
    authGovExpires: null
  }
}