import React, { Component } from 'react'

import styles from './HrField.css'

export default class HrField extends Component {
  render () {
    return (
      <hr className={styles.style13} />
    )
  }
}
