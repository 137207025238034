import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { FormattedMessage as FM } from 'react-intl'

import common from '../common/Body.css'
import spinner from '../../../css/Spinner.css'
import { fetchLocais, setLocais } from '../../../modules/locais'
import LocaisList from './LocaisList'
import { fetchCategorias, setCurrentObjType, resetSelectedCategorias } from '../../../modules/categorias'

class Locais extends Component {
  componentDidMount () {
    // console.log('Locais did mount')
    const { lang, availableMenus, currentObjectType, availableMenusAreLoading } = this.props
    if (currentObjectType !== 'local') {
      this.props.setCurrentObjType('local')
      this.props.resetSelectedCategorias()
      this.props.fetchCategorias(lang, 'local')
    }
    if (!availableMenusAreLoading && !availableMenus.locais) {
      this.props.fetchLocais(lang)
    }
  }

  componentDidUpdate () {
    // console.log('Locais did update')
    const { selectedCategorias, lang, currentPastaContent, availableMenus, currentPasta, availableMenusAreLoading } = this.props
    if (availableMenusAreLoading) {
      return
    }
    if (selectedCategorias && selectedCategorias.length > 0) {
      this.props.fetchLocais(lang, selectedCategorias)
    } else {
      this.props.fetchCategorias(lang, 'local')
      if (availableMenus.locais) {
        if (currentPastaContent && currentPastaContent.menuType === 4) {
          this.props.setLocais(currentPastaContent.objectos, !currentPasta ? false : currentPasta.listaAleatoria)
        } else if (!currentPastaContent) {
          this.props.setLocais([])
        }
      } else {
        this.props.fetchLocais(lang)
      }
    }
  }
  render () {
    const { selectedCategorias, currentObjectType, availableMenusAreLoading } = this.props
    if (availableMenusAreLoading) {
      return (
        <div className={spinner.loader} />
      )
    }
    if (currentObjectType !== 'local') {
      return null
    }
    const updateFilteredCategorias = selectedCategorias && selectedCategorias.length > 0
    if (this.props.short === true) {
      return <LocaisList short />
    }
    return (
      <div className={common.layoutGrid}>
        <div className={common.header}>
          <FM id='body.visit' defaultMessage='Visitar' />
        </div>
        <LocaisList filterCategorias={updateFilteredCategorias} />
      </div>
    )
  }
}

Locais.propTypes = {
  fetchLocais: PropTypes.func.isRequired,
  lang: PropTypes.string.isRequired,
  currentPastaContent: PropTypes.object,
  availableMenus: PropTypes.object.isRequired,
  fetchCategorias: PropTypes.func.isRequired,
  selectedCategorias: PropTypes.array.isRequired,
  setLocais: PropTypes.func.isRequired,
  setCurrentObjType: PropTypes.func.isRequired,
  currentObjectType: PropTypes.string.isRequired,
  resetSelectedCategorias: PropTypes.func.isRequired,
  currentPasta: PropTypes.object,
  availableMenusAreLoading: PropTypes.bool.isRequired
}

const mapStateToProps = state => ({
  lang: state.linguas.lang,
  currentPasta: state.pastas.currentPasta,
  currentPastaContent: state.pastas.currentPastaContent,
  selectedCategorias: state.categorias.selectedCategorias,
  availableMenus: state.pastas.availableMenus,
  currentObjectType: state.categorias.currentObjectType,
  availableMenusAreLoading: state.pastas.availableMenusAreLoading
})

const mapDispatchToProps = { fetchLocais, setLocais, fetchCategorias, setCurrentObjType, resetSelectedCategorias }

export default connect(mapStateToProps, mapDispatchToProps)(Locais)
