import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import styles from './EventosList.css'

class EventosList extends Component {
  constructor(props) {
    super(props)
    this.markSelectedMonths = this.markSelectedMonths.bind(this)
  }

  render () {
    const eventsList = this.buildEventsList()
    // this.markSelectedMonths()
    return (
      <ul className={styles.eventListContainer + ' ' + styles.ulEvents}>
        {eventsList}
      </ul>
    )
  }

  buildEventsList () {
    let array = []
    let even = true
    this.props.events.slice(0, 5).map((element, index) => {
      let strDate = this.getDateText(element.start, element.end, this.props.lang)
      let link = !element ? '/' : element.link
      if (even) {
        array.push(<li className={styles.liEvent} key={element.id + '-' + index}>
          <Link className={styles.dataCell + ' ' + styles.eventEvenColor} to={link}>
            <div className={styles.dataCellText}>
              {strDate}
            </div>
          </Link>
          <div className={styles.eventEvenColor + ' ' + styles.tituloCell}>
            <Link className={styles.link + ' ' + styles.tableCellText} to={link}>{element.title}</Link>
          </div>
        </li>)
      } else {
        array.push(<li className={styles.liEvent} key={element.id + '-' + index}>
          <Link className={styles.dataCell + ' ' + styles.eventUnevenColor} to={link}>
            <div className={styles.dataCellText}>
              {strDate}
            </div>
          </Link>
          <div className={styles.eventUnevenColor + ' ' + styles.tituloCell}>
            <Link className={styles.link + ' ' + styles.tableCellText} to={link}>{element.title}</Link>
          </div>
        </li>)
      }
      even = !even
      return array
    })
    return array
  }

  markSelectedMonths () {
    let ul = document.getElementById('ulMeses')
    if (ul == null) return
    const { selectedMonths } = this.props
    setTimeout(() => {
      let lis = ul.children
      for (let i = 0; i < lis.length; i++) {
        const li = lis[i]
        let index = parseInt(li.dataset.index)
        if (selectedMonths.includes(index)) {
          li.style.fontWeight = 'bold'
        } else {
          li.style.fontWeight = 'normal'
        }
      }
    }, 100)
  }

  getDateText (strStart, strEnd, lang) {
    let start = new Date(strStart)
    let result = start.toLocaleDateString(lang, { day: 'numeric', month: 'short' })
    if (strEnd !== null && strEnd !== '') {
      let end = new Date(strEnd)
      return result + '/' + end.toLocaleDateString(lang, { day: 'numeric', month: 'short' })
    }
    return result
  }

  // function getDateText (start, end, lang) {
  //   let startDate = new Date()
  //   startDate.setMonth(parseInt(start.substr(5, 2)) - 1)
  //   let result = start.substr(8, 2) + ' ' + startDate.toLocaleString(lang, { month: 'short' })
  //   if (end !== null || end !== '') {
  //     let endDate = new Date()
  //     endDate.setMonth(parseInt(end.substr(5, 2)) - 1)
  //     return result + '/' + end.substr(8, 2) + ' ' + endDate.toLocaleString(lang, { month: 'short' })
  //   }
  //   return result
  // }
}

EventosList.propTypes = {
  events: PropTypes.array.isRequired,
  selectedMonths: PropTypes.array.isRequired,
  lang: PropTypes.string.isRequired,
  maxEvents: PropTypes.number.isRequired
}

const mapStateToProps = state => ({
  events: state.events.homepageEvents,
  selectedMonths: state.events.selectedMonths,
  maxEvents: state.events.maxEvents,
  lang: state.linguas.lang
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(EventosList)
