import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Scrollbars } from 'react-custom-scrollbars'

import { selectMonth, setTopMonthYear, setTopDayMonth, updateDays } from '../../../modules/calendar'
import styles from './CalendarMonths.css'
import CalendarTopYear from './CalendarTopYear'

class CalendarMonths extends Component {
  constructor(props) {
    super(props)
    this.renderThumb = this.renderThumb.bind(this)
    this.selectMonth = this.selectMonth.bind(this)
    this.onScroll = this.onScroll.bind(this)
    this.ulRef = React.createRef()
    this.viewPort = React.createRef()
    this.scrollbar = React.createRef()
    this.selectedMonthDiv = React.createRef()
    this.currentMonthDiv = React.createRef()
  }

  render () {
    const { months, newsMonths, showNews } = this.props
    // if (!selectedMonth) {
    setTimeout(() => {
      if (this.currentMonthDiv.current !== null) {
        this.centerMonth(this.currentMonthDiv.current)
      }
    }, 250)
    // }
    return (
      <div className={styles.layoutGrid}>
        <CalendarTopYear />
        <div className={styles.monthsContainer} ref={this.viewPort}>
          <Scrollbars
            ref={this.scrollbar}
            autoHeight
            autoHeightMax={380}
            autoHide
            autoHideTimeout={1000}
            autoHideDuration={200}
            onScroll={(e) => this.onScroll(e, (showNews ? newsMonths : months))}
            renderThumbHorizontal={this.renderThumb}
            renderThumbVertical={this.renderThumb}>
            {this.buildMonthsList((showNews ? newsMonths : months))}
          </Scrollbars>
        </div>
        <div className={styles.divGradTop} />
        <div className={styles.divGradBottom} />
      </div>
    )
  }

  onScroll (e, months) {
    this.setVisibleTopMonth(months)
  }

  setVisibleTopMonth (months) {
    let sb = this.scrollbar.current
    let liItems = this.ulRef.current.children
    let ulRect = this.ulRef.current.getBoundingClientRect()
    let offset = ulRect.top
    for (let index = 0; index < liItems.length; index++) {
      const li = liItems[index]
      if (li.getBoundingClientRect().top - offset >= sb.getScrollTop()) {
        if (months[index].month !== null) {
          this.props.setTopMonthYear(months[index].year)
        } else {
          this.props.setTopMonthYear(months[index].year - 1)
        }
        break
      }
    }
  }

  centerMonth (target) {
    let viewPort = this.viewPort.current.getBoundingClientRect()
    let rect = target.getBoundingClientRect()
    let dist = rect.top - (viewPort.top + viewPort.height / 2) + (rect.height / 2)
    let sb = this.scrollbar.current
    dist = sb.getScrollTop() + dist
    this.scrollSmooth(sb.getScrollTop(), dist, 300)
  }

  scrollSmooth (oldValue, newValue, dur) {
    const stepDur = 5
    let steps = Math.floor(dur / stepDur)
    let stepSize = (newValue - oldValue) / steps
    for (let index = 0; index < steps; index++) {
      let sum = stepSize * (index + 1)
      if (!this.scrollbar || !this.scrollbar.current) {
        break
      }
      if (this.scrollbar.current) {
        setTimeout(() => {
          this.scrollbar.current.scrollTop(oldValue + sum)
        }, index * stepDur)
      }
    }
  }

  buildMonthsList (months) {
    const { showNews } = this.props
    return (
      <ul className={styles.ul} ref={this.ulRef}>
        {
          months.map((month, index) => {
            if (month.month !== null) {
              if (month.selectable) {
                return (
                  <li
                    key={index}
                    ref={this.isCurrentMonth(month) ? this.currentMonthDiv : null}
                    className={styles.li + (this.isSelectedMonth(month)
                      ? ' ' + styles.selected
                      : '') +
                      (this.isCurrentMonth(month) && !showNews
                        ? ' ' + styles.currentDayMonth
                        : '')}
                    onClick={(e) => this.selectMonth(e, month)}>
                    <span className={styles.textContainer}>{this.getMonthText(month)}</span>
                  </li>
                )
              } else {
                return (
                  <li key={index}
                    ref={this.isCurrentMonth(month) ? this.currentMonthDiv : null}
                    className={styles.li + ' ' + styles.notSelectable + (this.isSelectedMonth(month)
                      ? ' ' + styles.selected
                      : '') +
                      (this.isCurrentMonth(month) && !showNews
                        ? ' ' + styles.currentDayMonth
                        : '')}>
                    <span className={styles.textContainer}>{this.getMonthText(month)}</span>
                  </li>
                )
              }
            } else {
              return (
                <li key={index} className={styles.li + ' ' + styles.notSelectable + ' ' + styles.year}>
                  <span className={styles.textContainer}>{this.getMonthText(month)}</span>
                </li>
              )
            }
          })
        }
      </ul>
    )
  }

  selectMonth (e, month) {
    this.props.selectMonth(month, true)
    this.props.setTopDayMonth(new Date(month.year, month.month - 1, 1))
    this.props.updateDays(null)
    this.props.updateDays(month)
  }

  isSelectedMonth (month) {
    const { selectedMonth } = this.props
    if (month.month !== null && month.year === selectedMonth.year && month.month === selectedMonth.month) {
      return true
    }
    return false
  }

  isCurrentMonth (month) {
    const { topDayMonth } = this.props
    if (month.month !== null && month.year === topDayMonth.getFullYear() && month.month === topDayMonth.getMonth() + 1) {
      return true
    }
    return false
  }

  getMonthText (month) {
    const { lang } = this.props
    if (month.month === null) {
      return month.year
    } else {
      let date = new Date(month.year, month.month - 1, 1)
      return date.toLocaleDateString(lang, { month: 'long' })
    }
  }

  renderThumb ({ style, ...props }) {
    const thumbStyle = {
      minWidth: '8px',
      backgroundColor: '#9ebdd2',
      borderRadius: '4px'
    }
    return (
      <div
        style={{ ...style, ...thumbStyle }}
        {...props} />
    )
  }
}

CalendarMonths.propTypes = {
  lang: PropTypes.string.isRequired,
  months: PropTypes.array.isRequired,
  newsMonths: PropTypes.array.isRequired,
  selectedMonth: PropTypes.object,
  selectMonth: PropTypes.func.isRequired,
  setTopMonthYear: PropTypes.func.isRequired,
  setTopDayMonth: PropTypes.func.isRequired,
  updateDays: PropTypes.func.isRequired,
  showNews: PropTypes.bool.isRequired,
  topDayMonth: PropTypes.object
}

const mapStateToProps = state => ({
  lang: state.linguas.lang,
  months: state.calendar.months,
  newsMonths: state.calendar.newsMonths,
  selectedMonth: state.calendar.selectedMonth,
  showNews: state.calendar.showNews,
  topDayMonth: state.calendar.topDayMonth
})

const mapDispatchToProps = { selectMonth, setTopMonthYear, setTopDayMonth, updateDays }

export default connect(mapStateToProps, mapDispatchToProps)(CalendarMonths)
