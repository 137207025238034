import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import styles from './Gallery.css'
import { setIndex } from '../../../modules/gallery'
import { togglePopup } from '../../../modules/popup'
// import PopupCarousel from '../../PopupCarousel/PopupCarousel'

class Gallery extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { mediaList, model } = this.props
    if (!mediaList || mediaList.length === 0) {
      return null
    }
    return (
      <div>
        <div className={styles.grid}>
          {this.buildGallery(mediaList, model)}
        </div>
        {/* <PopupCarousel key={Date.now() + '-popup'} /> */}
      </div>
    )
  }

  buildCarouselItems(originalMediaList) {
    const { model } = this.props
    let mediaList = [...originalMediaList]
    if (model && model.image) {
      const modelImage = {
        id: model.id,
        text: null,
        file: model.image,
        imageSize: 0,
        filename: model.imagem.filename
      }
      mediaList.splice(0, 0, modelImage)
    }
    // transformar o mediaList para formato do PopupCarousel
    let mediaItems = []
    mediaList.forEach(media => {
      mediaItems.push({
        key: media.id,
        media: media.file,
        nome: media.text,
        filename: media.filename,
        tipo: getMediaType(media.filename)
      })
    })
    return mediaItems
  }

  buildGallery(mediaList, model) {
    return (
      mediaList.map((media, index) => {
        const indiceNaLista = model && model.image ? index + 1 : index
        return (
          <div key={index}
            className={styles.gridBlock + (media.imageSize === 1
              ? ' ' + styles.horizontal
              : (media.imageSize === 2
                ? ' ' + styles.vertical
                : (media.imageSize === 3
                  ? ' ' + styles.big
                  : '')))}>
            <img className={styles.img} src={media.file} onClick={() => this.props.togglePopup(indiceNaLista)} />
          </div>
        )
      })
    )
  }
}

Gallery.propTypes = {
  lang: PropTypes.string.isRequired,
  mediaList: PropTypes.array.isRequired,
  togglePopup: PropTypes.func.isRequired,
  setIndex: PropTypes.func.isRequired,
  mobile: PropTypes.bool.isRequired,
  model: PropTypes.object
}

const mapStateToProps = state => ({
  lang: state.linguas.lang,
  mediaList: state.gallery.mediaList,
  model: state.model.model,
  mobile: state.global.mobile
})

const mapDispatchToProps = { setIndex, togglePopup }

export default connect(mapStateToProps, mapDispatchToProps)(Gallery)
