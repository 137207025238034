/* eslint-env browser */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { FormattedMessage as FM } from 'react-intl'
import { withRouter } from 'react-router'

import { kc, profileUrl } from '../../request'
import styles from './MenuUser.css'
import { setMenuItems } from '../../modules/menus'
import { setUser } from '../../modules/auth'

class MenuUser extends Component {
  constructor(props) {
    super(props)
    this.logout = this.logout.bind(this)
    this.profile = this.profile.bind(this)
    this.backoffice = this.backoffice.bind(this)
    const { logoutFunc, user } = props
    if (user && user.isAuthGov) {
      this.props.setMenuItems('user',
        [
          { label: 'Backoffice', action: this.backoffice, fm: 'menu.backoffice' },
          '',
          { label: 'Sair', action: logoutFunc, fm: 'menu.logout' }
        ])
    } else {
      this.props.setMenuItems('user',
        [
          { label: 'Perfil', action: this.profile, fm: 'menu.profile' },
          { label: 'Backoffice', action: this.backoffice, fm: 'menu.backoffice' },
          '',
          { label: 'Sair', action: logoutFunc, fm: 'menu.logout' }
        ])
    }
  }

  render() {
    const { menuItems, showMenu } = this.props
    let menuStyle = {
      'opacity': showMenu ? 0.8 : 0
    }
    return (
      <ul className={styles.menuContainer} style={menuStyle}>
        {menuItems.map((item, index) => {
          if (item !== '') {
            return <li key={index} className={styles.li + ' ' + styles.menuItem} onClick={item.action}>
              <FM id={item.fm} defaultMessage={item.label} />
            </li>
          } else {
            return <li key={index} className={styles.li + ' ' + styles.menuItemSeparator} />
          }
        })}
      </ul>
    )
  }

  profile(e) {
    window.open(profileUrl, '_blank')
    // window.location.href = profileUrl
  }

  backoffice(e) {
    const { history: { push } } = this.props
    push('/backoffice')
  }

  logout(e) {
    localStorage.setItem('cmlf_logout', true)
    localStorage.setItem('cmlf_login', false)
    localStorage.setItem('cmlf_loginIsAuthGov', false)
    localStorage.setItem('cmlf_user', null)
    if (this.props.user.isAuthGov) {
      this.props.setUser(null)
    } else {
      kc.logout()
    }
  }
}

MenuUser.propTypes = {
  lang: PropTypes.string.isRequired,
  menuItems: PropTypes.array.isRequired,
  setUser: PropTypes.func.isRequired,
  setMenuItems: PropTypes.func.isRequired,
  showMenu: PropTypes.bool.isRequired
}

const mapStateToProps = state => ({
  lang: state.linguas.lang,
  menuItems: state.menus.user.items,
  user: state.auth.user,
  showMenu: state.menus.user.show
})

const mapDispatchToProps = { setMenuItems, setUser }

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MenuUser))
