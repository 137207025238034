import store from '../store'

const SELECT_MONTH = 'cmlf/calendar/SELECT_MONTH'
const SELECT_DAY = 'cmlf/calendar/SELECT_DAY'
const BUILD_MONTHS = 'cmlf/calendar/BUILD_MONTHS'
const BUILD_DAYS = 'cmlf/calendar/BUILD_DAYS'
const SET_TOP_MONTH_YEAR = 'cmlf/calendar/SET_TOP_MONTH_YEAR'
const SET_TOP_DAY_MONTH = 'cmlf/calendar/SET_TOP_DAY_MONTH'
const TOGGLE_UPDATE_DAYS = 'cmlf/calendar/TOGGLE_UPDATE_DAYS'
const TOGGLE_UPDATE_MONTHS = 'cmlf/calendar/TOGGLE_UPDATE_MONTHS'
const SET_SHOW_DAYS = 'cmlf/calendar/SET_SHOW_DAYS'
const SET_SHOW_NEWS = 'cmlf/calendar/SET_SHOW_NEWS'
const SET_NEWS_MONTHS = 'cmlf/calendar/SET_NEWS_MONTHS'

const initialState = {
  months: [],
  days: [],
  selectedMonth: null,
  selectedDay: null,
  topMonthYear: null,
  topDayMonth: null,
  toggleUpdateDays: null,
  toggleUpdateMonths: false,
  showDays: true,
  showNews: false,
  newsMonths: [],
  eventsMonths: []
}

export const selectMonth = (month, updateDay = false, today = false) => ({ type: SELECT_MONTH, month, updateDay, today })
export const selectDay = (day) => ({ type: SELECT_DAY, day })
export const setTopMonthYear = (year) => ({ type: SET_TOP_MONTH_YEAR, year })
export const setTopDayMonth = (month) => ({ type: SET_TOP_DAY_MONTH, month })
export const updateDays = (month) => ({ type: TOGGLE_UPDATE_DAYS, month })
export const updateMonths = () => ({ type: TOGGLE_UPDATE_MONTHS })
export const setShowDays = (show) => ({ type: SET_SHOW_DAYS, show })
export const setShowNews = (show) => ({ type: SET_SHOW_NEWS, show })

export function buildNewsMonths(news) {
  return (dispatch) => {
    if (!news || news.length === 0 || news[0].obj === null) {
      dispatch(
        { type: SET_NEWS_MONTHS, months: [] }
      )
      return
    }
    let months = []
    // obter lista reordenada de noticias
    let sortedNews = [...news]
    sortedNews.sort((a, b) => {
      if (a.date > b.date) {
        return 1
      }
      return -1
    })
    let refDate = strYMDToMonthStruct(sortedNews[0].obj.data, true)
    for (let index = 5; index >= 1; index--) {
      let newDate = new Date()
      newDate.setFullYear(refDate.year)
      newDate.setDate(1)
      newDate.setMonth(refDate.month - 1)
      newDate.setMonth(newDate.getMonth() - index)
      months.push({ year: newDate.getFullYear(), month: newDate.getMonth() + 1, selectable: false })
    }
    sortedNews.forEach(news => {
      let newMonth = strYMDToMonthStruct(news.obj.data, true)
      if (!monthExists(months, newMonth)) {
        months.push(newMonth)
      }
    })
    refDate = strYMDToMonthStruct(sortedNews[sortedNews.length - 1].obj.data, true)
    for (let index = 1; index <= 6; index++) {
      let newDate = new Date()
      newDate.setFullYear(refDate.year)
      newDate.setDate(1)
      newDate.setMonth(refDate.month - 1)
      newDate.setMonth(newDate.getMonth() + index)
      months.push({ year: newDate.getFullYear(), month: newDate.getMonth() + 1, selectable: false })
    }
    months = fillMonthGaps(months)
    months = fillYearGaps(months)
    dispatch(
      { type: SET_NEWS_MONTHS, months }
    )
    dispatch(
      { type: SET_TOP_MONTH_YEAR, year: months[0].year }
    )
    if (!store.getState().calendar.selectedMonth) {
      selectCurrentMonth(months, dispatch)
    }
  }
}
export function buildMonths(yearMonths) {
  return (dispatch) => {
    if (!yearMonths || yearMonths.length === 0) {
      yearMonths = []
      const date = new Date()
      yearMonths.push({ ano: date.getFullYear(), mes: date.getMonth() })
    }
    let refDate = yearMonths[0]
    let months = []
    for (let index = 5; index >= 1; index--) {
      let newDate = new Date()
      newDate.setFullYear(refDate.ano)
      newDate.setDate(1)
      newDate.setMonth(refDate.mes - 1)
      newDate.setMonth(newDate.getMonth() - index)
      months.push({ year: newDate.getFullYear(), month: newDate.getMonth() + 1, selectable: false })
    }
    yearMonths.forEach(date => {
      let newMonth = { year: date.ano, month: date.mes, selectable: true }
      if (!monthExists(months, newMonth)) {
        months.push(newMonth)
      }
    })
    refDate = yearMonths[yearMonths.length - 1]
    for (let index = 1; index <= 6; index++) {
      let newDate = new Date()
      newDate.setFullYear(refDate.ano)
      newDate.setDate(1)
      newDate.setMonth(refDate.mes - 1)
      newDate.setMonth(newDate.getMonth() + index)
      months.push({ year: newDate.getFullYear(), month: newDate.getMonth() + 1, selectable: false })
    }
    months = fillMonthGaps(months)
    months = fillYearGaps(months)
    dispatch(
      { type: BUILD_MONTHS, months }
    )
    dispatch(
      { type: SET_TOP_MONTH_YEAR, year: months[0].year }
    )
    if (!store.getState().calendar.selectedMonth) {
      selectCurrentMonth(months, dispatch)
    }
  }
}

export function buildDays(eventDays) {
  return (dispatch) => {
    if (!eventDays || eventDays.length === 0) {
      return
    }
    let days = []
    eventDays.forEach(day => {
      const ymd = getYMD(day)
      let dayDate = new Date(ymd.year, ymd.month - 1, ymd.day)
      const ddYear = dayDate.getFullYear()
      const ddMonth = dayDate.getMonth() + 1
      const ddDay = dayDate.getDate()
      const lastDay = days.length ? days[days.length - 1] : null
      if (!lastDay || !(lastDay.year === ddYear && lastDay.month === ddMonth && lastDay.day === ddDay)) {
        days.push({
          year: ddYear,
          month: ddMonth,
          day: ddDay,
          selectable: true,
          date: dayDate
        })
      }
    })
    days = insertDays(days)
    days = insertDays(days, { amount: 10, before: false })
    days = fillDayGaps(days)
    days = insertMonthSplitters(days)
    dispatch(
      { type: BUILD_DAYS, days }
    )
  }
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SELECT_MONTH:
      if (action.updateDay &&
        (state.selectedDay === null ||
          state.selectedDay.getMonth() !== action.month.month - 1)) {
        let day = getDayFromMonth(action.month, state, action.today)
        return { ...state, selectedMonth: action.month, selectedDay: day }
      } else {
        return { ...state, selectedMonth: action.month }
      }
    case SELECT_DAY:
      return { ...state, selectedDay: action.day }
    case BUILD_MONTHS:
      return { ...state, months: action.months }
    case BUILD_DAYS:
      return { ...state, days: action.days }
    case SET_TOP_MONTH_YEAR:
      return { ...state, topMonthYear: action.year }
    case SET_TOP_DAY_MONTH:
      if (state.topDayMonth === null ||
        state.topDayMonth.getMonth() !== action.month.getMonth() ||
        state.topDayMonth.getFullYear() !== action.month.getFullYear()) {
        return { ...state, topDayMonth: action.month }
      }
      return state
    case TOGGLE_UPDATE_DAYS:
      return { ...state, toggleUpdateDays: action.month }
    case TOGGLE_UPDATE_MONTHS:
      return { ...state, toggleUpdateMonths: !state.toggleUpdateMonths }
    case SET_SHOW_DAYS:
      return { ...state, showDays: action.show }
    case SET_SHOW_NEWS:
      return { ...state, showNews: action.show }
    case SET_NEWS_MONTHS:
      return { ...state, newsMonths: action.months }
    default:
      return state
  }
}

function dayInArray(array, day) {
  for (let index = 0; index < array.length; index++) {
    const element = array[index];
    if (element.year === day.getFullYear() &&
      element.month === day.getMonth() + 1 &&
      element.day === day.getDate()) {
      return true
    }
  }
  return false
}

function monthExists(array, monthObj) {
  if (!array || array.length === 0) {
    return false
  }
  for (let index = 0; index < array.length; index++) {
    const element = array[index]
    if (element.year === monthObj.year && element.month === monthObj.month) {
      return true
    }
  }
  return false
}

function strYMDToMonthStruct(str, selectable) {
  const obj = getYMD(str)
  return { year: parseInt(obj.year), month: parseInt(obj.month), selectable }
}

function getDayFromMonth(month, state, today = false) {
  const days = state.days
  let selectedDay = new Date(month.year, month.month - 1, 1)
  const now = new Date()
  for (let index = 0; index < days.length; index++) {
    const day = days[index]
    if (day.date !== null && day.selectable &&
      day.date.getMonth() === selectedDay.getMonth() &&
      day.date.getFullYear() === selectedDay.getFullYear()) {
      if (!today ||
        (today && day.date >= now)) {
        return day.date
      } else {
        selectedDay = day.date
      }
    }
  }
  return selectedDay
}

function insertDays(days, options = { amount: 10, before: true }) {
  let result = []
  let refDate = days[0].date
  if (options.before) {
    for (let index = options.amount; index > 0; index--) {
      let date = new Date(refDate)
      date.setDate(date.getDate() - index)
      result.push({
        year: date.getFullYear(),
        month: date.getMonth() + 1,
        day: date.getDate(),
        selectable: false,
        date: date
      })
    }
    days.forEach(day => (result.push(day)))
  } else {
    refDate = days[days.length - 1].date
    days.forEach(day => (result.push(day)))
    for (let index = 1; index <= options.amount; index++) {
      let date = new Date(refDate)
      date.setDate(date.getDate() + index)
      result.push({
        year: date.getFullYear(),
        month: date.getMonth() + 1,
        day: date.getDate(),
        selectable: false,
        date: date
      })
    }
  }
  return result
}

function selectCurrentMonth(months, dispatch) {
  let today = new Date()
  let currentSelected = null
  let anySelected = false
  months.forEach(month => {
    if (month.month !== null && month.selectable) {
      let monthDate = yearMonthToDate(month)
      month.selected = false
      if (!anySelected && monthDate >= today) {
        anySelected = true
        month.selected = true
        if (currentSelected !== null) {
          currentSelected.selected = false
        }
        currentSelected = month
      } else if (!anySelected &&
        (currentSelected === null ||
          yearMonthToDate(currentSelected) < monthDate)) {
        if (currentSelected !== null) {
          currentSelected.selected = false
        }
        month.selected = true
        currentSelected = month
      }
    }
  })
  dispatch(
    { type: SELECT_MONTH, month: currentSelected, updateDay: true, today: true }
  )
  // seleccionar topDayYear
  let tdyDate = new Date(currentSelected.year, currentSelected.month - 1, 1)
  dispatch({ type: SET_TOP_DAY_MONTH, month: tdyDate })
  // actualizar componente de dias
  dispatch({ type: TOGGLE_UPDATE_DAYS, month: currentSelected })
}

function yearMonthToDate(yearMonth) {
  return new Date(yearMonth.year, yearMonth.month, 0)
}

// insere dias falsos sempre que o último dia do mês calhe num número impar
function insertMonthSplitters(days) {
  let result = []
  let odd = true
  days.forEach((day, index) => {
    result.push(day)
    if ((odd && isOdd(index + 1) && isLastDayOfMonth(day.date)) ||
      (!odd && !isOdd(index + 1) && isLastDayOfMonth(day.date))) {
      result.push({
        year: day.year,
        month: day.month,
        day: null,
        selectable: false,
        date: null
      })
      odd = !odd
    }
  })
  return result
}

function isOdd(n) {
  return Math.abs(n % 2) === 1
}

function isLastDayOfMonth(date) {
  let tommorrow = new Date(date)
  tommorrow.setDate(tommorrow.getDate() + 1)
  if (tommorrow.getMonth() !== date.getMonth()) {
    return true
  }
  return false
}

function fillDayGaps(days) {
  let prevDay = null
  let result = []
  const oneDay = 24 * 60 * 60 * 1000
  days.forEach(day => {
    if (prevDay !== null) {
      let prevDate = new Date(prevDay.date)
      let date = new Date(day.date)
      let diff = Math.round(Math.abs((prevDate.getTime() - date.getTime()) / (oneDay)))
      if (diff > 1) {
        for (let index = 1; index < diff; index++) {
          let newDate = new Date(prevDate)
          newDate.setDate(newDate.getDate() + index)
          if (newDate.getFullYear() === date.getFullYear() &&
            newDate.getMonth() === date.getMonth() &&
            newDate.getDate() === date.getDate()) {
            break
          }
          result.push({
            year: newDate.getFullYear(),
            month: newDate.getMonth() + 1,
            day: newDate.getDate(),
            selectable: false,
            date: newDate
          })
        }
      }
    }
    result.push({
      year: day.year,
      month: day.month,
      day: day.day,
      selectable: day.selectable,
      date: day.date
    })
    prevDay = { ...day }
  })
  return result
}

function fillMonthGaps(months) {
  let prevMonth = null
  let result = []
  months.forEach(month => {
    if (prevMonth !== null) {
      let prevDate = new Date(prevMonth.year, prevMonth.month - 1, 1)
      let date = new Date(month.year, month.month - 1, 1)
      let diff = monthDiff(prevDate, date)
      if (diff > 1) {
        for (let index = 1; index < diff; index++) {
          let newDate = new Date(prevDate)
          newDate.setMonth(newDate.getMonth() + index)
          result.push({ year: newDate.getFullYear(), month: newDate.getMonth() + 1, selectable: false })
        }
      }
    }
    result.push({ year: month.year, month: month.month, selectable: month.selectable })
    prevMonth = { ...month }
  })
  return result
}

function monthDiff(d1, d2) {
  var months
  months = (d2.getFullYear() - d1.getFullYear()) * 12
  months -= d1.getMonth()
  months += d2.getMonth()
  return months <= 0 ? 0 : months
}

function fillYearGaps(months) {
  let prevMonth = null
  let result = []
  months.forEach(month => {
    if (prevMonth !== null) {
      let prevDate = new Date(prevMonth.year, prevMonth.month - 1, 1)
      let date = new Date(month.year, month.month - 1, 1)
      if (prevDate.getFullYear() < date.getFullYear()) {
        result.push({ year: date.getFullYear(), month: null, selectable: false })
      }
    }
    result.push({ year: month.year, month: month.month, selectable: month.selectable })
    prevMonth = { ...month }
  })
  return result
}

function getYMD(strDate) {
  return {
    year: strDate.substr(0, 4),
    month: strDate.substr(5, 2),
    day: strDate.substr(8, 2)
  }
}
