import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// import styles from './Layouts.css'
import Layout from './Layout'
import { resetGallery } from '../../../modules/gallery'
// import Gallery from './Gallery'

class Layouts extends Component {
  render () {
    const { model, parent } = this.props
    let layouts = []
    if (!parent) {
      layouts = model.layouts
    } else {
      layouts = parent.children
    }
    return (
      <div>
        {this.buildLayouts(layouts)}
      </div>
    )
  }

  buildLayouts (layouts) {
    if (!layouts || layouts.length === 0) {
      return null
    }
    let arr = []
    layouts.forEach((layout) => (
      arr.push(<Layout key={layout.id} layout={layout} />)
    ))
    return arr
  }
}

Layouts.propTypes = {
  lang: PropTypes.string.isRequired,
  resetGallery: PropTypes.func.isRequired,
  model: PropTypes.object
}

const mapStateToProps = state => ({
  lang: state.linguas.lang,
  model: state.model.model
})

const mapDispatchToProps = { resetGallery }

export default connect(mapStateToProps, mapDispatchToProps)(Layouts)
