import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import styles from './NavObjects.css'
import { goFirstPage, goLastPage, goNextPage, goPreviousPage, goPage } from '../../../modules/navigation'

class NavObjects extends Component {
  constructor(props) {
    super(props)
    this.goFirstPage = this.goFirstPage.bind(this)
    this.goLastPage = this.goLastPage.bind(this)
    this.goNextPage = this.goNextPage.bind(this)
    this.goPreviousPage = this.goPreviousPage.bind(this)
    this.onChangeCurrentPage = this.onChangeCurrentPage.bind(this)
  }

  render () {
    const { currentPage, totalPages, pageListSize, pageListStartPage, mobile, resultSize } = this.props
    if (totalPages <= 1 || resultSize === 0) {
      return null
    }
    let count = this.getPageCount(pageListSize, totalPages)
    let style = {}
    if (!mobile) {
      style = { gridTemplateColumns: '20px 20px repeat(' + count + ', min-content) 20px 20px auto' }
    } else {
      style = { gridTemplateColumns: '30px 30px repeat(' + count + ', min-content) 30px 30px auto' }
    }
    return (
      <div className={styles.paginationBlock} style={style}>
        <input
          className={styles.pageCell + ' ' + styles.input + ' ' + styles.goFirstPage}
          type='button'
          onClick={this.goFirstPage}
          disabled={!this.props.canFirstPage} />
        <input
          className={styles.pageCell + ' ' + styles.input + ' ' + styles.goPreviousPage}
          type='button'
          onClick={this.goPreviousPage}
          disabled={!this.props.canPreviousPage} />
        {this.buildPageList(pageListSize, currentPage, totalPages, pageListStartPage)}
        <input
          className={styles.pageCell + ' ' + styles.input + ' ' + styles.goNextPage}
          type='button'
          onClick={this.goNextPage}
          disabled={!this.props.canNextPage} />
        <input
          className={styles.pageCell + ' ' + styles.input + ' ' + styles.goLastPage}
          type='button'
          onClick={this.goLastPage}
          disabled={!this.props.canLastPage} />
      </div>
    )
  }

  getPageCount (pageListSize, totalPages) {
    return totalPages > pageListSize ? pageListSize : totalPages
  }

  buildPageList (pageListSize, currentPage, totalPages, pageListStartPage) {
    let count = this.getPageCount(pageListSize, totalPages)
    let result = []
    for (let index = 0; index < count; index++) {
      let pageNumber = index + pageListStartPage
      result.push(<div
        key={pageNumber}
        className={styles.pageCell + ' ' + styles.pageNumber +
          (pageNumber === currentPage ? ' ' + styles.selected : '')}
        onClick={(e) => this.onChangeCurrentPage(e, pageNumber)}>
        {pageNumber}
      </div>)
    }
    return result
  }

  goFirstPage (e) {
    this.props.goFirstPage()
  }

  goLastPage (e) {
    this.props.goLastPage()
  }

  goNextPage (e) {
    this.props.goNextPage()
  }

  goPreviousPage (e) {
    this.props.goPreviousPage()
  }

  onChangeCurrentPage (e, page) {
    e.stopPropagation()
    const { currentPage } = this.props
    if (page !== currentPage) {
      this.props.goPage(page)
    }
  }
}

NavObjects.propTypes = {
  lang: PropTypes.string.isRequired,
  goFirstPage: PropTypes.func.isRequired,
  goLastPage: PropTypes.func.isRequired,
  goNextPage: PropTypes.func.isRequired,
  goPreviousPage: PropTypes.func.isRequired,
  goPage: PropTypes.func.isRequired,
  pageListSize: PropTypes.number.isRequired,
  pageListStartPage: PropTypes.number.isRequired,
  resultSize: PropTypes.number.isRequired,
  mobile: PropTypes.bool.isRequired
}

const mapStateToProps = state => ({
  canFirstPage: state.navigation.canFirstPage,
  canLastPage: state.navigation.canLastPage,
  canNextPage: state.navigation.canNextPage,
  canPreviousPage: state.navigation.canPreviousPage,
  currentPage: state.navigation.currentPage,
  totalPages: state.navigation.totalPages,
  pageListSize: state.navigation.pageListSize,
  pageListStartPage: state.navigation.pageListStartPage,
  resultSize: state.navigation.resultSize,
  lang: state.linguas.lang,
  mobile: state.global.mobile
})

const mapDispatchToProps = { goFirstPage, goLastPage, goNextPage, goPreviousPage, goPage }

export default connect(mapStateToProps, mapDispatchToProps)(NavObjects)
