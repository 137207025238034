import React, { Component } from 'react'
import { Route, Switch, withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import Home from '../Home/Home'
import NotFound from '../NotFound/NotFound'
import ObjectWrapper from '../Objectos/common/ObjectWrapper'
import { locationChanged } from '../../modules/header'
import { closeMenu } from '../../modules/menus'

class Routes extends Component {
  componentDidUpdate (prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged()
    }
  }

  onRouteChanged () {
    this.props.locationChanged(this.props.location)
    this.props.closeMenu('searchObjects')
  }
  render () {
    return (
      <Switch>
        <Route exact path='/' component={Home} />
        <Route exact path={`/authgov/:username/name/:name/firstName/:firstName/lastName/:lastName/email/:email/nif/:nif/niss/:niss/token/:token/expires/:expires/digest/:digest`} render={(props) => <Home authGov {...props} />} />
        <Route exact path='/documentos' render={(props) => <ObjectWrapper objName='documentos' {...props} />} />
        <Route path='/paginas' render={(props) => <ObjectWrapper objName='paginas' {...props} />} />
        <Route path='/locais' render={(props) => <ObjectWrapper objName='locais' {...props} />} />
        <Route path='/servicos' render={(props) => <ObjectWrapper objName='servicos' {...props} />} />
        <Route path='/servicos-online' render={(props) => <ObjectWrapper objName='servicos-online' {...props} />} />
        <Route exact path='/noticias' render={() => <ObjectWrapper objName='noticias' />} />
        <Route exact path='/eventos' render={() => <ObjectWrapper objName='eventos' />} />
        <Route exact path={`/documentos/:pdest`} render={(props) => <ObjectWrapper objName='documentos' {...props} />} />
        <Route exact path={`/documentos/:pdest/:doc`} render={(props) => <ObjectWrapper objName='documentos' {...props} />} />
        <Route exact path={`/documentos/s/:psel`} render={(props) => <ObjectWrapper objName='documentos' {...props} />} />
        <Route exact path={`/documentos/s/:psel/:pdest`} render={(props) => <ObjectWrapper objName='documentos' {...props} />} />
        <Route path={`/documento/:permalink`} render={(props) => <ObjectWrapper objName='documento' {...props} />} />
        <Route path={`/evento/:permalink`} render={(props) => <ObjectWrapper objName='evento' key={Date.now()} {...props} />} />
        <Route path={`/local/:permalink`} render={(props) => <ObjectWrapper objName='local' key={Date.now()} {...props} />} />
        <Route path={`/noticia/:permalink`} render={(props) => <ObjectWrapper objName='noticia' key={Date.now()} {...props} />} />
        <Route path={`/pagina/:permalink`} render={(props) => <ObjectWrapper objName='pagina' key={Date.now()} {...props} />} />
        <Route path={`/servico/:permalink`} render={(props) => <ObjectWrapper objName='servico' key={Date.now()} {...props} />} />
        <Route path={`/servico-online/:permalink`} render={(props) => <ObjectWrapper objName='servico-online' key={Date.now()} {...props} />} />
        <Route exact path={`/formulario/:permalink`} render={(props) => <ObjectWrapper objName='formulario' {...props} />} />
        <Route exact path={`/formulario/:permalink/:formInstanceId`} render={(props) => <ObjectWrapper objName='formInstance' {...props} />} />
        <Route exact path={`/formulario/:permalink/:formInstanceId/resumo`} render={(props) => <ObjectWrapper objName='formInstance' isResumo {...props} />} />
        <Route exact path='/backoffice' render={(props) => <ObjectWrapper objName='backoffice' {...props} />} />
        <Route component={NotFound} />
      </Switch>
    )
  }
}

Routes.propTypes = {
  location: PropTypes.object,
  closeMenu: PropTypes.func.isRequired,
  locationChanged: PropTypes.func.isRequired
}

const mapStateToProps = state => ({})

const mapDispatchToProps = { locationChanged, closeMenu }

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Routes))
