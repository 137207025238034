import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { FormattedMessage as FM } from 'react-intl'

import common from '../common/Body.css'
import spinner from '../../../css/Spinner.css'
import ServicosList from './ServicosList'
import { fetchServicos, setServicos } from '../../../modules/servicos'
import { fetchCategorias, setCurrentObjType, resetSelectedCategorias } from '../../../modules/categorias'

class Servicos extends Component {
  componentDidMount () {
    const { availableMenus, online, currentObjectType, lang, availableMenusAreLoading } = this.props
    const onlineBool = online === 'true'
    if (onlineBool) {
      if (currentObjectType !== 'servicoOnline') {
        this.props.setCurrentObjType('servicoOnline')
        this.props.resetSelectedCategorias()
        this.props.fetchCategorias(lang, 'servico', onlineBool)
      }
      if (!availableMenusAreLoading && !availableMenus.servicosOnline) {
        this.props.fetchServicos(this.props.lang, null, true)
      }
    } else {
      if (currentObjectType !== 'servico') {
        this.props.setCurrentObjType('servico')
        this.props.resetSelectedCategorias()
        this.props.fetchCategorias(lang, 'servico', onlineBool)
      }
      if (!availableMenusAreLoading && !availableMenus.servicos) {
        this.props.fetchServicos(this.props.lang, null, false)
      }
    }
  }

  componentDidUpdate () {
    const { selectedCategorias, online, lang, currentPastaContent, availableMenus, availableMenusAreLoading } = this.props
    if (availableMenusAreLoading) {
      return
    }
    const onlineBool = online === 'true'
    if (selectedCategorias && selectedCategorias.length > 0) {
      this.props.fetchServicos(lang, selectedCategorias, onlineBool)
    } else {
      this.props.fetchCategorias(lang, 'servico', onlineBool)
      if ((online && availableMenus.servicosOnline) || (!online && availableMenus.servicos)) {
        if (currentPastaContent && currentPastaContent.menuType === (onlineBool ? 2 : 3)) {
          this.props.setServicos(currentPastaContent.objectos)
        } else if (!currentPastaContent) {
          this.props.setServicos([])
        }
      } else {
        this.props.fetchServicos(lang, null, onlineBool)
      }
    }
  }

  render () {
    const { selectedCategorias, availableMenusAreLoading } = this.props
    if (availableMenusAreLoading) {
      return (
        <div className={spinner.loader} />
      )
    }
    let online = this.props.online === 'true'
    const updateFilteredCategorias = selectedCategorias && selectedCategorias.length > 0
    if (this.props.short === true) {
      return <ServicosList online={this.props.online} short />
    }
    return (
      <div className={common.layoutGrid}>
        <div className={common.header}>
          {
            online
              ? <FM id='body.onlineServices' defaultMessage='Serviços online' />
              : <FM id='body.live' defaultMessage='Viver' />
          }
        </div>
        <ServicosList online={this.props.online} filterCategorias={updateFilteredCategorias} />
      </div>
    )
  }
}

Servicos.propTypes = {
  fetchServicos: PropTypes.func.isRequired,
  lang: PropTypes.string.isRequired,
  currentPastaContent: PropTypes.object,
  availableMenus: PropTypes.object.isRequired,
  fetchCategorias: PropTypes.func.isRequired,
  setServicos: PropTypes.func.isRequired,
  currentObjectType: PropTypes.string.isRequired,
  resetSelectedCategorias: PropTypes.func.isRequired,
  availableMenusAreLoading: PropTypes.bool.isRequired
}

const mapStateToProps = state => ({
  lang: state.linguas.lang,
  currentPastaContent: state.pastas.currentPastaContent,
  selectedCategorias: state.categorias.selectedCategorias,
  availableMenus: state.pastas.availableMenus,
  currentObjectType: state.categorias.currentObjectType,
  availableMenusAreLoading: state.pastas.availableMenusAreLoading
})

const mapDispatchToProps = { fetchServicos, setServicos, fetchCategorias, setCurrentObjType, resetSelectedCategorias }

export default connect(mapStateToProps, mapDispatchToProps)(Servicos)
