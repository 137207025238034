import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import styles from './FooterLinks.css'
import { fetchFooterLinks } from '../../modules/destaques'

class FooterLinks extends Component {
  componentDidMount () {
    let { footerLinksFetched, lang } = this.props
    if (!footerLinksFetched) {
      this.props.fetchFooterLinks(lang)
    }
  }

  render () {
    let { footerLinks, footerLinksFetched } = this.props
    if (!footerLinksFetched) {
      return null
    }
    return (
      <div>
        <ul className={styles.ul}>
          {
            footerLinks.map((link) => (
              this.buildLink(link)
            ))
          }
        </ul>
      </div >
    )
  }

  buildLink (link) {
    if (link.type === 'document') {
      return (
        <li className={styles.li} key={link.id}>
          <a className={styles.link} href={link.downloadLink} target='_blank'>{link.text}</a>
        </li>
      )
    } else {
      return (
        <li className={styles.li} key={link.id}>
          <Link className={styles.link} to={link.link}>{link.text}</Link>
        </li>
      )
    }
  }
}


FooterLinks.propTypes = {
  footerLinks: PropTypes.array.isRequired,
  footerLinksFetched: PropTypes.bool.isRequired,
  fetchFooterLinks: PropTypes.func.isRequired,
  lang: PropTypes.string
}

const mapStateToProps = state => ({
  footerLinks: state.destaques.footerLinks,
  footerLinksFetched: state.destaques.footerLinksFetched,
  lang: state.linguas.lang
})

const mapDispatchToProps = { fetchFooterLinks }

export default connect(mapStateToProps, mapDispatchToProps)(FooterLinks)
