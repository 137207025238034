import React, { Component } from 'react'

import styles from './ScrollTop.css'

export default class ScrollTop extends Component {
  constructor (props) {
    super(props)
    this.handleScroll = this.handleScroll.bind(this)
    this.state = { show: window.pageYOffset > 0 }
  }

  componentDidMount () {
    window.addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount () {
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll () {
    this.setState({ show: window.pageYOffset > 0 })
  }

  scrollTop (e) {
    e.preventDefault()
    e.stopPropagation()
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  }

  render () {
    let style = {
      'visibility': this.state.show ? 'visible' : 'hidden'
      // ,
      // 'opacity': this.state.show ? 0.7 : 0
    }
    return (
      <div className={styles.scrollTop} style={style} onClick={this.scrollTop} />
    )
  }
}
