import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import MyListMapComponent from './MyListMapComponent'
import MyListMap from './MyListMap'
import styles from './LocaisMap.css'
import indexStyles from '../../../index.css'

class LocaisMap extends Component {
  render () {
    const { locais, clickedLocal } = this.props
    return (
      <div className={styles.layoutGrid + (!clickedLocal ? '' : ' ' + styles.selected)}>
        <div className={styles.mapDiv + ' ' + indexStyles.shadow}>
          {/* <MyListMapComponent locais={locais} /> */}
          <MyListMap locais={locais} />
        </div>
        {
          !clickedLocal
            ? null
            : <div className={styles.selectedDiv + ' ' + indexStyles.shadow}>
              {this.drawObject(clickedLocal)}
            </div>
        }
      </div>
    )
  }

  drawObject (obj) {
    return (
      <div className={styles.containerDiv} >
        <Link to={obj.link}>
          <img className={styles.imageFrame} src={obj.image} />
        </Link>
        <Link className={styles.textContainerDiv} to={obj.link}>
          <div className={styles.textDiv}>
            {obj.titulo}
          </div>
        </Link>
      </div>
    )
  }
}

LocaisMap.propTypes = {
  clickedLocal: PropTypes.object
}

const mapStateToProps = state => ({
  clickedLocal: state.locais.clickedLocal
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(LocaisMap)
