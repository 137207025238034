import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { FormattedMessage as FM } from 'react-intl'

import styles from './ScopeMenu.css'
import { setCurrentScope, doSearch } from '../../modules/search'
import indexStyles from '../../index.css'

class ScopeMenu extends Component {
  constructor(props) {
    super(props)
    this.itemMenuClick = this.itemMenuClick.bind(this)
  }

  render () {
    const { currentScope, searchScopes, iconsOnly } = this.props
    return this.buildMenu(currentScope, searchScopes, iconsOnly)
  }

  buildMenu (currentScope, ss, iconsOnly) {
    let pos = 1
    return (
      <div className={styles.scopeMenuGrid + ' ' + indexStyles.shadow}>
        {
          ss.map((scope, index) => {
            let gridPos = this.getGridPos(pos++)
            let style = { gridRowStart: gridPos.start, gridRowEnd: gridPos.end }
            return (
              <div
                key={index}
                className={styles.menuItemGrid +
                  (index === currentScope ? ' ' + styles.selected : '') +
                  (iconsOnly ? ' ' + styles.iconsOnly : '')}
                style={style}
                onClick={(e) => this.itemMenuClick(e, index)}>
                <div className={styles.menuItemIcon + ' ' + styles[scope.className]} />
                {!iconsOnly
                  ? <div className={styles.menuItemText}>
                    <FM id={this.getCurrentScopeString(index, ss)} defaultMessage='Tudo' />
                  </div>
                  : null}
              </div>
            )
          })
        }
      </div>
    )
  }

  itemMenuClick (e, index) {
    const { lang, searchText, currentSortFieldOption, currentScope, searchScopes } = this.props
    if (index !== currentScope) {
      this.props.setCurrentScope(index)
      this.props.doSearch(searchText, lang, currentSortFieldOption, searchScopes[index].objSearchType)
    }
  }

  getCurrentScopeString (currentScope, searchScopes) {
    return 'search.' + searchScopes[currentScope].name
  }

  getGridPos (pos) {
    const offset = 2
    const inc = 1
    const start = (offset + inc * pos) - 1
    return { start: start, end: start + 1 }
  }
}

ScopeMenu.propTypes = {
  doSearch: PropTypes.func.isRequired,
  setCurrentScope: PropTypes.func.isRequired,
  currentScope: PropTypes.number.isRequired,
  searchScopes: PropTypes.array.isRequired,
  currentSortFieldOption: PropTypes.object.isRequired,
  searchText: PropTypes.string.isRequired
}

const mapStateToProps = state => ({
  lang: state.linguas.lang,
  currentScope: state.search.currentScope,
  searchScopes: state.search.searchScopes,
  currentSortFieldOption: state.search.currentSortFieldOption,
  searchText: state.search.searchText
})

const mapDispatchToProps = { setCurrentScope, doSearch }

export default connect(mapStateToProps, mapDispatchToProps)(ScopeMenu)
