import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { FormattedMessage as FM } from 'react-intl'

import common from '../common/Body.css'
import EventosList from './EventosList'
import { fetchEvents } from '../../../modules/events'
import { fetchCategorias, setCurrentObjType, resetSelectedCategorias } from '../../../modules/categorias'
import { ymdToDate } from '../../../lib/utils'

class Eventos extends Component {
  componentDidMount() {
    const { lang, currentObjectType, eventsAreLoading } = this.props
    if (eventsAreLoading) return
    this.props.fetchEvents(lang)
    if (currentObjectType !== 'evento') {
      this.props.setCurrentObjType('evento')
      this.props.resetSelectedCategorias()
      this.props.fetchCategorias(lang, 'evento')
    }
  }

  componentDidUpdate() {
    const { lang, selectedCategorias, eventsAreLoading, eventsFetched } = this.props
    if (eventsAreLoading || (!eventsAreLoading && eventsFetched)) return
    if (selectedCategorias && selectedCategorias.length > 0) {
      this.props.fetchEvents(lang, selectedCategorias)
    } else {
      this.props.fetchCategorias(lang, 'evento')
      this.props.fetchEvents(lang)
    }
  }

  render() {
    if (this.props.short === true) {
      return <EventosList short />
    }
    const { selectedCategorias } = this.props
    const updateFilteredCategorias = selectedCategorias && selectedCategorias.length > 0
    return (
      <div className={common.layoutGrid}>
        <div className={common.header}>
          <FM id='events.events' defaultMessage='Eventos' />
        </div>
        <EventosList filterCategorias={updateFilteredCategorias} />
      </div>
    )
  }

  filterFromSelectedDay(objects, selectedDay) {
    if (!objects) {
      return []
    }
    if (!selectedDay) {
      return objects
    }
    let result = objects.filter(function (obj) {
      return obj !== null && ymdToDate(obj.end) >= selectedDay
    })
    return result
  }
}

Eventos.propTypes = {
  fetchEvents: PropTypes.func.isRequired,
  lang: PropTypes.string.isRequired,
  fetchCategorias: PropTypes.func.isRequired,
  currentObjectType: PropTypes.string.isRequired,
  selectedCategorias: PropTypes.array.isRequired,
  selectedDay: PropTypes.object,
  setCurrentObjType: PropTypes.func.isRequired,
  resetSelectedCategorias: PropTypes.func.isRequired,
  eventsAreLoading: PropTypes.bool.isRequired,
  eventsFetched: PropTypes.bool.isRequired
}

const mapStateToProps = state => ({
  lang: state.linguas.lang,
  selectedDay: state.calendar.selectedDay,
  selectedCategorias: state.categorias.selectedCategorias,
  currentObjectType: state.categorias.currentObjectType,
  eventsAreLoading: state.events.eventsAreLoading,
  eventsFetched: state.events.eventsFetched
})

const mapDispatchToProps = { fetchEvents, setCurrentObjType, resetSelectedCategorias, fetchCategorias }

export default connect(mapStateToProps, mapDispatchToProps)(Eventos)
