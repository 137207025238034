import React, { Component } from 'react'

import styles from '../common/Body.css'

export default class ResumoLi extends Component {
  render () {
    const { text } = this.props
    if (!text) {
      return null
    }
    return (
      <div className={styles.textResumo}>
        <div dangerouslySetInnerHTML={{ __html: text }} />
        <div className={styles.textResumoBottom} />
      </div>
    )
  }
}
