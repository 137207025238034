import React, { Component } from 'react'

import styles from './HelpText.css'
import { getTranslation } from '../../../request'

export default class HelpText extends Component {
  constructor(props) {
    super(props)
    this.translation = null
    this.toggleHelp = this.toggleHelp.bind(this)
    this.myRef = React.createRef()
  }
  
  render () {
    const { field, lang } = this.props
    return (
      <div className={styles.wrapper}>
        {field.required === true ? <div className={styles.requiredImage} /> : ''}
        {this.buildHelp(field, lang)}
      </div>
    )
  }

  buildHelp (field, lang) {
    if (!this.hasHelpText(field.helpText, lang)) {
      return null
    }
    return (
      <div className={styles.helpImage} onClick={this.toggleHelp}>
        <div
          ref={this.myRef}
          id='tooltip'
          // onClick={this.toggleHelp}
          className={styles.hide}>
          {this.translation}
        </div>
      </div>
    )
    // return (
    //   <div className={styles.tooltipWrapper}>
    //     <span className={styles.clickable} onClick={this.toggleHelp}>?</span>
    //     <div id='tooltip' className={styles.hide}>{this.translation}</div>
    //   </div>
    // )
  }

  hasHelpText (obj, lang) {
    const trans = getTranslation(obj, lang)
    if (trans !== null && trans.trim() !== '') {
      this.translation = trans
      return true
    }
    this.translation = null
    return false
  }

  toggleHelp (e) {
    e.preventDefault()
    e.stopPropagation()
    let element = this.myRef.current
    if (element.className === styles.helpBox) {
      element.className = styles.hide
    } else {
      element.className = styles.helpBox
    }
  }
}
