import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { FormattedMessage as FM } from 'react-intl'

import styles from './Breadcrumb.css'
import { getTranslation } from '../../../request'

class Breadcrumb extends Component {
  render () {
    const { breadcrumb, backoffice } = this.props
    return (
      <div className={styles.header + (backoffice ? ' ' + styles.backoffice : '')}>
        <div className={styles.body}>
          {this.buildBreadcrumb(breadcrumb)}
        </div>
      </div>
    )
  }

  buildBreadcrumb (breadcrumb) {
    const { lang, mobile, backoffice } = this.props
    let result = []
    const start = mobile ? 1 : 0
    for (let index = start; index < breadcrumb.length; index++) {
      const element = breadcrumb[index]
      if (index < breadcrumb.length - 1) {
        result.push(
          <Link className={styles.breadcrumbLink + (backoffice ? ' ' + styles.backoffice : '')} key={element.link} to={element.link}>
            {
              !element.translate
                ? (element.translated
                  ? element.name
                  : <FM id={element.name} defaultMessage='Página principal' />)
                : getTranslation(element.name, lang)
            }
          </Link>
        )
        result.push(<div key={element.link + '_div'} className={styles.sepDiv} />)
      } else {
        result.push(
          <div className={styles.breadcrumbLink} key={element.link} to={element.link}>
            {
              !element.translate
                ? (element.translated
                  ? element.name
                  : <FM id={element.name} defaultMessage='Página principal' />)
                : getTranslation(element.name, lang)
            }
          </div>
        )
      }
    }
    return result
  }
}

Breadcrumb.propTypes = {
  lang: PropTypes.string.isRequired,
  breadcrumb: PropTypes.array.isRequired,
  mobile: PropTypes.bool.isRequired
}

const mapStateToProps = state => ({
  lang: state.linguas.lang,
  breadcrumb: state.header.breadcrumb,
  mobile: state.global.mobile
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Breadcrumb)
