import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { FormattedMessage as FM } from 'react-intl'

import styles from './FormPages.css'
import { setCurrenPage, fetchResumo, setShowErrors, saveAndFetchResumo } from '../../modules/formulario'
import FormPage from './FormPage'
// import { getTranslation } from '../../request'

class FormPages extends Component {
  constructor(props){
    super(props)
    this.pageChanged = this.pageChanged.bind(this)
    this.resumoClicked = this.resumoClicked.bind(this)
  }
  
  render () {
    const { pages } = this.props
    return (
      <div>
        {this.buildPageList(pages)}
        <FormPage />
        {this.buildPageList(pages)}
      </div>
    )
  }

  buildPageList (pages) {
    if (!pages || pages.length === 0) {
      return null
    }
    const { currentPage } = this.props
    return (
      <div className={styles.divPages}>
        {
          this.buildItems(pages, currentPage)
        }
        <div key={-1}
          className={styles.pageNumber + ' ' + styles.resumo + (currentPage === -1 ? ' ' + styles.selected : '')}
          selected={currentPage === -1}
          onClick={() => this.resumoClicked(-1)}><FM id='form.summary' defaultMessage='Resumo' />
        </div>
      </div>
    )
  }

  buildItems (pages, currentPage) {
    let divs = []
    for (let index = 0; index < pages.length; index++) {
      divs.push(
        <div key={index}
          className={styles.pageNumber + (index === currentPage ? ' ' + styles.selected : '')}
          selected={index === currentPage}
          onClick={() => this.pageChanged(index)}>{index + 1}
        </div>
      )
      divs.push(
        <div key={'sep' + index} className={styles.innerLine} />
      )
    }
    return divs
  }

  pageChanged (value) {
    const { currentPage, pages } = this.props
    if (!pages) {
      return
    }
    this.props.setShowErrors(false)
    if (currentPage === -1 || this.conditionsOk()) {
      this.props.setCurrenPage(value)
    } else {
      this.props.setShowErrors(true)
    }
  }

  resumoClicked (value) {
    this.props.setShowErrors(false)
    if (this.conditionsOk()) {
      // this.props.fetchResumo(this.props.formInstance.id)
      this.props.saveAndFetchResumo(this.props.formInstance.id)
      this.props.setCurrenPage(value)
    } else {
      this.props.setShowErrors(true)
    }
  }

  conditionsOk () {
    const { currentPage, pages, formInstance } = this.props
    if (!pages) {
      return false
    }
    let page = pages[currentPage]
    for (let index = 0; index < page.length; index++) {
      const field = page[index]
      if (field.required) {
        if (!this.fieldHasValue(field, formInstance)) {
          return false
        }
      }
    }
    return true
  }

  fieldHasValue (field, formInstance) {
    for (let index = 0; index < formInstance.values.length; index++) {
      const fieldInstance = formInstance.values[index]
      if (fieldInstance.fieldId === field.id) {
        const value = fieldInstance.value
        const kvs = fieldInstance.keyValues
        const files = fieldInstance.files
        const rows = fieldInstance.rows
        if (value !== null && value.trim() !== '') {
          return true
        }
        if (kvs && kvs.length > 0) {
          return true
        }
        if (files && files.length > 0) {
          return true
        }
        if (rows && rows.length) {
          return true
        }
      }
    }
    return false
  }
}

FormPages.propTypes = {
  lang: PropTypes.string.isRequired,
  pages: PropTypes.array,
  currentPage: PropTypes.number.isRequired,
  setCurrenPage: PropTypes.func.isRequired,
  formInstance: PropTypes.object,
  fetchResumo: PropTypes.func.isRequired,
  setShowErrors: PropTypes.func.isRequired,
  saveAndFetchResumo: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  pages: state.formulario.pages,
  lang: state.linguas.lang,
  formInstance: state.formulario.formInstance,
  currentPage: state.formulario.currentPage
})

const mapDispatchToProps = { setCurrenPage, fetchResumo, setShowErrors, saveAndFetchResumo }

export default connect(mapStateToProps, mapDispatchToProps)(FormPages)
