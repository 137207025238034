import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import FormPages from './FormPages'
import FormPage from './FormPage'
import SaveSection from './SaveSection'
import { fetchFormulario, fetchFormInstance, setFormInstance, saveFormInstance, setCurrenPage, fetchResumo } from '../../modules/formulario'
import { getTranslation } from '../../request'
import styles from './Form.css'
import { addBreadcrumb, restoreBreadcrumb } from '../../modules/header'

class Form extends Component {
  constructor(props) {
    super(props)
    this.breadcrumbUpdated = false
  }

  componentDidMount () {
    const { permalink, formInstanceId } = this.props.match.params
    const { isResumo } = this.props
    if (isResumo) {
      this.props.fetchResumo(formInstanceId)
    }
    this.props.fetchFormulario(permalink)
    if (formInstanceId !== null && formInstanceId !== undefined) {
      this.props.fetchFormInstance(formInstanceId)
    }
  }

  componentDidUpdate () {
    const { formulario, previousLocation, previousBreadcrumb } = this.props
    const { permalink, formInstanceId } = this.props.match.params
    if (!this.breadcrumbUpdated && formulario !== undefined && formulario !== null && formulario.nome !== undefined) {
      this.breadcrumbUpdated = true
      if (previousLocation !== undefined && previousLocation !== null && previousBreadcrumb !== null) {
        if (previousLocation.indexOf('/servico-online/') === 0 || previousLocation.indexOf('/backoffice') === 0) {
          this.props.restoreBreadcrumb()
        }
      }
      this.props.addBreadcrumb({ link: '/formulario/' + permalink + '/' + formInstanceId, name: formulario.nome, translate: true })
    }
  }

  render () {
    const { formulario, lang, isResumo } = this.props
    if (isResumo) {
      return (
        <div className={styles.body}>
          <h2 className={styles.h2}>{!formulario ? '' : getTranslation(formulario.nome, lang)}</h2>
          <FormPage isResumo />
        </div>
      )
    }
    return (
      <div className={styles.body}>
        <div className={styles.titleWrapper}>
          <h2 className={styles.h2}>{!formulario ? '' : getTranslation(formulario.nome, lang)}</h2>
          <SaveSection />
        </div>
        <FormPages />
      </div>
    )
  }
}

Form.propTypes = {
  fetchFormulario: PropTypes.func.isRequired,
  fetchFormInstance: PropTypes.func.isRequired,
  setFormInstance: PropTypes.func.isRequired,
  saveFormInstance: PropTypes.func.isRequired,
  lang: PropTypes.string.isRequired,
  formulario: PropTypes.object,
  addBreadcrumb: PropTypes.func.isRequired,
  formInstance: PropTypes.object,
  previousLocation: PropTypes.string,
  previousBreadcrumb: PropTypes.array,
  restoreBreadcrumb: PropTypes.func.isRequired,
  setCurrenPage: PropTypes.func.isRequired,
  fetchResumo: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  formulario: state.formulario.formulario,
  formInstance: state.formulario.formInstance,
  lang: state.linguas.lang,
  previousLocation: state.header.previousLocation,
  previousBreadcrumb: state.header.previousBreadcrumb
})

const mapDispatchToProps = { fetchFormulario, fetchFormInstance, setFormInstance, saveFormInstance, addBreadcrumb, restoreBreadcrumb, setCurrenPage, fetchResumo }

export default connect(mapStateToProps, mapDispatchToProps)(Form)
