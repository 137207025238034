import React, { Component } from 'react'
import { InlineShareButtons } from 'sharethis-reactjs'

import styles from './Share.css'

export default class Share extends Component {
  render () {
    let url = window.location.href
    if (process.env.NODE_ENV !== 'production') {
      url = 'http://www.cmlajesdasflores.pt/'
    }
    const image = require('../../../img/heraldica.png')
    return (
      <div className={styles.body}>
        <InlineShareButtons
          config={{
            alignment: 'center',
            color: 'social',
            enabled: true,
            font_size: 16,
            labels: null,
            language: 'pt',
            networks: [
              'facebook',
              'messenger',
              'whatsapp',
              'email',
              'print',
              'twitter'
            ],
            padding: 15,
            radius: 0,
            show_total: false,
            size: 40,
            url: url,
            image: image,
            description: '',
            title: 'Câmara Municipal das Lajes das Flores',
            message: url,
            subject: 'Câmara Municipal das Lajes das Flores',
            username: 'custom twitter handle'
          }}
        />
      </div>
    )
  }
}
