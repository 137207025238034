import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import styles from './SocialNet.css'

export default class SocialNet extends Component {
  render () {
    return (
      <div className={styles.grid}>
        {/* <Link to='/'><div className={styles.socialIcon + ' ' + styles.twitter} /></Link> */}
        {/* <Link to='/'><div className={styles.socialIcon + ' ' + styles.googleplus} /></Link> */}
        {/* <Link to='https://www.facebook.com/municipio.flores'><div className={styles.socialIcon + ' ' + styles.facebook} /></Link> */}
        <a href='https://www.facebook.com/municipio.lajes.flores' target='_blank'>
          <div className={styles.socialIcon + ' ' + styles.facebook} />
        </a>
        {/* <Link to='/'><div className={styles.socialIcon + ' ' + styles.instagram} /></Link> */}
      </div>
    )
  }
}
