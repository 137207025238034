/* eslint-env browser */
import { host, computeLink, kc, getFetchBody } from '../request'
import { getImage } from '../lib/utils'
import axios from 'axios'

import store from '../store'

const FETCH_MODEL = 'cmlf/model/FETCH_MODEL'
const RESET_MODEL = 'cmlf/model/RESET_MODEL'
const GET_RELATED = 'cmlf/model/GET_RELATED'
const GET_RELATED_LEFT = 'cmlf/model/GET_RELATED_LEFT'
const GET_RELATED_RIGHT = 'cmlf/model/GET_RELATED_RIGHT'
const MODEL_LOADING = 'cmlf/model/MODEL_LOADING'
const SET_MODEL_CONTENTS = 'cmlf/model/SET_MODEL_CONTENTS'

export const resetModel = () => ({ type: RESET_MODEL })

const initialState = {
  model: null,
  relatedObjects: [],
  relatedModelLeft: null,
  relatedModelRight: null,
  modelContents: [],
  modelIsLoading: false
}

export const modelLoading = (loading) => ({ type: MODEL_LOADING, loading })
export const setModelContents = (contents) => ({ type: SET_MODEL_CONTENTS, contents })

export function getRelated (permalink) {
  return (dispatch) => {
    resetModel()
    axios.get(host + '/api/public/categorias/getRelatedByPermalink/' + permalink)
      .then(res => res.data)
      .then(post => {
        dispatch({
          type: GET_RELATED,
          payload: post
        })
        dispatch({
          type: GET_RELATED_LEFT,
          payload: null
        })
        dispatch({
          type: GET_RELATED_LEFT,
          payload: null
        })
        fetchRelatedLeft(dispatch, post)
        fetchRelatedRight(dispatch, post)
      })
      .catch((error) => {
        console.log(error)
        dispatch({
          type: GET_RELATED,
          payload: []
        })
      })
  }
}

export function fetchModel (path) {
  return (dispatch) => {
    resetModel()
    dispatch(modelLoading(true))
    axios.get(host + '/api/public/getObject' + path)
      .then(res => res.data)
      .then(post => {
        if (!post || post === '') {
          dispatch({
            type: FETCH_MODEL,
            payload: null
          })
          return
        }
        post.link = computeLink(post)
        if (post.type === 'document') {
          post.downloadLink = host + '/api/public/get/mediaFile/documento/' + post.permalink
          // post.downloadLink = host + '/api/public/mediaFile/' + post.id
        }
        post.image = getImage(post.imagem)
        dispatch({
          type: FETCH_MODEL,
          payload: post
        })
        dispatch(setModelContents(post.contents))
        dispatch(modelLoading(false))
      })
      .catch((error) => {
        console.log(error)
        dispatch({
          type: FETCH_MODEL,
          payload: null
        })
        dispatch(setModelContents([]))
        dispatch(modelLoading(false))
      })
  }
}
export function fetchModel1 (path) {
  return (dispatch) => {
    dispatch(modelLoading(true))
    fetch(host + '/api/public/getObject' + path, getFetchBody('GET', kc))
      .then(res => res.json())
      .then(post => {
        post.link = computeLink(post)
        if (post.type === 'document') {
          post.downloadLink = host + '/api/public/get/mediaFile/documento/' + post.permalink
          // post.downloadLink = host + '/api/public/mediaFile/' + post.id
        }
        post.image = getImage(post.imagem)
        dispatch({
          type: FETCH_MODEL,
          payload: post
        })
        dispatch(setModelContents(post.contents))
        dispatch(modelLoading(false))
      })
  }
}

export function fetchContentObjectoBase (content) {
  return (dispatch) => {
    axios.get(host + '/api/public/objectoBase/' + content.objectId)
      .then(res => res.data)
      .then(post => {
        post.link = computeLink(post)
        if (post.type === 'document') {
          post.downloadLink = host + '/api/public/get/mediaFile/documento/' + post.permalink
        }
        post.image = getImage(post.imagem)
        content.loaded = true
        content.objectoBase = post
        const model = { ...store.getState().model.model }
        dispatch({
          type: FETCH_MODEL,
          payload: model
        })
      })
      .catch((error) => {
        console.log(error)
        content.loaded = true
        content.objectoBase = null
        const model = { ...store.getState().model.model }
        dispatch({
          type: FETCH_MODEL,
          payload: model
        })
      })
  }
}
export default function reducer (state = initialState, action = {}) {
  switch (action.type) {
    case FETCH_MODEL:
      return { ...state, model: action.payload }
    case RESET_MODEL:
      return { ...state, model: null }
    case GET_RELATED:
      return { ...state, relatedObjects: action.payload }
    case GET_RELATED_LEFT:
      return { ...state, relatedModelLeft: action.payload }
    case GET_RELATED_RIGHT:
      return { ...state, relatedModelRight: action.payload }
    case MODEL_LOADING:
      return { ...state, modelIsLoading: action.loading }
    case SET_MODEL_CONTENTS:
      return { ...state, modelContents: action.contents }
    default:
      return state
  }
}

function fetchRelatedLeft (dispatch, objects) {
  if (!objects || objects.length === 0) {
    dispatch({
      type: GET_RELATED_LEFT,
      payload: null
    })
    return
  } else {
    axios.get(host + '/api/public/objectoBase/' + objects[0].id)
      .then(res => res.data)
      .then(post => {
        post.link = computeLink(post)
        if (post.type === 'document') {
          post.downloadLink = host + '/api/public/get/mediaFile/documento/' + post.permalink
          // post.downloadLink = host + '/api/public/mediaFile/' + post.id
        }
        post.image = getImage(post.imagem)
        dispatch({
          type: GET_RELATED_LEFT,
          payload: post
        })
      })
      .catch((error) => {
        console.log(error)
        dispatch({
          type: GET_RELATED_LEFT,
          payload: null
        })
      })
  }
}

function fetchRelatedRight (dispatch, objects) {
  if (!objects || objects.length < 2) {
    dispatch({
      type: GET_RELATED_RIGHT,
      payload: null
    })
    return
  } else {
    axios.get(host + '/api/public/objectoBase/' + objects[1].id)
      .then(res => res.data)
      .then(post => {
        post.link = computeLink(post)
        if (post.type === 'document') {
          post.downloadLink = host + '/api/public/get/mediaFile/documento/' + post.permalink
          // post.downloadLink = host + '/api/public/mediaFile/' + post.id
        }
        post.image = getImage(post.imagem)
        dispatch({
          type: GET_RELATED_RIGHT,
          payload: post
        })
      })
      .catch((error) => {
        console.log(error)
        dispatch({
          type: GET_RELATED_RIGHT,
          payload: null
        })
      })
  }
}
