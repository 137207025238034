import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import common from '../common/Body.css'
import spinner from '../../../css/Spinner.css'
import { getCatFilteredObjects, filterCategoriasFromObjectos } from '../../../modules/categorias'
import { setResultSize } from '../../../modules/navigation'
import { ymdToDate } from '../../../lib/utils'
import NavObjects from '../common/NavObjects'
import ModelListItem from '../common/ModelListItem'

class EventosList extends Component {
  componentDidMount () {
    this.noShadow = true
  }

  componentDidUpdate () {
    const { categorias, events, filterCategorias, selectedDay } = this.props
    // this.props.setResultSize(events.length)
    if (filterCategorias) {
      this.props.filterCategoriasFromObjectos(events, categorias)
    }
    // const { events, categorias, selectedDay } = this.props
    let filteredList = []
    filteredList = getCatFilteredObjects(events, categorias)
    filteredList = this.filterFromSelectedDay(filteredList, selectedDay)
    this.props.setResultSize(filteredList.length)
    // this.props.filterCategoriasFromObjectos(filteredList, categorias)
  }

  render () {
    const { short, eventsAreLoading } = this.props
    if (eventsAreLoading) {
      return (
        <div className={common.listContainer + (short ? ' ' + common.short : '')}>
          <div className={common.listGrid}>
            <div className={spinner.loader} />
          </div>
        </div>
      )
    }
    let objectos = this.filterObjects()
    return (
      <div className={common.listContainer + (short ? ' ' + common.short : '')}>
        <div className={common.listGrid}>
          {this.buildObjectList(objectos, short)}
        </div>
        <NavObjects />
      </div>
    )
  }

  filterObjects () {
    const { events, categorias, currentPage, pageSize, selectedDay } = this.props
    let objectos = []
    objectos = getCatFilteredObjects(events, categorias)
    objectos = this.filterFromSelectedDay(objectos, selectedDay)
    return this.getListSubSet(objectos, currentPage, pageSize)
  }

  buildObjectList (objectos, short) {
    const { pageSize } = this.props
    return (
      <ul className={common.ul}>
        {
          this.buildLines(objectos, pageSize, short)
        }
      </ul>
    )
  }

  buildLines (objectos, pageSize, short) {
    const { model } = this.props
    const fakeLines = 0 // pageSize - objectos.length
    let list = []
    objectos.map((obj, index) => {
      let isModel = model && model.id === obj.id
      list.push(<ModelListItem
        key={index}
        obj={obj}
        index={index}
        isModel={isModel}
        short={short}
        prettyDate={obj.prettyDate}
        titulo={obj.title}
        resumo={obj.obj.resumo} />)
    })
    if (fakeLines > 0) {
      for (let index = 0; index < fakeLines; index++) {
        list.push(
          <li className={!short ? common.liFake : common.liFakeShort} key={'fake-' + index} />
        )
      }
    }
    return list
  }

  filterFromSelectedDay (objects, selectedDay) {
    if (!objects) {
      return []
    }
    if (!selectedDay) {
      return objects
    }
    const dayPlus1 = new Date(selectedDay)
    dayPlus1.setDate(dayPlus1.getDate() + 1)
    let result = objects.filter(function (obj) {
      return obj !== null && (ymdToDate(obj.start) >= selectedDay || (obj.end !== null && ymdToDate(obj.end) >= selectedDay))
    })
    return result
  }

  getListSubSet (arr, currentPage, pageSize) {
    const start = (currentPage - 1) * pageSize
    const end = start + pageSize
    if (arr.length < end) {
      return arr.slice(start)
    }
    return arr.slice(start, end)
  }
}

EventosList.propTypes = {
  events: PropTypes.array.isRequired,
  categorias: PropTypes.array.isRequired,
  currentPage: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  model: PropTypes.object,
  lang: PropTypes.string.isRequired,
  setResultSize: PropTypes.func.isRequired,
  selectedDay: PropTypes.object,
  filterCategoriasFromObjectos: PropTypes.func.isRequired,
  eventsAreLoading: PropTypes.bool.isRequired
}

const mapStateToProps = state => ({
  events: state.events.events,
  categorias: state.categorias.categorias,
  currentPage: state.navigation.currentPage,
  pageSize: state.navigation.pageSize,
  model: state.model.model,
  lang: state.linguas.lang,
  selectedDay: state.calendar.selectedDay,
  eventsAreLoading: state.events.eventsAreLoading
})

const mapDispatchToProps = { setResultSize, filterCategoriasFromObjectos }

export default connect(mapStateToProps, mapDispatchToProps)(EventosList)
