import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { setSelectedButton, setBreadcrumb, addBreadcrumb } from '../../../modules/header'
import { setObjectType, enableToolbar, disableToolbar, selectToolbar } from '../../../modules/toolbar'
import { setMenuType, fetchAvailableMenus, fetchPastas, setPathSelectedPermalink, setPathDestinationPermalink } from '../../../modules/pastas'
import { setCurrentScope } from '../../../modules/search'
import { setShowDays, setShowNews } from '../../../modules/calendar'
import { setClickedLocal } from '../../../modules/locais'
import Header from './Header'
import styles from './ObjectWrapper.css'
import Documentos from '../Documentos/Documentos'
import Eventos from '../Eventos/Eventos'
import Paginas from '../Paginas/Paginas'
import Locais from '../Locais/Locais'
import Servicos from '../Servicos/Servicos'
import Noticias from '../Noticias/Noticias'
import Breadcrumb from './Breadcrumb'
import Toolbar from './Toolbar'
import ModelWrapper from './ModelWrapper'
import { resetGallery } from '../../../modules/gallery'
import { setPastaClicked } from '../../../modules/documentos'
import Share from './Share'

class ObjectWrapper extends Component {
  constructor(props) {
    super(props)
    this.props.fetchAvailableMenus()
    this.props.setClickedLocal(null)
  }

  componentDidMount() {
    this.props.setPathSelectedPermalink(this.getPathSelectedFolder(this.props))
    this.props.setPathDestinationPermalink(this.getPathDestinationFolder(this.props))
  }

  componentDidUpdate() {
    const { objName, availableMenusAreLoading } = this.props
    if (availableMenusAreLoading) {
      return
    }
    let n = this.getSelectedButton(objName)
    if (n !== -1) {
      this.props.setSelectedButton(this.getSelectedButton(objName))
      this.props.setCurrentScope(this.getCurrentScope(objName))
    }
    this.updateBreadcrumbs(objName)
    this.props.resetGallery()
    this.updateObjectType(objName)
    this.props.setPastaClicked(false)
  }

  render() {
    const { objName, mobile, availableMenusAreLoading } = this.props
    if (availableMenusAreLoading) {
      return null
    }
    this.showToolbars = this.computeShowToolbars(objName)
    if (this.isSingle(objName)) this.showToolbars = false
    const backoffice = objName === 'backoffice'
    return (
      <div className={styles.document + (backoffice ? ' ' + styles.backoffice : '')}>
        <Header objName={objName} />
        {
          !mobile
            ? <Breadcrumb {...this.props} backoffice={backoffice} />
            : <Breadcrumb {...this.props} backoffice={backoffice} />
        }

        {this.showToolbars
          ? <div className={styles.body +
            (this.isSingle(objName)
              ? ' ' + styles.single
              : '')}>
            {
              this.showToolbars
                ? <div className={styles.toolbar}>
                  <Toolbar {...this.props} />
                </div>
                : null
            }
            <div className={styles.pageBody}>
              {this.getObject(objName)}
            </div>
            <Share />
          </div>
          : <div className={styles.bodyNoToolbars}>
            <div className={styles.pageBody}>
              {this.getObject(objName)}
            </div>
            <Share />
          </div>}
      </div>
    )
  }

  getPathSelectedFolder(props) {
    if (!props.match) {
      return null
    }
    const { psel } = props.match.params
    return !psel ? null : psel
  }

  getPathDestinationFolder(props) {
    if (!props.match) {
      return null
    }
    const { pdest } = props.match.params
    return !pdest ? null : pdest
  }

  computeShowToolbars(objName) {
    switch (objName) {
      case 'paginas':
      case 'locais':
      case 'servicos-online':
      case 'servicos':
      case 'documentos':
      case 'noticias':
      case 'eventos':
      case 'servico-online':
      case 'servico':
      case 'pagina':
      case 'local':
      case 'evento':
      case 'noticia':
        return true
      default:
        return false
    }
  }

  updateObjectType(objName) {
    const { lang, availableMenus } = this.props
    this.props.disableToolbar('calendar')
    this.props.disableToolbar('folders')
    this.props.disableToolbar('menu')
    this.props.disableToolbar('tags')
    this.props.setMenuType(0)
    switch (objName) {
      case 'pagina':
      // fallsthrough
      case 'paginas':
        this.props.setObjectType('pagina')
        if (availableMenus.paginas) {
          this.props.setMenuType(1)
          this.props.enableToolbar('menu')
          this.props.selectToolbar('menu')
          this.props.fetchPastas(lang, 1)
        } else {
          this.props.selectToolbar('tags')
        }
        if (objName === 'paginas') {
          this.props.enableToolbar('tags')
        }
        break
      case 'local':
      // fallsthrough
      case 'locais':
        this.props.setObjectType('local')
        if (availableMenus.locais) {
          this.props.setMenuType(4)
          this.props.enableToolbar('menu')
          this.props.selectToolbar('menu')
          this.props.fetchPastas(lang, 4)
        } else {
          this.props.selectToolbar('tags')
        }
        if (objName === 'locais') {
          this.props.enableToolbar('tags')
        }
        break
      case 'servico-online':
      // fallsthrough
      case 'servicos-online':
        this.props.setObjectType('servico')
        if (availableMenus.servicosOnline) {
          this.props.setMenuType(2)
          this.props.enableToolbar('menu')
          this.props.selectToolbar('menu')
          this.props.fetchPastas(lang, 2)
        } else {
          this.props.selectToolbar('tags')
        }
        if (objName === 'servicos-online') {
          this.props.enableToolbar('tags')
        }
        break
      case 'servico':
      // fallsthrough
      case 'servicos':
        this.props.setObjectType('servico')
        if (availableMenus.servicos) {
          this.props.setMenuType(3)
          this.props.enableToolbar('menu')
          this.props.selectToolbar('menu')
          this.props.fetchPastas(lang, 3)
        } else {
          this.props.selectToolbar('tags')
        }
        if (objName === 'servicos') {
          this.props.enableToolbar('tags')
        }
        break
      case 'documentos':
        this.props.setObjectType('documento')
        this.props.enableToolbar('folders')
        this.props.selectToolbar('folders')
        this.props.enableToolbar('tags')
        break
      case 'noticia':
      case 'noticias':
        this.props.setObjectType('noticia')
        this.props.enableToolbar('calendar')
        if (objName === 'noticias') {
          this.props.selectToolbar('calendar')
          this.props.enableToolbar('tags')
        } else {
          this.props.selectToolbar('')
        }
        this.props.setShowDays(false)
        this.props.setShowNews(true)
        break
      case 'evento':
      case 'eventos':
        this.props.setObjectType('evento')
        this.props.enableToolbar('calendar')
        if (objName === 'eventos') {
          this.props.selectToolbar('calendar')
          this.props.enableToolbar('tags')
        } else {
          this.props.selectToolbar('')
        }
        this.props.setShowDays(true)
        this.props.setShowNews(false)
        break
      default:
        break
    }
  }
  updateBreadcrumbs(objName) {
    this.props.setBreadcrumb([])
    switch (objName) {
      case 'paginas':
      case 'pagina':
        this.props.addBreadcrumb({ link: '/paginas', name: 'header.cityHall' })
        break
      case 'locais':
      case 'local':
        this.props.addBreadcrumb({ link: '/locais', name: 'body.visit' })
        break
      case 'servicos-online':
      case 'servico-online':
      case 'formulario':
        this.props.addBreadcrumb({ link: '/servicos-online', name: 'body.onlineServices' })
        break
      case 'servicos':
      case 'servico':
        this.props.addBreadcrumb({ link: '/servicos', name: 'body.live' })
        break
      case 'documentos':
      case 'documento':
        this.props.addBreadcrumb({ link: '/documentos', name: 'body.publications' })
        break
      case 'noticias':
      case 'noticia':
        this.props.addBreadcrumb({ link: '/noticias', name: 'news.news' })
        break
      case 'eventos':
      case 'evento':
        this.props.addBreadcrumb({ link: '/eventos', name: 'events.events' })
        break
      case 'backoffice':
        this.props.addBreadcrumb({ link: '/backoffice', name: 'menu.backoffice' })
        break
      default:
        break
    }
  }

  // consoante o objecto devolve a lista que deverá acompanhar
  getObjectShortList(objName) {
    if (this.props.mobile) {
      return null
    }
    switch (objName) {
      case 'pagina':
        return <Paginas {...this.props} short />
      case 'servico':
        return <Servicos {...this.props} online='false' short />
      case 'servico-online':
        return <Servicos {...this.props} online='true' short />
      case 'local':
        return <Locais {...this.props} short />
      case 'noticia':
        return <Noticias {...this.props} short />
      case 'evento':
        return <Eventos {...this.props} short />
      default:
        return null
    }
  }

  isSingle(objName) {
    switch (objName) {
      case 'paginas':
      case 'locais':
      case 'servicos-online':
      case 'servicos':
      case 'documentos':
      case 'noticias':
      case 'eventos':
        return false
      default:
        return true
    }
  }

  getObject(objName) {
    const minY = 250
    if (window.scrollY > minY) {
      window.scroll({
        top: minY,
        left: 0,
        behavior: 'smooth'
      })
    }
    switch (objName) {
      case 'paginas':
        return <Paginas {...this.props} />
      case 'locais':
        return <Locais {...this.props} />
      case 'servicos-online':
        return <Servicos online='true' {...this.props} />
      case 'servicos':
        return <Servicos online='false' {...this.props} />
      case 'documentos':
        return <Documentos {...this.props} />
      case 'noticias':
        return <Noticias {...this.props} />
      case 'eventos':
        return <Eventos {...this.props} />
      case 'backoffice':
      case 'formulario':
      case 'formInstance':
      case 'pagina':
      case 'local':
      case 'servico':
      case 'documento':
      case 'noticia':
      case 'evento':
        return <ModelWrapper {...this.props} />
      case 'servico-online':
        return <ModelWrapper {...this.props} online />
      default:
        return <Documentos />
    }
  }

  getSelectedButton(objName) {
    switch (objName) {
      case 'paginas':
      case 'pagina':
        return 0
      case 'locais':
      case 'local':
        return 1
      case 'servicos-online':
      case 'servico-online':
        return 2
      case 'servicos':
      case 'servico':
        return 3
      case 'documentos':
      case 'documento':
        return 4
      case 'noticias':
      case 'noticia':
        return 5
      case 'eventos':
      case 'evento':
        return 6
      default:
        return -1
    }
  }

  getCurrentScope(objName) {
    switch (objName) {
      case 'paginas':
      case 'pagina':
        return 1
      case 'locais':
      case 'local':
        return 2
      case 'servicos-online':
      case 'servico-online':
        return 3
      case 'servicos':
      case 'servico':
        return 4
      case 'documentos':
      case 'documento':
        return 5
      case 'noticias':
      case 'noticia':
        return 6
      case 'eventos':
      case 'evento':
        return 7
      default:
        return -1
    }
  }
}

ObjectWrapper.propTypes = {
  lang: PropTypes.string.isRequired,
  setSelectedButton: PropTypes.func.isRequired,
  setBreadcrumb: PropTypes.func.isRequired,
  addBreadcrumb: PropTypes.func.isRequired,
  setCurrentScope: PropTypes.func.isRequired,
  setObjectType: PropTypes.func.isRequired,
  enableToolbar: PropTypes.func.isRequired,
  disableToolbar: PropTypes.func.isRequired,
  setShowDays: PropTypes.func.isRequired,
  setShowNews: PropTypes.func.isRequired,
  selectToolbar: PropTypes.func.isRequired,
  setMenuType: PropTypes.func.isRequired,
  availableMenus: PropTypes.object.isRequired,
  fetchAvailableMenus: PropTypes.func.isRequired,
  fetchPastas: PropTypes.func.isRequired,
  setClickedLocal: PropTypes.func.isRequired,
  mobile: PropTypes.bool.isRequired,
  resetGallery: PropTypes.func.isRequired,
  setPathSelectedPermalink: PropTypes.func.isRequired,
  setPathDestinationPermalink: PropTypes.func.isRequired,
  setPastaClicked: PropTypes.func.isRequired,
  availableMenusAreLoading: PropTypes.bool.isRequired
}

const mapStateToProps = state => ({
  lang: state.linguas.lang,
  availableMenus: state.pastas.availableMenus,
  mobile: state.global.mobile,
  refreshPage: state.global.refreshPage,
  availableMenusAreLoading: state.pastas.availableMenusAreLoading
})

const mapDispatchToProps = { setSelectedButton, setCurrentScope, setBreadcrumb, addBreadcrumb, setObjectType, enableToolbar, disableToolbar, selectToolbar, setShowDays, setShowNews, setMenuType, fetchAvailableMenus, fetchPastas, setClickedLocal, resetGallery, setPathSelectedPermalink, setPathDestinationPermalink, setPastaClicked }

export default connect(mapStateToProps, mapDispatchToProps)(ObjectWrapper)
