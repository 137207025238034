import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { FormattedMessage as FM } from 'react-intl'
import { Link } from 'react-router-dom'

import styles from './FormPage.css'
import commonStyles from './Fields/CommonStyles.css'
import { setCurrenPage, removeFormInstance, submitFormInstance, setFormInstance } from '../../modules/formulario'
import { fetchInfoFormInProgress } from '../../modules/servicos'
import { getTranslation, getStatus } from '../../request'
import { prettyDate, isOdd } from '../../lib/utils'
import FormField from './FormField'
import messages from '../App/messages'

class FormPage extends Component {
  constructor(props) {
    super(props)
    this.removeFormInstance = this.removeFormInstance.bind(this)
    this.submitFormInstance = this.submitFormInstance.bind(this)
    this.obsChanged = this.obsChanged.bind(this)
  }

  render() {
    const { pages } = this.props
    return (
      <div>
        {this.buildFieldsList(pages)}
      </div>
    )
  }

  buildFieldsList(pages) {
    const { currentPage, showErrors, isResumo } = this.props
    if (!pages || pages.length === 0) {
      return null
    }
    if (currentPage === -1 || isResumo) {
      return this.buildPageResumo()
    }
    let page = pages[currentPage]
    return (
      <div>
        <ul className={styles.ul}>
          {
            page.map((field) => (
              <FormField key={field.id} field={field} showErrors={showErrors} />
            ))
          }
        </ul>
      </div>
    )
  }

  buildPageResumo() {
    const { resumo, lang, formInstance, isResumo } = this.props
    if (!formInstance) {
      return null
    }
    const submitDisabled = !this.okToSubmit(formInstance)
    return (
      <div>
        {this.getResumoDetail(formInstance, resumo, lang)}
        {this.putObs(formInstance)}
        {
          isResumo
            ? this.buildLog(formInstance)
            : null
        }
        {
          !isResumo
            ? <div className={styles.buttonsContainer}>
              <input
                type='button'
                disabled={submitDisabled}
                className={commonStyles.button + (submitDisabled ? ' ' + commonStyles.disabled : '')}
                value={messages[lang]['form.submit']}
                onClick={this.submitFormInstance} />
              {
                formInstance.status === 0
                  ? <input type='button' className={commonStyles.button} value={messages[lang]['form.cancel']} onClick={this.removeFormInstance} />
                  : null
              }
            </div>
            : <div className={styles.buttonsContainer}>
              <Link to={'/backoffice'} className={commonStyles.button + ' ' + styles.linkButton}>
                <div style={{ margin: 'auto' }}>
                  {messages[lang]['global.return']}
                </div>
              </Link>
            </div>
        }
      </div>
    )
  }

  buildLog(formInstance) {
    if (!formInstance || !formInstance.log) {
      return null
    }
    const { lang } = this.props
    let log = formInstance.log.filter(entry => {
      return (entry.status !== 5)
    })
    return (
      <div>
        <h3 className={styles.h3}>{messages[lang]['form.brief.log']}</h3>
        {
          log.map((log, index) => (
            <div key={log.id} className={styles.detailDiv + (isOdd(index) ? ' ' + styles.odd : '')} >
              <div>{messages[lang]['global.date']}:</div><div className={styles.itemDate}>{prettyDate(log.data, lang)}</div>
              <div>{messages[lang]['global.status']}:</div><div className={styles.itemStatus}>{getStatus(log.status, lang)}</div>
              <div>{messages[lang]['global.who']}:</div><div className={styles.itemUsername}>{log.username}</div>
              <div>{messages[lang]['form.obs']}:</div><div className={styles.itemObs}>{log.obs}</div>
            </div>
          ))
        }
      </div>
    )
  }

  statusIn(status) {
    switch (status) {
      case 0:
      case 1:
        return false
      default:
        return true
    }
  }

  putObs(formInstance) {
    if (!formInstance) {
      return null
    }
    const { isResumo } = this.props
    if (isResumo) {
      return (
        <div className={styles.obsWrapper}>
          <label htmlFor='formInstanceObs'><FM id='form.obs' defaultMessage='Observações a enviar' /></label>
          <textarea id='formInstanceObs' className={styles.textArea} rows='4' defaultValue={formInstance.obs} readOnly />
        </div>
      )
    }
    return (
      <div className={styles.obsWrapper}>
        <label htmlFor='formInstanceObs'><FM id='form.obs' defaultMessage='Observações a enviar' /></label>
        <textarea id='formInstanceObs' className={styles.textArea} rows='4' value={formInstance.newObs} onChange={this.obsChanged} />
      </div>
    )
  }

  obsChanged(e) {
    const { formInstance } = this.props
    formInstance.newObs = e.target.value
    this.props.setFormInstance(formInstance)
  }

  submitFormInstance(e) {
    const { formInstance, history: { push, goBack }, history, backofficeVisited } = this.props
    if (this.okToSubmit(formInstance)) {
      this.props.submitFormInstance(formInstance.id, formInstance.newObs, this.props.lang)
      if (history.length > 1 && backofficeVisited) {
        goBack()
      } else {
        if (!formInstance.servico) {
          push('/formulario/' + formInstance.formulario.permalink)
        } else {
          push('/servico-online/' + formInstance.servico.permalink)
        }
      }
    } else {
      alert("Existem campos por preencher")
    }
  }

  removeFormInstance(e) {
    const { formInstance, formulario, history: { push, goBack }, history, backofficeVisited } = this.props
    this.props.removeFormInstance(formInstance.id, formulario.id, this.props)
    if (history.length > 1 && backofficeVisited) {
      goBack()
    } else {
      if (!formInstance.servico) {
        push('/formulario/' + formulario.permalink)
      } else {
        push('/servico-online/' + formInstance.servico.permalink)
      }
    }
  }

  getResumoDetail(formInstance, resumo, lang) {
    if (!resumo || !formInstance || !formInstance.formulario || !formInstance.formulario.campos) {
      return null
    }
    let html = []
    formInstance.formulario.campos.map(campo => {
      if (campo.type === 'header') {
        if (this.isFirstHeader(campo, formInstance.formulario.campos)) {
          html.push(
            <li key={campo.id} className={styles.liResumo}>
              <div className={styles.itemFirstHeader}>{getTranslation(campo.label, lang)}</div>
            </li>
          )
        } else {
          html.push(
            <li key={campo.id} className={styles.liResumo}>
              <div className={styles.itemHeader}>{getTranslation(campo.label, lang)}</div>
            </li>
          )
        }
      } else if (campo.type === 'paragraph') {
        html.push(
          <li key={campo.id} className={styles.liResumo}>
            <div className={styles.itemParagraph}>{getTranslation(campo.label, lang)}</div>
          </li>
        )
      }
      resumo.map((field) => {
        if (field.fieldId === campo.id) {
          if (field.values !== undefined && field.values !== null) {
            html.push(
              <li key={field.fieldId} className={styles.liResumo}>
                <span>{getTranslation(field.label, lang)}</span>:&nbsp;
                <div className={styles.itemResumo}>{this.getValues(field.values)}</div>
              </li>
            )
          } else if (field.files && field.files.length > 0) {
            html.push(
              <li key={field.fieldId} className={styles.liResumo}>
                <span>{getTranslation(field.label, lang)}</span>:&nbsp;
                <div className={styles.itemResumo}>{this.getValues(field.files)}</div>
              </li>
            )
          } else if (field.rows) {
            console.log('colocar a tabela aqui')
            console.log('campo', campo)
            html.push(
              <li key={field.fieldId} className={styles.liResumo}>
                <span>{getTranslation(field.label, lang)}</span>:&nbsp;
                <div className={styles.itemResumo}>{this.buildTable(field.rows, campo.values, lang)}</div>
              </li>
            )
          } else {
            html.push(
              <li key={field.fieldId} className={styles.liResumo}>
                <span>{getTranslation(field.label, lang)}</span>:&nbsp;
                <div className={styles.itemResumo}>{field.value}</div>
              </li>
            )
          }
        }
      })
    })
    return (
      <ul className={styles.ul}>
        {html}
      </ul>
    )
  }

  isFirstHeader(campo, campos) {
    if (!campos || !campos.length) {
      return false
    }
    let newPage = false
    for (let index = 0; index < campos.length; index++) {
      const element = campos[index]
      if ((index === 0 || newPage) && element.id === campo.id) {
        return true;
      } else if (element.type === 'pageSeparator') {
        newPage = true
      } else {
        newPage = false
      }
    }
    return false
  }

  buildTable(rows, headerDef, lang) {
    if (!headerDef) {
      return null
    }
    const cols = this.getHeaderLang(headerDef, lang)
    return (
      <table>
        <thead>
          <tr>
            <th />
            {this.buildTableHeader(cols)}
          </tr>
        </thead>
        <tbody>
          {this.buildRows(rows, cols)}
        </tbody>
      </table>
    )
  }

  buildRows(rows, cols) {
    if (!rows.length) {
      return (
        <tr>
          <td />
          {cols.map((col, index) => (
            <td key={index}>-</td>
          ))}
        </tr>
      )
    }
    return (
      rows.map(row => (
        this.buildRow(row)
      ))
    )
  }

  buildRow(row) {
    return (
      <tr key={row.id}>
        <td />
        {
          row.cells.map(cell => (
            <td key={cell.id}>{cell.value}</td>
          ))
        }
      </tr>
    )
  }

  buildTableHeader(cols) {
    return (
      cols.map((col, index) => (
        <th key={index}>{col}</th>
      ))
    )
  }

  getHeaderLang(def, lang) {
    let result = []
    def.forEach(col => {
      if (col.lang === lang) {
        result.push(col.label)
      }
    })
    return result
  }

  getValues(values) {
    let result = []
    result.push(
      <div key={'kk'} className={styles.valuesWrapper}>
        {this.getValuesDiv(values)}
      </div>
    )
    return result
  }

  getValuesDiv(values) {
    let result = []
    for (let index = 0; index < values.length; index++) {
      const element = values[index]
      result.push(
        <span key={index}>{element}</span>
      )
    }
    return result
  }

  okToSubmit(formInstance) {
    if (!formInstance) return false
    let result = true
    formInstance.values.forEach(value => {
      const field = this.getFieldDef(value, formInstance)
      if (field && field.required) {
        const valor = value.value
        const kvs = value.keyValues
        const files = value.files
        const rows = value.rows
        let hasValue = false
        if (valor !== null && valor.trim() !== '') {
          hasValue = true
        }
        if (kvs && kvs.length > 0) {
          hasValue = true
        }
        if (files && files.length > 0) {
          hasValue = true
        }
        if (rows && rows.length) {
          hasValue = true
        }
        if (!hasValue) result = false
      }
    })
    return result
  }

  getFieldDef(value, formInstance) {
    const fieldDefs = formInstance.formulario.campos
    let result = null
    fieldDefs.forEach(field => {
      if (field.id === value.fieldId) {
        result = field
      }
    })
    return result
  }
}

FormPage.propTypes = {
  lang: PropTypes.string.isRequired,
  pages: PropTypes.array,
  currentPage: PropTypes.number.isRequired,
  setCurrenPage: PropTypes.func.isRequired,
  resumo: PropTypes.array,
  removeFormInstance: PropTypes.func.isRequired,
  submitFormInstance: PropTypes.func.isRequired,
  formInstance: PropTypes.object,
  fetchInfoFormInProgress: PropTypes.func.isRequired,
  formulario: PropTypes.object,
  setFormInstance: PropTypes.func.isRequired,
  showErrors: PropTypes.bool.isRequired
}

const mapStateToProps = state => ({
  pages: state.formulario.pages,
  lang: state.linguas.lang,
  currentPage: state.formulario.currentPage,
  formInstance: state.formulario.formInstance,
  formulario: state.formulario.formulario,
  resumo: state.formulario.resumo,
  backofficeVisited: state.backoffice.visited,
  showErrors: state.formulario.showErrors
})

const mapDispatchToProps = { setCurrenPage, removeFormInstance, fetchInfoFormInProgress, submitFormInstance, setFormInstance }

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FormPage))
