import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { FormattedMessage as FM } from 'react-intl'

import { fetchModel, getRelated } from '../../../modules/model'
import { getTranslation } from '../../../request'
import { addBreadcrumb } from '../../../modules/header'
import { getDateStr } from '../../../lib/utils'
import styles from '../common/ObjectoBase.css'
import indexStyles from '../../../index.css'
import { MyMapComponent } from './MyMapComponent'
import RelatedObjects from '../common/RelatedObjects'
import Contents from '../contents/Contents'
import Resumo from '../modelBlocks/Resumo'
import Descricao from '../modelBlocks/Descricao'
import TagsList from '../modelBlocks/TagsList'
import { fetchCategorias } from '../../../modules/categorias'
import GalleryImage from '../common/GalleryImage'
import spinner from '../../../css/Spinner.css'

class Local extends Component {
  componentDidMount () {
    const { permalink } = this.props.match.params
    this.props.fetchModel('/local/' + permalink)
    this.props.getRelated(permalink)
    const { categorias, fetchCategorias, lang } = this.props
    if (!categorias || categorias.length === 0) {
      fetchCategorias(lang, 'local')
    }
  }

  componentDidUpdate () {
    const { model } = this.props
    const { permalink } = this.props.match.params
    if (model !== undefined && model !== null && permalink === model.permalink) {
      this.props.addBreadcrumb({ link: '/local/' + permalink, name: model.titulo, translate: true })
    }
  }

  render () {
    const { lang, model, modelIsLoading } = this.props
    if (modelIsLoading || !model) {
      return (
        <div className={styles.layoutGrid}>
          <div className={spinner.loader} />
        </div>
      )
    }
    let includeContents = false
    if (!(!model.contents || model.contents.length === 0)) {
      includeContents = true
    }
    return (
      <div className={styles.layoutGrid}>
        <div className={styles.objectGrid + ' ' + styles.map + (includeContents ? ' ' + styles.layout : '')}>
          <div className={styles.titleArea}>
            <div className={styles.titleDiv}>
              {getTranslation(model.titulo, lang)}
            </div>
            {!model.publicationDate
              ? null
              : <div className={styles.dateDiv}>
                <FM id='global.publishedAt' defaultMessage='Publicado em' /> &nbsp;
                {getDateStr(model.publicationDate, lang)}
              </div>
            }
          </div>
          <Resumo model={model} lang={lang} />
          <GalleryImage />
          <Descricao model={model} lang={lang} />
          <div className={styles.mapDiv + ' ' + indexStyles.shadow}><MyMapComponent model={model} /></div>
          {
            includeContents
              ? <div className={styles.layoutDiv}>
                <Contents />
              </div>
              : null
          }
          <TagsList />
        </div>
        <RelatedObjects />
      </div>
    )
  }
}

Local.propTypes = {
  fetchModel: PropTypes.func.isRequired,
  addBreadcrumb: PropTypes.func.isRequired,
  getRelated: PropTypes.func.isRequired,
  model: PropTypes.object,
  lang: PropTypes.string.isRequired,
  fetchCategorias: PropTypes.func.isRequired,
  modelIsLoading: PropTypes.bool.isRequired
}

const mapStateToProps = state => ({
  model: state.model.model,
  lang: state.linguas.lang,
  modelIsLoading: state.model.modelIsLoading
})

const mapDispatchToProps = { fetchModel, addBreadcrumb, getRelated, fetchCategorias }

export default connect(mapStateToProps, mapDispatchToProps)(Local)
