import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { FormattedMessage as FM } from 'react-intl'

import LinkFormulario from './LinkFormulario'
import { initFormsInProgress } from '../../../modules/servicos'
import { setSelectedButton, addBreadcrumb } from '../../../modules/header'
import { ymdToDate, dateToYmdStr } from '../../../lib/utils'
import styles from './FormulariosList.css'

class FormulariosList extends Component {
  render() {
    const { model } = this.props
    if (!model || !model.formularios || model.formularios.length === 0) {
      return null
    }
    let filteredList = this.filterFormulario(model.formularios)
    if (!filteredList.length) {
      return null
    }
    return (
      <div className={styles.layoutGrid}>
        <div className={styles.titleDiv}>
          <FM id='global.formsList' defaultMessage='Lista de formulários' />
        </div>
        {this.buildFormulariosList(filteredList, model)}
      </div>
    )
  }

  buildFormulariosList(list, model) {
    return (
      <ul className={styles.objectListContainer}>
        {
          list.map(obj => (
            <LinkFormulario key={obj.id} formulario={obj} servico={model} />
          ))
        }
      </ul>
    )
  }

  filterFormulario(list) {
    let result = []
    let now = dateToYmdStr(new Date())

    list.map(obj => {
      if (!obj.dataInicio && !obj.dataFim) {
        result.push(obj)
      } else if (!obj.dataInicio) {
        if (now <= dateToYmdStr(ymdToDate(obj.dataFim))) {
          result.push(obj)
        }
      } else if (!obj.dataFim) {
        if (now >= dateToYmdStr(ymdToDate(obj.dataInicio))) {
          result.push(obj)
        }
      } else {
        if (now <= dateToYmdStr(ymdToDate(obj.dataFim)) && now >= dateToYmdStr(ymdToDate(obj.dataInicio))) {
          result.push(obj)
        }
      }
    })
    return result
  }
}

FormulariosList.propTypes = {
  initFormsInProgress: PropTypes.func.isRequired,
  lang: PropTypes.string.isRequired,
  setSelectedButton: PropTypes.func.isRequired,
  addBreadcrumb: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  model: state.model.model,
  lang: state.linguas.lang
})

const mapDispatchToProps = { initFormsInProgress, setSelectedButton, addBreadcrumb }

export default connect(mapStateToProps, mapDispatchToProps)(FormulariosList)
