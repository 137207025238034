export default {
  pt: {
    'header.contacts': 'Contactos',
    'header.login': 'Entrar',
    'header.loginAutenticacaoGov': 'Aut GOV',
    'header.logout': 'Sair',
    'header.cityHall': 'Município',
    'header.loginMessage': 'Benvindo',
    'header.logoutMessage': 'Obrigado pela sua visita',
    'header.geoportal': 'Geoportal',
    'body.visit': 'Visitar',
    'body.onlineServices': 'Serviços Online',
    'body.live': 'Viver',
    'body.publications': 'Publicações',
    'destaques.highlights': 'Destaques',
    'news.news': 'Notícias',
    'news.moreNews': 'mais notícias',
    'events.events': 'Eventos',
    'events.moreEvents': 'mais eventos',
    'january': 'Janeiro',
    'february': 'Fevereiro',
    'march': 'Março',
    'april': 'Abril',
    'may': 'Maio',
    'june': 'Junho',
    'july': 'Julho',
    'august': 'Agosto',
    'september': 'Setembro',
    'october': 'Outubro',
    'november': 'Novembro',
    'december': 'Dezembro',
    'shortJan': 'Jan',
    'shortFeb': 'Fev',
    'shortMar': 'Mar',
    'shortApr': 'Abr',
    'shortMay': 'Mai',
    'shortJun': 'Jun',
    'shortJul': 'Jul',
    'shortAug': 'Ago',
    'shortSep': 'Set',
    'shortOct': 'Out',
    'shortNov': 'Nov',
    'shortDec': 'Dez',
    'footer.address': 'Morada:',
    'footer.contacts': 'Contactos:',
    'footer.email': 'Email:',
    'footer.schedule': 'Horário de funcionamento:',
    'footer.reservedRights': 'Todos os direitos reservados',
    'footer.devBy': 'Desenvolvido por: Globaleda',
    'global.to': 'a',
    'global.toTime': 'às',
    'search.what': 'O que deseja encontrar?',
    'search.whatShort': 'Pesquisar',
    'search.sortOrderAsc': 'Ascendente',
    'search.sortOrderDesc': 'Descendente',
    'search.sortFieldScore': 'Relevância',
    'search.sortFieldScoreShort': 'Relevância',
    'search.sortFieldDateDesc': 'Cronológica Descendente',
    'search.sortFieldDateAsc': 'Cronológica Ascendente',
    'search.sortFieldDateDescShort': 'Cron. Desc.',
    'search.sortFieldDateAscShort': 'Cron. Asc.',
    'search.sortFieldDate': 'Data',
    'search.sortBy': 'Ordenar por',
    'search.sortType': 'Ordem',
    'search.scopeAll': 'Pesquisar Tudo',
    'search.scopeMunicipio': 'Município',
    'search.scopeVisitar': 'Visitar',
    'search.scopeServicosOnline': 'Serviços Online',
    'search.scopeViver': 'Viver',
    'search.scopePublicacoes': 'Publicações',
    'search.scopeNoticias': 'Notícias',
    'search.scopeEventos': 'Eventos',
    'menu.profile': 'Perfil',
    'menu.backoffice': 'Backoffice',
    'menu.logout': 'Logout',
    'backoffice.forms': 'Formulários',
    'backoffice.info': 'Informação',
    'backoffice.deleteFormTitle': 'Remoção de formulário',
    'backoffice.deleteFormBody': 'Confirma remoção? A remoção é permanente.',
    'form.new': 'Preencher novo formulário',
    'form.resume': 'Retomar',
    'form.remove': 'Remover',
    'form.submit': 'Submeter',
    'form.cancel': 'Cancelar',
    'form.reply': 'Responder',
    'form.obs': 'Observações',
    'form.summary': 'Resumo',
    'form.status.inProgress': 'Em progresso',
    'form.status.submitted': 'Submetido',
    'form.status.approved': 'Aprovado',
    'form.status.rejected': 'Rejeitado',
    'form.status.returned': 'Devolvido',
    'form.status.filed': 'Arquivado',
    'form.status.inAnalysis': 'Em análise',
    'form.field.value.required': 'O campo deverá ter um valor',
    'form.field.values.required': 'O campo deverá ter no mínimo um valor',
    'form.field.file.required': 'É obrigatório indicar um ficheiro',
    'form.field.files.required': 'É obrigatório indicar pelo menos um ficheiro',
    'form.field.date.required': 'O campo deverá ter uma data definida',
    'form.field.number.required': 'O campo deverá ter um número',
    'form.brief': 'Resumo',
    'form.brief.log': 'Histórico de estados e observações',
    'breadcrumb.home': 'Página principal',
    'tags.more': 'Mais tags',
    'tags.less': 'Menos tags',
    'tags.search': 'Pesquisar tags',
    'global.createdAt': 'Criado em',
    'global.relatedObjects': 'Objectos relacionados',
    'global.formsList': 'Lista de formulários',
    'global.publishedAt': 'Publicado em',
    'global.showText': 'Mostrar texto',
    'global.name': 'Nome',
    'global.date': 'Data',
    'global.status': 'Estado',
    'global.actions': 'Acções',
    'global.patternError': 'Formato errado',
    'global.return': 'Voltar',
    'global.who': 'Quem',
    'locais.dontShowAllInMap': 'Visualizar apenas locais da lista',
    'locais.showOnlyVisibleInMap': 'Visualizar apenas locais visíveis no mapa',
    'loginPopup.title': 'Entrar',
    'loginPopup.subtitle': 'Selecione a  sua opção',
    'loginPopup.login': 'utilizador/senha',
    'loginPopup.loginFacebook': 'Facebook',
    'loginPopup.register': 'Registar',
    'loginToFillForm':'É necessário fazer login para preencher o formulário'
  },
  en: {
    'header.contacts': 'Contacts',
    'header.login': 'Login',
    'header.loginAutenticacaoGov': 'Aut GOV',
    'header.logout': 'Logout',
    'header.cityHall': 'City Hall',
    'header.loginMessage': 'Welcome',
    'header.logoutMessage': 'Thank you for visiting us',
    'header.geoportal': 'Geoportal',
    'body.visit': 'Visit',
    'body.onlineServices': 'Online Services',
    'body.live': 'Live',
    'body.publications': 'Publications',
    'destaques.highlights': 'Highlights',
    'news.news': 'News',
    'news.moreNews': 'more news',
    'events.events': 'Events',
    'events.moreEvents': 'more events',
    'january': 'January',
    'february': 'February',
    'march': 'March',
    'april': 'April',
    'may': 'May',
    'june': 'June',
    'july': 'July',
    'august': 'August',
    'september': 'September',
    'october': 'October',
    'november': 'November',
    'december': 'December',
    'shortJan': 'Jan',
    'shortFeb': 'Feb',
    'shortMar': 'Mar',
    'shortApr': 'Apr',
    'shortMay': 'May',
    'shortJun': 'Jun',
    'shortJul': 'Jul',
    'shortAug': 'Aug',
    'shortSep': 'Sep',
    'shortOct': 'Oct',
    'shortNov': 'Nov',
    'shortDec': 'Dec',
    'footer.address': 'Address:',
    'footer.contacts': 'Contactos:',
    'footer.email': 'Email:',
    'footer.schedule': 'Business hours:',
    'footer.reservedRights': 'All Rights Reserved',
    'footer.devBy': 'Developed by: Globaleda',
    'global.to': 'to',
    'global.toTime': 'to',
    'search.what': 'What do you wish to find?',
    'search.whatShort': 'Search',
    'search.sortOrderAsc': 'Ascending',
    'search.sortOrderDesc': 'Descending',
    'search.sortFieldScore': 'Relevance',
    'search.sortFieldScoreShort': 'Relevance',
    'search.sortFieldDateDesc': 'Chronological Desc',
    'search.sortFieldDateAsc': 'Chronological Asc',
    'search.sortFieldDateDescShort': 'Chron. Desc.',
    'search.sortFieldDateAscShort': 'Chron. Asc.',
    'search.sortFieldDate': 'Date',
    'search.sortBy': 'Sort by',
    'search.sortType': 'Order',
    'search.scopeAll': 'Search All',
    'search.scopeMunicipio': 'County',
    'search.scopeVisitar': 'Visit',
    'search.scopeServicosOnline': 'Online Services',
    'search.scopeViver': 'Live',
    'search.scopePublicacoes': 'Publications',
    'search.scopeNoticias': 'News',
    'search.scopeEventos': 'Events',
    'menu.profile': 'Profile',
    'menu.backoffice': 'Backoffice',
    'menu.logout': 'Logout',
    'backoffice.forms': 'Forms',
    'backoffice.info': 'Information',
    'backoffice.deleteFormTitle': 'Delete form',
    'backoffice.deleteFormBody': 'Confirm delete? The form will be removed.',
    'form.new': 'Fill new form',
    'form.resume': 'Resume',
    'form.remove': 'Remove',
    'form.submit': 'Submit',
    'form.cancel': 'Cancel',
    'form.reply': 'Respond',
    'form.obs': 'Observations',
    'form.summary': 'Summary',
    'form.status.inProgress': 'In progress',
    'form.status.submitted': 'Submitted',
    'form.status.approved': 'Approved',
    'form.status.rejected': 'Rejected',
    'form.status.returned': 'Returned',
    'form.status.filed': 'Arquivado',
    'form.status.inAnalysis': 'Em análise',
    'form.field.value.required': 'Field must have a value',
    'form.field.values.required': 'Field must have at least one value',
    'form.field.file.required': 'One file must be provived',
    'form.field.files.required': 'At least one file must be provived',
    'form.field.date.required': 'A date is required',
    'form.field.number.required': 'A number is required',
    'form.brief': 'Summary',
    'form.brief.log': 'State and observations log',
    'breadcrumb.home': 'Homepage',
    'tags.more': 'More tags',
    'tags.less': 'Less tags',
    'tags.search': 'Search tags',
    'global.createdAt': 'Created at',
    'global.relatedObjects': 'Related objects',
    'global.formsList': 'Forms list',
    'global.publishedAt': 'Published at',
    'global.showText': 'Show text',
    'global.name': 'Name',
    'global.date': 'Date',
    'global.status': 'Status',
    'global.actions': 'Actions',
    'global.patternError': 'Wrong format',
    'global.return': 'Return',
    'global.who': 'Who',
    'locais.dontShowAllInMap': 'Show only places in list',
    'locais.showOnlyVisibleInMap': 'Show onlie places visible in map',
    'loginPopup.title': 'Sign in',
    'loginPopup.subtitle': 'Select your option',
    'loginPopup.login': 'user/password',
    'loginPopup.loginFacebook': 'Facebook',
    'loginPopup.register': 'Register',
    'loginToFillForm':'Sign in is required to fill the form'
  }
}
