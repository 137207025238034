import store from '../store'

const SET_CURRENT_PAGE = 'cmlf/navigation/SET_CURRENT_PAGE'
const SET_TOTAL_PAGES = 'cmlf/navigation/SET_TOTAL_PAGES'
const SET_PAGE_NAV_ABILIITY = 'cmlf/navigation/SET_PAGE_NAV_ABILIITY'
const SET_PAGE_LIST_START_PAGE = 'cmlf/navigation/SET_PAGE_LIST_START_PAGE'
const SET_RESULT_SIZE = 'cmlf/navigation/SET_RESULT_SIZE'

const initialState = {
  currentPage: 1,
  totalPages: 1,
  canFirstPage: false,
  canNextPage: false,
  canPreviousPage: false,
  canLastPage: false,
  pageSize: 4,
  pageListSize: 4,
  pageListStartPage: 1,
  resultSize: 0
}

export const setCurrentPage = (page) => ({ type: SET_CURRENT_PAGE, page })
export const setTotalPages = (total) => ({ type: SET_TOTAL_PAGES, total })
export const setPageListStartPage = (page) => ({ type: SET_PAGE_LIST_START_PAGE, page })

export function setResultSize (size) {
  return (dispatch) => {
    const myState = store.getState().navigation
    if (myState.resultSize !== size) {
      dispatch({ type: SET_RESULT_SIZE, size })
      dispatch({ type: SET_CURRENT_PAGE, page: 1 })
      dispatch({ type: SET_PAGE_LIST_START_PAGE, page: 1 })
      dispatch({ type: SET_PAGE_NAV_ABILIITY, first: canGoFirstPage(), last: canGoLastPage(), next: canGoNextPage(), previous: canGoPreviousPage() })
    }
  }
}

export function goPage (page) {
  if (!canGoPage(page)) {
    return (dispatch) => { }
  }
  if (typeof page === 'string') {
    page = parseInt(page)
  }
  return (dispatch) => {
    dispatch({ type: SET_CURRENT_PAGE, page: page })
    dispatch({ type: SET_PAGE_NAV_ABILIITY, first: canGoFirstPage(), last: canGoLastPage(), next: canGoNextPage(), previous: canGoPreviousPage() })
  }
}

export function goNextPage () {
  if (!canGoNextPage()) {
    return (dispatch) => { }
  }
  return (dispatch) => {
    let page = computeNextPage()
    dispatch({ type: SET_CURRENT_PAGE, page: page })
    dispatch({ type: SET_PAGE_LIST_START_PAGE, page: page })
    dispatch({ type: SET_PAGE_NAV_ABILIITY, first: canGoFirstPage(), last: canGoLastPage(), next: canGoNextPage(), previous: canGoPreviousPage() })
  }
}

export function goPreviousPage () {
  if (!canGoPreviousPage()) {
    return (dispatch) => { }
  }
  return (dispatch) => {
    let page = computePreviousPage()
    dispatch({ type: SET_CURRENT_PAGE, page: page })
    dispatch({ type: SET_PAGE_LIST_START_PAGE, page: page })
    dispatch({ type: SET_PAGE_NAV_ABILIITY, first: canGoFirstPage(), last: canGoLastPage(), next: canGoNextPage(), previous: canGoPreviousPage() })
  }
}

export function goFirstPage () {
  if (!canGoFirstPage()) {
    return (dispatch) => { }
  }
  return (dispatch) => {
    dispatch({ type: SET_CURRENT_PAGE, page: 1 })
    dispatch({ type: SET_PAGE_LIST_START_PAGE, page: 1 })
    dispatch({ type: SET_PAGE_NAV_ABILIITY, first: canGoFirstPage(), last: canGoLastPage(), next: canGoNextPage(), previous: canGoPreviousPage() })
  }
}

export function goLastPage () {
  if (!canGoLastPage()) {
    return (dispatch) => { }
  }
  return (dispatch) => {
    let page = computeLastPage()
    const myState = store.getState().navigation
    dispatch({ type: SET_CURRENT_PAGE, page: page + myState.pageListSize - 1 })
    dispatch({ type: SET_PAGE_LIST_START_PAGE, page: page })
    dispatch({ type: SET_PAGE_NAV_ABILIITY, first: canGoFirstPage(), last: canGoLastPage(), next: canGoNextPage(), previous: canGoPreviousPage() })
  }
}

function computeLastPage () {
  const myState = store.getState().navigation
  const totalPages = computeTotalPages()
  return totalPages - myState.pageListSize + 1
}

function computePreviousPage () {
  const myState = store.getState().navigation
  if (myState.pageListStartPage - myState.pageListSize >= 1) {
    return myState.pageListStartPage - myState.pageListSize
  } else {
    return 1
  }
}

function computeNextPage () {
  const myState = store.getState().navigation
  const totalPages = computeTotalPages()
  if (myState.pageListStartPage + myState.pageListSize * 2 - 1 <= totalPages) {
    return myState.pageListStartPage + myState.pageListSize
  } else {
    const lastPage = myState.pageListStartPage + myState.pageListSize - 1
    return myState.pageListStartPage + totalPages - lastPage
  }
}

export default function reducer (state = initialState, action = {}) {
  switch (action.type) {
    case SET_CURRENT_PAGE:
      return { ...state, currentPage: action.page }
    case SET_TOTAL_PAGES:
      return { ...state, totalPages: action.total }
    case SET_PAGE_NAV_ABILIITY:
      return { ...state, canFirstPage: action.first, canLastPage: action.last, canNextPage: action.next, canPreviousPage: action.previous }
    case SET_PAGE_LIST_START_PAGE:
      return { ...state, pageListStartPage: action.page }
    case SET_RESULT_SIZE:
      let totalPages = computeTotalPages(action.size)
      return { ...state, resultSize: action.size, totalPages: totalPages }
    default:
      return state
  }
}

function canGoFirstPage () {
  let state = store.getState()
  const myState = state.navigation
  if (myState.resultSize === undefined ||
    myState.resultSize <= myState.pageSize ||
    myState.pageListStartPage <= 1) {
    return false
  }
  return true
}

function canGoLastPage () {
  let state = store.getState()
  const myState = state.navigation
  if (myState.resultSize === undefined ||
    myState.resultSize <= myState.pageSize ||
    myState.pageListStartPage >= computeLastPage()) {
    return false
  }
  return true
}

function canGoPreviousPage () {
  let state = store.getState()
  const myState = state.navigation
  if (myState.resultSize === undefined ||
    myState.resultSize <= myState.pageSize ||
    myState.pageListStartPage === 1) {
    return false
  }
  return true
}

function canGoNextPage () {
  let state = store.getState()
  const myState = state.navigation
  if (computeNextPage() > myState.pageListStartPage) {
    return true
  }
  return false
}

function canGoPage (page) {
  page = parseInt(page)
  if (isNaN(page)) {
    return false
  }
  let state = store.getState()
  const myState = state.navigation
  if (myState.resultSize === undefined ||
    myState.currentPage === page ||
    page > computeTotalPages() ||
    page < 1) {
    return false
  }
  return true
}

function computeTotalPages (size = null) {
  let state = store.getState()
  const myState = state.navigation
  const resultSize = !size ? myState.resultSize : size
  if (resultSize === undefined || resultSize === 0) {
    return 0
  }
  if (resultSize <= myState.pageSize) {
    return 1
  }
  return 1 + Math.floor((resultSize - 1) / myState.pageSize)
}
