/* eslint-env browser */
import axios from 'axios'

import { host } from '../request'
import store from '../store'

const TOGGLE_SEARCH = 'cmlf/search/TOGGLE_SEARCH'
const DO_SEARCH = 'cmlf/search/DO_SEARCH'
const SET_SHOW_RESULTS = 'cmlf/search/SET_SHOW_RESULTS'
const SET_SEARCH_TEXT = 'cmlf/search/SET_SEARCH_TEXT'
const SET_CURRENT_LANG = 'cmlf/search/SET_CURRENT_LANG'
const SET_CURRENT_PAGE = 'cmlf/search/SET_CURRENT_PAGE'
const SET_TOTAL_PAGES = 'cmlf/search/SET_TOTAL_PAGES'
const SET_PAGE_NAV_ABILIITY = 'cmlf/search/SET_PAGE_NAV_ABILIITY'
const SET_CURRENT_SCOPE = 'cmlf/search/SET_CURRENT_SCOPE'
const SET_SORT_FIELD_OPTION = 'cmlf/search/SET_SORT_FIELD_OPTION'
const SET_PAGE_LIST_START_PAGE = 'cmlf/search/SET_PAGE_LIST_START_PAGE'

const initialState = {
  showResults: false,
  searchResult: {},
  sortFieldOptions: [
    // { key: 'score', value: 'sortFieldScore', sortOrder: 'asc', type: 'score' },
    { key: 'dataSortDateDesc', value: 'sortFieldDateDesc', sortOrder: 'desc', type: 'dataSort' },
    { key: 'dataSortDateAsc', value: 'sortFieldDateAsc', sortOrder: 'asc', type: 'dataSort' }
  ],
  // currentSortFieldOption: { key: 'score', value: 'sortFieldScore', sortOrder: 'asc', type: 'score' },
  currentSortFieldOption: { key: 'dataSortDateDesc', value: 'sortFieldDateDesc', sortOrder: 'desc', type: 'dataSort' },
  searchText: '',
  currentPage: 1,
  totalPages: 1,
  currentLang: null,
  canFirstPage: false,
  canNextPage: false,
  canPreviousPage: false,
  canLastPage: false,
  pageSize: 4,
  pageListSize: 5,
  pageListStartPage: 1,
  currentScope: 0,
  searchScopes: [
    { name: 'scopeAll', img: 'tudoazul.svg', className: 'tudo', objSearchType: null },
    { name: 'scopeMunicipio', img: 'municipioazul.svg', className: 'municipio', objSearchType: 'pagina' },
    { name: 'scopeVisitar', img: 'visitarazul.svg', className: 'visitar', objSearchType: 'local' },
    { name: 'scopeServicosOnline', img: 'servicosonlineazul.svg', className: 'servicosOnline', objSearchType: 'servico' },
    { name: 'scopeViver', img: 'viverazul.svg', className: 'viver', objSearchType: 'servico' },
    { name: 'scopePublicacoes', img: 'publicacoesazul.svg', className: 'publicacoes', objSearchType: 'documento' },
    { name: 'scopeNoticias', img: 'noticiasazul.svg', className: 'noticias', objSearchType: 'Noticia' },
    { name: 'scopeEventos', img: 'eventoscalendarioazul.svg', className: 'eventos', objSearchType: 'Evento' }
  ]
}

export const toggleSearch = () => ({ type: TOGGLE_SEARCH })
export const setShowResults = (showResults) => ({ type: SET_SHOW_RESULTS, showResults })
export const setSearchText = (searchText) => ({ type: SET_SEARCH_TEXT, searchText })
export const setCurrentLang = (lang) => ({ type: SET_CURRENT_LANG, lang })
export const setCurrentPage = (page) => ({ type: SET_CURRENT_PAGE, page })
export const setTotalPages = (total) => ({ type: SET_TOTAL_PAGES, total })
export const setCurrentScope = (scope) => ({ type: SET_CURRENT_SCOPE, scope })
export const setSortFieldOption = (sortFieldOption) => ({ type: SET_SORT_FIELD_OPTION, sortFieldOption })
export const setPageListStartPage = (page) => ({ type: SET_PAGE_LIST_START_PAGE, page })

export function doSearch(text, lang, sortFieldOption, objSearchType) {
  return (dispatch) => {
    let langParam = !lang ? '' : '&lang=' + lang
    let state = store.getState()
    let sortField = '&sortField=' + sortFieldOption.type
    let sortOrder = '&sort=' + sortFieldOption.sortOrder
    let page = '&page=' + state.search.currentPage
    let pageSize = '&pageSize=' + state.search.pageSize
    let obj = !objSearchType ? '' : '&obj=' + objSearchType
    dispatch({ type: SET_CURRENT_LANG, lang })
    dispatch({ type: SET_SEARCH_TEXT, searchText: text })
    axios.get(host + '/api/public/search/obj?searchText=' + text + page + pageSize + langParam + sortField + sortOrder + obj)
      .then(res => res.data)
      .then(post => {
        if (post.resultSize > 0) {
          dispatch({ type: SET_SHOW_RESULTS, showResults: true })
          dispatch({ type: DO_SEARCH, payload: post })
          dispatch({ type: SET_PAGE_NAV_ABILIITY, first: canGoFirstPage(), last: canGoLastPage(), next: canGoNextPage(), previous: canGoPreviousPage() })
          dispatch({ type: SET_CURRENT_PAGE, page: 1 })
          dispatch({ type: SET_TOTAL_PAGES, total: computeTotalPages() })
        } else {
          dispatch({ type: SET_SHOW_RESULTS, showResults: false })
          dispatch({ type: SET_PAGE_NAV_ABILIITY, first: false, last: false, next: false, previous: false })
          dispatch({ type: DO_SEARCH, payload: {} })
          dispatch({ type: SET_TOTAL_PAGES, total: 0 })
        }
      })
  }
}

function search(dispatch) {
  let state = store.getState()
  const myState = state.search
  let lang = !myState.currentLang ? '' : '&lang=' + myState.currentLang
  let sortField = '&sortField=' + myState.currentSortFieldOption.type
  let sortOrder = '&sort=' + myState.currentSortFieldOption.sortOrder
  let page = '&page=' + myState.currentPage
  let pageSize = '&pageSize=' + myState.pageSize
  let obj = !myState.searchScopes[myState.currentScope].objSearchType ? '' : '&obj=' + myState.searchScopes[myState.currentScope].objSearchType
  axios.get(host + '/api/public/search/obj?searchText=' + myState.searchText + page + pageSize + lang + sortField + sortOrder + obj)
    .then(res => res.data)
    .then(post => {
      if (post.resultSize > 0) {
        dispatch({ type: SET_SHOW_RESULTS, showResults: true })
        dispatch({ type: DO_SEARCH, payload: post })
        dispatch({ type: SET_PAGE_NAV_ABILIITY, first: canGoFirstPage(), last: canGoLastPage(), next: canGoNextPage(), previous: canGoPreviousPage() })
        dispatch({ type: SET_TOTAL_PAGES, total: computeTotalPages() })
      } else {
        dispatch({ type: SET_SHOW_RESULTS, showResults: false })
        dispatch({ type: SET_PAGE_NAV_ABILIITY, first: false, last: false, next: false, previous: false })
        dispatch({ type: DO_SEARCH, payload: {} })
        dispatch({ type: SET_TOTAL_PAGES, total: 0 })
      }
    })
}

export function goPage(page) {
  if (!canGoPage(page)) {
    return (dispatch) => { }
  }
  if (typeof page === 'string') {
    page = parseInt(page)
  }
  return (dispatch) => {
    dispatch({ type: SET_CURRENT_PAGE, page: page })
    search(dispatch)
  }
}

export function goNextPage() {
  if (!canGoNextPage()) {
    return (dispatch) => { }
  }
  return (dispatch) => {
    let page = computeNextPage()
    dispatch({ type: SET_CURRENT_PAGE, page: page })
    dispatch({ type: SET_PAGE_LIST_START_PAGE, page: page })
    search(dispatch)
  }
}

export function goPreviousPage() {
  if (!canGoPreviousPage()) {
    return (dispatch) => { }
  }
  return (dispatch) => {
    let page = computePreviousPage()
    dispatch({ type: SET_CURRENT_PAGE, page: page })
    dispatch({ type: SET_PAGE_LIST_START_PAGE, page: page })
    search(dispatch)
  }
}

export function goFirstPage() {
  if (!canGoFirstPage()) {
    return (dispatch) => { }
  }
  return (dispatch) => {
    dispatch({ type: SET_CURRENT_PAGE, page: 1 })
    dispatch({ type: SET_PAGE_LIST_START_PAGE, page: 1 })
    search(dispatch)
  }
}

export function goLastPage() {
  if (!canGoLastPage()) {
    return (dispatch) => { }
  }
  return (dispatch) => {
    let page = computeLastPage()
    const myState = store.getState().search
    dispatch({ type: SET_CURRENT_PAGE, page: page + myState.pageListSize - 1 })
    dispatch({ type: SET_PAGE_LIST_START_PAGE, page: page })
    search(dispatch)
  }
}

function computeLastPage() {
  const myState = store.getState().search
  const totalPages = computeTotalPages()
  return totalPages - myState.pageListSize + 1
}

function computePreviousPage() {
  const myState = store.getState().search
  if (myState.pageListStartPage - myState.pageListSize >= 1) {
    return myState.pageListStartPage - myState.pageListSize
  } else {
    return 1
  }
}

function computeNextPage() {
  const myState = store.getState().search
  const totalPages = computeTotalPages()
  if (myState.pageListStartPage + myState.pageListSize * 2 - 1 <= totalPages) {
    return myState.pageListStartPage + myState.pageListSize
  } else {
    const lastPage = myState.pageListStartPage + myState.pageListSize - 1
    return myState.pageListStartPage + totalPages - lastPage
  }
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case TOGGLE_SEARCH:
      return { ...state, showResults: !state.showResults }
    case SET_SHOW_RESULTS:
      return { ...state, showResults: action.showResults }
    case DO_SEARCH:
      return { ...state, searchResult: action.payload }
    case SET_SEARCH_TEXT:
      return { ...state, searchText: action.searchText }
    case SET_CURRENT_LANG:
      return { ...state, currentLang: action.lang }
    case SET_CURRENT_PAGE:
      return { ...state, currentPage: action.page }
    case SET_CURRENT_SCOPE:
      return { ...state, currentScope: action.scope }
    case SET_TOTAL_PAGES:
      return { ...state, totalPages: action.total }
    case SET_PAGE_NAV_ABILIITY:
      return { ...state, canFirstPage: action.first, canLastPage: action.last, canNextPage: action.next, canPreviousPage: action.previous }
    case SET_SORT_FIELD_OPTION:
      return { ...state, currentSortFieldOption: action.sortFieldOption }
    case SET_PAGE_LIST_START_PAGE:
      return { ...state, pageListStartPage: action.page }
    default:
      return state
  }
}

function canGoFirstPage() {
  let state = store.getState()
  const myState = state.search
  if (myState.searchResult.resultSize === undefined ||
    myState.searchResult.resultSize <= myState.pageSize ||
    myState.pageListStartPage <= 1) {
    return false
  }
  return true
}

function canGoLastPage() {
  let state = store.getState()
  const myState = state.search
  if (myState.searchResult.resultSize === undefined ||
    myState.searchResult.resultSize <= myState.pageSize ||
    myState.pageListStartPage >= computeLastPage()) {
    return false
  }
  return true
}

function canGoPreviousPage() {
  let state = store.getState()
  const myState = state.search
  if (myState.searchResult.resultSize === undefined ||
    myState.searchResult.resultSize <= myState.pageSize ||
    myState.pageListStartPage === 1) {
    return false
  }
  return true
}

function canGoNextPage() {
  let state = store.getState()
  const myState = state.search
  if (computeNextPage() > myState.pageListStartPage) {
    return true
  }
  return false
}

function canGoPage(page) {
  page = parseInt(page)
  if (isNaN(page)) {
    return false
  }
  let state = store.getState()
  const myState = state.search
  if (myState.searchResult.resultSize === undefined ||
    myState.currentPage === page ||
    page > computeTotalPages() ||
    page < 1) {
    return false
  }
  return true
}

function computeTotalPages() {
  let state = store.getState()
  const myState = state.search
  if (myState.searchResult.resultSize === undefined || myState.searchResult.resultSize === 0) {
    return 0
  }
  if (myState.searchResult.resultSize <= myState.pageSize) {
    return 1
  }
  return 1 + Math.floor((myState.searchResult.resultSize - 1) / myState.pageSize)
}
