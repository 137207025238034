import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import Body from './Body'
import Destaques from './Destaques'
import News from './News'
import Eventos from './Eventos'
import ShareHome from './ShareHome'

import styles from './Home.css'
import { verifyLoginCredentials } from '../../modules/auth'

class Home extends Component {
  componentDidMount () {
    const { authGov, match: { params } } = this.props
    if (authGov) {
      this.props.verifyLoginCredentials(params.username, params.name, params.firstName, params.lastName, params.email, params.nif, params.niss, params.token, params.expires, params.digest)
      window.history.replaceState(null, null, '/')
    }
  }
  render () {
    return (
      <div className={styles.body}>
        <Body />
        <Destaques />
        <News />
        <Eventos />
        <ShareHome />
      </div>
    )
  }
}

Home.propTypes = {
  verifyLoginCredentials: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
})

const mapDispatchToProps = { verifyLoginCredentials }

export default connect(mapStateToProps, mapDispatchToProps)(Home)
