import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import commonStyles from './CommonStyles.css'
import { setFormInstance, saveFormInstance } from '../../../modules/formulario'
import TreeView from '../../TreeView/TreeView'
import Label from './Label'

class TreeListField extends Component {
  constructor(props) {
    super(props)
    this.valueChanged = this.valueChanged.bind(this)
    this.onSelect = this.onSelect.bind(this)
  }

  render () {
    const { field, lang, formInstance } = this.props
    return (
      <div className={commonStyles.labelWrapper}>
        <Label {...this.props} />
        {this.buildInput(field, lang, formInstance)}
      </div>
    )
  }

  buildInput (field, lang, formInstance) {
    let values = this.getValues(field.values, lang)
    values = { children: this.buildTreeViewData(values[0].values), key: '-1', value: '', open: true, icon: 'file', type: 'root', selectable: false, display: 'none' }
    return (
      <TreeView data={values} onSelect={this.onSelect} />
    )
  }

  buildTreeViewData (tree) {
    let newTree = []
    const formInstanceValue = this.getFormInstanceFieldValue()
    let selectedNodeKey = null
    if (!(!formInstanceValue || !formInstanceValue.keyValues || formInstanceValue.keyValues.length === 0)) {
      selectedNodeKey = formInstanceValue.keyValues[0].key
    }
    for (let index = 0; index < tree.length; index++) {
      const element = tree[index]
      let selected = element.key === selectedNodeKey
      let children = []
      if (element.children && element.children.length > 0) {
        children = this.buildTreeViewData(element.children)
        if (children.length > 0) {
          newTree.push({ value: element.value, key: element.key, open: true, icon: 'folder', type: 'ramo', selectable: element.selectable, children: children, selected: selected })
        } else {
          newTree.push({ value: element.value, key: element.key, open: true, icon: 'folder', type: 'ramo', selectable: element.selectable, selected: selected })
        }
      } else {
        newTree.push({ value: element.value, key: element.key, open: true, icon: 'file', type: 'folha', selectable: element.selectable, selected: selected })
      }
    }
    return newTree
  }

  onSelect (data) {
    const { formInstance } = this.props
    let formInstanceValue = this.getFormInstanceFieldValue()
    if (formInstanceValue === null) {
      formInstanceValue = this.initNewFormInstanceField()
      formInstance.values.push(formInstanceValue)
    }
    formInstanceValue.keyValues = []
    if (data.selected) {
      formInstanceValue.keyValues.push({ key: data.key, value: data.value })
    }
    let values = []
    values.push(formInstanceValue)
    this.props.setFormInstance(formInstance)
    this.props.saveFormInstance(formInstance.id, values)
  }

  valueChanged (e) {
    const { field, formInstance, lang } = this.props
    let formInstanceValue = this.getFormInstanceFieldValue()
    if (formInstanceValue === null) {
      formInstanceValue = this.initNewFormInstanceField()
      formInstance.values.push(formInstanceValue)
    }
    formInstanceValue.keyValues = []
    e.target.childNodes.forEach(option => {
      if (option.selected) {
        const item = this.getFieldValue(field, option.value, lang)
        formInstanceValue.keyValues.push({ key: item.value, value: item.label })
      }
    })
    let values = []
    values.push(formInstanceValue)
    this.props.setFormInstance(formInstance)
    this.props.saveFormInstance(formInstance.id, values)
  }

  getValues (values, lang) {
    let array = []
    if (!values || values.length === 0) return []
    if (values[0].lang !== undefined) {
      for (let index = 0; index < values.length; index++) {
        const element = values[index]
        if (element.lang === lang) {
          array.push(element)
        }
      }
      return array
    } else {
      for (let index = 0; index < values.length; index++) {
        const element = values[index]
        array.push(element)
      }
      return array
    }
  }

  getFieldValue (field, value, lang) {
    for (let index = 0; index < field.values.length; index++) {
      const item = field.values[index]
      if (item.lang === lang && item.value === value) {
        return item
      }
    }
    return null
  }

  popKeyValue (formInstanceValue, key) {
    for (let index = 0; index < formInstanceValue.keyValues.length; index++) {
      const kv = formInstanceValue.keyValues[index]
      if (kv.key === key) {
        formInstanceValue.keyValues.splice(index, 1)
        return
      }
    }
  }

  initNewFormInstanceField () {
    const { field } = this.props
    return { fieldId: field.id, key: null, value: null, keyValues: [] }
  }

  getFormInstanceFieldValue () {
    const { field, formInstance } = this.props
    if (!formInstance || !formInstance.values) {
      return null
    }
    for (let index = 0; index < formInstance.values.length; index++) {
      const element = formInstance.values[index]
      if (element.fieldId === field.id) {
        return element
      }
    }
    return null
  }
}

TreeListField.propTypes = {
  lang: PropTypes.string.isRequired,
  formInstance: PropTypes.object,
  setFormInstance: PropTypes.func.isRequired,
  saveFormInstance: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  formInstance: state.formulario.formInstance,
  lang: state.linguas.lang
})

const mapDispatchToProps = { setFormInstance, saveFormInstance }

export default connect(mapStateToProps, mapDispatchToProps)(TreeListField)
