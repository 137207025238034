import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import Documento from '../Documentos/Documento'
import Evento from '../Eventos/Evento'
import Local from '../Locais/Local'
import Noticia from '../Noticias/Noticia'
import Pagina from '../Paginas/Pagina'
import Servico from '../Servicos/Servico'
import Form from '../../Formulario/Form'
import Formulario from '../../Formulario/Formulario'
import Backoffice from '../../Backoffice/Backoffice'
import { fetchModel, getRelated } from '../../../modules/model'
import { setCurrentPasta, expandPasta, fetchCurrentPastaContent } from '../../../modules/pastas'
import { addBreadcrumb } from '../../../modules/header'
import PopupCarousel from '../../PopupCarousel/PopupCarousel'
import { resetList } from '../../../modules/popup'
import { closeToolbarMenu } from '../../../modules/toolbar'

class ModelWrapper extends Component {
  constructor(props) {
    super(props)
    this.props.resetList()
  }

  componentDidUpdate () {
    const { model, pastas, objName, availableMenusAreLoading, mobileMenu } = this.props
    if (availableMenusAreLoading) {
      return
    }
    const { permalink } = this.props.match.params
    if (model !== undefined && model !== null && permalink === model.permalink) {
      if (this.willSelectPasta(objName) && model.pasta !== null && model.pasta !== undefined) {
        this.selectPasta(pastas, model.pasta.id)
      }
    }
    if (mobileMenu) {
      this.props.closeToolbarMenu()
    }
  }

  render () {
    const { objName } = this.props
    return (
      <div>
        <PopupCarousel key={Date.now() + '-popup'} />
        {this.getObject(objName)}
      </div>
    )
  }

  getObject (objName) {
    switch (objName) {
      case 'pagina':
        return <Pagina {...this.props} />
      case 'local':
        return <Local {...this.props} />
      case 'servico':
        return <Servico {...this.props} />
      case 'servico-online':
        return <Servico {...this.props} online />
      case 'documento':
        return <Documento {...this.props} />
      case 'noticia':
        return <Noticia {...this.props} />
      case 'evento':
        return <Evento {...this.props} />
      case 'backoffice':
        return <Backoffice {...this.props} />
      case 'formulario':
        return <Formulario {...this.props} />
      case 'formInstance':
        return <Form {...this.props} />
      default:
        return <Pagina {...this.props} />
    }
  }

  willSelectPasta (objName) {
    const { availableMenus, currentPasta } = this.props
    switch (objName) {
      case 'pagina':
        if (availableMenus.paginas && !currentPasta) {
          return true
        }
        break
      case 'local':
        if (availableMenus.locais && !currentPasta) {
          return true
        }
        break
      case 'servico':
        if (availableMenus.servicos && !currentPasta) {
          return true
        }
        break
      case 'servico-online':
        if (availableMenus.servicosOnline && !currentPasta) {
          return true
        }
        break
      default:
    }
    return false
  }

  selectPasta (pastas, pastaId, level = 0) {
    const { lang } = this.props
    for (let index = 0; index < pastas.length; index++) {
      const pasta = pastas[index]
      if (pasta.id === pastaId) {
        if (level === 0) {
          this.props.expandPasta(pastaId)
        }
        this.props.setCurrentPasta(pasta)
        this.props.fetchCurrentPastaContent(lang, pasta, false, pasta.typeEnum)
        return true
      }
      let inPath = this.selectPasta(pasta.subPastas, pastaId, level + 1)
      if (inPath) {
        this.props.expandPasta(pasta.id)
        return false
      }
    }
    return false
  }
}

ModelWrapper.propTypes = {
  fetchModel: PropTypes.func.isRequired,
  addBreadcrumb: PropTypes.func.isRequired,
  getRelated: PropTypes.func.isRequired,
  model: PropTypes.object,
  lang: PropTypes.string.isRequired,
  pastas: PropTypes.array.isRequired,
  expandPasta: PropTypes.func.isRequired,
  availableMenus: PropTypes.object.isRequired,
  currentPasta: PropTypes.object,
  fetchCurrentPastaContent: PropTypes.func.isRequired,
  setCurrentPasta: PropTypes.func.isRequired,
  resetList: PropTypes.func.isRequired,
  availableMenusAreLoading: PropTypes.bool.isRequired,
  mobileMenu: PropTypes.bool.isRequired,
  closeToolbarMenu: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  model: state.model.model,
  lang: state.linguas.lang,
  availableMenus: state.pastas.availableMenus,
  currentPasta: state.pastas.currentPasta,
  pastas: state.pastas.pastas,
  mobileMenu: state.global.mobileMenu,
  availableMenusAreLoading: state.pastas.availableMenusAreLoading
})

const mapDispatchToProps = { fetchModel, addBreadcrumb, getRelated, expandPasta, setCurrentPasta, fetchCurrentPastaContent, resetList, closeToolbarMenu }

export default connect(mapStateToProps, mapDispatchToProps)(ModelWrapper)
