/* eslint-env browser */
import { host, computeLink } from '../request'
import { getImage, stringArrToCommaSepString } from '../lib/utils'
import store from '../store'
import axios from 'axios'
import msg from '../components/App/messages'

const FETCH_HOMEPAGE_EVENTS = 'cmlf/events/FETCH_HOMEPAGE_EVENTS'
const FETCH_EVENTS = 'cmlf/events/FETCH_EVENTS'
const SET_START_DATE = 'cmlf/events/SET_START_DATE'
const TOOGLE_DATE = 'cmlf/events/TOOGLE_DATE'
const TOOGLE_MONTHS = 'cmlf/events/TOOGLE_MONTHS'
const SET_SEL_MONTHS = 'cmlf/events/SET_SEL_MONTHS'
const FETCH_EVENT_YEARS = 'cmlf/events/FETCH_EVENT_YEARS'
const FETCH_EVENT_MONTHS = 'cmlf/events/FETCH_EVENT_MONTHS'
const FETCH_EVENT_DAYS = 'cmlf/events/FETCH_EVENT_DAYS'
const SET_SELECTED_YEAR = 'cmlf/events/SET_SELECTED_YEAR'
const SET_SELECTED_MONTH = 'cmlf/events/SET_SELECTED_MONTH'
const SET_SELECTED_DAY = 'cmlf/events/SET_SELECTED_DAY'
const SET_DAYS_SHOWN = 'cmlf/events/SET_DAYS_SHOWN'
const SET_MONTHS_SHOWN = 'cmlf/events/SET_MONTHS_SHOWN'
const EVENTS_LOADING = 'cmlf/events/EVENTS_LOADING'
const EVENTS_FETCHED = 'cmlf/events/EVENTS_FETCHED'
const EVENTS_HOMEPAGE_LOADING = 'cmlf/events/EVENTS_HOMEPAGE_LOADING'

const initialState = {
  homepageEvents: [],
  events: [],
  startYear: 2018,
  startMonth: 7,
  startDay: 30,
  monthsShown: 6,
  daysShown: 15,
  scrollDays: 15, // dias de scroll de cada clique
  scrollMonths: 1, // meses de scroll provocados pelo scroll de dias quando ultrapassa o mês
  maxEvents: 500,
  selectedMonths: [7, 8, 9],
  dateChanged: false,
  monthChanged: false,
  eventYears: [],
  eventMonths: [],
  eventDays: [],
  monthList: ['shortJan', 'shortFeb', 'shortMar', 'shortApr', 'shortMay', 'shortJun', 'shortJul', 'shortAug', 'shortSep', 'shortOct', 'shortNov', 'shortDec'],
  selectedYear: new Date().getFullYear(),
  selectedMonth: new Date().getMonth(),
  selectedDay: new Date().getDate(),
  eventsAreLoading: false,
  eventsFetched: false,
  eventsHomePageAreLoading: false
}

export const toogleDate = () => ({ type: TOOGLE_DATE })
export const toogleMonths = () => ({ type: TOOGLE_MONTHS })
export const setSelMonths = (months) => ({ type: SET_SEL_MONTHS, months })
export const setSelectedYear = (year) => ({ type: SET_SELECTED_YEAR, year })
export const setSelectedMonth = (month) => ({ type: SET_SELECTED_MONTH, month })
export const setSelectedDay = (day) => ({ type: SET_SELECTED_DAY, day })
export const setDaysShown = (days) => ({ type: SET_DAYS_SHOWN, days })
export const setMonthsShown = (months) => ({ type: SET_MONTHS_SHOWN, months })
export const eventsLoading = (loading) => ({ type: EVENTS_LOADING, loading })
export const eventsFetched = () => ({ type: EVENTS_FETCHED })
export const eventsHomepageLoading = (loading) => ({ type: EVENTS_HOMEPAGE_LOADING, loading })

export function setStartDate(date) {
  return (dispatch) => {
    dispatch({ type: SET_START_DATE, date })
    dispatch(fetchHomepageEvents(store.getState().linguas.lang))
  }
}
export function fetchHomepageEvents(lang) {
  return (dispatch) => {
    dispatch(eventsHomepageLoading(true))
    let state = store.getState()
    let maxEvents = state.events.maxEvents
    let year = state.events.startYear
    let month = state.events.startMonth
    let day = state.events.startDay
    month = parseInt(month)
    axios.get(host + '/api/public/eventosList?lang=' + lang + '&date=' + formatDate(year, month + 1, day) + '&max=' + maxEvents)
      .then(res => res.data)
      .then(posts => {
        let resp = []
        posts.forEach(post => {
          if (post.start !== null) {
            let newObj = { id: post.id, title: post.titulo, start: post.start, end: post.end, link: computeLink(post), obj: post }
            resp.push(newObj)
          }
        })
        let selMonths = computeSelectedMonths(resp)
        dispatch(setSelMonths(selMonths))
        dispatch({
          type: FETCH_HOMEPAGE_EVENTS,
          payload: resp
        })
        dispatch(eventsHomepageLoading(false))
      })
  }
}

export function fetchEvents(lang, categIdArray) {
  return (dispatch) => {
    dispatch(eventsLoading(true))
    const cats = stringArrToCommaSepString(categIdArray)
    axios.get(host + '/api/public/eventos?lang=' + lang + (cats !== null ? '&cats=' + cats : ''))
      .then(res => res.data)
      .then(posts => {
        let resp = []
        posts.forEach(post => {
          if (post.start !== null) {
            let newObj = {
              id: post.id,
              title: post.titulo,
              start: post.start,
              end: post.end,
              prettyDate: formatDateInterval(post.start, lang, post.end),
              categorias: post.categorias,
              link: computeLink(post),
              obj: post
            }
            newObj.image = getImage(post.imagem)
            resp.push(newObj)
          }
        })
        let selMonths = computeSelectedMonths(resp)
        dispatch(setSelMonths(selMonths))
        dispatch({
          type: FETCH_EVENTS,
          payload: resp
        })
        dispatch(eventsLoading(false))
        dispatch(eventsFetched())
      })
  }
}

export function fetchEventYears() {
  return (dispatch) => {
    axios.get(host + '/api/public/eventosAno')
      .then(res => res.data)
      .then(posts => {
        dispatch({
          type: FETCH_EVENT_YEARS,
          payload: posts
        })
      })
  }
}

export function fetchEventMonths() {
  return (dispatch) => {
    axios.get(host + '/api/public/eventosAnoMes')
      .then(res => res.data)
      .then(posts => {
        dispatch({
          type: FETCH_EVENT_MONTHS,
          payload: posts
        })
      })
  }
}

export function fetchEventDays() {
  return (dispatch) => {
    axios.get(host + '/api/public/eventosAnoMesDia')
      .then(res => res.data)
      .then(posts => {
        dispatch({
          type: FETCH_EVENT_DAYS,
          payload: posts
        })
        axios.get(host + '/api/public/eventosAnoMes')
          .then(res => res.data)
          .then(posts => {
            dispatch({
              type: FETCH_EVENT_MONTHS,
              payload: posts
            })
          })
      })
  }
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case FETCH_HOMEPAGE_EVENTS:
      return { ...state, homepageEvents: action.payload }
    case SET_START_DATE:
      return { ...state, startYear: action.date.year, startMonth: action.date.month, startDay: action.date.day }
    case TOOGLE_DATE:
      return { ...state, dateChanged: !state.dateChanged }
    case TOOGLE_MONTHS:
      return { ...state, monthChanged: !state.monthChanged }
    case SET_SEL_MONTHS:
      return { ...state, selectedMonths: action.months }
    case FETCH_EVENT_YEARS:
      return { ...state, eventYears: action.payload }
    case FETCH_EVENT_MONTHS:
      return { ...state, eventMonths: action.payload }
    case FETCH_EVENT_DAYS:
      return { ...state, eventDays: action.payload }
    case FETCH_EVENTS:
      return { ...state, events: action.payload }
    case SET_SELECTED_YEAR:
      return { ...state, selectedYear: action.year }
    case SET_SELECTED_MONTH:
      return { ...state, selectedMonth: action.month }
    case SET_SELECTED_DAY:
      return { ...state, selectedDay: action.day }
    case SET_DAYS_SHOWN:
      return { ...state, daysShown: action.days, scrollDays: action.days }
    case SET_MONTHS_SHOWN:
      return { ...state, monthsShown: action.months }
    case EVENTS_LOADING:
      return { ...state, eventsAreLoading: action.loading }
    case EVENTS_FETCHED:
      return { ...state, eventsFetched: true }
    case EVENTS_HOMEPAGE_LOADING:
      return { ...state, eventsHomePageAreLoading: action.loading }
    default:
      return state
  }
}

function formatDate(year, month, day) {
  return year + '-' + ('0' + month).slice(-2) + '-' + ('0' + day).slice(-2)
}

function computeSelectedMonths(events) {
  let months = []
  events.forEach(event => {
    if (event.start !== null)
      months.push(parseInt(event.start.substr(5, 2) - 1))
  })
  months = months.filter((item, index) => {
    return months.indexOf(item) === index
  })
  return months
}

export function formatDateInterval(strDate, lang, strEnd) {
  let date = new Date(strDate)
  if (strEnd == null) {
    return date.toLocaleDateString(lang, { day: 'numeric', month: 'long', year: 'numeric' })
  }
  let end = new Date(strEnd)
  let divisor = ' ' + msg[lang]['global.to'] + ' '
  if (date.getFullYear() === end.getFullYear()) { // mesmo ano
    if (date.getMonth() === end.getMonth()) { // mesmo mês
      if (date.getDate() === end.getDate()) { // mesmo dia
        return date.toLocaleDateString(lang, { day: 'numeric', month: 'long', year: 'numeric' })
      } else {
        return date.toLocaleDateString(lang, { day: 'numeric' }) + divisor + end.toLocaleDateString(lang, { day: 'numeric', month: 'long', year: 'numeric' })
      }
    } else { // mês diferente
      return date.toLocaleDateString(lang, { day: 'numeric', month: 'long' }) + divisor + end.toLocaleDateString(lang, { day: 'numeric', month: 'long', year: 'numeric' })
    }
  } else { // anos diferentes
    return date.toLocaleDateString(lang, { day: 'numeric', month: 'long', year: 'numeric' }) + divisor + end.toLocaleDateString(lang, { day: 'numeric', month: 'long', year: 'numeric' })
  }
}
