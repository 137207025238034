import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Scrollbars } from 'react-custom-scrollbars'

import styles from './FoldersToolbar.css'
import spinner from '../../../css/Spinner.css'
import {
  fetchPastas, toggleExpandedPasta, setCurrentPasta, fetchCurrentPastaContent,
  setCurrentPastaByPermalink, fetchDestinationPasta, setPathSelectedPermalink
} from '../../../modules/pastas'
import { setPastaClicked } from '../../../modules/documentos'

class FoldersToolbar extends Component {
  constructor(props) {
    super(props)
    this.renderThumbHor = this.renderThumbHor.bind(this)
    this.renderThumbVert = this.renderThumbVert.bind(this)
    this.pastaOnClick = this.pastaOnClick.bind(this)
    this.subPastaOnClick = this.subPastaOnClick.bind(this)
    this.toggleExpandPasta = this.toggleExpandPasta.bind(this)
    this.viewPort = React.createRef()
    this.scrollbar = React.createRef()
    this.currentExpandedDiv = React.createRef()
  }

  componentDidMount() {
    const { expandedPastaId, currentPasta, pastas, lang, pathSelectedPermalink, pathDestinationPermalink, currentPastaContentIsLoading } = this.props
    this.props.fetchPastas(this.props.lang)

    this.props.setPathSelectedPermalink(null)
    if (pathDestinationPermalink) {
      console.log('pathDestinationPermalink', pathDestinationPermalink)
      this.props.fetchDestinationPasta(pathDestinationPermalink, lang)
    }
  }

  render() {
    const { pastas, pastasAreLoading, currentPastaContentIsLoading } = this.props
    if (pastasAreLoading || currentPastaContentIsLoading) {
      return (
        <div className={styles.layoutGrid}>
          <div className={spinner.loader} />
        </div>
      )
    }
    return (
      <div className={styles.layoutGrid}>
        <div className={styles.sbDiv} ref={this.viewPort}>
          <Scrollbars
            ref={this.scrollbar}
            autoHeight
            autoHeightMax={350}
            autoHide
            // autoHideTimeout={1000}
            // autoHideDuration={200}
            // thumbMinSize={30}
            renderThumbHorizontal={this.renderThumbHor}
            renderThumbVertical={this.renderThumbVert}>
            {this.buildList(pastas)}
          </Scrollbars>
        </div>
        <div className={styles.divGradTop} />
        <div className={styles.divGradBottom} />
      </div>
    )
  }

  buildList(pastas) {
    const { expandedPastaId } = this.props
    return (
      <ul className={styles.ul}>
        {
          pastas.map((obj, index) => {
            if (obj.id === expandedPastaId) {
              let elements = []
              elements.push(<li className={styles.li + ' ' + styles.expanded} key={obj.id} ref={this.currentExpandedDiv}>
                <span
                  className={styles.nomePastaDiv + (this.isCurrentPasta(obj) ? ' ' + styles.current : '')}
                  onClick={(e) => this.pastaOnClick(e, obj)}>
                  {obj.nome}
                </span>
                <div className={styles.toggleExpandPasta + ' ' + styles.expanded}
                  onClick={(e) => this.toggleExpandPasta(e, obj)} />
              </li>)
              elements.push(this.listSubPastas(obj.subPastas, index))
              return (elements)
            } else {
              return (
                <li className={styles.li} key={obj.id}>
                  <span
                    className={styles.nomePastaDiv + (this.isCurrentPasta(obj) ? ' ' + styles.current : '')}
                    onClick={(e) => this.pastaOnClick(e, obj)}>
                    {obj.nome}
                  </span>
                  {this.canExpandPasta(obj)
                    ? <div className={styles.toggleExpandPasta}
                      onClick={(e) => this.toggleExpandPasta(e, obj)} />
                    : null}
                </li>
              )
            }
          })
        }
      </ul>
    )
  }

  scrollTopExpanded(target) {
    let viewPort = this.viewPort.current.getBoundingClientRect()
    let rect = target.getBoundingClientRect()
    // let dist = rect.top - (viewPort.top + viewPort.height / 2) + (rect.height / 2)
    let dist = rect.top - viewPort.top - 10
    let sb = this.scrollbar.current
    dist = sb.getScrollTop() + dist
    this.scrollSmooth(sb.getScrollTop(), dist, 300)
  }

  scrollSmooth(oldValue, newValue, dur) {
    const stepDur = 5
    let steps = Math.floor(dur / stepDur)
    let stepSize = (newValue - oldValue) / steps
    for (let index = 0; index < steps; index++) {
      let sum = stepSize * (index + 1)
      if (!this.scrollbar || !this.scrollbar.current) {
        break
      }
      setTimeout(() => {
        if (this.scrollbar.current) {
          this.scrollbar.current.scrollTop(oldValue + sum)
        }
      }, index * stepDur)
    }
  }

  listSubPastas(pastas, index) {
    return (
      <ul className={styles.ulSubPastas} key={index}>
        {
          pastas.map(obj => (
            <li className={styles.liSubPasta} key={obj.id}>
              <span
                className={styles.nomeSubPastaDiv + (this.isCurrentPasta(obj) ? ' ' + styles.current : '')}
                onClick={(e) => this.subPastaOnClick(obj)}>
                {obj.nome}
              </span>
            </li>
          ))
        }
      </ul>
    )
  }

  isCurrentPasta(pasta) {
    const { currentPasta } = this.props
    if (!currentPasta) {
      return false
    }
    if (pasta.id === currentPasta.id) {
      return true
    }
    return false
  }

  canExpandPasta(pasta) {
    if (!pasta.subPastas || pasta.subPastas.length === 0) {
      return false
    }
    return true
  }

  pastaOnClick(e, pasta) {
    const { lang, currentPasta, expandedPastaId } = this.props
    this.props.setPastaClicked(true)
    if (!currentPasta || currentPasta.id !== pasta.id) {
      let onlyFiles = this.canExpandPasta(pasta)
      onlyFiles = false
      this.props.setCurrentPasta(pasta)
      // this.props.fetchCurrentPastaContent(lang, pasta, onlyFiles)
      if (pasta.subPastas.length > 0 &&
        (!expandedPastaId || expandedPastaId !== pasta.id)) {
        this.toggleExpandPasta(e, pasta)
      }
    } else {
      if (expandedPastaId !== null && expandedPastaId === pasta.id) {
        this.toggleExpandPasta(e, pasta)
      }
      this.props.setCurrentPasta(null)
      // this.props.fetchCurrentPastaContent(lang, null)
    }
  }

  subPastaOnClick(pasta) {
    const { lang, currentPasta } = this.props
    this.props.setPastaClicked(true)
    if (!currentPasta || currentPasta.id !== pasta.id) {
      this.props.setCurrentPasta(pasta)
      // this.props.fetchCurrentPastaContent(lang, pasta)
    } else {
      this.props.setCurrentPasta(null)
      // this.props.fetchCurrentPastaContent(lang, null)
    }
  }

  toggleExpandPasta(e, pasta) {
    const { expandedPastaId } = this.props
    if (pasta.id !== expandedPastaId) {
      this.scrollTopExpanded(e.target)
    }
    this.props.toggleExpandedPasta(pasta)
  }

  renderThumbHor({ style, ...props }) {
    const thumbStyle = {
      backgroundColor: '#9ebdd2',
      borderRadius: '4px'
    }
    return (
      <div
        style={{ ...style, ...thumbStyle }}
        {...props} />
    )
  }

  renderThumbVert({ style, ...props }) {
    const thumbStyle = {
      minWidth: '8px',
      backgroundColor: '#9ebdd2',
      borderRadius: '4px'
    }
    return (
      <div
        style={{ ...style, ...thumbStyle }}
        {...props} />
    )
  }
}

FoldersToolbar.propTypes = {
  lang: PropTypes.string.isRequired,
  pastas: PropTypes.array.isRequired,
  fetchPastas: PropTypes.func.isRequired,
  toggleExpandedPasta: PropTypes.func.isRequired,
  setCurrentPasta: PropTypes.func.isRequired,
  fetchCurrentPastaContent: PropTypes.func.isRequired,
  currentPasta: PropTypes.object,
  expandedPastaId: PropTypes.string,
  setCurrentPastaByPermalink: PropTypes.func.isRequired,
  fetchDestinationPasta: PropTypes.func.isRequired,
  pathSelectedPermalink: PropTypes.string,
  pathDestinationPermalink: PropTypes.string,
  setPastaClicked: PropTypes.func.isRequired,
  currentPastaContentIsLoading: PropTypes.bool.isRequired,
  setPathSelectedPermalink: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  lang: state.linguas.lang,
  pastas: state.pastas.pastas,
  currentPasta: state.pastas.currentPasta,
  expandedPastaId: state.pastas.expandedPastaId,
  pathSelectedPermalink: state.pastas.pathSelectedPermalink,
  pathDestinationPermalink: state.pastas.pathDestinationPermalink,
  pastasAreLoading: state.pastas.pastasAreLoading,
  currentPastaContentIsLoading: state.pastas.currentPastaContentIsLoading
})

const mapDispatchToProps = {
  fetchPastas,
  toggleExpandedPasta, setCurrentPasta, fetchCurrentPastaContent, setCurrentPastaByPermalink,
  fetchDestinationPasta, setPastaClicked, setPathSelectedPermalink
}

export default connect(mapStateToProps, mapDispatchToProps)(FoldersToolbar)
