import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { FormattedMessage as FM } from 'react-intl'

import common from '../common/Body.css'
import NoticiasList from './NoticiasList'
import { fetchNews } from '../../../modules/news'
import { ymdToDate } from '../../../lib/utils'
import { fetchCategorias, setCurrentObjType, resetSelectedCategorias } from '../../../modules/categorias'

class Noticias extends Component {
  componentDidMount() {
    const { lang, currentObjectType, newsAreLoading } = this.props
    if (newsAreLoading) return
    this.props.fetchNews(lang)
    if (currentObjectType !== 'noticia') {
      this.props.setCurrentObjType('noticia')
      this.props.resetSelectedCategorias()
      this.props.fetchCategorias(lang, 'noticia')
    }
  }

  componentDidUpdate() {
    const { lang, selectedCategorias, newsAreLoading, newsFetched } = this.props
    if (newsAreLoading || (!newsAreLoading && newsFetched)) return
    if (selectedCategorias && selectedCategorias.length > 0) {
      this.props.fetchNews(lang, selectedCategorias)
    }
    // else {
    //   this.props.fetchCategorias(lang, 'noticia')
    //   this.props.fetchNews(lang)
    // }
  }

  render() {
    if (this.props.short === true) {
      return <NoticiasList short />
    }
    const { selectedCategorias } = this.props
    const updateFilteredCategorias = selectedCategorias && selectedCategorias.length > 0
    return (
      <div className={common.layoutGrid}>
        <div className={common.header}>
          <FM id='news.news' defaultMessage='Notícias' />
        </div>
        <NoticiasList filterCategorias={updateFilteredCategorias} />
      </div>
    )
  }

  filterFromSelectedMonth(objects, selectedMonth) {
    if (!objects) {
      return []
    }
    if (!selectedMonth) {
      return objects
    }
    const selDate = new Date(selectedMonth.year, selectedMonth.month, 0)
    let result = objects.filter(function (obj) {
      return obj !== null && ymdToDate(obj.date) <= selDate
    })
    return result
  }
}

Noticias.propTypes = {
  fetchNews: PropTypes.func.isRequired,
  lang: PropTypes.string.isRequired,
  fetchCategorias: PropTypes.func.isRequired,
  selectedCategorias: PropTypes.array.isRequired,
  currentObjectType: PropTypes.string.isRequired,
  selectedMonth: PropTypes.object,
  setCurrentObjType: PropTypes.func.isRequired,
  resetSelectedCategorias: PropTypes.func.isRequired,
  newsAreLoading: PropTypes.bool.isRequired,
  newsFetched: PropTypes.bool.isRequired
}

const mapStateToProps = state => ({
  lang: state.linguas.lang,
  selectedCategorias: state.categorias.selectedCategorias,
  selectedMonth: state.calendar.selectedMonth,
  currentObjectType: state.categorias.currentObjectType,
  newsAreLoading: state.news.newsAreLoading,
  newsFetched: state.news.newsFetched
})

const mapDispatchToProps = { fetchNews, fetchCategorias, setCurrentObjType, resetSelectedCategorias }

export default connect(mapStateToProps, mapDispatchToProps)(Noticias)
