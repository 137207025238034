/* eslint-env browser */
import { host } from '../request'
import { getImage } from '../lib/utils'
import axios from 'axios'

const SET_MOBILE = 'cmlf/global/SET_MOBILE'
const SET_MOBILE_MENU = 'cmlf/global/SET_MOBILE_MENU'
const REFRESH_PAGE = 'cmlf/global/REFRESH_PAGE'
const FETCH_CONFIG = 'cmlf/global/FETCH_CONFIG'

const initialState = {
  mobile: false,
  refreshPage: false,
  config: null,
  mobileMenu: false
}

export const setMobile = (mobile) => ({ type: SET_MOBILE, mobile })
export const setMobileMenu = (mobileMenu) => ({ type: SET_MOBILE_MENU, mobileMenu })
export const refreshPage = () => ({ type: REFRESH_PAGE })

export function fetchConfig () {
  return (dispatch) => {
    axios.get(host + '/api/public/config')
      .then(res => res.data)
      .then(post => {
        let homeImages = []
        post.homeImages.forEach(img => {
          homeImages.push(getImage(img))
        })
        const config = {
          positionY: post.positionY,
          imageUrl: getImage(post.image),
          slideDelaySecs: post.slideDelaySecs,
          homeImages: homeImages
        }
        dispatch({
          type: FETCH_CONFIG,
          payload: config
        })
      })
  }
}

export default function reducer (state = initialState, action = {}) {
  switch (action.type) {
    case SET_MOBILE:
      return { ...state, mobile: action.mobile }
    case SET_MOBILE_MENU:
      return { ...state, mobileMenu: action.mobileMenu }
    case REFRESH_PAGE:
      return { ...state, refreshPage: !state.refreshPage }
    case FETCH_CONFIG:
      return { ...state, config: action.payload }
    default:
      return state
  }
}
