import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { buildMonths, buildDays, buildNewsMonths } from '../../../modules/calendar'
import styles from './CalendarToolbar.css'
import spinner from '../../../css/Spinner.css'
import CalendarMonths from './CalendarMonths'
import CalendarDays from './CalendarDays'
import { fetchNews } from '../../../modules/news'

class CalendarToolbar extends Component {
  constructor(props) {
    super(props)
    // const { newsFetched, newsAreLoading, lang } = props
    // if (!newsFetched) {
    //   if (!newsAreLoading) {
    //     this.props.fetchNews(lang)
    //   }
    // }
  }
  componentDidMount() {
    const { eventMonths, eventDays, showNews, news, newsFetched, newsAreLoading } = this.props
    if (!showNews) {
      this.props.buildMonths(eventMonths)
      this.props.buildDays(eventDays)
    } else {
      if (newsFetched && !newsAreLoading) {
        this.props.buildNewsMonths(news)
      }
      // this.props.buildNewsMonths(news)
    }
  }

  componentDidUpdate() {
    const { eventMonths, eventDays, showNews, news, newsFetched, newsAreLoading, lang } = this.props
    if (!showNews) {
      this.props.buildMonths(eventMonths)
      this.props.buildDays(eventDays)
    } else {
      if (newsFetched && !newsAreLoading) {
        this.props.buildNewsMonths(news)
      }
      // if (!newsFetched) {
      //   if (!newsAreLoading) {
      //     this.props.fetchNews(lang)
      //   }
      // } else {
      //   this.props.buildNewsMonths(news)
      // }
    }
  }

  render() {
    const { showDays, newsFetched, newsAreLoading } = this.props
    if (!showDays) {
      if (!newsFetched || newsAreLoading) {
        return (
          <div className={styles.layoutGrid + ' ' + styles.monthsOnly}>
            <div className={spinner.loader} />
          </div>
        )
      }
    }
    return (
      <div className={styles.layoutGrid + (showDays ? '' : ' ' + styles.monthsOnly)}>
        <CalendarMonths />
        {showDays ? <CalendarDays /> : null}
      </div>
    )
  }
}

CalendarToolbar.propTypes = {
  lang: PropTypes.string.isRequired,
  selectedDay: PropTypes.object,
  selectDay: PropTypes.func,
  buildMonths: PropTypes.func.isRequired,
  buildDays: PropTypes.func.isRequired,
  eventMonths: PropTypes.array.isRequired,
  eventDays: PropTypes.array.isRequired,
  showDays: PropTypes.bool.isRequired,
  showNews: PropTypes.bool.isRequired,
  buildNewsMonths: PropTypes.func.isRequired,
  news: PropTypes.array.isRequired,
  fetchNews: PropTypes.func.isRequired,
  newsFetched: PropTypes.bool.isRequired,
  newsAreLoading: PropTypes.bool.isRequired
}

const mapStateToProps = state => ({
  lang: state.linguas.lang,
  eventMonths: state.events.eventMonths,
  eventDays: state.events.eventDays,
  showDays: state.calendar.showDays,
  showNews: state.calendar.showNews,
  news: state.news.news,
  newsFetched: state.news.newsFetched,
  newsAreLoading: state.news.newsAreLoading
})

const mapDispatchToProps = { buildMonths, buildDays, buildNewsMonths, fetchNews }

export default connect(mapStateToProps, mapDispatchToProps)(CalendarToolbar)
