import { getMediaType } from '../lib/utils'

const TOGGLE_POPUP = 'cmlf/popup/TOGGLE_POPUP'
const ADD_ITEM = 'cmlf/popup/ADD_ITEM'
const SET_CURRENT = 'cmlf/popup/SET_CURRENT'
const RESET_LIST = 'cmlf/popup/RESET_LIST'
const RESET_INITIAL_SLIDE = 'cmlf/popup/RESET_INITIAL_SLIDE'

const initialState = {
  itemList: [],
  showPopup: false,
  color: '#0c598e',
  current: 0,
  initialSlide: -1
}

export const togglePopup = (initialSlide) => ({ type: TOGGLE_POPUP, initialSlide })
export const addItem = (media, pos) => ({ type: ADD_ITEM, media, pos })
export const setCurrent = (current) => ({ type: SET_CURRENT, current })
export const resetList = () => ({ type: RESET_LIST })
export const resetInitialState = () => { { type: RESET_INITIAL_SLIDE } }

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case TOGGLE_POPUP:
      if (state.showPopup)
        return { ...state, showPopup: !state.showPopup }
      else
        return { ...state, showPopup: !state.showPopup, initialSlide: action.initialSlide }
    case ADD_ITEM:
      let list = [...state.itemList]
      let item = convertMediaToItem(action.media)
      return { ...state, itemList: pushIfNew(list, item, action.pos) }
    case SET_CURRENT:
      return { ...state, current: action.current, initialSlide: -1 }
    case RESET_LIST:
      return { ...state, itemList: [] }
    case RESET_INITIAL_SLIDE:
      return { ...state, initialState: -1 }
    default:
      return state
  }
}

function convertMediaToItem(media) {
  return ({
    key: media.id,
    media: media.file,
    nome: media.text,
    filename: media.filename,
    tipo: getMediaType(media.filename)
  })
}

function pushIfNew(list, item, pos) {
  if (!list || list.length === 0) {
    list = []
    list.push(item)
    return list
  }
  let exists = false
  list.forEach(element => {
    if (element.key === item.key) {
      exists = true
    }
  })
  if (!exists) {
    if (!pos && pos !== 0) {
      list.push(item)
    } else {
      list.unshift(item)
    }
  }
  return list
}
