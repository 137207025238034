import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import styles from './HeaderLinks.css'
import { fetchHeaderLinks } from '../../modules/destaques'

class HeaderLinks extends Component {
  componentDidMount () {
    let { headerLinksFetched, lang } = this.props
    if (!headerLinksFetched) {
      this.props.fetchHeaderLinks(lang)
    }
  }

  render () {
    let { headerLinks, headerLinksFetched } = this.props
    if (!headerLinksFetched) {
      return null
    }
    return (
      <ul className={styles.ul}>
        {
          headerLinks.map((link) => (
            <li className={styles.li} key={link.id}>
              <Link className={styles.link} to={link.link}>{link.text}</Link>
            </li>
          ))
        }
      </ul>
    )
  }
}

HeaderLinks.propTypes = {
  headerLinks: PropTypes.array.isRequired,
  headerLinksFetched: PropTypes.bool.isRequired,
  fetchHeaderLinks: PropTypes.func.isRequired,
  lang: PropTypes.string
}

const mapStateToProps = state => ({
  headerLinks: state.destaques.headerLinks,
  headerLinksFetched: state.destaques.headerLinksFetched,
  lang: state.linguas.lang
})

const mapDispatchToProps = { fetchHeaderLinks }

export default connect(mapStateToProps, mapDispatchToProps)(HeaderLinks)
