import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { FormattedMessage as FM } from 'react-intl'
import axios from 'axios'
import { withRouter } from 'react-router'

import { host, getTranslation, generateUUID } from '../../../request'
import { fetchInfoFormInProgress } from '../../../modules/servicos'
import styles from './LinkFormulario.css'
import { convertApiEndpoint } from '../../../lib/utils'
import { setLoginPopupState } from '../../../modules/header'

class LinkFormulario extends Component {
  constructor(props) {
    super(props)
    this.clickNewFormInstance = this.clickNewFormInstance.bind(this)
    this.clickRemoveFormInstance = this.clickRemoveFormInstance.bind(this)
    this.showLoginPopup = this.showLoginPopup.bind(this)
  }

  componentDidMount() {
    const { formulario, user } = this.props
    if (user.username !== null) {
      this.props.fetchInfoFormInProgress(formulario.id)
    }
  }

  render() {
    const { formulario, lang, formulariosInProgress, user, servico } = this.props
    return (
      <li className={styles.layoutGrid}>
        <div className={styles.nomeDiv}>
          {getTranslation(formulario.nome, lang)}
        </div>
        {/* <div className={styles.dataDiv}>{getDateStr(formulario.data, lang)}</div> */}
        {
          !formulario.descricao
            ? null
            : <div className={styles.descricaoDiv}>
              <div dangerouslySetInnerHTML={{ __html: getTranslation(formulario.descricao, lang) }} />
            </div>
        }
        {this.displayWarn(user.username)}
        {this.buildActions(formulario, formulariosInProgress, user.username, servico)}
      </li>
    )
  }

  displayWarn(username) {
    if (!username) {
      return (
        <div className={styles.buttonsContainer}>
          <a className={styles.button} href='#' onClick={this.showLoginPopup}>
            <FM id='loginToFillForm' defaultMessage='Login necessário para preencher' />
          </a>
        </div>
      )
    }
    return null
  }

  showLoginPopup() {
    this.props.setLoginPopupState(false)
  }

  buildActions(formulario, formulariosInProgress, username, servico) {
    if (!username) {
      return null
    }
    // for (let index = 0; index < formulariosInProgress.length; index++) {
    //   const element = formulariosInProgress[index]
    //   if (element.formularioId === formulario.id && element.formInstanceId !== null) {
    //     return (
    //       <div className={styles.buttonsContainer}>
    //         <Link className={styles.button} to={'/formulario/' + formulario.permalink + '/' + element.formInstanceId}>
    //           <FM id='form.resume' defaultMessage='Retomar' />
    //         </Link>&nbsp;
    //         <a className={styles.button} href='#' onClick={(e) => this.clickRemoveFormInstance(e, element.formInstanceId, formulario.id)}>
    //           <FM id='form.remove' defaultMessage='Remover' />
    //         </a>
    //       </div>
    //     )
    //   }
    // }
    return (
      <div className={styles.buttonsContainer}>
        <a className={styles.button} href='#'
          onClick={(e) => this.clickNewFormInstance(e, formulario.permalink, formulario.id, servico.id)}>
          <FM id='form.new' defaultMessage='Preencher novo formulário' />
        </a>
      </div>
    )
  }

  clickRemoveFormInstance(e, formInstanceId, formId) {
    e.preventDefault()
    axios.delete(host + '/api/user/formInstance/' + formInstanceId)
      .then(() => {
        this.props.fetchInfoFormInProgress(formId)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  clickNewFormInstance(e, permalink, formId, servicoId) {
    e.preventDefault()
    const instanceId = generateUUID()
    const { user } = this.props
    // gravar a instância do formulário
    axios.put(host + convertApiEndpoint(user, '/api/public/formulario/') + formId + '/new/' + instanceId, { formId: formId, instanceId: instanceId, servicoId: servicoId })
      .then(() => {
        // obter a instância da api
        axios.get(host + '/api/user/formInstance/' + instanceId)
          .then(res => res.data)
          .then(post => {
            post.newObs = ''
            let changed = this.initValues(post)
            if (changed) {
              // havendo alterações na inicialização de valores, então gravar
              axios.put(host + '/api/user/formInstance/' + instanceId, { id: instanceId, values: post.values })
                .then(() => {
                  this.props.fetchInfoFormInProgress(formId)
                  const { history: { push } } = this.props
                  push('/formulario/' + permalink + '/' + instanceId)
                })
            } else {
              this.props.fetchInfoFormInProgress(formId)
              const { history: { push } } = this.props
              push('/formulario/' + permalink + '/' + instanceId)
            }
          })
      })
      .catch((error) => {
        console.log(error)
      })
  }

  initValues(formInstance) {
    const { lang } = this.props
    if (!formInstance) {
      return false
    }
    let changed = false
    formInstance.formulario.campos.forEach(formField => {
      let exist = false
      for (let index = 0; index < formInstance.values.length; index++) {
        const instItem = formInstance.values[index]
        if (instItem.fieldId === formField.id) {
          exist = true
          break
        }
      }
      if (!exist) {
        // inicializar
        let value = this.getFieldValue(formField, lang)
        if (value != null) {
          formInstance.values.push(value)
          changed = true
        }
      }
    })
    return changed
  }

  getFieldValue(field, lang) {
    let value = { fieldId: field.id }
    let keyValues = []
    switch (field.type) {
      case 'header':
        return null
      case 'hr':
        return null
      case 'inputText':
        return { fieldId: field.id, value: '' }
      case 'inputNumber':
        return { fieldId: field.id, value: '' }
      case 'inputDate':
        return { fieldId: field.id, value: '' }
      case 'paragraph':
        return null
      case 'listBox':
        for (let index = 0; index < field.values.length; index++) {
          const item = field.values[index]
          if (item.lang === lang && item.defaultValue === true) {
            keyValues.push({ key: item.value, value: item.label })
            if (!field.allowMultiSelection || field.fielType === 0) {
              break
            }
          }
        }
        value.keyValues = keyValues
        return value
      case 'radioButtonGroup':
        for (let index = 0; index < field.values.length; index++) {
          const item = field.values[index]
          if (item.lang === lang && item.defaultValue === true) {
            keyValues.push({ key: item.value, value: item.label })
            break // Como só posso ter um valor seleccionado, saio logo no primeiro que encontro
          }
        }
        value.keyValues = keyValues
        return value
      case 'checkBoxGroup':
        for (let index = 0; index < field.values.length; index++) {
          const item = field.values[index]
          if (item.lang === lang && item.defaultValue === true) {
            keyValues.push({ key: item.value, value: item.label })
          }
        }
        value.keyValues = keyValues
        return value
      default:
        return null
    }
  }
}

LinkFormulario.propTypes = {
  fetchInfoFormInProgress: PropTypes.func.isRequired,
  lang: PropTypes.string.isRequired,
  user: PropTypes.object,
  formulariosInProgress: PropTypes.array.isRequired,
  router: PropTypes.object,
  setLoginPopupState: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  model: state.model.model,
  lang: state.linguas.lang,
  user: state.auth.user,
  formulariosInProgress: state.servicos.formulariosInProgress
})

const mapDispatchToProps = { fetchInfoFormInProgress, setLoginPopupState }

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LinkFormulario))
