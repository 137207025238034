import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import styles from './Toolbar.css'
import { toggleToolbar } from '../../../modules/toolbar'
import TagsToolbar from './TagsToolbar'
import indexStyles from '../../../index.css'
import FoldersToolbar from './FoldersToolbar'
import CalendarToolbar from './CalendarToolbar'
import MenuToolbar from './MenuToolbar'

class Toolbar extends Component {
  constructor(props) {
    super(props)
    this.menuClick = this.menuClick.bind(this)
    this.handleScroll = this.handleScroll.bind(this)
    this.toolbarRef = React.createRef()
  }

  componentDidMount () {
    window.addEventListener('scroll', this.handleScroll, { passive: true })
  }

  componentWillUnmount () {
    window.removeEventListener('scroll', this.handleScroll)
  }

  render () {
    const { selectedToolbar, menus } = this.props
    // style={{ zIndex: 0 }}
    return (
      <div className={styles.toolbarGrid} ref={this.toolbarRef}>
        <div className={styles.headerGrid}>
          {this.buildMenus(selectedToolbar, menus)}
        </div>
        {
          selectedToolbar === ''
            ? null
            : <div className={styles.toolbarContainerDiv + ' ' + indexStyles.shadow}>
              {this.getToolbar(selectedToolbar)}
            </div>
        }
      </div>
    )
  }

  handleScroll (event) {
    const { mobile } = this.props
    if (mobile) {
      return
    }
    let el = this.toolbarRef.current
    let parent = el.parentElement
    let parentRect = parent.getBoundingClientRect()
    if (parentRect.top < 0) {
      el.style.top = (-1 * parentRect.top) + 10 + 'px'
    } else {
      el.style.top = '10px'
    }
  }

  getToolbar (selectedToolbar) {
    switch (selectedToolbar) {
      case 'tags':
        return <TagsToolbar />
      case 'folders':
        return <FoldersToolbar {...this.props} />
      case 'calendar':
        return <CalendarToolbar />
      case 'menu':
        return <MenuToolbar />
      default:
        return <TagsToolbar />
    }
  }

  buildMenus (selectedToolbar, menus) {
    let result = []
    let pos = 1
    for (let index = 0; index < menus.length; index++) {
      const menu = menus[index]
      if (menu.props.enabled) {
        let style = { gridArea: 'a' + pos++ }
        result.push(
          <div key={index}
            className={styles.headerDiv + ' ' + styles[menu.name + 'Div'] +
              this.getSelected(menu.name, selectedToolbar) +
              this.getDisabled(menu.name) + ' ' + indexStyles.shadow}
            style={style}
            onClick={(e) => this.menuClick(menu.name)}
          />
        )
      }
    }
    return result
  }

  getSelected (menu, selectedToolbar) {
    if (menu === selectedToolbar) {
      return ' ' + styles.selected
    }
    return ''
  }

  getDisabled (menuName) {
    let menu = this.getMenu(menuName)
    if (!menu) {
      return ''
    }
    if (!menu.props.enabled) {
      return ' ' + styles.disabled
    } else {
      return ''
    }
  }

  getMenu (menuName) {
    const { menus } = this.props
    for (let index = 0; index < menus.length; index++) {
      const element = menus[index]
      if (element.name === menuName) {
        return element
      }
    }
  }

  menuClick (menu) {
    this.props.toggleToolbar(menu)
  }
}

Toolbar.propTypes = {
  lang: PropTypes.string.isRequired,
  toggleToolbar: PropTypes.func.isRequired,
  menus: PropTypes.array.isRequired,
  mobile: PropTypes.bool.isRequired
}

const mapStateToProps = state => ({
  lang: state.linguas.lang,
  selectedToolbar: state.toolbar.selectedToolbar,
  menus: state.toolbar.menus,
  mobile: state.global.mobile
})

const mapDispatchToProps = { toggleToolbar }

export default connect(mapStateToProps, mapDispatchToProps)(Toolbar)
