const HEADER_COLOR = 'cmlf/header/HEADER_COLOR'
const HEADER_LOGO_WIDTH = 'cmlf/header/HEADER_LOGO_WIDTH'
const SET_SELECTED_BUTTON = 'cmlf/header/SET_SELECTED_BUTTON'
const TOGGLE_SEARCH_MODE = 'cmlf/header/SET_SEARCH_MODE'
const SET_BREADCRUMB = 'cmlf/header/SET_BREADCRUMB'
const ADD_BREADCRUMB = 'cmlf/header/ADD_BREADCRUMB'
const LOCATION_CHANGED = 'cmlf/header/LOCATION_CHANGED'
const RESTORE_BREADCRUMB = 'cmlf/header/RESTORE_BREADCRUMB'
const SET_MENU_EXPANDED = 'cmlf/header/SET_MENU_EXPANDED'
const SET_LOGIN_POPUP_STATE = 'cmlf/header/SET_LOGIN_POPUP_STATE'

const initialState = {
  color: '#fff',
  logoWidth: '165px',
  selectedButton: 0,
  searchMode: false,
  breadcrumb: [{ link: '/', name: 'breadcrumb.home' }],
  previousBreadcrumb: null,
  previousLocation: null,
  currentLocation: null,
  menuExpanded: false,
  loginPopupClosed: true
}

export const setColor = (color) => ({ type: HEADER_COLOR, color })
export const setLogoWidth = (width) => ({ type: HEADER_LOGO_WIDTH, width })
export const setSelectedButton = (index) => ({ type: SET_SELECTED_BUTTON, index })
export const toggleSearchMode = () => ({ type: TOGGLE_SEARCH_MODE })
export const setBreadcrumb = (breadcrumb) => ({ type: SET_BREADCRUMB, breadcrumb })
export const addBreadcrumb = (breadcrumb) => ({ type: ADD_BREADCRUMB, breadcrumb })
export const locationChanged = (location) => ({ type: LOCATION_CHANGED, location })
export const restoreBreadcrumb = () => ({ type: RESTORE_BREADCRUMB })
export const setMenuExpanded = (expanded) => ({ type: SET_MENU_EXPANDED, expanded })
export const setLoginPopupState = (closed) => ({ type: SET_LOGIN_POPUP_STATE, closed })

export default function reducer (state = initialState, action = {}) {
  let bc = []
  let prevBc = null
  switch (action.type) {
    case HEADER_COLOR:
      return { ...state, color: action.color }
    case HEADER_LOGO_WIDTH:
      return { ...state, logoWidth: action.width }
    case SET_SELECTED_BUTTON:
      return { ...state, selectedButton: action.index }
    case TOGGLE_SEARCH_MODE:
      return { ...state, searchMode: !state.searchMode }
    case SET_BREADCRUMB:
      prevBc = [...state.breadcrumb]
      bc = [{ link: '/', name: 'breadcrumb.home' }]
      action.breadcrumb.forEach(element => {
        if (element.link !== '/') {
          bc.push(element)
        }
      })
      return { ...state, breadcrumb: bc, previousBreadcrumb: prevBc }
    case ADD_BREADCRUMB:
      if (inBreadcrumb(state.breadcrumb, action.breadcrumb)) {
        return state
      }
      bc = [...state.breadcrumb]
      bc.push(action.breadcrumb)
      return { ...state, breadcrumb: bc }
    case LOCATION_CHANGED:
      return { ...state, previousLocation: state.currentLocation, currentLocation: action.location.pathname }
    case RESTORE_BREADCRUMB:
      if (state.previousBreadcrumb !== null) {
        prevBc = [...state.previousBreadcrumb]
      }
      return { ...state, breadcrumb: prevBc }
    case SET_MENU_EXPANDED:
      return { ...state, menuExpanded: action.expanded }
    case SET_LOGIN_POPUP_STATE:
      return { ...state, loginPopupClosed: action.closed }
    default:
      return state
  }
}

function inBreadcrumb (arr, item) {
  if (!arr || arr.length === 0) {
    return false
  }
  const lastItem = arr[arr.length - 1]
  if (lastItem.link === item.link) {
    return true
  }
  return false
}
