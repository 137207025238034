import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { FormattedMessage as FM } from 'react-intl'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import styles from './News.css'
import { fetchNews } from '../../modules/news'
import indexStyles from '../../index.css'

class News extends Component {
  componentDidMount() {
    this.props.fetchNews(this.props.lang)
  }

  render() {
    let { news } = this.props
    return (
      <div className={styles.container}>
        <div className={styles.top} />
        <div className={styles.body}>
          <div className={styles.textContainer}>
            <div className={styles.titleText}><FM id='news.news' defaultMessage='Notícias' /></div>
          </div>
          <div className={styles.newsContainer}>
            {this.buildNewsDivs(news)}
          </div>
        </div>
        <Link className={styles.verMais} to='/noticias'><FM id='news.moreNews' defaultMessage='mais notícias' /></Link>
      </div>
    )
  }

  buildNewsDivs(news) {
    let array = []
    let i = 0
    news.map(element => {
      if (i >= 6) {
        return array
      }
      let link = !element ? '' : element.link
      array.push(<Link className={styles.link} to={link} key={i}>
        <div className={styles.newsBox + ' ' + indexStyles.shadow}>
          <img className={styles.newsImage} src={element.image} alt='' />
          <div className={styles.newsTextNovo}>
            {element.titulo}
          </div>
          <div className={styles.newsDate}>{this.formatDate(element.date)}</div>
        </div>
      </Link>)
      i++
      return array
    })
    return array
  }

  formatDate(strDate) {
    if (!strDate) {
      return null
    }
    strDate += 'T00:00:00'
    let date = new Date(strDate)
    const options = { day: 'numeric', month: 'long', year: 'numeric' }
    return date.toLocaleDateString(this.props.lang, options)
  }
}

News.propTypes = {
  fetchNews: PropTypes.func.isRequired,
  lang: PropTypes.string.isRequired,
  news: PropTypes.array.isRequired
}

const mapStateToProps = state => ({
  news: state.news.news,
  lang: state.linguas.lang
})

const mapDispatchToProps = { fetchNews }

export default connect(mapStateToProps, mapDispatchToProps)(News)
