/* eslint-env browser */
import Keycloak from 'keycloak-js'
import { notify } from 'react-notify-toast'

import store from './store'
import msg from './components/App/messages'

export const host = window.location.protocol === 'http:' ? 'https://globaleda-dev.duckdns.org' : ''
export const hostUrl = 'https://' + (window.location.protocol === 'http:' ? 'globaleda-dev.duckdns.org' : window.location.host)
// export const host = window.location.protocol === 'http:' ? 'https://internal.globaleda.pt' : ''
// export const hostUrl = 'https://' + (window.location.protocol === 'http:' ? 'internal.globaleda.pt' : window.location.host)

export const isMobile = (window.innerWidth < 768)

export const kc = Keycloak({
  url: hostUrl + '/auth',
  realm: 'cm-lajes-flores',
  clientId: 'fo'
})

export const profileUrl = hostUrl + '/auth/realms/cm-lajes-flores/account' + '?referrer=fo&redirect_uri=' + window.location.origin

export function generateUUID() {
  var d = new Date().getTime()
  if (window.performance && typeof window.performance.now === 'function') {
    d += window.performance.now() // use high-precision timer if available
  }
  var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (d + Math.random() * 16) % 16 | 0
    d = Math.floor(d / 16)
    return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16)
  })
  return uuid
}

export function computeLink(post, type) {
  if (!post) {
    return null
  }
  let prefixLink = false
  if (post.permalink && post.permalink.toLowerCase().indexOf('http') !== 0) {
    prefixLink = true
  }
  switch (post.type || type) {
    case 'document':
      if (prefixLink && post.pasta && post.pasta.permalink) {
        return '/documentos/' + post.pasta.permalink + '/' + post.permalink
      }
      return !prefixLink ? post.permalink : '/documentos'
    case 'page':
      return !prefixLink ? post.permalink : '/pagina/' + post.permalink
    case 'news':
      return !prefixLink ? post.permalink : '/noticia/' + post.permalink
    case 'event':
      return !prefixLink ? post.permalink : '/evento/' + post.permalink
    case 'place':
      return !prefixLink ? post.permalink : '/local/' + post.permalink
    case 'service':
      if (!post.online) {
        return !prefixLink ? post.permalink : '/servico/' + post.permalink
      } else {
        return !prefixLink ? post.permalink : '/servico-online/' + post.permalink
      }
    case 'formulario':
      return !prefixLink ? post.permalink : '/formulario/' + post.permalink
    default:
      return post.permalink
  }
}

export function getTranslation(objText, lang) {
  if (!objText) {
    return ''
  }
  let arr = objText.translations
  let trans = ''
  arr.forEach(t => {
    if (t.lang === lang) {
      trans = t.text
    }
  })
  return trans
}

export function getFetchBody(method, kc) {
  if (!kc.token) {
    return {}
  } else {
    return {
      method: 'GET',
      // credentials: 'include',
      cache: 'no-cache',
      headers: {
        Authorization: 'Bearer ' + kc.token
      }
    }
  }
}

export const updateLocalStorage = (token, refreshToken) => {
  localStorage.setItem('cmlf_kcToken', token)
  localStorage.setItem('cmlf_kcRefreshToken', refreshToken)
}

export const updateState = (token, refreshToken) => {
  let state = store.getState()
  state.auth.token = token
  state.auth.refreshToken = refreshToken
}

export function getStatus(status, lang) {
  switch (status) {
    case 1:
      return msg[lang]['form.status.submitted']
    case 2:
      return msg[lang]['form.status.approved']
    case 3:
      return msg[lang]['form.status.rejected']
    case 4:
      return msg[lang]['form.status.returned']
    case 5:
      return msg[lang]['form.status.filed']
    case 6:
      return msg[lang]['form.status.inAnalysis']
    default:
      return msg[lang]['form.status.inProgress']
  }
}

const infoColor = { background: 'rgba(8,53,68,0.7)', text: '#FFFFFF' }
const errorColor = { background: 'rgba(8,53,68,0.7)', text: '#FFFFFF' }
const warnColor = { background: 'rgba(8,53,68,0.7)', text: '#FFFFFF' }

export const notifyFo = (message = { text: '', info: true, error: false, warn: false, duration: 5000 }) => {
  if (message.error === true) {
    notify.show(message.text, 'custom', message.duration, errorColor)
  } else if (message.warn === true) {
    notify.show(message.text, 'custom', message.duration, warnColor)
  } else {
    notify.show(message.text, 'custom', message.duration, infoColor)
  }
}

export function formatDateWithWeek(strDate, lang, strEnd) {
  let date = new Date(strDate)
  if (strEnd == null) {
    return date.toLocaleDateString(lang, { day: 'numeric', month: 'long', year: 'numeric', weekday: 'short' })
  }
  let end = new Date(strEnd)
  let divisor = ' ' + msg[lang]['global.to'] + ' '
  if (date.getFullYear() === end.getFullYear()) { // mesmo ano
    if (date.getMonth() === end.getMonth()) { // mesmo mês
      if (date.getDate() === end.getDate()) { // mesmo dia
        return date.toLocaleDateString(lang, { day: 'numeric', month: 'long', year: 'numeric', weekday: 'short' })
      } else {
        return date.toLocaleDateString(lang, { day: 'numeric', weekday: 'short' }) + divisor + end.toLocaleDateString(lang, { day: 'numeric', month: 'long', year: 'numeric', weekday: 'short' })
      }
    } else { // mês diferente
      return date.toLocaleDateString(lang, { day: 'numeric', month: 'long', weekday: 'short' }) + divisor + end.toLocaleDateString(lang, { day: 'numeric', month: 'long', year: 'numeric', weekday: 'short' })
    }
  } else { // anos diferentes
    return date.toLocaleDateString(lang, { day: 'numeric', month: 'long', year: 'numeric', weekday: 'short' }) + divisor + end.toLocaleDateString(lang, { day: 'numeric', month: 'long', year: 'numeric', weekday: 'short' })
  }
}
