import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { FormattedMessage as FM } from 'react-intl'

import styles from './Body.css'
import Search from '../Search/Search'
import { setSearchText, doSearch, setShowResults, toggleSearch, setCurrentScope } from '../../modules/search'
import { showMenu, toggleMenu } from '../../modules/menus'
import { fetchConfig } from '../../modules/global'
import msg from '../App/messages'

class Body extends Component {
  constructor(props) {
    super(props)
    this.searchKeyUp = this.searchKeyUp.bind(this)
    this.toggleSearchMenu = this.toggleSearchMenu.bind(this)
    this.onChange = this.onChange.bind(this)
    this.searchText = props.searchText
    this.myInputBox = React.createRef()
    this.currentSlide = 1
    this.stopSlideShow = false
    this.slideDiv = React.createRef()
    this.runSlider = this.runSlider.bind(this)
    this.slideShowInProgress = false
  }

  componentDidMount () {
    const { lang, searchText, currentSortFieldOption, currentScope, searchScopes, config } = this.props
    this.searchText = searchText
    if (currentScope !== 0) {
      this.props.setCurrentScope(0)
      if (searchText.length > 2) {
        this.props.doSearch(searchText, lang, currentSortFieldOption, searchScopes[0].objSearchType)
      } else {
        this.props.setShowResults(false)
      }
    }
    if (!config) {
      this.props.fetchConfig()
    }
  }

  render () {
    const { currentScope, searchScopes, lang, mobile, config } = this.props
    if (!config) {
      return null
    }
    let placeholderText = msg[lang]['search.whatShort']
    if (!mobile) {
      placeholderText = msg[lang]['search.what']
    } else if (mobile && window.innerWidth < 360) {
      placeholderText = ''
    }
    let className = styles.backImageBox + ' ' + styles.backImage
    let topStyle = null
    if (config.homeImages && config.homeImages.length) {
      if (config.homeImages.length === 1 || mobile) {
        topStyle = { backgroundImage: 'url(' + config.homeImages[0] + ')' }
      } else {
        topStyle = { backgroundImage: 'url(' + config.homeImages[0] + ')' }
        if (!this.slideShowInProgress) {
          this.slideShowInProgress = true
          window.setTimeout(() => {
            this.runSlider(config)
          }, config.slideDelaySecs * 1000)
        }
      }
    }
    console.log('Body render')
    return (
      <div ref={this.slideDiv} className={className} style={topStyle} onClick={(e) => this.stopSlider(e)}>
        <div className={styles.heraldica} />
        <div className={styles.bottom}>
          <div className={styles.inputDivParent}>
            <div className={styles.inputWrapper}>
              <div className={styles.searchGrid}>
                {
                  !mobile
                    ? <div className={styles.searchLeftMenu} onClick={this.toggleSearchMenu}>
                      <div className={styles.searchScopeText}>
                        <FM id={this.getCurrentScopeString(currentScope, searchScopes)} defaultMessage='Tudo' />
                      </div>
                      <div className={styles.arrowDownDiv} />
                    </div>
                    : <div className={styles.searchLeftMenu} onClick={this.toggleSearchMenu}>
                      <div className={styles.currentScopeDiv + ' ' + this.getCurrentScopeClassname(currentScope)} />
                      <div className={styles.openSearchMenuDiv} />
                    </div>
                }
                <div className={styles.searchRightDiv}>
                  <input
                    ref={this.myInputBox}
                    className={styles.searchInput}
                    type='text'
                    onClick={this.toggleSearchMenu}
                    onChange={this.onChange}
                    placeholder={placeholderText}
                    value={this.searchText} />
                  <div className={styles.searchDiv} onClick={this.toggleSearchMenu} />
                </div>
              </div>
            </div>
            <Search width='765' iconsOnly={mobile} />
          </div>
          <div className={styles.bottomMenu}>
            <nav>
              <ul className={styles.list}>
                <li className={styles.listItem}>
                  <div className={styles.circle + ' ' + styles.blueButton}
                    onClick={(e) => this.clickedCircle(e)}>
                    <Link className={styles.link1} to='/paginas'>
                      <div className={styles.square}>
                        <div className={styles.objDiv + ' ' + styles.municipio} />
                        <div className={styles.shortcutText}><FM id='header.cityHall' defaultMessage='Município' /></div>
                      </div>
                    </Link>
                  </div>
                </li>
                <li className={styles.listItem}>
                  <div className={styles.circle + ' ' + styles.blueButton}
                    onClick={(e) => this.clickedCircle(e)}>
                    <Link className={styles.link1} to='/locais'>
                      <div className={styles.square}>
                        <div className={styles.objDiv + ' ' + styles.visitar} />
                        <div className={styles.shortcutText}><FM id='body.visit' defaultMessage='Visitar' /></div>
                      </div>
                    </Link>
                  </div>
                </li>
                <li className={styles.listItem}>
                  <div className={styles.circle + ' ' + styles.whiteButton}
                    onClick={(e) => this.clickedCircle(e)}>
                    <Link className={styles.link2} to='/servicos-online'>
                      <div className={styles.square}>
                        <div className={styles.objDiv + ' ' + styles.servicosOnline} />
                        <div className={styles.shortcutText}><FM id='body.onlineServices' defaultMessage='Serviços Online' /></div>
                      </div>
                    </Link>
                  </div>
                </li>
                <li className={styles.listItem}>
                  <div className={styles.circle + ' ' + styles.blueButton}
                    onClick={(e) => this.clickedCircle(e)}>
                    <Link className={styles.link1} to='/servicos'>
                      <div className={styles.square}>
                        <div className={styles.objDiv + ' ' + styles.viver} />
                        <div className={styles.shortcutText}><FM id='body.live' defaultMessage='Viver' /></div>
                      </div>
                    </Link>
                  </div>
                </li>
                <li className={styles.listItem}>
                  <div className={styles.circle + ' ' + styles.blueButton}
                    onClick={(e) => this.clickedCircle(e)}>
                    <Link className={styles.link1} to='/documentos'>
                      <div className={styles.square}>
                        <div className={styles.objDiv + ' ' + styles.publicacoes} />
                        <div className={styles.shortcutText}><FM id='body.publications' defaultMessage='Publicações' /></div>
                      </div>
                    </Link>
                  </div>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div >
    )
  }

  clickedCircle (e) {
    e.stopPropagation()
  }

  stopSlider (e) {
    e.stopPropagation()
    this.stopSlideShow = true
  }

  runSlider (config) {
    if (this.stopSlideShow) {
      return
    }
    if (this.slideDiv && this.slideDiv.current) {
      // console.log('this.currentSlide', this.currentSlide)
      // console.log(config.homeImages[this.currentSlide])
      this.slideDiv.current.style.backgroundImage = 'url(' + config.homeImages[this.currentSlide] + ')'
      this.currentSlide++
      if (this.currentSlide >= config.homeImages.length) {
        this.currentSlide = 0
      }
    }
    window.setTimeout(() => {
      this.runSlider(config)
    }, config.slideDelaySecs * 1000)
  }

  getCurrentScopeClassname (scope) {
    switch (scope) {
      case 1:
        return styles.municipio
      case 2:
        return styles.visitar
      case 3:
        return styles.servicosOnline
      case 4:
        return styles.viver
      case 5:
        return styles.publicacoes
      case 6:
        return styles.noticias
      case 7:
        return styles.eventos
      default:
        return styles.all
    }
  }

  getCurrentScopeString (currentScope, searchScopes) {
    if (currentScope < 0) {
      currentScope = 0
    }
    return 'search.' + searchScopes[currentScope].name
  }

  onChange (e) {
    e.stopPropagation()
    const { lang, currentSortFieldOption, currentScope, searchScopes } = this.props
    let text = e.target.value
    this.searchText = text
    this.props.setSearchText(text)
    if (text.length > 2) {
      this.props.doSearch(text, lang, currentSortFieldOption, searchScopes[currentScope].objSearchType)
    } else {
      this.props.setShowResults(false)
    }
    this.props.showMenu('searchObjects')
  }

  searchKeyUp (e) {
    e.stopPropagation()
    const { lang, currentSortFieldOption, currentScope, searchScopes } = this.props
    let text = e.target.value
    this.searchText = text
    this.props.setSearchText(text)
    if (text.length > 2) {
      this.props.doSearch(text, lang, currentSortFieldOption, searchScopes[currentScope].objSearchType)
    } else {
      this.props.setShowResults(false)
    }
    this.props.showMenu('searchObjects')
  }

  toggleSearchMenu (e) {
    e.stopPropagation()
    this.props.toggleMenu('searchObjects')
    this.myInputBox.current.focus()
  }
}

Body.propTypes = {
  doSearch: PropTypes.func.isRequired,
  setShowResults: PropTypes.func.isRequired,
  showMenu: PropTypes.func.isRequired,
  toggleMenu: PropTypes.func.isRequired,
  toggleSearch: PropTypes.func.isRequired,
  searchText: PropTypes.string.isRequired,
  setSearchText: PropTypes.func.isRequired,
  currentScope: PropTypes.number.isRequired,
  searchScopes: PropTypes.array.isRequired,
  currentSortFieldOption: PropTypes.object.isRequired,
  setCurrentScope: PropTypes.func.isRequired,
  mobile: PropTypes.bool.isRequired,
  config: PropTypes.object,
  fetchConfig: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  searchResults: state.search.searchResults,
  searchText: state.search.searchText,
  lang: state.linguas.lang,
  currentScope: state.search.currentScope,
  searchScopes: state.search.searchScopes,
  currentSortFieldOption: state.search.currentSortFieldOption,
  mobile: state.global.mobile,
  config: state.global.config
})

const mapDispatchToProps = { setSearchText, doSearch, setShowResults, toggleSearch, showMenu, toggleMenu, setCurrentScope, fetchConfig }

export default connect(mapStateToProps, mapDispatchToProps)(Body)
