import React from 'react'
import { compose, withProps } from 'recompose'
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from 'react-google-maps'

import { apiKey } from '../../../lib/utils'

export const MyMapComponent = compose(
  withProps({
    googleMapURL: 'https://maps.googleapis.com/maps/api/js?key=' + apiKey + '&v=3.exp&libraries=geometry,drawing,places',
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `400px` }} />,
    mapElement: <div style={{ height: `100%` }} />
  }),
  withScriptjs,
  withGoogleMap
)((props) => {
  const { model } = props
  if (!model || !model.latInicio) {
    return null
  }
  let zoom = !model || !model.zoom ? 8 : (model.zoom < 14 ? model.zoom : 14)
  const mapStyles = [
    {
      featureType: 'water',
      elementType: 'geometry.fill',
      stylers: [{ color: '#0c598e' }]
    }
  ]
  return (
    <GoogleMap
      defaultOptions={{ styles: mapStyles }}
      defaultZoom={zoom}
      center={{ lat: model.latInicio, lng: model.longInicio }}
      defaultCenter={{ lat: model.latInicio, lng: model.longInicio }}>
      <Marker position={{ lat: model.latInicio, lng: model.longInicio }} />
    </GoogleMap>
  )
}
)
