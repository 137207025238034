/* eslint-env browser */
import axios from 'axios'

import { host, computeLink } from '../request'
import { getImage, stringArrToCommaSepString } from '../lib/utils'

const FETCH_LOCAIS = 'cmlf/locais/FETCH_LOCAIS'
const SET_CLICKED_LOCAL = 'cmlf/locais/SET_CLICKED_LOCAL'
const SET_LOCAIS = 'cmlf/locais/SET_LOCAIS'
const SET_SHOW_ONLY_IN_LIST = 'cmlf/locais/SET_SHOW_ONLY_IN_LIST'
const SET_SHOW_ONLY_VISIBLE_IN_MAP = 'cmlf/locais/SET_SHOW_ONLY_VISIBLE_IN_MAP'
const SET_BOUNDS = 'cmlf/locais/SET_BOUNDS'

const initialState = {
  locais: [],
  clickedLocal: null,
  showOnlyInList: false,
  showOnlyVisibleInMap: true,
  bounds: null
}

export const setClickedLocal = (local) => ({ type: SET_CLICKED_LOCAL, local })
export const setLocais = (locais, listaAleatoria) => ({ type: SET_LOCAIS, locais, listaAleatoria })
export const setShowOnlyInList = (show) => ({ type: SET_SHOW_ONLY_IN_LIST, show })
export const setShowOnlyVisibleInMap = (show) => ({ type: SET_SHOW_ONLY_VISIBLE_IN_MAP, show })
export const setBounds = (bounds) => ({ type: SET_BOUNDS, bounds })

export function fetchLocais (lang, categIdArray) {
  return (dispatch) => {
    const cats = stringArrToCommaSepString(categIdArray)
    axios.get(host + '/api/public/locais?lang=' + lang + (cats !== null ? '&cats=' + cats : ''))
      .then(res => res.data)
      .then(posts => {
        let resp = []
        posts.forEach(post => {
          let item = {
            title: post.titulo
          }
          item.image = getImage(post.imagem)
          item.link = computeLink(post)
          item.id = post.id
          item.obj = post
          item.titulo = post.titulo
          item.resumo = post.resumo
          item.date = post.publicationDate
          item.categorias = post.categorias
          resp.push(item)
        })
        // resp = randomlySortLocais(resp)
        dispatch({
          type: FETCH_LOCAIS,
          payload: resp
        })
      })
  }
}
export default function reducer (state = initialState, action = {}) {
  switch (action.type) {
    case FETCH_LOCAIS:
      return { ...state, locais: action.payload }
    case SET_CLICKED_LOCAL:
      return { ...state, clickedLocal: action.local }
    case SET_LOCAIS:
      let sorted = randomlySortLocais(action.locais, action.listaAleatoria)
      return { ...state, locais: sorted }
    case SET_SHOW_ONLY_IN_LIST:
      return { ...state, showOnlyInList: action.show }
    case SET_SHOW_ONLY_VISIBLE_IN_MAP:
      return { ...state, showOnlyVisibleInMap: action.show }
    case SET_BOUNDS:
      return { ...state, bounds: action.bounds }
    default:
      return state
  }
}

function randomlySortLocais (list, listaAleatoria) {
  if (!listaAleatoria) {
    return list
  }
  if (list.length) {
    if (list[0].sortOrder) {
      return list
    }
  }
  list.forEach(element => {
    element.sortOrder = Math.floor((Math.random() * 1000) + 1)
  })
  return list.sort((a, b) => { return b.sortOrder - a.sortOrder })
}