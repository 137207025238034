/* eslint-env browser */
import { host, computeLink } from '../request'
import { getImage } from '../lib/utils'
import msg from '../components/App/messages'
import axios from 'axios'

const SET_CURRENT_DESTAQUE_INDEX = 'cmlf/destaques/SET_CURRENT_DESTAQUE_INDEX'
const SET_CURRENT_DESTAQUE = 'cmlf/destaques/SET_CURRENT_DESTAQUE'
const FETCH_DESTAQUES = 'cmlf/destaques/FETCH_DESTAQUES'
const FETCH_HEADER_LINKS = 'cmlf/destaques/FETCH_HEADER_LINKS'
const FETCH_FOOTER_LINKS = 'cmlf/destaques/FETCH_FOOTER_LINKS'
const SET_AUTO_PLAY_SLIDES = 'cmlf/destaques/SET_AUTO_PLAY_SLIDES'
const SET_HEADER_LINKS_FETCHED = 'cmlf/destaques/SET_HEADER_LINKS_FETCHED'
const SET_FOOTER_LINKS_FETCHED = 'cmlf/destaques/SET_FOOTER_LINKS_FETCHED'
const DESTAQUES_LOADING = 'cmlf/destaques/DESTAQUES_LOADING'

const initialState = {
  destaquesList: [],
  currentDestaque: null,
  currentDestaqueIndex: 0,
  headerLinks: [],
  footerLinks: [],
  playSlides: true,
  headerLinksFetched: false,
  footerLinksFetched: false,
  destaquesAreLoading: false
}

export const setCurrentDestaqueIndex = (index) => ({ type: SET_CURRENT_DESTAQUE_INDEX, index })
export const setCurrentDestaque = (destaque) => ({ type: SET_CURRENT_DESTAQUE, destaque })
export const setAutoPlaySlides = (playSlides) => ({ type: SET_AUTO_PLAY_SLIDES, playSlides })
export const setHeaderLinksFetched = (fetched) => ({ type: SET_HEADER_LINKS_FETCHED, fetched })
export const setFooterLinksFetched = (fetched) => ({ type: SET_FOOTER_LINKS_FETCHED, fetched })
export const destaquesLoading = (loading) => ({ type: DESTAQUES_LOADING, loading })

export function fetchDestaques (lang) {
  return (dispatch) => {
    dispatch(destaquesLoading(true))
    axios.get(host + '/api/public/destaques?lang=' + lang)
      .then(res => res.data)
      .then(posts => {
        let resp = []
        posts.forEach(post => {
          let item = {
            title: post.titulo,
            text: post.resumo
          }
          item.image = getImage(post.imagem)
          item.permalink = computeLink(post)
          item.type = post.type
          switch (post.type) {
            case 'document':
              item.date = formatDate(post.publicationDate, lang)
              break
            case 'page':
              item.date = formatDate(post.publicationDate, lang)
              break
            case 'news':
              item.date = formatDate(post.data, lang)
              break
            case 'event':
              item.date = formatDate(post.start, lang, post.end)
              break
            case 'place':
              item.date = formatDate(post.publicationDate, lang)
              break
            default:
              item.date = formatDate(post.publicationDate, lang)
              break
          }
          resp.push(item)
        })
        dispatch({
          type: FETCH_DESTAQUES,
          payload: resp
        })
        dispatch(destaquesLoading(false))
      })
  }
}

export function fetchHeaderLinks (lang) {
  return (dispatch) => {
    dispatch(setHeaderLinksFetched(false))
    axios.get(host + '/api/public/headerLinks?lang=' + lang)
      .then(res => res.data)
      .then(posts => {
        let resp = []
        posts.forEach(post => {
          resp.push({ id: post.id, type: post.type, text: post.shortcutText, link: computeLink(post) })
        })
        dispatch({
          type: FETCH_HEADER_LINKS,
          payload: resp
        })
      })
  }
}

export function fetchFooterLinks (lang) {
  return (dispatch) => {
    dispatch(setFooterLinksFetched(false))
    axios.get(host + '/api/public/footerLinks?lang=' + lang)
      .then(res => res.data)
      .then(posts => {
        let resp = []
        posts.forEach(post => {
          resp.push({
            id: post.id,
            type: post.type,
            text: post.shortcutText,
            link: computeLink(post), downloadLink: host + '/api/public/get/mediaFile/documento/' + post.permalink
          })
        })
        dispatch({
          type: FETCH_FOOTER_LINKS,
          payload: resp
        })
      })
  }
}

export default function reducer (state = initialState, action = {}) {
  switch (action.type) {
    case SET_CURRENT_DESTAQUE_INDEX:
      return { ...state, currentDestaqueIndex: action.index, currentDestaque: state.destaquesList[action.index] }
    case SET_CURRENT_DESTAQUE:
      return { ...state, currentDestaque: action.currentDestaque }
    case FETCH_DESTAQUES:
      return { ...state, destaquesList: action.payload, currentDestaque: action.payload[state.currentDestaqueIndex] }
    case FETCH_HEADER_LINKS:
      return { ...state, headerLinks: action.payload, headerLinksFetched: true }
    case FETCH_FOOTER_LINKS:
      return { ...state, footerLinks: action.payload, footerLinksFetched: true }
    case SET_AUTO_PLAY_SLIDES:
      return { ...state, playSlides: action.playSlides }
    case DESTAQUES_LOADING:
      return { ...state, destaquesAreLoading: action.loading }
    default:
      return state
  }
}

function formatDate (strDate, lang, strEnd) {
  let date = new Date(strDate)
  if (strEnd == null) {
    return date.toLocaleDateString(lang, { day: 'numeric', month: 'long', year: 'numeric' })
  }
  let end = new Date(strEnd)
  let divisor = ' ' + msg[lang]['global.to'] + ' '
  if (date.getFullYear() === end.getFullYear()) { // mesmo ano
    if (date.getMonth() === end.getMonth()) { // mesmo mês
      if (date.getDate() === end.getDate()) { // mesmo dia
        return date.toLocaleDateString(lang, { day: 'numeric', month: 'long', year: 'numeric' })
      } else {
        return date.toLocaleDateString(lang, { day: 'numeric' }) + divisor + end.toLocaleDateString(lang, { day: 'numeric', month: 'long', year: 'numeric' })
      }
    } else { // mês diferente
      return date.toLocaleDateString(lang, { day: 'numeric', month: 'long' }) + divisor + end.toLocaleDateString(lang, { day: 'numeric', month: 'long', year: 'numeric' })
    }
  } else { // anos diferentes
    return date.toLocaleDateString(lang, { day: 'numeric', month: 'long', year: 'numeric' }) + divisor + end.toLocaleDateString(lang, { day: 'numeric', month: 'long', year: 'numeric' })
  }
}
