import { combineReducers } from 'redux'
import { routerReducer } from 'react-router-redux'
import { createResponsiveStateReducer } from 'redux-responsive'

import destaques from './modules/destaques'
import linguas from './modules/linguas'
import auth from './modules/auth'
import news from './modules/news'
import events from './modules/events'
import menus from './modules/menus'
import model from './modules/model'
import search from './modules/search'
import locais from './modules/locais'
import documentos from './modules/documentos'
import paginas from './modules/paginas'
import servicos from './modules/servicos'
import categorias from './modules/categorias'
import formulario from './modules/formulario'
import backoffice from './modules/backoffice'
import header from './modules/header'
import toolbar from './modules/toolbar'
import pastas from './modules/pastas'
import calendar from './modules/calendar'
import navigation from './modules/navigation'
import global from './modules/global'
import gallery from './modules/gallery'
import objectos from './modules/objectos'
import popup from './modules/popup'

export default combineReducers({
  routing: routerReducer,
  keycloak: (keycloak = {}) => keycloak,
  browser: createResponsiveStateReducer({
    extraSmall: 500,
    small: 767,
    medium: 960,
    large: 1280,
    extraLarge: 1400
  }),
  auth,
  destaques,
  linguas,
  news,
  events,
  locais,
  documentos,
  paginas,
  servicos,
  model,
  search,
  categorias,
  formulario,
  menus,
  backoffice,
  header,
  toolbar,
  pastas,
  calendar,
  navigation,
  global,
  gallery,
  objectos,
  popup
})
