import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { addItem, togglePopup } from '../../../modules/popup'
import styles from './ObjectoBase.css'
import indexStyles from '../../../index.css'

class GalleryImage extends Component {
  componentDidUpdate() {
    const { model } = this.props
    if (!model) {
      return
    }
    if (model.image) {
      this.props.addItem({
        id: model.id,
        text: null,
        file: model.image,
        imageSize: 0,
        filename: model.imagem.filename
      }, 0)
    }
  }

  render() {
    const { model } = this.props
    if (!model) {
      return null
    }
    return (
      <div className={styles.imageDiv + ' ' + indexStyles.shadow}>
        <img
          className={styles.imageFrame}
          style={{ cursor: 'pointer' }}
          src={model.image} onClick={() => this.props.togglePopup(0)} />
      </div>
    )
  }
}

GalleryImage.propTypes = {
  model: PropTypes.object,
  togglePopup: PropTypes.func.isRequired,
  addItem: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  model: state.model.model
})

const mapDispatchToProps = { togglePopup, addItem }

export default connect(mapStateToProps, mapDispatchToProps)(GalleryImage)