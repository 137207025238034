import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { getTranslation } from '../../../request'
import commonStyles from './CommonStyles.css'
import { setFormInstance, saveFormInstance } from '../../../modules/formulario'
import Label from './Label'

class InputNumberField extends Component {
  constructor(props) {
    super(props)
    this.valueChanged = this.valueChanged.bind(this)
  }

  render () {
    const { field, lang, formInstance } = this.props
    return (
      <div className={commonStyles.labelWrapper}>
        <Label {...this.props} />
        {this.buildInput(field, lang, formInstance)}
      </div>
    )
  }

  buildInput (field, lang, formInstance) {
    return (
      <input
        className={commonStyles.inputText}
        type='number'
        placeholder={getTranslation(field.placeholder, lang)}
        min={field.min}
        max={field.max}
        step={field.step}
        readOnly={field.readonly}
        onChange={(e) => this.valueChanged(e, field, formInstance)}
        value={this.getValue(field, formInstance)} />
    )
  }

  getValue (field, formInstance) {
    if (!formInstance) {
      return ''
    }
    for (let index = 0; index < formInstance.values.length; index++) {
      const element = formInstance.values[index]
      if (element.fieldId === field.id) {
        return element.value
      }
    }
    return ''
  }

  valueChanged (e, field, formInstance) {
    this.setValue(field, formInstance, e.target.value)
    this.props.saveFormInstance(formInstance.id, [{ fieldId: field.id, value: e.target.value }])
  }

  setValue (field, formInstance, value) {
    if (!formInstance) {
      return
    }
    for (let index = 0; index < formInstance.values.length; index++) {
      const element = formInstance.values[index]
      if (element.fieldId === field.id) {
        element.value = value
        this.props.setFormInstance(formInstance)
        return
      }
    }
    formInstance.values.push({ fieldId: field.id, value: value })
    this.props.setFormInstance(formInstance)
  }
}

InputNumberField.propTypes = {
  lang: PropTypes.string.isRequired,
  formInstance: PropTypes.object,
  setFormInstance: PropTypes.func.isRequired,
  saveFormInstance: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  formInstance: state.formulario.formInstance,
  lang: state.linguas.lang
})

const mapDispatchToProps = { setFormInstance, saveFormInstance }

export default connect(mapStateToProps, mapDispatchToProps)(InputNumberField)
