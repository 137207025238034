import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import styles from '../common/ObjectoBase.css'
import myStyles from './TagsList.css'

class TagsList extends Component {
  render () {
    const { model, categorias } = this.props
    return (
      <div className={styles.tags}>
        {this.buildList(model, categorias)}
      </div>
    )
  }
  buildList (model, categorias) {
    if (!model || !model.categorias || model.categorias.length === 0 || !categorias || categorias.length === 0) {
      return null
    }
    return (
      <ul className={myStyles.ul}>
        {
          model.categorias.map((catId, index) => {
            let cat = this.getCategoria(catId, categorias)
            if (!cat) {
              return null
            }
            return <li key={index} className={myStyles.li + (cat.selected ? ' ' + myStyles.selected : '')}>{cat.categoria.nome}</li>
          })
        }
      </ul>
    )
  }
  getCategoria (id, categorias) {
    for (let index = 0; index < categorias.length; index++) {
      const cat = categorias[index]
      if (cat.categoria.id === id) {
        return cat
      }
    }
    return null
  }
}

TagsList.propTypes = {
  model: PropTypes.object,
  lang: PropTypes.string.isRequired,
  categorias: PropTypes.array.isRequired
}

const mapStateToProps = state => ({
  model: state.model.model,
  lang: state.linguas.lang,
  categorias: state.categorias.categorias
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(TagsList)
