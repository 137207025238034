import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import styles from './MenuLinguas.css'
import { fetchLinguas, setLang } from '../../modules/linguas'
import {toggleMenu} from '../../modules/menus'

class MenuLinguas extends Component {
  constructor(props) {
    super(props)
    this.selectLingua = this.selectLingua.bind(this)
  }

  componentDidMount () {
    this.linguasList = []
    this.props.fetchLinguas()
  }

  render () {
    this.linguasList = this.buildLinguasList(this.props.linguas)
    let menuLinguasStyle = {
      // 'visibility': this.props.showMenu ? 'visible' : 'hidden',
      'opacity': this.props.showMenu ? 0.8 : 0
    }
    return (
      <ul className={styles.menuContainer} style={menuLinguasStyle}>
        {this.linguasList}
      </ul>
    )
  }

  buildLinguasList (list) {
    let itemsList = []
    list.forEach(element => {
      let style = {}
      if (element.sigla === this.props.lang) {
        style = { 'color': 'yellow' }
      }
      itemsList.push(
        <li id={element.sigla} key={element.sigla} className={styles.menuItem + ' ' + styles.li} onClick={this.selectLingua} style={style}>
          <div className={styles.menuItemLeft}>{element.nome}</div>
          <div>-</div>
          <div className={styles.menuItemRight}>{element.sigla}</div>
        </li>)
    })
    return itemsList
  }

  selectLingua (e) {
    e.preventDefault()
    e.stopPropagation()
    let id = e.target.id
    if (!id) {
      id = e.target.parentElement.id
    }
    this.props.setLang(id)
    this.props.toggleMenu('linguas')
  }
}

MenuLinguas.propTypes = {
  toggleMenu: PropTypes.func.isRequired,
  fetchLinguas: PropTypes.func.isRequired,
  showMenu: PropTypes.bool.isRequired,
  setLang: PropTypes.func.isRequired,
  linguas: PropTypes.array.isRequired,
  lang: PropTypes.string.isRequired
}

const mapStateToProps = state => ({
  linguas: state.linguas.linguas,
  showMenu: state.menus.linguas.show,
  lang: state.linguas.lang
})

const mapDispatchToProps = { fetchLinguas, setLang, toggleMenu }

export default connect(mapStateToProps, mapDispatchToProps)(MenuLinguas)
