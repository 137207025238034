import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { fetchModel, getRelated } from '../../../modules/model'
import { getTranslation } from '../../../request'
import { addBreadcrumb } from '../../../modules/header'
import { formatTimeInterval } from '../../../lib/utils'
import styles from '../common/ObjectoBase.css'
import indexStyles from '../../../index.css'
import myStyles from './Evento.css'
import { formatDateInterval } from '../../../modules/events'
import RelatedObjects from '../common/RelatedObjects'
import Contents from '../contents/Contents'
import Resumo from '../modelBlocks/Resumo'
import Descricao from '../modelBlocks/Descricao'
import TagsList from '../modelBlocks/TagsList'
import { fetchCategorias } from '../../../modules/categorias'
import GalleryImage from '../common/GalleryImage'
import spinner from '../../../css/Spinner.css'

class Evento extends Component {
  componentDidMount () {
    const { permalink } = this.props.match.params
    this.props.fetchModel('/evento/' + permalink)
    this.props.getRelated(permalink)
    const { categorias, fetchCategorias, lang } = this.props
    if (!categorias || categorias.length === 0) {
      fetchCategorias(lang, 'evento')
    }
  }
  componentDidUpdate () {
    const { model } = this.props
    const { permalink } = this.props.match.params
    if (model !== undefined && model !== null && permalink === model.permalink) {
      this.props.addBreadcrumb({ link: '/evento/' + permalink, name: model.titulo, translate: true })
    }
  }
  render () {
    const { lang, model, modelIsLoading } = this.props
    if (modelIsLoading || !model) {
      return (
        <div className={styles.layoutGrid}>
          <div className={spinner.loader} />
        </div>
      )
    }
    let includeContents = false
    if (!(!model.contents || model.contents.length === 0)) {
      includeContents = true
    }
    return (
      <div className={styles.layoutGrid}>
        <div className={styles.objectGrid + ' ' + styles.event + (includeContents ? ' ' + styles.layout : '')}>
          <div className={styles.titleDiv}>
            {getTranslation(model.titulo, lang)}
          </div>
          <Resumo model={model} lang={lang} />
          <GalleryImage />
          <div className={myStyles.whereWhenContainer}>
            <div className={myStyles.wwTopBar} />
            {this.buildWhereWhen(model)}
            <div className={myStyles.wwBottomBar} />
          </div>
          {this.buildSubEventos(model)}
          <Descricao model={model} lang={lang} />
          {
            includeContents
              ? <div className={styles.layoutDiv}>
                <Contents />
              </div>
              : null
          }
          <TagsList />
        </div>
        <RelatedObjects />
      </div>
    )
  }

  buildSubEventos (model) {
    if (!model || !model.subEventos || model.subEventos.length === 0) {
      return null
    }
    const { lang } = this.props
    return (
      <div key='sub-eventos' className={myStyles.subEventosBody}>
        <span className={myStyles.tituloSubEventos}>{getTranslation(model.tituloSubEventos, lang)}</span>
        <div className={myStyles.bar} />
        <div className={myStyles.listaSubEventos}>
          {
            model.subEventos.map((sub, index) => (
              this.buildWhereWhenSubEvento(sub, index)
            ))
          }
        </div>
      </div>
    )
  }

  buildWhereWhenSubEvento (model, index) {
    const { lang } = this.props
    return (
      <div key={index} className={myStyles.subEventoBody}>
        <div className={myStyles.tituloSubEvento}>{getTranslation(model.titulo, lang)}</div>
        {
          this.hasLocal(model)
            ? <div className={myStyles.wwLine + ' ' + myStyles.localSubEvento}>
              <div className={myStyles.wwLineIcon + ' ' + myStyles.where} />
              <Link className={myStyles.wwLineText + ' ' + myStyles.link}
                to={this.getLocalUrl(model.local)}
                target='_blank'>{getTranslation(model.local.titulo, lang)}
              </Link>
            </div>
            : null
        }
        {
          !model.inicio
            ? null
            : <div className={myStyles.wwLine + ' ' + myStyles.dataSubEvento}>
              <div className={myStyles.wwLineIcon + ' ' + myStyles.date} />
              <div className={myStyles.wwLineText}>{formatDateInterval(model.inicio, lang, model.fim)}</div>
            </div>
        }{
          !model.temHora || !model.inicio
            ? null
            : <div className={myStyles.wwLine + ' ' + myStyles.horaSubEvento}>
              <div className={myStyles.wwLineIcon + ' ' + myStyles.time} />
              <div className={myStyles.wwLineText}>{formatTimeInterval(model.inicio, lang, model.fim)}</div>
            </div>
        }
        {/* <div className={myStyles.textoSubEvento}>{getTranslation(model.texto, lang)}</div> */}
        <div className={myStyles.textoSubEvento} dangerouslySetInnerHTML={{ __html: getTranslation(model.texto, lang) }} />
        <div className={myStyles.bar} />
      </div>
    )
  }

  buildWhereWhen (model) {
    const { lang } = this.props
    return (
      <div className={myStyles.wwBody}>
        {
          this.hasLocal(model)
            ? <div className={myStyles.wwLine}>
              <div className={myStyles.wwLineIcon + ' ' + myStyles.where} />
              <Link className={myStyles.wwLineText + ' ' + myStyles.link}
                to={this.getLocalUrl(model.local)}
                target='_blank'>{getTranslation(model.local.titulo, lang)}
              </Link>
            </div>
            : null
        }
        <div className={myStyles.wwLine}>
          <div className={myStyles.wwLineIcon + ' ' + myStyles.date} />
          <div className={myStyles.wwLineText}>{formatDateInterval(model.start, lang, model.end)}</div>
        </div>
        <div className={myStyles.wwLine}>
          <div className={myStyles.wwLineIcon + ' ' + myStyles.time} />
          <div className={myStyles.wwLineText}>{formatTimeInterval(model.start, lang, model.end)}</div>
        </div>
        {
          this.hasUrl(model)
            ? <div className={myStyles.wwLine}>
              <div className={myStyles.wwLineIcon + ' ' + myStyles.link} />
              <a className={myStyles.wwLineText + ' ' + myStyles.link}
                href={model.url}
                target='_blank'>{this.getUrlText(model, lang)}
              </a>
              {/* <Link className={myStyles.wwLineText + ' ' + myStyles.link}
                to={model.url}
                target='_blank'>{this.getUrlText(model, lang)}
              </Link> */}
            </div>
            : null
        }
      </div>
    )
  }

  hasLocal (model) {
    if (!model.local || !model.local.id) {
      return false
    }
    return true
  }

  getLocalUrl (local) {
    return '/local/' + local.permalink
  }

  hasUrl (model) {
    return !(!model.url)
  }

  getUrlText (model, lang) {
    if (!model.urlText || !model.urlText.id) {
      return model.url
    }
    return getTranslation(model.urlText, lang)
  }
}

Evento.propTypes = {
  fetchModel: PropTypes.func.isRequired,
  addBreadcrumb: PropTypes.func.isRequired,
  getRelated: PropTypes.func.isRequired,
  model: PropTypes.object,
  lang: PropTypes.string.isRequired,
  fetchCategorias: PropTypes.func.isRequired,
  modelIsLoading: PropTypes.bool.isRequired
}

const mapStateToProps = state => ({
  model: state.model.model,
  lang: state.linguas.lang,
  modelIsLoading: state.model.modelIsLoading
})

const mapDispatchToProps = { fetchModel, addBreadcrumb, getRelated, fetchCategorias }

export default connect(mapStateToProps, mapDispatchToProps)(Evento)
