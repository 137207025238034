/* eslint-env browser */
const SET_MENU_ITEMS = 'cmlf/menus/SET_MENU_ITEMS'
const TOGGLE_MENU = 'cmlf/menus/TOGGLE_MENU'
const CLOSE_ALL_MENUS = 'cmlf/menus/CLOSE_ALL_MENUS'
const SHOW_MENU = 'cmlf/menus/SHOW_MENU'
const CLOSE_MENU = 'cmlf/menus/CLOSE_MENU'

const initialState = {
  user: {
    items: [{
      label: 'Perfil', action: null, fm: 'menu.profile'
    }, '', { label: 'Sair', action: null, fm: 'menu.logout' }
    ],
    show: false
  },
  linguas: {
    items: [],
    show: false
  },
  searchObjects: {
    items: [],
    show: false
  }
}

export const setMenuItems = (menu, items) => ({ type: SET_MENU_ITEMS, menu, items })
export const toggleMenu = (menu, exceptions) => ({ type: TOGGLE_MENU, menu, exceptions })
export const closeAllMenus = () => ({ type: CLOSE_ALL_MENUS })
export const showMenu = (menu) => ({ type: SHOW_MENU, menu })
export const closeMenu = (menu) => ({ type: CLOSE_MENU, menu })

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_MENU_ITEMS:
      let items = { ...state[action.menu] }
      items.items = action.items
      switch (action.menu) {
        case 'user':
          return { ...state, user: items }
        case 'linguas':
          return { ...state, linguas: items }
        default:
          return state
      }
    case TOGGLE_MENU:
      let nextVal = !state[action.menu].show
      state[action.menu].show = nextVal
      if (nextVal) {
        closeOtherMenus(action.menu, state, action.exceptions)
      }
      return { ...state }
    case SHOW_MENU:
      state[action.menu].show = true
      closeOtherMenus(action.menu, state)
      return { ...state }
    case CLOSE_ALL_MENUS:
      closeAllMenusFunc(state)
      return { ...state }
    case CLOSE_MENU:
      state[action.menu].show = false
      return { ...state }
    default:
      return state
  }
}

function closeOtherMenus(exceptMenu, state, exceptAlso) {
  let menus = Object.keys(state)
  menus.forEach(menu => {
    let closeIt = false
    if (menu !== exceptMenu) {
      closeIt = true
    }
    if (exceptAlso != null) {
      exceptAlso.forEach(name => {
        if (name === menu) {
          closeIt = false
        }
      })
    }
    if (closeIt) {
      state[menu].show = false
    }
  })
}

function closeAllMenusFunc(state) {
  let menus = Object.keys(state)
  menus.forEach(menu => {
    state[menu].show = false
  })
}
