import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// import { getTranslation } from '../../../request'
import styles from './ErrorField.css'
import msg from '../../App/messages'

class ErrorField extends Component {
  render () {
    const { field, lang, msg } = this.props
    if (msg) {
      return (
        <div className={styles.container}>
          <div className={styles.icon} />
          <div className={styles.errorMsg}>{msg}</div>
        </div>
      )
    }
    let message = this.getErrorMessage(field, lang)
    if (!message) {
      return null
    }
    return (
      <div className={styles.container}>
        <div className={styles.icon} />
        <div className={styles.errorMsg}>{message}</div>
      </div>
    )
  }

  getErrorMessage (field, lang) {
    if (!field.required) {
      return null
    }
    const { formInstance } = this.props
    let value = null
    let values = null
    switch (field.type) {
      case 'header':
        return null
      case 'hr':
        return null
      case 'inputText':
        value = this.getValue(field, formInstance)
        if (!value || value.trim() === '') {
          return msg[lang]['form.field.value.required']
        }
        break
      case 'inputNumber':
        value = this.getValue(field, formInstance)
        if (!value || value.trim() === '') {
          return msg[lang]['form.field.number.required']
        }
        break
      case 'inputDate':
        value = this.getValue(field, formInstance)
        if (!value || value.trim() === '') {
          return msg[lang]['form.field.date.required']
        }
        break
      case 'paragraph':
        return null
      case 'listBox':
        values = this.getKeyValues(field, formInstance)
        if (!values || values.length === 0) {
          if (field.allowMultiSelection) {
            return msg[lang]['form.field.values.required']
          } else {
            return msg[lang]['form.field.value.required']
          }
        }
        break
      case 'radioButtonGroup':
        values = this.getKeyValues(field, formInstance)
        if (!values || values.length === 0 || (values[0].key === '-1' && values[0].value.trim() === '')) {
          return msg[lang]['form.field.value.required']
        }
        break
      case 'checkBoxGroup':
        values = this.getKeyValues(field, formInstance)
        if (!values || values.length === 0 || (values[0].key === '-1' && values[0].value.trim() === '')) {
          return msg[lang]['form.field.values.required']
        }
        break
      case 'autoComplete':
        values = this.getKeyValues(field, formInstance)
        if (!values || values.length === 0 || (values[0].key === '-1' && values[0].value.trim() === '')) {
          return msg[lang]['form.field.value.required']
        }
        break
      case 'tree':
        values = this.getKeyValues(field, formInstance)
        if (!values || values.length === 0) {
          if (field.allowMultiSelection) {
            return msg[lang]['form.field.values.required']
          } else {
            return msg[lang]['form.field.value.required']
          }
        }
        break
      case 'fileUpload':
        value = this.getFiles(field, formInstance)
        if (!value || value.length === 0) {
          if (field.multipleFiles) {
            return msg[lang]['form.field.files.required']
          }
          return msg[lang]['form.field.file.required']
        }
        break
      case 'textArea':
        value = this.getValue(field, formInstance)
        if (!value || value.trim() === '') {
          return msg[lang]['form.field.value.required']
        }
        break
      default:
        return null
    }
    return null
  }

  getValue (field, formInstance) {
    if (!formInstance) {
      return null
    }
    for (let index = 0; index < formInstance.values.length; index++) {
      const element = formInstance.values[index]
      if (element.fieldId === field.id) {
        return element.value
      }
    }
    return null
  }
  getKeyValues (field, formInstance) {
    if (!formInstance) {
      return null
    }
    for (let index = 0; index < formInstance.values.length; index++) {
      const element = formInstance.values[index]
      if (element.fieldId === field.id) {
        return element.keyValues
      }
    }
    return null
  }
  getFiles (field, formInstance) {
    if (!formInstance) {
      return null
    }
    for (let index = 0; index < formInstance.values.length; index++) {
      const element = formInstance.values[index]
      if (element.fieldId === field.id) {
        return element.files
      }
    }
    return null
  }
}

ErrorField.propTypes = {
  lang: PropTypes.string.isRequired
}

const mapStateToProps = state => ({
  formInstance: state.formulario.formInstance
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(ErrorField)
