/* eslint-env browser */
// import { host, computeLink } from '../request'
// import axios from 'axios'

const SET_PAGES = 'cmlf/backoffice/SET_PAGES'
const SET_CURRENT_PAGE = 'cmlf/backoffice/SET_CURRENT_PAGE'
const BACKOFFICE_VISITED = 'cmlf/backoffice/BACKOFFICE_VISITED'

const initialState = {
  pages: [],
  currentPage: 0,
  visited: false
}

export const setCurrentPage = (page) => ({ type: SET_CURRENT_PAGE, page })
export const setPages = (pages) => ({ type: SET_PAGES, pages })
export const setBackofficeVisited = () => ({ type: BACKOFFICE_VISITED })

export default function reducer (state = initialState, action = {}) {
  switch (action.type) {
    case SET_PAGES:
      return { ...state, pages: action.pages }
    case SET_CURRENT_PAGE:
      return { ...state, currentPage: action.page }
    case BACKOFFICE_VISITED:
      return { ...state, visited: true }
    default:
      return state
  }
}
