/* eslint-env browser */
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { FormattedMessage as FM } from 'react-intl'
import Notifications, { notify } from 'react-notify-toast'
import axios from 'axios'

import { kc, updateLocalStorage, updateState } from '../../request'
import MenuLinguas from './MenuLinguas'
import styles from './Header.css'
import { toggleMenu } from '../../modules/menus'
import messages from '../App/messages'
import HeaderLinks from './HeaderLinks'
import MenuUser from './MenuUser'
import { setUser } from '../../modules/auth'
import { setMenuExpanded, setLoginPopupState } from '../../modules/header'
import { loginAuthGov } from '../../modules/auth'
import { host } from '../../request'
import LoginPopup from './LoginPopup'

class Header extends Component {
  constructor(props) {
    super(props)
    this.authenticated = kc.authenticated
    this.clickDropLangMenu = this.clickDropLangMenu.bind(this)
    this.toggleMenuUser = this.toggleMenuUser.bind(this)
    this.login = this.login.bind(this)
    this.logout = this.logout.bind(this)
    this.expandMenu = this.expandMenu.bind(this)
    this.samlForm = React.createRef()
    this.samlInput = React.createRef()
    this.showLoginPopup = this.showLoginPopup.bind(this)
    this.samlLogoutForm = React.createRef()
    this.samlLogoutInput = React.createRef()
  }

  componentDidMount() {
    const loginIsAuthGov = localStorage.getItem('cmlf_loginIsAuthGov')
    const { setUser } = this.props
    if (loginIsAuthGov === 'true') {
      let userStr = localStorage.getItem('cmlf_user')
      let myUser = JSON.parse(userStr)
      localStorage.setItem('cmlf_logout', false)
      localStorage.setItem('cmlf_login', true)
      setUser(myUser)
    }
  }

  render() {
    const { lang, mobileMenu, user, setUser } = this.props
    let myColor = { background: 'rgba(8,53,68,0.7)', text: '#FFFFFF' }
    if (localStorage.getItem('cmlf_logout') === 'true') {
      localStorage.setItem('cmlf_logout', false)
      this.authenticated = false
      setTimeout(() => {
        notify.show(messages[lang]['header.logoutMessage'], 'custom', 5000, myColor)
      }, 300)
    }
    if (localStorage.getItem('cmlf_login') === 'true') {
      localStorage.setItem('cmlf_login', false)
      if (user && user.isAuthGov) {
        setTimeout(() => {
          notify.show(messages[lang]['header.loginMessage'] + ' ' + this.props.user.firstName, 'custom', 5000, myColor)
        }, 200)
      } else {
        kc.loadUserProfile()
          .success(function (profile) {
            setTimeout(() => {
              notify.show(messages[lang]['header.loginMessage'] + ' ' + profile.firstName, 'custom', 5000, myColor)
              setUser(profile)
            }, 200)
          })
          .error(() => {
            console.log('HEADER: falha ao ler dados do user')
          })
      }
    }
    let menuLinguasStyle = {
      display: this.props.showMenuLinguas ? 'block' : 'none'
    }
    let menuUserStyle = {
      display: this.props.showMenuUser ? 'block' : 'none'
    }
    let firstName = '      '
    if (user && user.isAuthGov) {
      this.authenticated = true
    } else {
      if (this.authenticated && (user === null || user.firstName === null)) {
        kc.updateToken(30)
          .then(refreshed => {
            if (refreshed) {
              updateLocalStorage(kc.token, kc.refreshToken)
              updateState(kc.token, kc.refreshToken)
            } else {
              // token era válido
            }
            kc.loadUserProfile()
              .success(function (profile) {
                setUser(profile)
              })
              .error(function () {
                console.log('HEADER: Falha ao obter dados do utilizador')
              })
          })
          .catch(() => {
            console.log('HEADER: vou fazer login')
            kc.login()
          })
      }
    }
    if (this.authenticated && user != null && user.firstName != null) {
      firstName = user.firstName
    }
    const loginOut = this.authenticated ? this.logout : this.showLoginPopup
    return (
      <header className={styles.header}>
        <Notifications />
        <div className={styles.body}>
          <div className={styles.container}>
            <Link className={styles.link} to='/'>
              <div className={styles.titleParentDiv}>
                <h1 className={styles.titleTop}><FM id='header.cityHall' defaultMessage='Município' /></h1>
                <h1 className={styles.titleBottom}>Lajes das Flores</h1>
              </div>
            </Link>
            {
              !mobileMenu
                ? this.buildDesktopNav(lang, loginOut, menuLinguasStyle, menuUserStyle, firstName)
                : this.buildMobileMenu(lang, loginOut, menuLinguasStyle, menuUserStyle, firstName)
            }
          </div>
        </div>
        <LoginPopup samlForm={this.samlForm} samlInput={this.samlInput} />
      </header>
    )
  }

  buildMobileMenu(lang, loginOut, menuLinguasStyle, menuUserStyle, firstName) {
    const { menuExpanded } = this.props
    return (
      !menuExpanded
        ? <div className={styles.menu} onClick={(e) => this.expandMenu(menuExpanded)} />
        : <div className={styles.menu + ' ' + styles.open} onClick={(e) => this.expandMenu(menuExpanded)}>
          <nav className={styles.mobileNav}>
            <HeaderLinks />
            {this.buildStaticHeaderItems(lang, loginOut, menuLinguasStyle, menuUserStyle, firstName)}
          </nav>
        </div>
    )
  }

  expandMenu(expanded) {
    this.props.setMenuExpanded(!expanded)
  }

  buildDesktopNav(lang, loginOut, menuLinguasStyle, menuUserStyle, firstName) {
    return (
      <nav>
        <HeaderLinks />
        {this.buildStaticHeaderItems(lang, loginOut, menuLinguasStyle, menuUserStyle, firstName)}
      </nav>
    )
  }

  buildStaticHeaderItems(lang, loginOut, menuLinguasStyle, menuUserStyle, firstName) {
    return (
      <ul className={styles.list + ' ' + styles.ul}>
        <li className={styles.listItem}>
          <a className={styles.divEntrar} href='https://sigweb.cmlajesdasflores.pt' target='_blank'>
            <div className={styles.textoDiv}>
              <FM id='header.geoportal' defaultMessage='Geoportal' />
            </div>
            <div className={styles.composedIcon + ' ' + styles.geoportal} />
          </a>
        </li>
        {this.buildAutenticacaoGovForm()}
        {this.buildLogoutAutenticacaoGovForm()}
        <li className={styles.listItem}>
          {this.authenticated
            ? <div>
              <div className={styles.divEntrar} onClick={this.toggleMenuUser}>{firstName}
                <div className={styles.composedIcon + ' ' + styles.drop} />
              </div>
              <div className={styles.menuLinguas} style={menuUserStyle}><MenuUser logoutFunc={this.logout} /></div>
            </div>
            : <div>
              <div className={styles.divEntrar} onClick={loginOut}>
                <div className={styles.textoDiv}>
                  <FM id='header.login' defaultMessage='Entrar' />
                </div>
                <div className={styles.composedIcon + ' ' + styles.entrar} />
              </div>
            </div>
          }
        </li>
        <li className={styles.listItem + ' ' + styles.menuLinguasContainer}>
          <Link className={styles.link} to='/'>
            <div className={styles.divEntrar} onClick={this.clickDropLangMenu}>
              <div className={styles.textoDiv}>
                {lang}
              </div>
              <div className={styles.composedIcon + ' ' + styles.drop} />
            </div>
          </Link>
          <div className={styles.menuLinguas} style={menuLinguasStyle}><MenuLinguas /></div>
        </li>
        <li className={styles.listItem}><a href={'https://www.facebook.com/municipio.lajes.flores'} target='_blank'><div className={styles.socialIcon + ' ' + styles.facebook} /></a></li>
      </ul>
    )
  }

  showLoginPopup() {
    this.props.setLoginPopupState(false)
  }

  buildAutenticacaoGovForm() {
    if (this.authenticated) {
      return null
    }
    return (
      <form ref={this.samlForm} id='authGovForm' action='' method='post'>
        <input ref={this.samlInput} type='hidden' id='authGovFormSamlInput' name='SAMLRequest' value='' />
        <input type='hidden' name='RelayState' value='' />
      </form>
    )
  }

  buildLogoutAutenticacaoGovForm() {
    if (!this.authenticated) {
      return null
    }
    return (
      <form ref={this.samlLogoutForm} id='authGovLogoutForm' action='' method='post'>
        <input ref={this.samlLogoutInput} type='hidden' id='authGovLogoutFormSamlInput' name='SAMLRequest' value='' />
        <input type='hidden' name='RelayState' value='' />
      </form>
    )
  }

  toggleMenuUser(e) {
    e.preventDefault()
    e.stopPropagation()
    this.props.toggleMenu('user')
  }

  clickDropLangMenu(e) {
    e.preventDefault()
    e.stopPropagation()
    this.props.toggleMenu('linguas', ['searchObjects'])
  }

  login(e) {
    localStorage.setItem('cmlf_login', true)
    localStorage.setItem('cmlf_loginIsAuthGov', false)
    kc.login()
  }

  logout(e) {
    localStorage.setItem('cmlf_logout', true)
    localStorage.setItem('cmlf_login', false)
    localStorage.setItem('cmlf_loginIsAuthGov', false)
    localStorage.setItem('cmlf_user', null)
    if (this.props.user.isAuthGov) {
      this.logoutAuthGov()
      this.props.setUser(null)
    } else {
      kc.logout()
    }
  }

  logoutAuthGov() {
    if (!this.authenticated) {
      return
    }
    const url = host + '/api/public/userAccount/buildLogoutSAML'
    if (process.env.NODE_ENV !== 'development') {
      fetch(url)
        .then(res => res.json())
        .then(post => {
          if (this.samlLogoutInput.current) {
            this.samlLogoutInput.current.value = post.saml
            // this.samlLogoutForm.current.submit()
          }
          // this.props.setUser(null)
        })
        .catch((error) => {
          console.log('error', error)
          this.props.setUser(null)
        })
    } else {
      fetch(url)
        .then(res => res.json())
        .then(post => {
          if (this.samlLogoutInput.current) {
            this.samlLogoutInput.current.value = post.saml
            // this.samlLogoutForm.current.submit()
          }
          // this.props.setUser(null)
        })
        .catch((error) => {
          console.log('error', error)
          this.props.setUser(null)
        })
    }
  }
}



Header.propTypes = {
  toggleMenu: PropTypes.func.isRequired,
  setUser: PropTypes.func.isRequired,
  showMenuLinguas: PropTypes.bool.isRequired,
  showMenuUser: PropTypes.bool.isRequired,
  lang: PropTypes.string.isRequired,
  user: PropTypes.object,
  mobileMenu: PropTypes.bool.isRequired,
  menuExpanded: PropTypes.bool.isRequired,
  setMenuExpanded: PropTypes.func.isRequired,
  loginAuthGov: PropTypes.func.isRequired,
  setLoginPopupState: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  showMenuLinguas: state.menus.linguas.show,
  showMenuUser: state.menus.user.show,
  lang: state.linguas.lang,
  user: state.auth.user,
  mobileMenu: state.global.mobileMenu,
  menuExpanded: state.header.menuExpanded
})

const mapDispatchToProps = { toggleMenu, setUser, setMenuExpanded, loginAuthGov, setLoginPopupState }

export default connect(mapStateToProps, mapDispatchToProps)(Header)
