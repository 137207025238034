import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { FormattedMessage as FM } from 'react-intl'

import { computeLink, getTranslation, formatDateWithWeek } from '../../request'
import styles from './Search.css'
import { doSearch, setSortFieldOption } from '../../modules/search'
import messages from '../App/messages'
import ScopeMenu from './ScopeMenu'
import NavResults from './NavResults'
import indexStyles from '../../index.css'
import { isOdd, host } from '../../lib/utils'

class Search extends Component {
  constructor(props) {
    super(props)
    this.onClick = this.onClick.bind(this)
    this.changeSortFieldOption = this.changeSortFieldOption.bind(this)
    this.myRef = React.createRef()
  }

  render () {
    const { showResults, searchResult, showMenu, iconsOnly, mobile, short } = this.props
    let width = this.props.width
    if (mobile) {
      width = window.innerWidth - 60
    }
    let showMe = showResults
    showMe = true
    if (showMenu === false) {
      showMe = false
    }
    let style = {
      'display': showMe === true ? 'grid' : 'none',
      'width': width
    }
    if (mobile) {
      style = {
        'display': showMe === true ? 'grid' : 'none'
      }
    }
    return (
      <div className={styles.container} >
        <div className={styles.searchBox + (iconsOnly ? ' ' + styles.iconsOnly : '') + (short ? ' ' + styles.short : '')}
          style={style} onClick={this.onClick}
          ref={this.myRef}>
          <ScopeMenu iconsOnly={iconsOnly} />
          <div className={styles.divisorDiv} />
          <div className={styles.bodyGrid + ' ' + indexStyles.shadow}>
            <div className={styles.searchOptionsBlock}>
              <div className={styles.orderLabelBlock}>
                <FM id='search.sortType' defaultMessage='Ordem' />
              </div>
              <div className={styles.orderComboBlock}>
                {this.buildSortFieldOptions()}
              </div>
            </div>
            <NavResults />
            {/* {this.displayResultTypes(searchResult.list)} */}
            {this.displayResults(searchResult.list)}
          </div>
        </div>
      </div>
    )
  }

  onClick (e) {
    e.stopPropagation()
  }

  makeVisible () {
    if (this.props.mobile) {
      return
    }
    let el = this.myRef.current
    let rect = el.getBoundingClientRect()
    let height = (window.innerHeight || document.documentElement.clientHeight)
    if (rect.bottom + 10 > height) {
      window.scroll({
        top: rect.bottom + 10 - height,
        left: 0,
        behavior: 'smooth'
      })
    }
  }

  buildSortFieldOptions () {
    const { lang, mobile } = this.props
    return (
      <select className={styles.comboStyle} onChange={this.changeSortFieldOption}>
        {
          this.props.sortFieldOptions.map(field => (
            mobile
              ? <option value={field.key} key={field.key}>{messages[lang]['search.' + field.value + 'Short']}</option>
              : <option value={field.key} key={field.key}>{messages[lang]['search.' + field.value]}</option>
          ))
        }
      </select>
    )
  }

  changeSortFieldOption (e) {
    const { lang, currentScope, searchScopes } = this.props
    let value = e.target.value
    let sfo = this.getSortFieldOption(value)
    this.props.setSortFieldOption(sfo)
    this.props.doSearch(this.props.searchText, lang, sfo, searchScopes[currentScope].objSearchType)
  }

  getSortFieldOption (value) {
    const { sortFieldOptions } = this.props
    for (let index = 0; index < sortFieldOptions.length; index++) {
      const element = sortFieldOptions[index]
      if (value === element.key) {
        return element
      }
    }
  }

  displayResults (objectos) {
    if (!objectos) {
      return
    }
    let result = []
    objectos.forEach((item, index) => {
      let titulo = (typeof item.obj.titulo) === 'string' ? item.obj.titulo : getTranslation(item.obj.titulo, this.props.lang)
      let descricao = (typeof item.obj.descricao) === 'string' ? item.obj.descricao : getTranslation(item.obj.descricao, this.props.lang)
      let style = { gridRowStart: index + 2, gridRowEnd: index + 3 }
      if (item.obj.type === 'document') {
        result.push(
          <a className={styles.resultLine}
            key={item.obj.id}
            style={style}
            href={host + '/api/public/get/mediaFile/documento/' + item.obj.permalink} target='_blank'>
            <div className={styles.objTypeContainer + (isOdd(index) ? ' ' + styles.odd : '')}>
              <div className={styles.objTypeBlock + ' ' + styles[this.getObjTypeClassName(item.obj)]} />
            </div>
            {this.buildItemBlock(item, index, titulo, descricao)}
          </a>
        )
      } else {
        result.push(
          <Link className={styles.resultLine}
            key={item.obj.id}
            style={style}
            to={computeLink(item.obj)}>
            <div className={styles.objTypeContainer + (isOdd(index) ? ' ' + styles.odd : '')}>
              <div className={styles.objTypeBlock + ' ' + styles[this.getObjTypeClassName(item.obj)]} />
            </div>
            {this.buildItemBlock(item, index, titulo, descricao)}
          </Link>
        )
      }
    })
    return result
  }

  buildItemBlock (item, index, titulo, descricao) {
    if (item.obj.type === 'event' || item.obj.type === 'news') {
      return (
        <div className={styles.objBlock + (isOdd(index) ? ' ' + styles.odd : '')}>
          <div className={styles.titleBlock}>{titulo}</div>
          <div className={styles.dateBlock}>{this.getDateStr(item.obj)}</div>
        </div>
      )
    } else {
      return (
        <div className={styles.objBlockNoDate + (isOdd(index) ? ' ' + styles.odd : '')}>
          <div className={styles.titleBlock}>{titulo}</div>
        </div>
      )
    }
  }

  displayResultTypes (objectos) {
    if (!objectos) {
      return
    }
    let result = []
    objectos.forEach((item, index) => {
      let style = { gridRowStart: index + 2, gridRowEnd: index + 3 }
      result.push(
        <div
          key={item.obj.id + item.obj.type}
          className={styles.objTypeContainer + (isOdd(index) ? ' ' + styles.odd : '')}
          style={style}>
          <div className={styles.objTypeBlock + ' ' + styles[this.getObjTypeClassName(item.obj)]} />
        </div>
      )
    })
    return result
  }

  getDateStr (obj) {
    const { lang } = this.props
    switch (obj.type) {
      case 'document':
        return formatDateWithWeek(obj.publicationDate, lang)
      case 'news':
        return formatDateWithWeek(obj.data, lang)
      case 'event':
        return formatDateWithWeek(obj.start, lang, obj.end)
      default:
        return formatDateWithWeek(obj.publicationDate, lang)
    }
  }

  getObjTypeClassName (obj) {
    const { searchScopes } = this.props
    switch (obj.type) {
      case 'page':
        return searchScopes[1].className
      case 'place':
        return searchScopes[2].className
      case 'service':
        if (obj.online) {
          return searchScopes[3].className
        } else {
          return searchScopes[4].className
        }
      case 'document':
        return searchScopes[5].className
      case 'news':
        return searchScopes[6].className
      case 'event':
        return searchScopes[7].className
      default:
        return ''
    }
  }

  getObjTypeImg (obj) {
    const { searchScopes } = this.props
    switch (obj.type) {
      case 'page':
        return searchScopes[1].img
      case 'place':
        return searchScopes[2].img
      case 'service':
        if (obj.online) {
          return searchScopes[3].img
        } else {
          return searchScopes[4].img
        }
      case 'document':
        return searchScopes[5].img
      case 'news':
        return searchScopes[6].img
      case 'event':
        return searchScopes[7].img
      default:
        return ''
    }
  }
}

Search.propTypes = {
  searchResult: PropTypes.object.isRequired,
  lang: PropTypes.string.isRequired,
  showResults: PropTypes.bool.isRequired,
  showMenu: PropTypes.bool.isRequired,
  searchText: PropTypes.string.isRequired,
  doSearch: PropTypes.func.isRequired,
  currentScope: PropTypes.number.isRequired,
  searchScopes: PropTypes.array.isRequired,
  setSortFieldOption: PropTypes.func.isRequired,
  currentSortFieldOption: PropTypes.object.isRequired,
  sortFieldOptions: PropTypes.array.isRequired,
  mobile: PropTypes.bool.isRequired
}

const mapStateToProps = state => ({
  showResults: state.search.showResults,
  searchResult: state.search.searchResult,
  showMenu: state.menus.searchObjects.show,
  searchText: state.search.searchText,
  lang: state.linguas.lang,
  currentScope: state.search.currentScope,
  searchScopes: state.search.searchScopes,
  currentSortFieldOption: state.search.currentSortFieldOption,
  sortFieldOptions: state.search.sortFieldOptions,
  mobile: state.global.mobile
})

const mapDispatchToProps = { doSearch, setSortFieldOption }

export default connect(mapStateToProps, mapDispatchToProps)(Search)
