import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { fetchModel } from '../../../modules/model'
import { getTranslation } from '../../../request'
import { addBreadcrumb } from '../../../modules/header'

class BodyObjecto extends Component {
  componentDidMount () {
    let { permalink } = this.props
    this.props.fetchModel('/documento/' + permalink)
  }

  componentDidUpdate () {
    const { model, permalink } = this.props
    if (model !== undefined && model !== null && permalink === model.permalink) {
      this.props.addBreadcrumb({ link: '/documento/' + permalink, name: model.titulo, translate: true })
    }
  }

  render () {
    const { permalink, lang, model } = this.props
    return (
      <div>
        <h1>Body documento</h1>
        <h2>Permalink:{permalink}</h2>
        <h3>Titulo:{!model ? '' : getTranslation(model.titulo, lang)}</h3>
      </div>
    )
  }
}

BodyObjecto.propTypes = {
  fetchModel: PropTypes.func.isRequired,
  lang: PropTypes.string.isRequired
}

const mapStateToProps = state => ({
  model: state.model.model,
  addBreadcrumb: PropTypes.func.isRequired,
  lang: state.linguas.lang
})

const mapDispatchToProps = { fetchModel, addBreadcrumb }

export default connect(mapStateToProps, mapDispatchToProps)(BodyObjecto)
