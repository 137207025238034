import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Scrollbars } from 'react-custom-scrollbars'
import { withRouter } from 'react-router-dom'

import styles from './FoldersToolbar.css'
import spinner from '../../../css/Spinner.css'
import { toggleExpandedPasta, setCurrentPasta, fetchCurrentPastaContent, expandPasta } from '../../../modules/pastas'
import { resetModel } from '../../../modules/model'
import { refreshPage } from '../../../modules/global'

class MenuToolbar extends Component {
  constructor(props) {
    super(props)
    this.renderThumbHor = this.renderThumbHor.bind(this)
    this.renderThumbVert = this.renderThumbVert.bind(this)
    this.pastaOnClick = this.pastaOnClick.bind(this)
    this.subPastaOnClick = this.subPastaOnClick.bind(this)
    this.toggleExpandPasta = this.toggleExpandPasta.bind(this)
    console.log('construtor menutoolbar')
  }

  componentDidUpdate() {
    const { location } = this.props
    this.setCurrentPasta(location.pathname)
  }

  componentWillUnmount() {
    this.props.setCurrentPasta(null)
  }

  render() {
    const { pastas, availableMenusAreLoading } = this.props
    if (availableMenusAreLoading) {
      return (
        <div className={styles.layoutGrid}>
          <div className={styles.sbDiv}>
            <div className={spinner.loader} />
          </div>
          <div className={styles.divGradTop} />
          <div className={styles.divGradBottom} />
        </div>
      )
    }
    return (
      <div className={styles.layoutGrid}>
        <div className={styles.sbDiv}>
          <Scrollbars
            autoHeight
            autoHeightMax={300}
            // autoHide
            // autoHideTimeout={1000}
            // autoHideDuration={200}
            renderThumbHorizontal={this.renderThumbHor}
            renderThumbVertical={this.renderThumbVert}>
            {this.buildList(pastas)}
          </Scrollbars>
        </div>
        <div className={styles.divGradTop} />
        <div className={styles.divGradBottom} />
      </div>
    )
  }

  setCurrentPasta(path) {
    const { pastas, currentPasta, lang, menuType } = this.props
    if (!pastas || !pastas.length) {
      return
    }
    let arr = path.split('/')
    if (arr.length > 2 && arr[2]) {
      const folder = arr[2]
      if (arr.length > 3 && arr[3]) {
        const subFolder = arr[3]
        if (currentPasta && subFolder === currentPasta.permalink) {
          return
        }
        const pasta = this.getPastaByFolders(pastas, folder, subFolder)
        if (!pasta) return
        const parentPasta = this.getPastaByFolders(pastas, folder)
        if (parentPasta) {
          this.props.expandPasta(parentPasta.id)
        }
        this.props.setCurrentPasta(pasta)
        this.props.fetchCurrentPastaContent(lang, pasta, false, pasta.typeEnum)
      } else {
        if (currentPasta && (folder === currentPasta.permalink || this.pastaIsChildOf(pastas, currentPasta, folder))) {
          if (currentPasta.subPastas.length && folder === currentPasta.permalink) {
            this.props.expandPasta(currentPasta.id)
            this.props.setCurrentPasta(currentPasta.subPastas[0])
            this.props.fetchCurrentPastaContent(lang, currentPasta.subPastas[0], true, menuType)
          }
          return
        }
        let pasta = this.getPastaByFolders(pastas, folder)
        if (!pasta) return
        this.props.expandPasta(pasta.id)
        if (pasta.subPastas.length) {
          pasta = pasta.subPastas[0]
        }
        this.props.setCurrentPasta(pasta)
        this.props.fetchCurrentPastaContent(lang, pasta, false, pasta.typeEnum)
      }
    } else {
      let pasta = pastas[0]
      if (pasta.subPastas.length) {
        this.props.expandPasta(pasta.id)
        pasta = pasta.subPastas[0]
      }
      this.props.setCurrentPasta(pasta)
      this.props.fetchCurrentPastaContent(lang, pasta, true, menuType)
    }
  }

  pastaIsChildOf(pastas, pasta, folder) {
    for (let index = 0; index < pastas.length; index++) {
      const p = pastas[index];
      if (p.permalink === folder) {
        if (p.subPastas.length && p.subPastas[0].id === pasta.id) return true
      }
    }
    return false
  }

  getPastaByFolders(pastas, folder, subFolder) {
    for (let index = 0; index < pastas.length; index++) {
      const p = pastas[index];
      if (p.permalink === folder) {
        if (!subFolder) {
          // if(p.subPastas.length) return p.subPastas[0]
          return p
        } else if (subFolder && p.subPastas.length) {
          for (let j = 0; j < p.subPastas.length; j++) {
            const sp = p.subPastas[j];
            if (sp.permalink === subFolder) {
              return sp
            }
          }
        }
      }
    }
    return null
  }

  buildList(pastas) {
    const { expandedPastaId } = this.props
    return (
      <ul className={styles.ul}>
        {
          pastas.map((obj, index) => {
            if (obj.id === expandedPastaId) {
              let elements = []
              elements.push(<li className={styles.li + ' ' + styles.expanded} key={obj.id}>
                <span
                  className={styles.nomePastaDiv + (this.isCurrentPasta(obj) ? ' ' + styles.current : '')}
                  onClick={(e) => this.pastaOnClick(obj)}>
                  {obj.nome}
                </span>
                <div className={styles.toggleExpandPasta + ' ' + styles.expanded}
                  onClick={(e) => this.toggleExpandPasta(obj)} />
              </li>)
              elements.push(this.listSubPastas(obj.subPastas, index))
              return (elements)
            } else {
              return (
                <li className={styles.li} key={obj.id}>
                  <span
                    className={styles.nomePastaDiv + (this.isCurrentPasta(obj) ? ' ' + styles.current : '')}
                    onClick={(e) => this.pastaOnClick(obj)}>
                    {obj.nome}
                  </span>
                  {this.canExpandPasta(obj)
                    ? <div className={styles.toggleExpandPasta}
                      onClick={(e) => this.toggleExpandPasta(obj)} />
                    : null}
                </li>
              )
            }
          })
        }
      </ul>
    )
  }

  listSubPastas(pastas, index) {
    return (
      <ul className={styles.ulSubPastas} key={index}>
        {
          pastas.map(obj => (
            <li className={styles.liSubPasta} key={obj.id}>
              <span
                className={styles.nomeSubPastaDiv + (this.isCurrentPasta(obj) ? ' ' + styles.current : '')}
                onClick={(e) => this.subPastaOnClick(obj)}>
                {obj.nome}
              </span>
            </li>
          ))
        }
      </ul>
    )
  }

  isCurrentPasta(pasta) {
    const { currentPasta } = this.props
    if (!currentPasta) {
      return false
    }
    if (pasta.id === currentPasta.id) {
      return true
    }
    return false
  }

  canExpandPasta(pasta) {
    if (!pasta.subPastas || pasta.subPastas.length === 0) {
      return false
    }
    return true
  }

  pastaOnClick(pasta) {
    const { currentPasta, expandedPastaId, lang, history: { push } } = this.props
    if (!currentPasta || currentPasta.id !== pasta.id) {
      const originalPasta = { ...pasta }
      if (pasta.subPastas.length > 0) {
        pasta = pasta.subPastas[0]
      }
      this.props.setCurrentPasta(pasta)
      this.props.fetchCurrentPastaContent(lang, pasta, false, pasta.typeEnum)
      if (originalPasta.subPastas.length > 0 &&
        (!expandedPastaId || expandedPastaId !== originalPasta.id)) {
        this.props.toggleExpandedPasta(originalPasta)
      }
      const link = this.getPastaPermalink(pasta)
      const path = this.getRoutePath()
      push(path + '/' + link)
    } else {
      const link = this.getPastaPermalink(pasta)
      const path = this.getRoutePath()
      push(path + '/' + link)
    }
  }
  subPastaOnClick(pasta) {
    const { currentPasta, lang, history: { push } } = this.props
    const routePath = this.getRoutePath()
    if (!currentPasta || currentPasta.id !== pasta.id) {
      this.props.setCurrentPasta(pasta)
      this.props.fetchCurrentPastaContent(lang, pasta, false, pasta.typeEnum)
      const link = this.getPastaPermalink(pasta)
      push(routePath + '/' + link)
    } else {
      const link = this.getPastaPermalink(pasta)
      push(routePath + '/' + link)
    }
  }

  getPastaPermalink(pasta) {
    const { pastas } = this.props
    if (!pasta || !pastas || !pastas.length) {
      return null
    }
    for (let index = 0; index < pastas.length; index++) {
      const p = pastas[index];
      if (p.id === pasta.id) {
        return p.permalink
      } else if (p.subPastas) {
        for (let j = 0; j < p.subPastas.length; j++) {
          const subPasta = p.subPastas[j];
          if (subPasta.id === pasta.id) {
            return p.permalink + '/' + subPasta.permalink
          }
        }
      }
    }
    return null
  }

  getRoutePath(single = false) {
    const { menuType } = this.props
    switch (menuType) {
      case 1:
        if (!single) {
          return '/paginas'
        } else {
          return '/pagina'
        }
      case 2:
        if (!single) {
          return '/servicos-online'
        } else {
          return '/servico-online'
        }
      case 3:
        if (!single) {
          return '/servicos'
        } else {
          return '/servico'
        }
      case 4:
        if (!single) {
          return '/locais'
        } else {
          return '/local'
        }
      default:
        if (!single) {
          return '/paginas'
        } else {
          return '/pagina'
        }
    }
  }

  toggleExpandPasta(pasta) {
    this.props.toggleExpandedPasta(pasta)
  }

  renderThumbHor({ style, ...props }) {
    const thumbStyle = {
      backgroundColor: '#9ebdd2',
      borderRadius: '4px'
    }
    return (
      <div
        style={{ ...style, ...thumbStyle }}
        {...props} />
    )
  }

  renderThumbVert({ style, ...props }) {
    const thumbStyle = {
      minWidth: '8px',
      backgroundColor: '#9ebdd2',
      borderRadius: '4px'
    }
    return (
      <div
        style={{ ...style, ...thumbStyle }}
        {...props} />
    )
  }
}

MenuToolbar.propTypes = {
  lang: PropTypes.string.isRequired,
  pastas: PropTypes.array.isRequired,
  toggleExpandedPasta: PropTypes.func.isRequired,
  setCurrentPasta: PropTypes.func.isRequired,
  currentPasta: PropTypes.object,
  expandedPastaId: PropTypes.string,
  fetchCurrentPastaContent: PropTypes.func.isRequired,
  menuType: PropTypes.number.isRequired,
  resetModel: PropTypes.func.isRequired,
  refreshPage: PropTypes.func.isRequired,
  expandPasta: PropTypes.func.isRequired,
  availableMenusAreLoading: PropTypes.bool.isRequired,
}

const mapStateToProps = state => ({
  lang: state.linguas.lang,
  pastas: state.pastas.pastas,
  currentPasta: state.pastas.currentPasta,
  expandedPastaId: state.pastas.expandedPastaId,
  menuType: state.pastas.menuType,
  availableMenusAreLoading: state.pastas.availableMenusAreLoading
})

const mapDispatchToProps = {
  toggleExpandedPasta, setCurrentPasta, fetchCurrentPastaContent, resetModel, refreshPage,
  expandPasta
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MenuToolbar))
