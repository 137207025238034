import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { FormattedMessage as FM } from 'react-intl'

import styles from './LoginPopup.css'
import indexStyles from '../../index.css'
import { setLoginPopupState } from '../../modules/header'
import { loginAuthGov } from '../../modules/auth'
import { kc, host } from '../../request'

class LoginPopup extends Component {
  constructor(props) {
    super(props)
    this.closePopup = this.closePopup.bind(this)
    this.skipClosePopup = this.skipClosePopup.bind(this)
    this.loginAutenticacaoGov = this.loginAutenticacaoGov.bind(this)
  }

  render () {
    const { loginPopupClosed, samlForm, samlInput } = this.props
    console.log('samlForm', samlForm)
    console.log('samlInput', samlInput)
    if (loginPopupClosed) {
      return null
    }
    return (
      <div className={styles.main + ' ' + styles.show} onClick={this.closePopup}>
        <div className={styles.windowCont + ' ' + indexStyles.shadow} onClick={this.skipClosePopup}>
          <div className={styles.closeButton} onClick={this.closePopup} />
          <div className={styles.title}>
            <FM id='loginPopup.title' defaultMessage='Entrar' />
          </div>
          <div className={styles.subtitle}>
            <FM id='loginPopup.subtitle' defaultMessage='Selecione a sua opção' />
          </div>
          <div className={styles.buttons}>
            <div className={styles.buttonContainer} onClick={this.loginAutenticacaoGov}>
              <div className={styles.button + ' ' + styles.autenticacaoGov} />
            </div>
            <div className={styles.buttonContainer + ' ' + styles.login} onClick={(e) => this.login(e)}>
              <div className={styles.button + ' ' + styles.login}>
                <FM id='loginPopup.login' defaultMessage='Login' />
              </div>
            </div>
            <div className={styles.buttonContainer + ' ' + styles.login} onClick={(e) => this.loginFacebook(e)}>
              <div className={styles.button + ' ' + styles.login + ' ' + styles.facebook}>
                <FM id='loginPopup.loginFacebook' defaultMessage='Facebook' />
              </div>
            </div>
            <div className={styles.buttonContainer + ' ' + styles.login} onClick={(e) => this.register(e)}>
              <div className={styles.button + ' ' + styles.register}>
                <FM id='loginPopup.register' defaultMessage='Registar' />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  loginAutenticacaoGov () {
    const { samlForm, samlInput } = this.props
    if (process.env.NODE_ENV !== 'development') {
      this.loadSaml(samlForm.current, samlInput.current)
    } else {
      this.loadSamlMock(samlForm.current, samlInput.current)
      // this.props.loginAuthGov()
      // this.closePopup()
    }
  }

  loadSamlMock (form, samlInput) {
    console.log('loadSamlDev')
    console.log('form', form)
    console.log('samlInput', samlInput)
    fetch(host + '/api/public/userAccount/buildSAML')
      .then(res => res.json())
      .then(post => {
        console.log('post', post)
        samlInput.value = post.saml
        form.action = post.url
        form.action = host + '/api/public/userAccount/handleAuthResponseMock'
        form.submit()
      })
  }

  loadSaml (form, samlInput) {
    console.log('loadSamlProd')
    console.log('form', form)
    console.log('samlInput', samlInput)
    fetch(host + '/api/public/userAccount/buildSAML')
      .then(res => res.json())
      .then(post => {
        console.log('post', post)
        samlInput.value = post.saml
        form.action = post.url
        // window.alert('Vai ser feito submit')
        form.submit()
      })
  }

  login () {
    localStorage.setItem('cmlf_login', true)
    localStorage.setItem('cmlf_loginIsAuthGov', false)
    kc.login()
  }

  register () {
    kc.register()
  }

  loginFacebook () {
    localStorage.setItem('cmlf_login', true)
    localStorage.setItem('cmlf_loginIsAuthGov', false)
    kc.login({ idpHint: 'facebook' })
  }

  closePopup (e) {
    if (e) {
      e.stopPropagation()
    }
    this.props.setLoginPopupState(true)
  }

  skipClosePopup (e) {
    if (e) {
      e.stopPropagation()
    }
  }
}

LoginPopup.propTypes = {
  setLoginPopupState: PropTypes.func.isRequired,
  loginPopupClosed: PropTypes.bool.isRequired,
  user: PropTypes.object,
  loginAuthGov: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  loginPopupClosed: state.header.loginPopupClosed,
  user: state.auth.user
})

const mapDispatchToProps = { setLoginPopupState, loginAuthGov }

export default connect(mapStateToProps, mapDispatchToProps)(LoginPopup)
