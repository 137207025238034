import msg from '../components/App/messages'
import axios from 'axios'
import { computeLink } from '../request'

export const host = window.location.protocol === 'http:' ? 'https://globaleda-dev.duckdns.org' : ''

export function intelliMatchTextToTerm (text, value) {
  // score é a distância entre o primeiro e o último match
  // first é a posição da primeira ocorrência
  if (value === '' || value === null) {
    return { text: text, score: text.length, first: 0 }
  }
  let offset = 0
  let pos = 0
  let min = -1
  for (let index = 0; index < value.length; index++) {
    const char = value.charAt(index)
    pos = text.substr(offset).toLowerCase().indexOf(char.toLowerCase())
    if (pos === -1) {
      return false
    }
    offset = offset + pos + 1
    if (min === -1) {
      min = pos
    }
  }
  return { text: text, score: offset - min, first: min }
}

export function ymdToDate (str) {
  if (!str) {
    return new Date()
  }
  let obj = {
    year: parseInt(str.substr(0, 4)),
    month: parseInt(str.substr(5, 2)),
    day: parseInt(str.substr(8, 2))
  }
  return new Date(obj.year, obj.month - 1, obj.day)
}

export function dateToYmdStr (date) {
  let mm = date.getMonth() + 1 // getMonth() is zero-based
  let dd = date.getDate()

  return [date.getFullYear(), (mm > 9 ? '' : '0') + mm, (dd > 9 ? '' : '0') + dd].join('')
}

export const apiKey = 'AIzaSyBQL08qQog0TftFMRgpq4d5cMOY6urniFI'

export const oldApiKey = 'AIzaSyD3E1D9b-Z7ekrT3tbhl_dy8DCXuIuDDRc'

export function getDateStr (strDate, lang) {
  if (!strDate) {
    return null
  }
  let date = ymdToDate(strDate)
  return date.toLocaleDateString(lang, { day: '2-digit', year: 'numeric', month: 'long' })
}

export function getTimeStr (strDate, lang) {
  if (!strDate) {
    return null
  }
  let date = new Date(strDate)
  return date.toLocaleTimeString(lang, { hour: '2-digit', minute: '2-digit' })
}

export function formatTimeInterval (strDate, lang, strEnd) {
  let date = new Date(strDate)
  if (strEnd == null) {
    return date.toLocaleTimeString(lang, { hour: '2-digit', minute: '2-digit' })
  }
  let end = new Date(strEnd)
  let divisor = ' ' + msg[lang]['global.toTime'] + ' '
  return date.toLocaleTimeString(lang, { hour: '2-digit', minute: '2-digit' }) + divisor + end.toLocaleTimeString(lang, { hour: '2-digit', minute: '2-digit' })
}

export function getImage (imagem) {
  if (imagem !== null && imagem !== undefined &&
    imagem.mediaType !== null && imagem.id !== null) {
    return host + '/api/public/getImage/' + imagem.id
  } else {
    return null
  }
}

export function isOdd (n) {
  return Math.abs(n % 2) === 1
}

export function getDateTime (strDate, lang) {
  if (!strDate) {
    return null
  }
  let date = new Date(strDate)
  return date.toLocaleDateString(lang, { day: '2-digit', year: 'numeric', month: 'long', hour: 'numeric', minute: 'numeric' })
}

export function prettyDate (strDate, lang, strEnd) {
  let date = new Date(strDate)
  if (strEnd == null) {
    return date.toLocaleDateString(lang, { day: 'numeric', month: 'long', year: 'numeric' })
  }
  let end = new Date(strEnd)
  let divisor = ' ' + msg[lang]['global.to'] + ' '
  if (date.getFullYear() === end.getFullYear()) { // mesmo ano
    if (date.getMonth() === end.getMonth()) { // mesmo mês
      if (date.getDate() === end.getDate()) { // mesmo dia
        return date.toLocaleDateString(lang, { day: 'numeric', month: 'long', year: 'numeric' })
      } else {
        return date.toLocaleDateString(lang, { day: 'numeric' }) + divisor + end.toLocaleDateString(lang, { day: 'numeric', month: 'long', year: 'numeric' })
      }
    } else { // mês diferente
      return date.toLocaleDateString(lang, { day: 'numeric', month: 'long' }) + divisor + end.toLocaleDateString(lang, { day: 'numeric', month: 'long', year: 'numeric' })
    }
  } else { // anos diferentes
    return date.toLocaleDateString(lang, { day: 'numeric', month: 'long', year: 'numeric' }) + divisor + end.toLocaleDateString(lang, { day: 'numeric', month: 'long', year: 'numeric' })
  }
}

export function prettyDateShort (strDate, lang, strEnd) {
  let date = new Date(strDate)
  if (strEnd == null) {
    return date.toLocaleDateString(lang, { day: 'numeric', month: '2-digit', year: 'numeric' })
  }
  let end = new Date(strEnd)
  let divisor = ' ' + msg[lang]['global.to'] + ' '
  if (date.getFullYear() === end.getFullYear()) { // mesmo ano
    if (date.getMonth() === end.getMonth()) { // mesmo mês
      if (date.getDate() === end.getDate()) { // mesmo dia
        return date.toLocaleDateString(lang, { day: 'numeric', month: '2-digit', year: 'numeric' })
      } else {
        return date.toLocaleDateString(lang, { day: 'numeric' }) + divisor + end.toLocaleDateString(lang, { day: 'numeric', month: '2-digit', year: 'numeric' })
      }
    } else { // mês diferente
      return date.toLocaleDateString(lang, { day: 'numeric', month: '2-digit' }) + divisor + end.toLocaleDateString(lang, { day: 'numeric', month: '2-digit', year: 'numeric' })
    }
  } else { // anos diferentes
    return date.toLocaleDateString(lang, { day: 'numeric', month: '2-digit', year: 'numeric' }) + divisor + end.toLocaleDateString(lang, { day: 'numeric', month: '2-digit', year: 'numeric' })
  }
}

export function stringArrToCommaSepString (arr) {
  if (!arr || arr.length === 0) {
    return null
  }
  let result = ''
  for (let index = 0; index < arr.length; index++) {
    const element = arr[index]
    result += result === '' ? element : ',' + element
  }
  return result
}

export function getObjectoBase (id) {
  axios.get(host + '/api/public/objectoBase/' + id)
    .then(res => res.data)
    .then(post => {
      post.link = computeLink(post)
      if (post.type === 'document') {
        post.downloadLink = host + '/api/public/get/mediaFile/documento/' + post.permalink
      }
      post.image = getImage(post.imagem)
      return post
    })
    .catch((error) => {
      console.log(error)
      return null
    })
}

export function getMediaType (filename) {
  if (!filename) {
    return 'img'
  }
  const extension = filename.substr((filename.lastIndexOf('.') + 1))
  switch (extension.toLowerCase()) {
    case 'jpg':
    case 'jpeg':
    case 'png':
    case 'tiff':
    case 'gif':
    case 'jfif':
      return 'img'
    default:
      return 'video'
  }
}

export function addParamToUrl (url, key, value) {
  if (value === null) {
    return url
  }
  let delimiter = url.indexOf('?') < 0 ? '?' : '&'
  return url + delimiter + encodeURIComponent(key) + '=' + encodeURIComponent(value)
}

export function convertApiEndpoint (user, endpoint) {
  if (!user || !user.isAuthGov) {
    return endpoint
  }
  switch (endpoint) {
    case '/api/public/formulario/':
      return '/api/public/authgov/formulario/'
    default:
      return endpoint
  }
}
