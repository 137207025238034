import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import styles from './CalendarTopYear.css'

class CalendarTopYear extends Component {
  render () {
    const { topMonthYear } = this.props
    return (
      <div className={styles.topMonthYear}>{topMonthYear}</div>
    )
  }
}

CalendarTopYear.propTypes = {
  topMonthYear: PropTypes.number
}

const mapStateToProps = state => ({
  topMonthYear: state.calendar.topMonthYear
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(CalendarTopYear)
