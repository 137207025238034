import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { getTranslation } from '../../request'
import styles from './FormField.css'

import HeaderField from './Fields/HeaderField'
import HrField from './Fields/HrField'
import InputTextField from './Fields/InputTextField'
import InputNumberField from './Fields/InputNumberField'
import InputDateField from './Fields/InputDateField'
import ParagraphField from './Fields/ParagraphField'
import ListBoxField from './Fields/ListBoxField'
import RadioButtonGroupField from './Fields/RadioButtonGroupField'
import CheckBoxGroupField from './Fields/CheckBoxGroupField'
import AutoCompleteField from './Fields/AutoCompleteField'
import TreeListField from './Fields/TreeListField'
import FileUploadField from './Fields/FileUploadField'
import ErrorField from './Fields/ErrorField'
import InputTextArea from './Fields/InputTextArea'
import FormTableField from './Fields/FormTableField'

class FormField extends Component {
  render() {
    const { field, lang, showErrors } = this.props
    return (
      <div>
        {this.buildInterface(field, lang)}
        {
          showErrors
            ? <ErrorField field={field} lang={lang} />
            : null
        }
      </div>
    )
  }
  buildInterface(field, lang) {
    switch (field.type) {
      case 'header':
        return (<HeaderField field={field} lang={lang} />)
      case 'hr':
        return (<HrField />)
      case 'inputText':
        return (<InputTextField field={field} lang={lang} />)
      case 'inputNumber':
        return (<InputNumberField field={field} lang={lang} />)
      case 'inputDate':
        return (<InputDateField field={field} lang={lang} />)
      case 'paragraph':
        return (<ParagraphField field={field} lang={lang} />)
      case 'listBox':
        return (<ListBoxField field={field} lang={lang} />)
      case 'radioButtonGroup':
        return (<RadioButtonGroupField field={field} lang={lang} />)
      case 'checkBoxGroup':
        return (<CheckBoxGroupField field={field} lang={lang} />)
      case 'autoComplete':
        return (<AutoCompleteField field={field} lang={lang} />)
      case 'tree':
        return (<TreeListField field={field} lang={lang} />)
      case 'fileUpload':
        return (<FileUploadField field={field} lang={lang} />)
      case 'textArea':
        return (<InputTextArea field={field} lang={lang} />)
      case 'table':
        return (<FormTableField field={field} lang={lang} />)
      default:
        return (
          <li key={field.id} className={styles.liField}>
            <span>Tipo:{field.type} {getTranslation(field.label, lang)}</span>
          </li>
        )
    }
  }
}

FormField.propTypes = {
  lang: PropTypes.string.isRequired
}

const mapStateToProps = state => ({
  lang: state.linguas.lang
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(FormField)
