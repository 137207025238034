import React, { Component } from 'react'

import { getTranslation } from '../../../request'
import styles from './Label.css'
import HelpText from './HelpText'

export default class Label extends Component {
  render () {
    const { field, lang } = this.props
    return (
      <div className={styles.wrapper}>
        <label
          key='label'
          className={styles.label}
          htmlFor='input'>{getTranslation(field.label, lang)}
        </label>
        <HelpText field={field} lang={lang} />
      </div>
    )
  }
}
