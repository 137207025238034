const RESET_GALLERY = 'cmlf/gallery/RESET_GALLERY'
const ADD_MEDIA = 'cmlf/gallery/ADD_MEDIA'
const SET_CURRENT_MEDIA_INDEX = 'cmlf/gallery/SET_CURRENT_MEDIA_INDEX'

const initialState = {
  mediaList: [],
  currentMediaIndex: 0,
  currentMedia: null
}

export const resetGallery = () => ({ type: RESET_GALLERY })
export const addMedia = (media) => ({ type: ADD_MEDIA, media })
export const setIndex = (index) => ({ type: SET_CURRENT_MEDIA_INDEX, index })

export default function reducer (state = initialState, action = {}) {
  switch (action.type) {
    case RESET_GALLERY:
      return { ...state, mediaList: [] }
    case ADD_MEDIA:
      let list = [...state.mediaList]
      return { ...state, mediaList: pushIfNew(list, action.media) }
    case SET_CURRENT_MEDIA_INDEX:
      return { ...state, currentMediaIndex: action.index }
    default:
      return state
  }
}

function pushIfNew (list, media) {
  if (!list || list.length === 0) {
    list = []
    list.push(media)
    return list
  }
  let exists = false
  list.forEach(element => {
    if (element.id === media.id) {
      exists = true
    }
  })
  if (!exists) {
    list.push(media)
  }
  return list
}
