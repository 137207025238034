import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import commonStyles from './CommonStyles.css'
import { setFormInstance, saveFormInstance } from '../../../modules/formulario'
import Label from './Label'
import styles from './ListBoxField.css'

class ListBoxField extends Component {
  constructor(props) {
    super(props)
    this.valueChanged = this.valueChanged.bind(this)
  }

  render () {
    const { field, lang, formInstance } = this.props
    return (
      <div className={commonStyles.labelWrapper}>
        <Label {...this.props} />
        <div className={styles.boxWrapper}>
          {this.buildInput(field, lang, formInstance)}
        </div>
      </div>
    )
  }

  buildInput (field, lang, formInstance) {
    const values = this.getValues(field.values, lang)
    return (
      <select
        className={styles.box + (!field.allowMultiSelection ? ' ' + styles.combo : '')}
        disabled={field.readonly}
        size={this.setListboxSize(field)}
        value={this.getSelectedValues(field, formInstance)}
        onChange={this.valueChanged}
        multiple={field.allowMultiSelection}>
        {
          values.map(item => (
            <option
              className={styles.option}
              key={item.value}
              value={item.value}>
              {item.label}
            </option>
          ))
        }
      </select>
    )
  }

  valueChanged (e) {
    const { field, formInstance, lang } = this.props
    let formInstanceValue = this.getFormInstanceFieldValue()
    if (formInstanceValue === null) {
      formInstanceValue = this.initNewFormInstanceField()
      formInstance.values.push(formInstanceValue)
    }
    formInstanceValue.keyValues = []
    e.target.childNodes.forEach(option => {
      if (option.selected) {
        const item = this.getFieldValue(field, option.value, lang)
        formInstanceValue.keyValues.push({ key: item.value, value: item.label })
      }
    })
    let values = []
    values.push(formInstanceValue)
    this.props.setFormInstance(formInstance)
    this.props.saveFormInstance(formInstance.id, values)
    this.forceUpdate()
  }

  getSelectedValues (field, formInstance) {
    if (field.allowMultiSelection && field.fieldType === 1) {
      return this.getSelectedValuesArray(field, formInstance)
    }
    return this.getSelectedValue(field, formInstance)
  }

  getSelectedValuesArray (field, formInstance) {
    let result = []
    if (!formInstance || !formInstance.values) {
      return result
    }
    for (let index = 0; index < formInstance.values.length; index++) {
      const element = formInstance.values[index]
      if (element.fieldId === field.id) {
        for (let i = 0; i < element.keyValues.length; i++) {
          const keyValue = element.keyValues[i]
          result.push(keyValue.key)
        }
        break
      }
    }
    return result
  }

  getSelectedValue (field, formInstance) {
    let result = ''
    if (!formInstance) {
      return result
    }
    for (let index = 0; index < formInstance.values.length; index++) {
      const element = formInstance.values[index]
      if (element.fieldId === field.id) {
        for (let i = 0; i < element.keyValues.length; i++) {
          const keyValue = element.keyValues[i]
          return keyValue.key
        }
        break
      }
    }
    return result
  }

  setListboxSize (field, lang) {
    if (!field || field.fieldType === 0) return 0
    let values = this.getValues(field.values, lang)
    if (values.length > 15) {
      return 15
    }
    return values.length
  }

  valueChecked (field, formInstance, fieldValue) {
    if (!formInstance) {
      return false
    }
    for (let index = 0; index < formInstance.values.length; index++) {
      const element = formInstance.values[index]
      if (element.fieldId === field.id) {
        for (let i = 0; i < element.keyValues.length; i++) {
          const keyValue = element.keyValues[i]
          if (fieldValue.value === keyValue.key) {
            return true
          }
        }
        break
      }
    }
    return false
  }

  getFieldValue (field, value, lang) {
    for (let index = 0; index < field.values.length; index++) {
      const item = field.values[index]
      if (item.lang === lang && item.value === value) {
        return item
      }
    }
    return null
  }

  initNewFormInstanceField () {
    const { field } = this.props
    return { fieldId: field.id, key: null, value: null, keyValues: [] }
  }

  getFormInstanceFieldValue () {
    const { field, formInstance } = this.props
    if (!formInstance) {
      return null
    }
    for (let index = 0; index < formInstance.values.length; index++) {
      const element = formInstance.values[index]
      if (element.fieldId === field.id) {
        return element
      }
    }
    return null
  }

  getValues (values, lang) {
    let array = []
    if (!values || values.length === 0) return []
    if (values[0].lang !== undefined) {
      for (let index = 0; index < values.length; index++) {
        const element = values[index]
        if (element.lang === lang) {
          array.push(element)
        }
      }
      return array
    } else {
      for (let index = 0; index < values.length; index++) {
        const element = values[index]
        array.push(element)
      }
      return array
    }
  }

  defaultValue (field, lang) {
    const values = this.getValues(field.values, lang)
    if (field.allowMultiSelection) {
      let result = []
      for (let index = 0; index < values.length; index++) {
        const element = values[index]
        if (element.defaultValue) {
          result.push(element.value)
        }
      }
      return result
    } else {
      for (let index = 0; index < values.length; index++) {
        const element = values[index]
        if (element.defaultValue) {
          return element.value
        }
      }
      return null
    }
  }
}

ListBoxField.propTypes = {
  lang: PropTypes.string.isRequired,
  formInstance: PropTypes.object,
  setFormInstance: PropTypes.func.isRequired,
  saveFormInstance: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  formInstance: state.formulario.formInstance,
  lang: state.linguas.lang
})

const mapDispatchToProps = { setFormInstance, saveFormInstance }

export default connect(mapStateToProps, mapDispatchToProps)(ListBoxField)
