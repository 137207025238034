import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { FormattedMessage as FM } from 'react-intl'

import styles from './Documentos.css'
import spinner from '../../../css/Spinner.css'
import { getCatFilteredObjects, fetchCategorias, setCurrentObjType, resetSelectedCategorias, setCategoriasLoaded } from '../../../modules/categorias'
import { fetchDocumentos, setPastaClicked } from '../../../modules/documentos'
import { toggleTreeExpandedPasta, fetchCurrentPastaContent, initPath } from '../../../modules/pastas'
import { getDateStr, isOdd } from '../../../lib/utils'
import DocumentosList from './DocumentosList'
import { addBreadcrumb, setBreadcrumb } from '../../../modules/header'
import DocumentosCategorias from './DocumentosCategorias'

class Documentos extends Component {
  constructor(props) {
    super(props)
    this.toggleFolder = this.toggleFolder.bind(this)
    props.setCategoriasLoaded(false)
  }

  componentDidMount() {
    const { lang, currentObjectType, categoriasIsLoading, categoriasLoaded, documentosAreLoading, documentosLoaded } = this.props
    this.props.initPath()
    this.globalIndex = 0
    if (currentObjectType !== 'documento') {
      this.props.setCurrentObjType('documento')
      this.props.resetSelectedCategorias()
      if (!categoriasIsLoading && !categoriasLoaded) {
        this.props.fetchCategorias(lang, 'documento')
      }
    }
  }

  componentDidUpdate() {
    const { selectedCategorias, lang, path, categoriasIsLoading, categoriasLoaded, currentPastaContent } = this.props
    if (!categoriasIsLoading && !categoriasLoaded) {
      this.props.fetchCategorias(lang, 'documento')
    }
    this.buildBreadcrumb(path)
  }

  render() {
    const { currentPastaContent, path, selectedCategorias, destinationPasta,
      currentPasta, match, pastaClicked, documentosAreLoading,
      objectosTreeFromPastaAreLoading, currentPastaContentIsLoading,
      documentsTreeRoot } = this.props
    const updateFilteredCategorias = selectedCategorias && selectedCategorias.length > 0
    if (!match.params.doc || pastaClicked) {
      if ((currentPasta && currentPasta.typeEnum === 0) || destinationPasta) {
        if (currentPasta) {
          if (!destinationPasta || destinationPasta.id === currentPasta.id) {
            window.history.replaceState({}, '', '/documentos/s/' + currentPasta.permalink)
          } else {
            window.history.replaceState({}, '', '/documentos/s/' + currentPasta.permalink + '/' + destinationPasta.permalink)
          }
        } else {
          window.history.replaceState({}, '', '/documentos/' + destinationPasta.permalink)
        }
      } else {
        window.history.replaceState({}, '', '/documentos')
      }
    }
    if (updateFilteredCategorias) {
      return (
        <DocumentosCategorias />
        // <DocumentosList filterCategorias />
      )
    }
    this.globalIndex = 0
    let lastPastaFromPath = path[path.length - 1]
    if (documentosAreLoading || objectosTreeFromPastaAreLoading || currentPastaContentIsLoading ||
      (currentPastaContent && currentPastaContent.menuType !== 0)) {
      return (
        <div className={styles.layoutGrid}>
          <div className={styles.header}><FM id='body.publications' defaultMessage='Publicações' /></div>
          <div className={styles.listContainer}>
            <div className={spinner.loader} />
          </div>
        </div>
      )
    }
    const root = this.findTreeRoot(currentPastaContent, documentsTreeRoot)
    return (
      <div className={styles.layoutGrid}>
        <div className={styles.header}><FM id='body.publications' defaultMessage='Publicações' /></div>
        {
          !lastPastaFromPath || !lastPastaFromPath.descricao
            ? null
            : <div className={styles.resumoDiv}>{lastPastaFromPath.descricao}</div>
        }
        <div className={styles.listContainer}>
          {this.buildFoldersList(root)}
          {this.buildDocumentsList(root)}
        </div>
      </div>
    )
  }

  findTreeRoot(tree, root) {
    if (!root || root.id === null) return tree
    const result = this.findTreeRootRec(tree.subPastas, root.id)
    if (!result) return tree
    return result
  }

  findTreeRootRec(subPastas, id) {
    for (let index = 0; index < subPastas.length; index++) {
      const element = subPastas[index];
      if (element.id === id) {
        return element
      }
      const result = this.findTreeRootRec(element.subPastas, id)
      if (result) return result;
    }
    return null
  }

  buildBreadcrumb(path) {
    let breadcrumb = [{ link: '/documentos', name: 'body.publications' }]
    if (!path || path.length === 0) {
      this.props.setBreadcrumb(breadcrumb)
      return
    }
    const { currentPastaContent } = this.props
    const hasSelection = currentPastaContent !== null && currentPastaContent.id !== null
    let selectionPermalink = null
    let passeiSeleccao = false
    path.forEach(element => {
      let permalink = element.permalink
      if (hasSelection) {
        if (element.id === currentPastaContent.id) {
          permalink = 's/' + element.permalink
          selectionPermalink = element.permalink
          passeiSeleccao = true
        } else if (selectionPermalink) {
          permalink = 's/' + selectionPermalink + '/' + element.permalink
        } else if (!passeiSeleccao) {
          permalink = 's/' + element.permalink
        }
      }
      breadcrumb.push({ link: '/documentos/' + permalink, name: element.nome, translated: true })
    })
    this.props.setBreadcrumb(breadcrumb)
  }

  buildFoldersList(content) {
    const { treeSelectedPastaId } = this.props
    if (!content) {
      return null
    }
    return (
      <ul className={styles.ul}>
        {
          content.subPastas.map((pasta, index) => {
            let selected = this.inTheWayOfSelectedFolder(treeSelectedPastaId, pasta)
            let disabled = this.noContent(pasta)
            return (
              <li className={styles.liWrapper} key={pasta.id}>
                <div className={styles.li +
                  (selected ? ' ' + styles.selected : '') +
                  (disabled ? ' ' + styles.disabled : '') +
                  (isOdd(this.globalIndex++) ? ' ' + styles.odd : '')} onClick={(e) => this.toggleFolder(pasta, disabled)}>
                  <div className={styles.icon + ' ' + styles.pasta + (selected ? ' ' + styles.selected : '')} />
                  <div className={styles.text}>
                    {pasta.nome}
                  </div>
                  {!disabled
                    ? <div className={styles.action + ' ' + (selected ? styles.contract : styles.expand)} />
                    : null
                  }
                </div>
                {selected
                  ? <div className={styles.liSpacing}>
                    {selected ? this.buildFoldersList(pasta) : null}
                    {selected ? this.buildDocumentsList(pasta) : null}
                  </div>
                  : null}
              </li>
            )
          })
        }
      </ul>
    )
  }

  buildDocumentsList(content) {
    if (!content) {
      return null
    }
    const { lang, match, pastaClicked } = this.props
    // TODO: obter lista de categorias associadas aos documentos
    // let docs = getCatFilteredObjects(content.documentos, this.props.categorias)
    let docs = content.objectos
    let docDest = match.params.doc
    if (pastaClicked) {
      docDest = null
    }
    return (
      <ul className={styles.ul}>
        {
          docs.map((doc) => (
            <li key={doc.id}>
              <div className={styles.li +
                ' ' + styles.file + (isOdd(this.globalIndex++) ? ' ' + styles.odd : '') +
                (doc.labelUrl && doc.url ? ' ' + styles.withUrl : '') +
                (docDest && doc.permalink === docDest ? ' ' + styles.currentDoc : '')}>
                <div className={styles.icon + ' ' + styles.documento} />
                <a className={styles.textDocument} href={doc.downloadLink} target='_blank'>{doc.titulo}</a>
                <a className={styles.action + ' ' + styles.download} href={doc.downloadLink} target='_blank' />
                <div className={styles.size}>{this.getSizeString(doc.fileSizeKb, lang)}</div>
                <div className={styles.data}>{getDateStr(doc.pubDate, lang)}</div>
                {
                  doc.labelUrl && doc.url
                    ? <a className={styles.urlDoc} href={doc.url} target='_blank'>{doc.labelUrl}</a>
                    : null
                }
              </div>
            </li>
          ))
        }
      </ul>
    )
  }

  getSizeString(number, lang) {
    if (!number || !lang) {
      return null
    }
    if (number > 1000) {
      return Math.floor(number / 1024).toLocaleString(lang) + ' MB'
    } else {
      return number.toLocaleString(lang) + ' KB'
    }
  }

  noContent(pasta) {
    if (!pasta || (!pasta.subPastas && !pasta.objectos)) {
      return true
    }
    if (pasta.subPastas.length === 0 && pasta.objectos.length === 0) {
      return true
    }
    return false
  }

  toggleFolder(pasta, disabled) {
    if (disabled) {
      return
    }
    this.props.setPastaClicked(true)
    this.props.toggleTreeExpandedPasta(pasta)
  }

  inTheWayOfSelectedFolder(pastaId, root) {
    if (!pastaId || !root) {
      return false
    }
    if (pastaId === root.id) {
      return true
    }
    for (let index = 0; index < root.subPastas.length; index++) {
      const element = root.subPastas[index]
      if (element.id === pastaId) {
        return true
      }
      if (this.inTheWayOfSelectedFolder(pastaId, element)) {
        return true
      }
    }
    return false
  }

  buildObjectList() {
    const documentos = getCatFilteredObjects(this.props.documentos, this.props.categorias)
    return (
      <ul className={styles.objectListContainer}>
        {
          documentos.map(obj => (
            <li key={obj.id}><Link to={obj.link}>{obj.title}</Link>&nbsp;<a href={obj.downloadLink}>Download</a>
            </li>
          ))
        }
      </ul>
    )
  }
}

Documentos.propTypes = {
  currentPasta: PropTypes.object,
  currentPastaContent: PropTypes.object,
  treeSelectedPastaId: PropTypes.string,
  toggleTreeExpandedPasta: PropTypes.func.isRequired,
  lang: PropTypes.string.isRequired,
  path: PropTypes.array.isRequired,
  fetchCurrentPastaContent: PropTypes.func.isRequired,
  initPath: PropTypes.func.isRequired,
  selectedCategorias: PropTypes.array.isRequired,
  setCurrentObjType: PropTypes.func.isRequired,
  currentObjectType: PropTypes.string.isRequired,
  resetSelectedCategorias: PropTypes.func.isRequired,
  fetchDocumentos: PropTypes.func.isRequired,
  fetchCategorias: PropTypes.func.isRequired,
  addBreadcrumb: PropTypes.func.isRequired,
  setBreadcrumb: PropTypes.func.isRequired,
  destinationPasta: PropTypes.object,
  setPastaClicked: PropTypes.func.isRequired,
  pastaClicked: PropTypes.bool.isRequired,
  documentosAreLoading: PropTypes.bool.isRequired,
  documentosLoaded: PropTypes.bool.isRequired,
  objectosTreeFromPastaAreLoading: PropTypes.bool.isRequired,
  categoriasIsLoading: PropTypes.bool.isRequired,
  setCategoriasLoaded: PropTypes.func.isRequired,
  categoriasLoaded: PropTypes.bool.isRequired,
  currentPastaContentIsLoading: PropTypes.bool.isRequired,
  documentsTreeRoot: PropTypes.object
}

const mapStateToProps = state => ({
  currentPasta: state.pastas.currentPasta,
  currentPastaContent: state.pastas.documentsTree,
  documentsTreeRoot: state.pastas.documentsTreeRoot,
  treeSelectedPastaId: state.pastas.treeSelectedPastaId,
  lang: state.linguas.lang,
  path: state.pastas.path,
  selectedCategorias: state.categorias.selectedCategorias,
  currentObjectType: state.categorias.currentObjectType,
  destinationPasta: state.pastas.destinationPasta,
  pastaClicked: state.documentos.pastaClicked,
  documentosAreLoading: state.documentos.documentosAreLoading,
  documentosLoaded: state.documentos.documentosLoaded,
  objectosTreeFromPastaAreLoading: state.pastas.objectosTreeFromPastaAreLoading,
  categoriasIsLoading: state.categorias.isLoading,
  categoriasLoaded: state.categorias.categoriasLoaded,
  currentPastaContentIsLoading: state.pastas.currentPastaContentIsLoading
})

const mapDispatchToProps = { toggleTreeExpandedPasta, initPath, fetchCurrentPastaContent, fetchCategorias, resetSelectedCategorias, setCurrentObjType, fetchDocumentos, addBreadcrumb, setBreadcrumb, setPastaClicked, setCategoriasLoaded }

export default connect(mapStateToProps, mapDispatchToProps)(Documentos)
