import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import styles from './CategoriasObjecto.css'
import { setCategorias, setFilteredCategorias, computeSelectedCategorias } from '../../../modules/categorias'
import { fetchDocumentos } from '../../../modules/documentos'
import { intelliMatchTextToTerm } from '../../../lib/utils'

class CategoriasObjecto extends Component {
  constructor(props) {
    super(props)
    this.tagOnClick = this.tagOnClick.bind(this)
  }

  render() {
    const { filteredCategorias } = this.props
    return (
      <div>
        {this.buildCategoriasList(filteredCategorias)}
      </div>
    )
  }
  buildCategoriasList(categorias) {
    const { initialTagsCount, moreTags, filterTagsText } = this.props
    // filtrar categorias pelo texto
    let cats = []
    if (filterTagsText !== '' && filterTagsText !== null) {
      cats = this.filterCategorias(categorias, filterTagsText)
    } else {
      cats = categorias.sort((a, b) => {
        return (a.score > b.score ? -1 : 1)
      })
    }
    return (
      <ul className={styles.objectListContainer}>
        {
          cats.map((obj, index) => (
            !moreTags || initialTagsCount > index
              ? <li className={styles.li} key={obj.categoria.id}>
                <div
                  className={styles.tagContainerGrid + (obj.selected
                    ? ' ' + styles.selected
                    : '')}
                  onClick={(e) => this.tagOnClick(obj)}>
                  <div className={styles.tagNameDiv + (obj.selected
                    ? ' ' + styles.selected
                    : '')}>
                    <span className={styles.tagTextSpan}>{obj.categoria.nome}</span>
                  </div>
                  <span className={styles.objects}>{obj.objectos}</span>
                  {obj.selected ? <div className={styles.marked} /> : null}
                </div>
              </li>
              : null
          ))
        }
      </ul>
    )
  }

  sortCategorias(categorias) {
    return categorias.sort((a, b) => {
      return (a.score > b.score
        ? 1
        : -1)
    })
  }

  filterCategorias(categorias, filter) {
    let result = []
    categorias.forEach(obj => {
      const match = intelliMatchTextToTerm(obj.categoria.nome, filter)
      if (match !== false) {
        obj.match = match
        result.push(obj)
      }
    })
    result.sort((a, b) => {
      return (a.match.score > b.match.score
        ? 1
        : (a.match.score < b.match.score
          ? -1
          : (a.match.first > b.match.first
            ? 1
            : -1)))
    })
    return result
  }

  tagOnClick(obj) {
    const { categorias, filteredCategorias } = this.props
    if (!obj.selected) {
      obj.selected = true
    } else {
      obj.selected = false
    }
    categorias.forEach(cat => {
      if (cat.categoria.id === obj.categoria.id) {
        cat.selected = obj.selected
      }
    })
    const catsCopy = [...categorias]
    this.props.setCategorias(catsCopy)
    const filteredCatsCopy = [...filteredCategorias]
    this.props.setFilteredCategorias(filteredCatsCopy)
    this.props.computeSelectedCategorias(filteredCatsCopy)
  }
}

CategoriasObjecto.propTypes = {
  lang: PropTypes.string.isRequired,
  setCategorias: PropTypes.func.isRequired,
  setFilteredCategorias: PropTypes.func.isRequired,
  categorias: PropTypes.array.isRequired,
  initialTagsCount: PropTypes.number.isRequired,
  moreTags: PropTypes.bool.isRequired,
  filterTagsText: PropTypes.string,
  filteredCategorias: PropTypes.array.isRequired,
  computeSelectedCategorias: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  categorias: state.categorias.categorias,
  initialTagsCount: state.categorias.initialTagsCount,
  moreTags: state.categorias.moreTags,
  filterTagsText: state.categorias.filterTagsText,
  lang: state.linguas.lang,
  filteredCategorias: state.categorias.filteredCategorias
})

const mapDispatchToProps = { setCategorias, setFilteredCategorias, computeSelectedCategorias }

export default connect(mapStateToProps, mapDispatchToProps)(CategoriasObjecto)
