import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import commonStyles from './CommonStyles.css'
import { setFormInstance, saveFormInstance, setStuffToSave } from '../../../modules/formulario'
import Label from './Label'
import styles from './FormTableField.css'
import { generateUUID } from '../../../request'

class FormTableField extends Component {
  constructor(props) {
    super(props)
    this.changed = false
    this.saveRef = React.createRef()
  }
  
  render () {
    const { field, lang, formInstance } = this.props
    return (
      <div className={commonStyles.labelWrapper}>
        <Label {...this.props} />
        <div className={styles.boxWrapper}>
          {this.buildTable(field, lang, formInstance)}
        </div>
      </div>
    )
  }

  buildTable (field, lang, formInstance) {
    if (!field || !lang || !formInstance) {
      return null
    }
    return (
      <table className={styles.table}>
        <thead>
          <tr>
            <th style={{ width: '0px' }} />
            {field.values.map(col => {
              if (lang === col.lang) {
                return (
                  <th key={col.value}>{col.label}</th>
                )
              }
            })}
            {/* <th ref={this.saveRef} className={styles.saveTable}
              onClick={() => this.saveTable()} /> */}
            <th ref={this.saveRef} />
          </tr>
        </thead>
        <tbody>
          {this.buildRows(field, lang, formInstance)}
          {this.buildFooter(field, lang, formInstance)}
        </tbody>
      </table>
    )
  }

  buildRows (field, lang, formInstance) {
    if (!field || !lang || !formInstance) {
      return null
    }
    if (!formInstance.values) {
      return null
    }
    let values = this.getValues(formInstance, field)
    if (!values) {
      return null
    }
    return (
      values.rows.map((row, index) => (
        this.buildRow(row, values.rows, index)
      ))
    )
  }

  getValues (formInstance, field) {
    for (let index = 0; index < formInstance.values.length; index++) {
      const element = formInstance.values[index]
      if (element.fieldId === field.id) {
        return element
      }
    }
    return null
  }

  buildRow (row, rows, index) {
    return (
      <tr key={row.id}>
        <td key='0' />
        {
          row.keyValues.map(cell => (
            this.buildCell(cell)
          ))
        }
        <td key='delete' className={styles.deleteRow} style={{ width: '36px' }}
          onClick={() => this.deleteRow(rows, index)} />
      </tr>
    )
  }

  deleteRow (rows, index) {
    rows.splice(index, 1)
    this.changed = true
    this.updateSaveButton()
    this.forceUpdate()
  }

  buildCell (cell) {
    let ref = React.createRef()
    return (
      <td key={cell.id}>
        <input ref={ref} className={styles.input}
          defaultValue={cell.value}
          onChange={() => this.changeCell(cell, ref)} />
      </td>
    )
  }

  changeCell (cell, ref) {
    cell.value = ref.current.value
    this.changed = true
    this.updateSaveButton()
  }

  updateSaveButton () {
    this.props.setStuffToSave(true)
    // if (this.changed) {
    //   this.saveRef.current.className = styles.saveTable + ' ' + styles.activo
    // } else {
    //   this.saveRef.current.className = styles.saveTable
    // }
  }

  buildFooter (field, lang, formInstance) {
    if (!field || !lang || !formInstance) {
      return null
    }
    let cols = 0;
    for (let index = 0; index < field.values.length; index++) {
      const element = field.values[index];
      if (element.lang === lang) {
        cols++
      }
    }
    return (
      <tr>
        <td style={{ width: '0px' }} />
        {/* {field.values.map(col => {
          if (lang === col.lang) {
            let cell = {
              id: generateUUID(),
              key: col.value,
              value: ''
            }
            row.keyValues.push(cell)
            let ref = React.createRef()
            refs.push(ref)
            return (
              <td key={col.value}>
                <input ref={ref} type='text' className={styles.input}
                  onChange={() => this.changeCell(cell, ref)} />
              </td>
            )
          }
        })} */}
        <td colSpan={cols} />
        <td style={{ width: '36px' }}>
          <div className={styles.addRow} onClick={() => this.addNewRow(field, lang)} /></td>
        {/* <div className={styles.addRow} onClick={() => this.addRow(row, refs)} /></td> */}
      </tr>
    )
  }

  addNewRow (field, lang) {
    const { formInstance } = this.props
    let row = { id: generateUUID(), keyValues: [] }
    let values = this.getValues(formInstance, field)
    if (!values) {
      let formInstanceValue = this.initNewFormInstanceField()
      formInstance.values.push(formInstanceValue)
      values = this.getValues(formInstance, field)
    }
    field.values.map(col => {
      if (lang === col.lang) {
        let cell = {
          id: generateUUID(),
          key: col.value,
          value: ''
        }
        row.keyValues.push(cell)
      }
    })
    values.rows.push(row)
    this.forceUpdate()
  }

  addRow (row, refs) {
    const { formInstance } = this.props
    if (!row || !row.keyValues.length) {
      return
    }
    let formInstanceValue = this.getFormInstanceFieldValue()
    if (!formInstanceValue) {
      formInstanceValue = this.initNewFormInstanceField()
      formInstance.values.push(formInstanceValue)
    }
    formInstanceValue.rows.push(row)
    let values = []
    values.push(formInstanceValue)
    this.props.setFormInstance(formInstance)
    this.props.saveFormInstance(formInstance.id, values)
    this.changed = false
    this.updateSaveButton()
    refs.forEach(ref => {
      ref.current.value = null
    })
    this.forceUpdate()
  }

  saveTable () {
    if (!this.changed) {
      return
    }
    const { formInstance } = this.props
    let formInstanceValue = this.getFormInstanceFieldValue()
    if (!formInstanceValue) {
      formInstanceValue = this.initNewFormInstanceField()
    }
    let values = []
    values.push(formInstanceValue)
    this.props.setFormInstance(formInstance)
    this.props.saveFormInstance(formInstance.id, values)
    this.changed = false
    this.updateSaveButton()
    this.forceUpdate()
  }

  initNewFormInstanceField () {
    const { field } = this.props
    return { fieldId: field.id, key: null, value: null, rows: [] }
  }

  getFormInstanceFieldValue () {
    const { field, formInstance } = this.props
    if (!formInstance) {
      return null
    }
    for (let index = 0; index < formInstance.values.length; index++) {
      const element = formInstance.values[index]
      if (element.fieldId === field.id) {
        return element
      }
    }
    return null
  }

}

FormTableField.propTypes = {
  lang: PropTypes.string.isRequired,
  formInstance: PropTypes.object,
  setFormInstance: PropTypes.func.isRequired,
  saveFormInstance: PropTypes.func.isRequired,
  setStuffToSave: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  formInstance: state.formulario.formInstance,
  lang: state.linguas.lang
})

const mapDispatchToProps = { setFormInstance, saveFormInstance, setStuffToSave }

export default connect(mapStateToProps, mapDispatchToProps)(FormTableField)
