import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { FormattedMessage as FM } from 'react-intl'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { Modal, Button } from 'react-bootstrap'

import { fetchFormInstances } from '../../modules/formulario'
import { getTranslation, getStatus, host } from '../../request'
import { prettyDateShort, getDateTime } from '../../lib/utils'
import msg from '../App/messages'
import styles from './FormsList.css'

class FormsList extends Component {
  constructor(props) {
    super(props)
    this.state = { showRemoveForm: false }
    this.closeDelete = this.closeDelete.bind(this)
    this.openDelete = this.openDelete.bind(this)
    this.removeForm = this.removeForm.bind(this)
  }

  componentDidMount() {
    this.props.fetchFormInstances()
  }

  render() {
    const { formInstances, lang } = this.props
    return (
      <div className={styles.container}>
        <h3 className={styles.h3}><FM id='backoffice.forms' defaultMessage='Formulários' /></h3>
        {this.buildList(formInstances, lang)}
        <Modal show={this.state.showRemoveForm} onHide={this.closeDelete}>
          <Modal.Header closeButton>
            <Modal.Title><FM id='backoffice.deleteFormTitle' defaultMessage='Remoção de formulário' /></Modal.Title>
          </Modal.Header>
          <Modal.Body><FM id='backoffice.deleteFormBody' defaultMessage='Confirma operação?' /></Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={this.closeDelete}>Não</Button>
            <Button variant='primary' onClick={this.removeForm}>Sim</Button>
          </Modal.Footer>
        </Modal>
      </div>
    )
  }

  closeDelete() {
    this.setState({ ...this.state, showRemoveForm: false })
  }

  openDelete() {
    this.setState({ ...this.state, showRemoveForm: true })
  }

  compare(a, b) {
    let dateA = a.creationDate
    if (a.log && a.log.length > 0) {
      dateA = a.log[a.log.length - 1].data
    }
    let dateB = b.creationDate
    if (b.log && b.log.length > 0) {
      dateB = b.log[b.log.length - 1].data
    }
    if (dateA > dateB) {
      return -1
    }
    return 1
  }

  buildList(formInstances, lang) {
    let instances = [...formInstances]
    instances = instances.sort(this.compare)
    // let instances = formInstances
    return (
      <div>
        <div className={styles.mainGrid}>
          <div><FM id='global.name' defaultMessage='Nome' /></div>
          <div><FM id='global.date' defaultMessage='Data' /></div>
          <div><FM id='global.status' defaultMessage='Estado' /></div>
          <div><FM id='global.actions' defaultMessage='Acções' /></div>
          <div />
        </div>
        {
          instances.map((item) => {
            let style = null
            // if (item.detailExpanded) {
            //   style = { maxHeight: ((item.log.length * 70) + 100) + 'px' }
            // }
            let extraText = this.getExtraText(item)
            return (
              <div key={item.id}
                className={styles.formInstanceContainer + (extraText !== null ? ' ' + styles.extraText : '')}
                // className={styles.formInstanceContainer}
                style={style}>
                <div
                  className={styles.mainGrid + ' ' + styles.form + (extraText !== null ? ' ' + styles.extraText : '')}>
                  <div className={styles.formName}>{getTranslation(item.formulario.nome, lang)}</div>
                  <div className={styles.formData}>{prettyDateShort(item.creationDate, lang)}</div>
                  <div className={styles.formStatus}>{this.getStatusFiltered(item, lang)}</div>
                  {this.buildActions(item, lang)}
                  <div className={styles.expandContainer}>
                    {
                      this.hasDetails(item)
                        ? <div className={styles.detailIcon + (item.detailExpanded ? '' : ' ' + styles.closed)}
                          onClick={() => this.toggleDetail(item)} />
                        : null
                    }
                  </div>
                  {
                    extraText !== null
                      ? <div className={styles.extraTextPuro}>{extraText}</div>
                      : null
                  }
                </div>
                {this.showDetail(item, lang, item.detailExpanded)}
              </div>
            )
          })
        }
      </div >
    )
  }

  getExtraText(formInstance) {
    if (!formInstance) {
      return null
    }
    let field = null
    for (let index = 0; index < formInstance.formulario.campos.length; index++) {
      const campo = formInstance.formulario.campos[index];
      if (campo.required && (campo.type === 'inputText' || campo.type === 'textArea')) {
        field = campo
        break
      }
    }
    if (field) {
      for (let i = 0; i < formInstance.values.length; i++) {
        const value = formInstance.values[i];
        if (value.fieldId === field.id) {
          return value.value
        }
      }
    }
    return null
  }

  getStatusFiltered(item, lang) {
    if (item.status === 5) {
      return getStatus(item.log[item.log.length - 2].status, lang)
    }
    return getStatus(item.status, lang)
  }

  hasDetails(item) {
    return !(!item || !item.log || item.log.length === 0)
  }

  showDetail(item, lang, expanded) {
    let log = item.log.filter(entry => {
      return (entry.status !== 5)
    })
    // let log = item.log
    return (
      <div className={styles.detailsContainer + (expanded ? ' ' + styles.expanded : '')}>
        {
          log.map(log => (
            // <div key={log.id} className={styles.detailDiv + (!expanded ? ' ' + styles.hide : '')}>
            <div key={log.id} className={styles.detailDiv}>
              <div className={styles.itemIcon + ' ' + (this.statusIn(log.status) ? styles.in : styles.out)} />
              <div className={styles.itemDate}>{getDateTime(log.data, lang)}</div>
              <div className={styles.itemStatus}>{getStatus(log.status, lang)}</div>
              <div className={styles.itemUsername}>{log.username}</div>
              <div className={styles.itemObs}>{log.obs}</div>
            </div>
          ))
        }
      </div>
    )
  }

  statusIn(status) {
    switch (status) {
      case 0:
      case 1:
        return false
      default:
        return true
    }
  }

  toggleDetail(item) {
    if (!this.hasDetails(item)) {
      return
    }
    item.detailExpanded = !item.detailExpanded
    this.forceUpdate()
  }

  buildActions(formInstance, lang) {
    switch (formInstance.status) {
      case 1: // submetido
        return (
          <span className={styles.actions}>
            <Link to={formInstance.link + '/resumo'} className={styles.action}>
              {msg[lang]['form.brief']}
            </Link>
          </span>
        )
      case 2: // aprovado
        return (
          <span className={styles.actions}>
            <Link to={formInstance.link + '/resumo'} className={styles.action}>
              {msg[lang]['form.brief']}
            </Link>
          </span>
        )
      case 3: // rejeitado
        return (
          <span className={styles.actions}>
            <Link to={formInstance.link + '/resumo'} className={styles.action}>
              {msg[lang]['form.brief']}
            </Link>
          </span>
        )
      case 4: // devolvido
        return (
          <span className={styles.actions}>
            <Link to={formInstance.link + '/resumo'} className={styles.action}>
              {msg[lang]['form.brief']}
            </Link>
            <Link to={formInstance.link} className={styles.action}>
              {msg[lang]['form.reply']}
            </Link>
          </span>
        )
      case 5: // arquivado
        return (
          <span className={styles.actions}>
            <Link to={formInstance.link + '/resumo'} className={styles.action}>
              {msg[lang]['form.brief']}
            </Link>
          </span>
        )
      case 6: // em análise
        return (
          <span className={styles.actions}>
            <Link to={formInstance.link + '/resumo'} className={styles.action}>
              {msg[lang]['form.brief']}
            </Link>
          </span>
        )
      default: // em progresso
        return (
          <span className={styles.actions}>
            <Link to={formInstance.link + '/resumo'} className={styles.action}>
              {msg[lang]['form.brief']}
            </Link>
            <Link to={formInstance.link} className={styles.action}>
              {msg[lang]['form.resume']}
            </Link>
            <a className={styles.action} href='#' onClick={(e) => this.clickRemoveFormInstance(e, formInstance.id, formInstance.formulario.id)}>
              {msg[lang]['form.remove']}
            </a>
          </span>
        )
    }
  }

  removeForm() {
    const { formInstanceId, formId } = this.state

    axios.delete(host + '/api/user/formInstance/' + formInstanceId)
      .then(() => {
        this.props.fetchFormInstances()
      })
      .catch((error) => {
        console.log(error)
      })
    this.setState({ ...this.state, showRemoveForm: false, formInstanceId: null, formId: null })
  }

  clickRemoveFormInstance(e, formInstanceId, formId) {
    e.preventDefault()
    this.setState({ ...this.state, showRemoveForm: true, formInstanceId, formId })
  }
}

FormsList.propTypes = {
  lang: PropTypes.string.isRequired,
  formInstances: PropTypes.array.isRequired,
  fetchFormInstances: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  lang: state.linguas.lang,
  formInstances: state.formulario.formInstances
})

const mapDispatchToProps = { fetchFormInstances }

export default connect(mapStateToProps, mapDispatchToProps)(FormsList)
