import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import Content from './Content'
import Gallery from './Gallery'
import { host, getTranslation } from '../../../request'
import { addMedia } from '../../../modules/gallery'
import { addItem } from '../../../modules/popup'

class Contents extends Component {
  componentDidMount () {
    const { contents, lang } = this.props
    if (!contents || !lang) {
      return
    }
    contents.map(content => {
      if (content.mediaFile && (content.embed || content.galleryStyle)) {
        let media = {
          id: content.id,
          text: (content.text === null ? null : getTranslation(content.text, lang)),
          file: this.getFile(content.mediaFile),
          imageSize: content.imageSize,
          filename: content.mediaFile.filename
        }
        if (content.galleryStyle) {
          this.props.addMedia(media)
        }
        this.props.addItem(media)
      }
    })
  }

  render () {
    const { contents } = this.props
    if (!contents) {
      return (
        <div />
      )
    }
    return (
      <div>
        {contents.map((content) => (
          <Content key={content.id} content={content} />
        ))}
        <Gallery />
      </div>
    )
  }

  getFile (mediaFile) {
    return host + '/api/public/mediaFile/' + mediaFile.id
  }
}

Contents.propTypes = {
  lang: PropTypes.string.isRequired,
  addMedia: PropTypes.func.isRequired,
  addItem: PropTypes.func.isRequired,
  contents: PropTypes.array
}

const mapStateToProps = state => ({
  lang: state.linguas.lang,
  contents: state.model.modelContents
})

const mapDispatchToProps = { addMedia, addItem }

export default connect(mapStateToProps, mapDispatchToProps)(Contents)
