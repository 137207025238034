import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import styles from './EventosMeses.css'
import msg from '../App/messages'
import { setStartDate, toogleMonths, setMonthsShown } from '../../modules/events'

class EventosMeses extends Component {
  constructor(props) {
    super(props)
    this.scrollMonthsRight = this.scrollMonthsRight.bind(this)
    this.scrollMonthsLeft = this.scrollMonthsLeft.bind(this)
    this.setDay = this.setDay.bind(this)
    this.setRefDates()
  }

  componentDidUpdate () {
    const { mobile } = this.props
    if (mobile) {
      this.props.setMonthsShown(2)
    } else {
      this.props.setMonthsShown(6)
    }
  }
  render () {
    const { startDay, startMonth, startYear, monthsShown, lang } = this.props
    // const months = [msg[lang].january, msg[lang].february, msg[lang].march, msg[lang].april, msg[lang].may, msg[lang].june, msg[lang].july, msg[lang].august, msg[lang].september, msg[lang].october, msg[lang].november, msg[lang].december]
    const months = [msg[lang].shortJan, msg[lang].shortFeb, msg[lang].shortMar, msg[lang].shortApr, msg[lang].shortMay, msg[lang].shortJun, msg[lang].shortJul, msg[lang].shortAug, msg[lang].shortSep, msg[lang].shortOct, msg[lang].shortNov, msg[lang].shortDec]
    let startDate = new Date(this.refDate)
    let currentDate = new Date(startYear, startMonth, startDay)
    this.currentDay = new Date(currentDate)
    if (startDate.getTime() <= currentDate.getTime()) {
    } else {
      startDate = new Date(currentDate)
    }
    let liMonths = this.buildAllMonthsDiv(this.refDate, currentDate, monthsShown, months)
    if (this.refDate.getTime() > currentDate.getTime()) {
      setTimeout(() => {
        this.scrollMonthsRight()
      }, 100)
    }
    if (this.refDate.getTime() < currentDate.getTime()) {
      setTimeout(() => {
        this.scrollMonthsLeft()
      }, 250)
    }
    this.setRefDates()
    return (
      <div id='divMeses' className={styles.ulMonthsContainer}>
        <ul id='ulMeses' className={styles.monthsContainer + ' ' + styles.list}>
          {liMonths}
        </ul>
      </div>
    )
  }

  scrollMonthsRight () {
    let div = document.getElementById('divMeses')
    let ul = document.getElementById('ulMeses')
    let distance = ul.offsetWidth - div.offsetWidth + 50
    div.scrollLeft = ul.offsetWidth - div.offsetWidth + 50
    distance = div.scrollLeft
    for (let i = 1; i <= distance; i++) {
      setTimeout(() => {
        div.scrollLeft -= 1
      }, i + 20)
    }
    setTimeout(() => {
      this.props.toogleMonths()
    }, distance + 20)
  }

  scrollMonthsLeft () {
    let div = document.getElementById('divMeses')
    let ul = document.getElementById('ulMeses')
    let distance = ul.offsetWidth - div.offsetWidth + 50
    let steps = 200
    let pixels = distance / steps
    div.scrollLeft = 0
    if (pixels < 1) {
      pixels = 1
      steps = distance
    }
    pixels = Math.ceil(pixels)
    for (let i = 1; i < steps; i++) {
      setTimeout(() => {
        div.scrollLeft += pixels
      }, i * 2 + 10)
    }
    setTimeout(() => {
      this.props.toogleMonths()
    }, steps + 200)
  }

  buildAllMonthsDiv (startDate, newDate, monthsShown, months) {
    let startDate1
    let newDate1
    if (startDate.getTime() <= newDate.getTime()) {
      startDate1 = new Date(startDate)
      startDate1.setDate(1)
      newDate1 = new Date(newDate)
      newDate1.setDate(1)
      let endDateRange = newDate1
      endDateRange.setMonth(endDateRange.getMonth() + monthsShown)
      let diffMonths = this.monthDiff(startDate, endDateRange)
      return this.buildMonthsDiv(startDate.getMonth(), diffMonths, months)
    } else {
      startDate1 = new Date(newDate)
      startDate1.setDate(1)
      newDate1 = new Date(startDate)
      newDate1.setDate(1)
      let endDateRange = newDate1
      endDateRange.setMonth(endDateRange.getMonth() + monthsShown)
      let diffMonths = this.monthDiff(newDate, endDateRange)
      return this.buildMonthsDiv(newDate.getMonth(), diffMonths, months)
    }
  }

  buildMonthsDiv (startMonth, monthsShown, months) {
    let arr = []
    const { startYear } = this.props
    let startDate = new Date(startYear, startMonth, 1)
    for (let j = 0; j < monthsShown; j++) {
      let index = j + startMonth
      if (index >= months.length) {
        index = index - months.length
      }
      let date = new Date(startDate)
      date.setMonth(startDate.getMonth() + j)
      let currMonth = false
      if (date.getMonth() === this.currentDay.getMonth()) {
        currMonth = true
      }
      if (this.monthInEventMonths(date.getFullYear(), date.getMonth() + 1)) {
        arr.push(<li data-year={date.getFullYear()} data-month={date.getMonth()} data-day={date.getDate()} className={styles.liMonth + (currMonth ? ' ' + styles.current : '') + ' ' + styles.selectedMonth} key={date.getMonth()} data-index={index} onClick={this.setDay}>{this.formatMonth(months[index], date.getFullYear())}</li>)
      } else {
        arr.push(<li data-year={date.getFullYear()} data-month={date.getMonth()} data-day={date.getDate()} className={styles.liMonth + (currMonth ? ' ' + styles.current : '')} key={date.getMonth()} data-index={index} onClick={this.setDay}>{this.formatMonth(months[index], date.getFullYear())}</li>)
      }
    }
    return arr
  }

  formatMonth (name, year) {
    return name + '\'' + year.toString().substr(2, 2)
  }

  monthInEventMonths (year, month) {
    const { eventMonths } = this.props
    let result = false
    eventMonths.forEach(element => {
      if (element.ano === year && element.mes === month) {
        result = true
      }
    })
    return result
  }

  setRefDates () {
    const { startDay, startMonth, startYear } = this.props
    this.refDate = new Date(startYear, startMonth, startDay)
  }

  monthDiff (d1, d2) {
    let months
    months = (d2.getFullYear() - d1.getFullYear()) * 12
    months -= d1.getMonth()
    months += d2.getMonth()
    return months <= 0 ? 0 : months
  }

  setDay (e) {
    let day = parseInt(e.target.dataset.day, 0)
    let month = parseInt(e.target.dataset.month, 0)
    let year = parseInt(e.target.dataset.year, 0)
    this.props.setStartDate({ year: year, month: month, day: day })
  }
}

EventosMeses.propTypes = {
  lang: PropTypes.string.isRequired,
  startDay: PropTypes.number.isRequired,
  startMonth: PropTypes.number.isRequired,
  startYear: PropTypes.number.isRequired,
  monthsShown: PropTypes.number.isRequired,
  setStartDate: PropTypes.func.isRequired,
  toogleMonths: PropTypes.func.isRequired,
  eventMonths: PropTypes.array.isRequired,
  mobile: PropTypes.bool.isRequired,
  setMonthsShown: PropTypes.func.isRequired,
  monthChanged: PropTypes.bool.isRequired
}

const mapStateToProps = state => ({
  lang: state.linguas.lang,
  startDay: state.events.startDay,
  startMonth: state.events.startMonth,
  startYear: state.events.startYear,
  monthsShown: state.events.monthsShown,
  eventMonths: state.events.eventMonths,
  mobile: state.global.mobile,
  monthChanged: state.events.monthChanged
})

const mapDispatchToProps = { setStartDate, toogleMonths, setMonthsShown }

export default connect(mapStateToProps, mapDispatchToProps)(EventosMeses)
