import React from 'react'
import { compose, withProps } from 'recompose'
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from 'react-google-maps'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { apiKey } from '../../../lib/utils'
import { setClickedLocal } from '../../../modules/locais'
import pinAzul from '../../../img/pinazulclaro.svg'
import pinVermelho from '../../../img/pinvermelho.svg'
// import styles from './MyListMapComponent.css'

const MyListMapComponent = compose(
  withProps({
    googleMapURL: 'https://maps.googleapis.com/maps/api/js?key=' + apiKey + '&v=3.exp&libraries=geometry,drawing,places',
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `400px` }} />,
    mapElement: <div style={{ height: `100%` }} />
  }),
  withScriptjs,
  withGoogleMap
)((props) => {
  const { locais, clickedLocal } = props
  let zoom = 12
  let lat = 39.411705
  let lng = -31.198887
  const onMarkerClick = (e, local) => {
    if (clickedLocal !== null && local.id === clickedLocal.id) {
      props.setClickedLocal(null)
    } else {
      props.setClickedLocal(local)
    }
  }
  const mapStyles = [
    {
      featureType: 'water',
      elementType: 'geometry.fill',
      stylers: [{ color: '#0c598e' }]
    }
  ]
  return (
    <GoogleMap
      defaultOptions={{ styles: mapStyles }}
      defaultZoom={zoom}
      defaultCenter={{ lat: lat, lng: lng }}>
      {locais.map((local) => {
        if (!local.latInicio) {
          return null
        }
        if (clickedLocal !== null && local.id === clickedLocal.id) {
          return (
            <Marker
              key={local.id}
              position={{ lat: local.latInicio, lng: local.lngInicio }}
              icon={{
                url: pinVermelho,
                strokeColor: 'blue',
                scaledSize: new google.maps.Size(40, 40)
              }}
              onClick={(e) => onMarkerClick(e, local)} />
          )
        } else {
          return (
            <Marker
              key={local.id}
              position={{ lat: local.latInicio, lng: local.lngInicio }}
              icon={{
                url: pinAzul,
                strokeColor: 'blue',
                scaledSize: new google.maps.Size(40, 40)
              }}
              onClick={(e) => onMarkerClick(e, local)} />
          )
        }
      })}
    </GoogleMap>
  )
}
)

MyListMapComponent.propTypes = {
  setClickedLocal: PropTypes.func.isRequired,
  clickedLocal: PropTypes.object
}

const mapStateToProps = state => ({
  clickedLocal: state.locais.clickedLocal
})

const mapDispatchToProps = { setClickedLocal }

export default connect(mapStateToProps, mapDispatchToProps)(MyListMapComponent)
