import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import common from '../common/Body.css'
import spinner from '../../../css/Spinner.css'
import { filterCategoriasFromObjectos } from '../../../modules/categorias'
import { setResultSize } from '../../../modules/navigation'
import NavObjects from '../common/NavObjects'
import ModelListItem from '../common/ModelListItem'

class ServicosList extends Component {
  componentDidMount () {
    this.noShadow = true
  }

  componentDidUpdate () {
    const { categorias, servicos, filterCategorias } = this.props
    let online = this.props.online === 'true'
    let array = servicos.filter(obj => {
      if (obj !== null && obj !== undefined && obj.online !== undefined) {
        return obj.online === online
      } else {
        return obj !== null && obj !== undefined && obj.obj !== null && obj.obj.online === online
      }
      // return obj !== null && obj !== undefined && obj.obj !== null && obj.obj.online === online
    })
    this.props.setResultSize(array.length)
    if (filterCategorias) {
      this.props.filterCategoriasFromObjectos(array, categorias)
    }
  }

  render () {
    const { short, objectosTreeFromPastaAreLoading } = this.props
    if (objectosTreeFromPastaAreLoading) {
      return (
        <div className={common.listContainer + (short ? ' ' + common.short : '')}>
          <div className={common.listGrid}>
            <div className={spinner.loader} />
          </div>
        </div>
      )
    }
    let objectos = this.filterObjects()
    return (
      <div className={common.listContainer + (short ? ' ' + common.short : '')}>
        <div className={common.listGrid}>
          {this.buildObjectList(objectos, short)}
        </div>
        <NavObjects />
      </div>
    )
  }

  filterObjects () {
    const { currentPage, servicos, pageSize } = this.props
    let online = this.props.online === 'true'
    let array = servicos.filter(obj => {
      if (obj !== null && obj !== undefined && obj.online !== undefined) {
        return obj.online === online
      } else {
        return obj !== null && obj !== undefined && obj.obj !== null && obj.obj.online === online
      }
    })
    return this.getListSubSet(array, currentPage, pageSize)
  }

  buildObjectList (objectos, short) {
    const { pageSize } = this.props
    return (
      <ul className={common.ul}>
        {
          this.buildLines(objectos, pageSize, short)
        }
      </ul>
    )
  }

  buildLines (objectos, pageSize, short) {
    const { model } = this.props
    const fakeLines = 0 // pageSize - objectos.length
    let list = []
    objectos.map((obj, index) => {
      let isModel = model && model.id === obj.id
      list.push(<ModelListItem key={index} obj={obj} index={index} isModel={isModel} short={short} />)
    })
    if (fakeLines > 0) {
      for (let index = 0; index < fakeLines; index++) {
        list.push(
          <li className={!short ? common.liFake : common.liFakeShort} key={'fake-' + index} />
        )
      }
    }
    return list
  }

  getListSubSet (arr, currentPage, pageSize) {
    const start = (currentPage - 1) * pageSize
    const end = start + pageSize
    if (arr.length < end) {
      return arr.slice(start)
    }
    return arr.slice(start, end)
  }
}

ServicosList.propTypes = {
  servicos: PropTypes.array.isRequired,
  categorias: PropTypes.array.isRequired,
  currentPage: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  model: PropTypes.object,
  setResultSize: PropTypes.func.isRequired,
  lang: PropTypes.string.isRequired,
  filterCategoriasFromObjectos: PropTypes.func.isRequired,
  objectosTreeFromPastaAreLoading: PropTypes.bool.isRequired
}

const mapStateToProps = state => ({
  servicos: state.servicos.servicos,
  categorias: state.categorias.categorias,
  currentPage: state.navigation.currentPage,
  pageSize: state.navigation.pageSize,
  model: state.model.model,
  lang: state.linguas.lang,
  objectosTreeFromPastaAreLoading: state.pastas.objectosTreeFromPastaAreLoading
})

const mapDispatchToProps = { setResultSize, filterCategoriasFromObjectos }

export default connect(mapStateToProps, mapDispatchToProps)(ServicosList)
