/* eslint-env browser */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { FormattedMessage as FM } from 'react-intl'

import { setCurrentPage, setPages, setBackofficeVisited } from '../../modules/backoffice'
import FormsList from './FormsList'
import { kc } from '../../request'

class Backoffice extends Component {
  constructor(props) {
    super(props)
    this.clickForms = this.clickForms.bind(this)
    this.clickInfo = this.clickInfo.bind(this)
    this.props.setBackofficeVisited()
    this.props.setPages([
      { label: 'Formulários', fm: 'backoffice.forms', action: this.clickForms },
      { label: 'Informação', fm: 'backoffice.info', action: this.clickInfo }
    ])
  }

  componentDidUpdate () {
    const { user } = this.props
    if (user && user.isAuthGov) {
      return
    }
    if (!kc.authenticated) {
      kc.login()
    }
  }

  render () {
    const { user } = this.props
    if (!user || !user.isAuthGov) {
      if (!kc.authenticated) {
        return null
      }
    }
    return (
      <div>
        {/* {this.buildOptionsList()} */}
        {this.displayPage()}
      </div>
    )
  }

  displayPage () {
    const { currentPage } = this.props
    switch (currentPage) {
      case 0:
        return <FormsList />
      default:
        return null
    }
  }

  buildOptionsList () {
    const { currentPage, pages } = this.props
    return (
      <ul>
        {
          pages.map((page, index) => {
            return (
              <li key={index}>
                <input type='radio' name='page' value={index} checked={index === currentPage} onChange={page.action} />
                <span><FM id={page.fm} defaultMessage={page.label} /></span>
              </li>
            )
          })
        }
      </ul>
    )
  }

  clickForms (e) {
    this.props.setCurrentPage(0)
  }
  clickInfo (e) {
    this.props.setCurrentPage(1)
  }
}

Backoffice.propTypes = {
  lang: PropTypes.string.isRequired,
  pages: PropTypes.array.isRequired,
  currentPage: PropTypes.number.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
  setBackofficeVisited: PropTypes.func.isRequired,
  setPages: PropTypes.func.isRequired,
  user: PropTypes.object
}

const mapStateToProps = state => ({
  lang: state.linguas.lang,
  pages: state.backoffice.pages,
  currentPage: state.backoffice.currentPage,
  user: state.auth.user
})

const mapDispatchToProps = { setCurrentPage, setPages, setBackofficeVisited }

export default connect(mapStateToProps, mapDispatchToProps)(Backoffice)
