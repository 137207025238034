import React, { Component } from 'react'

import styles from './NotFound.css'

export default class NotFound extends Component {
  render () {
    return (
      <div className={styles.body}>
        <h1>Página não encontrada</h1>
      </div>
    )
  }
}
