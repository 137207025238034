/* eslint-env browser */
import React, { Component } from 'react'
import { FaRegFile, FaRegFolder, FaRegFolderOpen } from 'react-icons/fa'

import styles from './TreeViewNode.css'

export default class TreeViewNode extends Component {
  constructor(props) {
    super(props)
    this.toggleChildren = this.toggleChildren.bind(this)
    this.nodeSelected = this.nodeSelected.bind(this)
    this.select = this.select.bind(this)
    this.node = React.createRef()
  }

  componentDidMount () {
    window.addEventListener('select', this.nodeSelected)
  }

  componentWillUnmount () {
    window.removeEventListener('select', this.nodeSelected)
  }

  render () {
    const { data } = this.props
    return (
      <div className={styles.nodeContainer}>
        <div className={styles.nodeRow} style={this.setNodeStyle(data)}>
          <span className={this.computeClass(data)} onClick={this.toggleChildren} />
          {this.writeValueLine(data)}
        </div>
        {data.open ? this.writeSubTree(data) : null}
      </div>
    )
  }

  writeValueLine (node) {
    if (node.selectable === true) {
      return (
        <div className={styles.nodeWrapper + (node.selected ? ' ' + styles.selected : '')}>
          {this.computeIcon(node)}
          <span
            className={styles.nodeName}
            onClick={this.select}>
            {node.value}
          </span>
        </div>
      )
    } else {
      return (
        <div className={styles.nodeWrapper + (node.selected ? ' ' + styles.selected : '')}>
          {this.computeIcon(node)}
          <span className={styles.nodeName + ' ' + styles.noPointer} style={node.style}>{node.value}</span>
        </div>
      )
    }
  }

  selectedStyle (node) {
    if (node.selected) {
      return { backgroundColor: 'gainsboro' }
    }
    return {}
  }

  select (e) {
    let { data, onSelect } = this.props
    let ev = new Event('select')
    ev.initEvent('select', true, true)
    ev.data = data
    window.dispatchEvent(ev)
    data.selected = !data.selected
    onSelect(data)
    this.forceUpdate()
  }

  nodeSelected (e) {
    const { data } = this.props
    if (data.selected && data.key !== e.data.key) {
      data.selected = false
      this.forceUpdate()
    }
  }

  writeSubTree (data) {
    if (!data.children) {
      return null
    }
    const { onSelect } = this.props
    return (
      <ul className={styles.ul}>
        {
          data.children.map(item => (
            <li key={item.key} className={styles.li}>
              <TreeViewNode data={item} onSelect={onSelect} />
            </li>
          ))
        }
      </ul>
    )
  }

  computeIcon (node) {
    if (!node.children || node.children.length === 0) {
      return <FaRegFile />
    }
    if (node.open) {
      return <FaRegFolderOpen />
    }
    return <FaRegFolder />
  }

  setNodeStyle (node) {
    if (!node) {
      return {}
    }
    if (node.display === 'none') {
      return { display: 'none' }
    }
    return {}
  }

  computeClass (node) {
    let open = node && node.open
    let children = node && node.children
    return styles.nodePreicon + ((open && children && children.length) ? ' ' + styles.expanded : children && children.length ? ' ' + styles.collapsed : '')
  }

  toggleChildren (e) {
    const { data } = this.props
    data.open = !data.open && data.children && data.children.length
    this.forceUpdate()
  }
}
