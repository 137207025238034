import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { FormattedMessage as FM } from 'react-intl'
import { Scrollbars } from 'react-custom-scrollbars'

import styles from './TagsToolbar.css'
import CategoriasObjecto from './CategoriasObjecto'
import { toggleMoreTags, setFilterTagsText } from '../../../modules/categorias'
import msg from '../../App/messages'

class TagsToolbar extends Component {
  constructor(props) {
    super(props)
    this.renderThumbHor = this.renderThumbHor.bind(this)
    this.renderThumbVert = this.renderThumbVert.bind(this)
    this.moreTagsClick = this.moreTagsClick.bind(this)
    this.searchTextChanged = this.searchTextChanged.bind(this)
  }

  render () {
    const { objectType, moreTags, categorias, initialTagsCount, lang
    } = this.props
    const showMoreTags = categorias.length > initialTagsCount
    return (
      <div className={styles.layoutGrid + (showMoreTags ? '' : ' ' + styles.hideMoreTags)}>
        <div className={styles.searchLayoutGrid}>
          <div className={styles.searchGrid}>
            <input
              className={styles.inputDiv}
              type='text'
              placeholder={msg[lang]['tags.search']}
              onChange={this.searchTextChanged} />
            <div className={styles.lupaDiv} />
          </div>
        </div>
        {
          showMoreTags
            ? <div className={styles.moreTagsDiv} onClick={this.moreTagsClick}>
              <span className={styles.moreTagsSpan}>
                {moreTags
                  ? <FM id='tags.more' defaultMessage='Mais tags' />
                  : <FM id='tags.less' defaultMessage='Menos tags' />}
              </span>
            </div>
            : null
        }
        <div className={styles.listDiv}>
          <Scrollbars
            autoHeight
            autoHeightMax={300}
            // autoHide
            // autoHideTimeout={1000}
            // autoHideDuration={200}
            renderThumbHorizontal={this.renderThumbHor}
            renderThumbVertical={this.renderThumbVert}>
            <CategoriasObjecto objType={objectType} />
          </Scrollbars>
        </div>
        <div className={styles.divGradTop} />
        <div className={styles.divGradBottom} />
      </div>
    )
  }

  searchTextChanged (e) {
    this.props.setFilterTagsText(e.target.value)
  }

  renderThumbHor ({ style, ...props }) {
    const thumbStyle = {
      backgroundColor: '#9ebdd2',
      borderRadius: '4px',
      overflowX:'hidden'
    }
    return (
      <div
        style={{ ...style, ...thumbStyle }}
        {...props} />
    )
  }

  renderThumbVert ({ style, ...props }) {
    const thumbStyle = {
      minWidth: '8px',
      backgroundColor: '#9ebdd2',
      borderRadius: '4px',
      overflowX:'hidden'
    }
    return (
      <div
        style={{ ...style, ...thumbStyle }}
        {...props} />
    )
  }

  moreTagsClick () {
    this.props.toggleMoreTags()
  }
}

TagsToolbar.propTypes = {
  objectType: PropTypes.string.isRequired,
  toggleMoreTags: PropTypes.func.isRequired,
  moreTags: PropTypes.bool.isRequired,
  categorias: PropTypes.array.isRequired,
  initialTagsCount: PropTypes.number.isRequired,
  setFilterTagsText: PropTypes.func.isRequired,
  lang: PropTypes.string.isRequired
}

const mapStateToProps = state => ({
  objectType: state.toolbar.objectType,
  moreTags: state.categorias.moreTags,
  categorias: state.categorias.filteredCategorias,
  initialTagsCount: state.categorias.initialTagsCount,
  lang: state.linguas.lang
})

const mapDispatchToProps = { toggleMoreTags, setFilterTagsText }

export default connect(mapStateToProps, mapDispatchToProps)(TagsToolbar)
