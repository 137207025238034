import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import ResumoLi from '../modelBlocks/ResumoLi'
import common from './Body.css'
import indexStyles from '../../../index.css'
import { getDateStr, isOdd } from '../../../lib/utils'

class ModelListItem extends Component {
  componentDidMount () {
    this.noShadow = true
  }
  
  render () {
    const { obj, index, isModel, short, lang } = this.props
    if (!obj.image) {
      return this.buildLineWithoutImage(obj, index, lang, isModel, short)
    } else {
      return this.buildLineWithImage(obj, index, lang, isModel, short)
    }
  }

  buildLineWithoutImage (obj, index, lang, isModel, short) {
    const { prettyDate, resumo, titulo } = this.props
    return (
      <li className={(!short ? common.li + ' ' + common.noImage : common.liShort) + (this.noShadow ? ' ' + common.lightBorder : ' ' + indexStyles.shadow) + (isOdd(index) ? ' ' + common.odd : '')} key={obj.id}>
        <Link className={common.liText + (short ? ' ' + common.short : '') + (isModel && short ? ' ' + common.currentModel : '')}
          to={obj.link}>
          <div className={common.textTitle}>
            {titulo || obj.titulo}
          </div>
          <div className={common.textDate}>
            {prettyDate || getDateStr(obj.date, lang)}
          </div>
          {short
            ? null
            : <ResumoLi text={resumo || obj.resumo} />
          }
        </Link>
      </li>
    )
  }

  buildLineWithImage (obj, index, lang, isModel, short) {
    const { prettyDate, resumo, titulo } = this.props
    return (
      <li className={(!short ? common.li : common.liShort) + (this.noShadow ? ' ' + common.lightBorder : ' ' + indexStyles.shadow) + (isOdd(index) ? ' ' + common.odd : '')} key={obj.id}>
        <Link className={common.liImg} to={obj.link}>
          <img className={common.imageFrame + (short ? ' ' + common.short : '')} src={obj.image} />
        </Link>
        <Link className={common.liText + (short ? ' ' + common.short : '') + (isModel && short ? ' ' + common.currentModel : '')}
          to={obj.link}>
          <div className={common.textTitle}>
            {titulo || obj.titulo}
          </div>
          <div className={common.textDate}>
            {prettyDate || getDateStr(obj.date, lang)}
          </div>
          {short
            ? null
            : <ResumoLi text={resumo || obj.resumo} />
          }
        </Link>
      </li>
    )
  }
}

ModelListItem.propTypes = {
  lang: PropTypes.string.isRequired
}

const mapStateToProps = state => ({
  lang: state.linguas.lang
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(ModelListItem)
