/* eslint-env browser */
import axios from 'axios'

import { host, computeLink } from '../request'
import { getImage, stringArrToCommaSepString } from '../lib/utils'

const FETCH_SERVICOS = 'cmlf/servicos/FETCH_SERVICOS'
const INIT_FORMS_IN_PROGRESS = 'cmlf/servicos/INIT_FORMS_IN_PROGRESS'
const FETCH_INFO_FORM_IN_PROGRESS = 'cmlf/servicos/FETCH_INFO_FORM_IN_PROGRESS'
const SET_SERVICOS = 'cmlf/servicos/SET_SERVICOS'

const initialState = {
  servicos: [],
  formulariosInProgress: []
}

export const setServicos = (servicos) => ({ type: SET_SERVICOS, servicos })

export function fetchServicos (lang, categIdArray, online) {
  const cats = stringArrToCommaSepString(categIdArray)
  return (dispatch) => {
    axios.get(host + '/api/public/servicos?lang=' + lang +
      (cats !== null && cats !== undefined ? '&cats=' + cats : '') +
      (online !== null && online !== undefined ? '&online=' + online : ''))
      .then(res => res.data)
      .then(posts => {
        let resp = []
        posts.forEach(post => {
          let item = {
            id: post.id,
            titulo: post.titulo,
            resumo: post.resumo,
            categorias: post.categorias,
            link: computeLink(post),
            date: post.publicationDate,
            obj: post,
            image: getImage(post.imagem)
          }
          resp.push(item)
        })
        dispatch({
          type: FETCH_SERVICOS,
          payload: resp
        })
      })
  }
}

export const initFormsInProgress = () => ({ type: INIT_FORMS_IN_PROGRESS })

export function fetchInfoFormInProgress (id) {
  return (dispatch) => {
    axios.get(host + '/api/user/formulario/' + id + '/inProgress')
      .then(res => res.data)
      .then(post => {
        dispatch({
          type: FETCH_INFO_FORM_IN_PROGRESS,
          payload: post
        })
      })
  }
}

export default function reducer (state = initialState, action = {}) {
  switch (action.type) {
    case FETCH_SERVICOS:
      return { ...state, servicos: action.payload }
    case INIT_FORMS_IN_PROGRESS:
      return { ...state, formulariosInProgress: [] }
    case FETCH_INFO_FORM_IN_PROGRESS:
      let formulariosInProgress = insertInProgressInfo(state.formulariosInProgress, action.payload)
      return { ...state, formulariosInProgress: formulariosInProgress }
    case SET_SERVICOS:
      return { ...state, servicos: action.servicos }
    default:
      return state
  }
}

function insertInProgressInfo (formulariosInProgress, data) {
  let updated = false
  let newArray = [...formulariosInProgress]
  newArray.forEach(item => {
    if (item.formularioId === data.formularioId) {
      item.formInstanceId = data.formInstanceId
      updated = true
    }
  })
  if (!updated) {
    newArray.push(data)
  }
  return newArray
}
