import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { saveAllValues } from '../../modules/formulario'

import styles from './SaveSection.css'

class SaveSection extends Component {
  render () {
    const { stuffToSave } = this.props
    return (
      <div className={styles.saveForm + (stuffToSave ? ' ' + styles.activo : '')}
        onClick={() => this.saveForm(stuffToSave)} />
    )
  }

  saveForm (save) {
    if (!save) return
    console.log('gravar')
    this.props.saveAllValues()
  }
}

SaveSection.propTypes = {
  saveAllValues: PropTypes.func.isRequired,
  stuffToSave: PropTypes.bool.isRequired,
  savingForm: PropTypes.bool.isRequired,
  savingSuccess: PropTypes.bool.isRequired
}

const mapStateToProps = state => ({
  savingForm: state.formulario.savingForm,
  savingSuccess: state.formulario.savingSuccess,
  stuffToSave: state.formulario.stuffToSave
})

const mapDispatchToProps = { saveAllValues }

export default connect(mapStateToProps, mapDispatchToProps)(SaveSection)
