import React, { Component } from 'react'

import styles from '../common/ObjectoBase.css'
import { getTranslation } from '../../../request'

export default class Resumo extends Component {
  render() {
    const { model, lang } = this.props
    return (
      <div className={styles.resumoDiv} dangerouslySetInnerHTML={{ __html: getTranslation(model.resumo, lang) }} />
    )
  }
}
