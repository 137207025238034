import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { FormattedMessage as FM } from 'react-intl'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import styles from './Eventos.css'
import { setStartDate, toogleDate, setDaysShown } from '../../modules/events'
import EventosList from './EventosList'
import EventosMeses from './EventosMeses'

class Eventos extends Component {
  constructor(props) {
    super(props)
    this.scrollLeft = this.scrollLeft.bind(this)
    this.scrollRight = this.scrollRight.bind(this)
    this.setDay = this.setDay.bind(this)
    this.scrollDaysLeft = this.scrollDaysLeft.bind(this)
    this.scrollDaysRight = this.scrollDaysRight.bind(this)
    this.setRefDates()
    this.currentDay = this.refDate
  }

  componentDidUpdate () {
    const { mobile } = this.props
    if (mobile) {
      const cols = Math.floor((window.innerWidth - 100) / 50)
      this.props.setDaysShown(cols)
    } else {
      this.props.setDaysShown(15)
    }
  }

  render () {
    const { startDay, startMonth, startYear, daysShown } = this.props
    let startDate = new Date(this.refDate)
    let currentDate = new Date(startYear, startMonth, startDay)
    this.currentDay = new Date(currentDate)
    let endDate = new Date(startYear, startMonth, startDay)
    let styleUl = { 'left': '0px' }
    if (startDate.getTime() <= currentDate.getTime()) {
      endDate.setDate(endDate.getDate() + daysShown)
      styleUl = { 'left': '0px' }
    } else {
      startDate = new Date(currentDate)
      endDate = new Date(this.refDate)
      endDate.setDate(endDate.getDate() + daysShown)
      styleUl = {}
    }
    let liDays = this.buildAllDaysDiv(startDate, endDate)
    if (this.refDate.getTime() > currentDate.getTime()) {
      setTimeout(() => {
        this.scrollDaysRight()
      }, 100)
    }
    if (this.refDate.getTime() < currentDate.getTime()) {
      setTimeout(() => {
        this.scrollDaysLeft()
      }, 250)
    }
    this.setRefDates()
    return (
      <div className={styles.container}>
        <div className={styles.top} />
        <div className={styles.body}>
          <div className={styles.textContainer}>
            <div className={styles.titleText}><FM id='events.events' defaultMessage='Eventos' /></div>
          </div>
          <div className={styles.eventosContainer}>
            <EventosMeses />
            <div />
            <div className={styles.moveLeft} onClick={this.scrollRight} />
            <div id='divDias' className={styles.ulContainer}>
              <ul id='ulDias' className={styles.daysContainer + ' ' + styles.list} style={styleUl}>
                {liDays}
              </ul>
            </div>
            <div className={styles.moveRight} onClick={this.scrollLeft} />
            <EventosList />
          </div>
        </div>
        <Link className={styles.verMais} to='/eventos'><FM id='events.moreEvents' defaultMessage='mais eventos' /></Link>
      </div>
    )
  }

  scrollDaysRight () {
    let div = document.getElementById('divDias')
    let ul = document.getElementById('ulDias')
    let distance = ul.offsetWidth - div.offsetWidth + 50
    // ul.style.left = -1 * distance + 'px'
    // ul.style.right = 'unset'
    div.scrollLeft = ul.offsetWidth - div.offsetWidth + 50
    distance = div.scrollLeft
    for (let i = 1; i <= distance; i++) {
      setTimeout(() => {
        div.scrollLeft -= 1
      }, i + 20)
    }
    setTimeout(() => {
      this.props.toogleDate()
    }, distance + 20)
  }
  scrollDaysLeft () {
    let div = document.getElementById('divDias')
    let ul = document.getElementById('ulDias')
    let distance = ul.offsetWidth - div.offsetWidth + 50
    let steps = 200
    let pixels = distance / steps
    div.scrollLeft = 0
    if (pixels < 1) {
      pixels = 1
      steps = distance
    }
    pixels = Math.ceil(pixels)
    for (let i = 1; i < steps; i++) {
      setTimeout(() => {
        div.scrollLeft += pixels
      }, i * 2 + 10)
    }
    setTimeout(() => {
      this.props.toogleDate()
    }, steps + 200)
  }

  setRefDates () {
    const { startDay, startMonth, startYear } = this.props
    this.refDate = new Date(startYear, startMonth, startDay)
  }

  scrollRight (e) {
    const { startDay, startMonth, startYear } = this.props
    let newDate = new Date(startYear, startMonth, startDay)
    newDate.setDate(newDate.getDate() - this.props.scrollDays)
    this.props.setStartDate({ year: newDate.getFullYear(), month: newDate.getMonth(), day: newDate.getDate() })
  }

  scrollLeft (e) {
    const { startDay, startMonth, startYear, scrollDays } = this.props
    let date = new Date(startYear, startMonth, startDay)
    date.setDate(date.getDate() + scrollDays)
    this.props.setStartDate({ year: date.getFullYear(), month: date.getMonth(), day: date.getDate() })
  }

  setDay (e) {
    let day = parseInt(e.target.dataset.day, 0)
    let month = parseInt(e.target.dataset.month, 0)
    let year = parseInt(e.target.dataset.year, 0)
    this.props.setStartDate({ year: year, month: month, day: day })
  }

  buildAllDaysDiv (startDate, endDate) {
    let timeDiff = Math.abs(endDate.getTime() - startDate.getTime())
    let diffDays = Math.round(timeDiff / (1000 * 3600 * 24))
    return this.buildDaysDiv(startDate, diffDays)
  }

  buildDaysDiv (startDate, days) {
    let arr = []
    let style = {}
    if (this.props.mobile) {
      const cols = Math.floor((window.innerWidth - 100) / 50)
      const width = Math.floor((window.innerWidth - 100) / cols) - 10
      style = { width: width, minWidth: width }
    }
    for (let i = 0; i < days; i++) {
      let date = new Date(startDate)
      date.setDate(startDate.getDate() + i)
      if (date.getTime() === this.currentDay.getTime()) {
        arr.push(
          <li data-year={date.getFullYear()} data-month={date.getMonth()} data-day={date.getDate()}
            className={styles.liDay + ' ' + styles.current + (this.dateInEvent(date) ? ' ' + styles.event : '')}
            key={i} onClick={this.setDay} style={style}>
            {date.getDate()}
          </li>)
      } else {
        arr.push(
          <li data-year={date.getFullYear()} data-month={date.getMonth()} data-day={date.getDate()}
            className={styles.liDay + (this.dateInEvent(date) ? ' ' + styles.event : '')}
            key={i} onClick={this.setDay} style={style}>
            {date.getDate()}
          </li>)
      }
    }
    return arr
  }

  dateInEvent (date) {
    const { eventDays } = this.props
    let strDate = this.formatDate(date)
    if (eventDays.indexOf(strDate) !== -1) {
      return true
    }
    return false
  }

  formatDate (date) {
    let d = new Date(date)
    let month = '' + (d.getMonth() + 1)
    let day = '' + d.getDate()
    let year = d.getFullYear()

    if (month.length < 2) month = '0' + month
    if (day.length < 2) day = '0' + day

    return [year, month, day].join('-')
  }
}

Eventos.propTypes = {
  lang: PropTypes.string.isRequired,
  startDay: PropTypes.number.isRequired,
  startMonth: PropTypes.number.isRequired,
  startYear: PropTypes.number.isRequired,
  daysShown: PropTypes.number.isRequired,
  setStartDate: PropTypes.func.isRequired,
  scrollDays: PropTypes.number.isRequired,
  toogleDate: PropTypes.func.isRequired,
  dateChanged: PropTypes.bool.isRequired,
  mobile: PropTypes.bool.isRequired,
  setDaysShown: PropTypes.func.isRequired,
  eventDays: PropTypes.array.isRequired
}

const mapStateToProps = state => ({
  lang: state.linguas.lang,
  startDay: state.events.startDay,
  startMonth: state.events.startMonth,
  startYear: state.events.startYear,
  daysShown: state.events.daysShown,
  scrollDays: state.events.scrollDays,
  dateChanged: state.events.dateChanged,
  mobile: state.global.mobile,
  eventDays: state.events.eventDays
})

const mapDispatchToProps = { setStartDate, toogleDate, setDaysShown }

export default connect(mapStateToProps, mapDispatchToProps)(Eventos)
