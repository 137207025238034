/* eslint-env browser */
import axios from 'axios'

import { host, computeLink } from '../request'
import { getImage, stringArrToCommaSepString } from '../lib/utils'

const FETCH_OBJECTOS = 'cmlf/objectos/FETCH_OBJECTOS'

const initialState = {
  objectos: []
}

export function fetchPaginas (lang, categIdArray) {
  return (dispatch) => {
    const cats = stringArrToCommaSepString(categIdArray)
    axios.get(host + '/api/public/objectos?lang=' + lang + (cats !== null ? '&cats=' + cats : ''))
      .then(res => res.data)
      .then(posts => {
        let resp = []
        posts.forEach(post => {
          let item = {
            id: post.id,
            titulo: post.titulo,
            resumo: post.resumo,
            categorias: post.categorias,
            link: computeLink(post),
            date: post.publicationDate,
            obj: post
          }
          item.image = getImage(post.imagem)
          resp.push(item)
        })
        dispatch({
          type: FETCH_OBJECTOS,
          payload: resp
          // payload: initialState.news
        })
      })
  }
}

export default function reducer (state = initialState, action = {}) {
  switch (action.type) {
    case FETCH_OBJECTOS:
      return { ...state, objectos: action.payload }
    default:
      return state
  }
}
