import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
// import ToggleButton from 'react-toggle-button'
import Switch from '@material-ui/core/Switch'
import { FormattedMessage as FM } from 'react-intl'

import common from '../common/Body.css'
import styles from './LocaisList.css'
import spinner from '../../../css/Spinner.css'
import { filterCategoriasFromObjectos } from '../../../modules/categorias'
import { setResultSize } from '../../../modules/navigation'
import NavObjects from '../common/NavObjects'
import LocaisMap from './LocaisMap'
import ModelListItem from '../common/ModelListItem'
import { setShowOnlyInList, setShowOnlyVisibleInMap } from '../../../modules/locais'

class LocaisList extends Component {
  constructor(props) {
    super(props)
    this.noShadow = true
    this.showAllPins = true
    this.isSorted = false
  }

  componentDidUpdate () {
    const { categorias, locais, filterCategorias } = this.props
    let filteredList = this.filterOnlyInVisibleMap(locais)
    this.props.setResultSize(filteredList.length)
    if (filterCategorias) {
      this.props.filterCategoriasFromObjectos(locais, categorias)
    }
    this.isSorted = true
  }

  render () {
    const { short, objectosTreeFromPastaAreLoading } = this.props
    if (objectosTreeFromPastaAreLoading) {
      return (
        <div className={common.listContainer + (short ? ' ' + common.short : '')}>
          <div className={common.listGrid}>
            <div className={spinner.loader} />
          </div>
        </div>
      )
    }
    let locais = this.filterObjects()
    let locaisToPin = this.filterObjectsToPin()
    return (
      <div className={common.listContainer + (short ? ' ' + common.short : ' ' + common.map)}>
        <div className={styles.filterDiv}>
          <div>
            <FM id={'locais.showOnlyVisibleInMap'} defaultMessage={'Visualizar apenas locais visíveis no mapa'} />
            {this.toggleScopeArea()}
          </div>
        </div>
        {
          short
            ? null
            : <div className={common.mapDiv}><LocaisMap locais={locaisToPin} /></div>
        }
        <div className={common.listGrid}>
          {this.buildObjectList(locais, short)}
        </div>
        <NavObjects />
      </div>
    )
  }

  toggleScopeArea () {
    const { showOnlyVisibleInMap } = this.props
    return (
      <Switch
        checked={showOnlyVisibleInMap}
        onChange={(value) => this.setScopeVisibleArea(value)}
        value={showOnlyVisibleInMap}
        color='primary'
      />
    )
  }

  setScopeVisibleArea (value) {
    const val = value.target.value === 'true'
    this.props.setShowOnlyVisibleInMap(!val)
  }

  toggleScope () {
    const { showOnlyInList, showOnlyVisibleInMap } = this.props
    return (
      <Switch
        checked={showOnlyInList}
        onChange={(value) => this.setScope(value)}
        value={showOnlyInList}
        readOnly={showOnlyVisibleInMap}
        ena
        color='primary'
      />
    )
  }

  setScope (value) {
    const { showOnlyVisibleInMap } = this.props
    if (showOnlyVisibleInMap) return
    const val = value.target.value === 'true'
    this.props.setShowOnlyInList(!val)
  }

  filterObjectsToPin () {
    const { showOnlyInList, currentPage, locais, pageSize } = this.props
    if (showOnlyInList) {
      return this.getListSubSet(locais, currentPage, pageSize)
    }
    return locais
  }


  filterObjects () {
    const { currentPage, locais, pageSize, showOnlyVisibleInMap } = this.props
    let filteredList = locais // this.randomlySortLocais(locais)
    if (showOnlyVisibleInMap) {
      filteredList = this.filterOnlyInVisibleMap(locais)
    }
    let list = this.getListSubSet(filteredList, currentPage, pageSize)
    return list
  }

  filterOnlyInVisibleMap (locais) {
    const { bounds } = this.props
    if (!bounds) {
      return locais
    }
    let newList = []
    locais.map(local => {
      if (local.latInicio <= bounds.ne.lat && local.lngInicio <= bounds.ne.lng &&
        local.latInicio >= bounds.sw.lat && local.lngInicio >= bounds.sw.lng) {
        newList.push(local)
      } else if (local.latFim && local.lngFim) {
        if (local.latFim <= bounds.ne.lat && local.lngFim <= bounds.ne.lng &&
          local.latFim >= bounds.sw.lat && local.lngFim >= bounds.sw.lng) {
          newList.push(local)
        }
      }
    })
    return newList
  }

  randomlySortLocais (list) {
    list.forEach(element => {
      element.sortOrder = Math.floor((Math.random() * 1000) + 1)
    })
    return list.sort((a, b) => { return b.sortOrder - a.sortOrder })
  }

  buildObjectList (locais, short) {
    const { pageSize } = this.props
    return (
      <ul className={common.ul}>
        {
          this.buildLines(locais, pageSize, short)
        }
      </ul>
    )
  }

  buildLines (locais, pageSize, short) {
    const { model } = this.props
    const fakeLines = 0 // pageSize - locais.length
    let list = []
    locais.map((obj, index) => {
      let isModel = model && model.id === obj.id
      list.push(<ModelListItem key={index} obj={obj} index={index} isModel={isModel} short={short} />)
    })
    if (fakeLines > 0) {
      for (let index = 0; index < fakeLines; index++) {
        list.push(
          <li className={!short ? common.liFake : common.liFakeShort} key={'fake-' + index} />
        )
      }
    }
    return list
  }

  getListSubSet (arr, currentPage, pageSize) {
    const start = (currentPage - 1) * pageSize
    const end = start + pageSize
    if (arr.length < end) {
      return arr.slice(start)
    }
    return arr.slice(start, end)
  }
}

LocaisList.propTypes = {
  locais: PropTypes.array.isRequired,
  categorias: PropTypes.array.isRequired,
  currentPage: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  model: PropTypes.object,
  lang: PropTypes.string.isRequired,
  setResultSize: PropTypes.func.isRequired,
  filterCategoriasFromObjectos: PropTypes.func.isRequired,
  showOnlyInList: PropTypes.bool.isRequired,
  setShowOnlyInList: PropTypes.func.isRequired,
  showOnlyVisibleInMap: PropTypes.bool.isRequired,
  setShowOnlyVisibleInMap: PropTypes.func.isRequired,
  bounds: PropTypes.object,
  objectosTreeFromPastaAreLoading: PropTypes.bool.isRequired
}

const mapStateToProps = state => ({
  locais: state.locais.locais,
  categorias: state.categorias.categorias,
  currentPage: state.navigation.currentPage,
  pageSize: state.navigation.pageSize,
  model: state.model.model,
  lang: state.linguas.lang,
  showOnlyInList: state.locais.showOnlyInList,
  showOnlyVisibleInMap: state.locais.showOnlyVisibleInMap,
  bounds: state.locais.bounds,
  objectosTreeFromPastaAreLoading: state.pastas.objectosTreeFromPastaAreLoading
})

const mapDispatchToProps = { setResultSize, filterCategoriasFromObjectos, setShowOnlyInList, setShowOnlyVisibleInMap }

export default connect(mapStateToProps, mapDispatchToProps)(LocaisList)
