/* eslint-env browser */
import { host } from '../request'
import axios from 'axios'

const FETCH_CATEGORIAS = 'cmlf/categorias/FETCH_CATEGORIAS'
const SET_CURRENT_OBJ_TYPE = 'cmlf/categorias/SET_CURRENT_OBJ_TYPE'
const SET_CATEGORIAS = 'cmlf/categorias/SET_CATEGORIAS'
const TOGGLE_MORE_TAGS = 'cmlf/categorias/TOGGLE_MORE_TAGS'
const SET_FILTER_TAGS_TEXT = 'cmlf/categorias/SET_FILTER_TAGS_TEXT'
const SET_FILTERED_CATEGORIAS = 'cmlf/categorias/SET_FILTERED_CATEGORIAS'
const SET_HAS_FILTERED_CATEGORIAS = 'cmlf/categorias/SET_HAS_FILTERED_CATEGORIAS'
const SET_SELECTED_CATEGORIAS = 'cmlf/categorias/SET_SELECTED_CATEGORIAS'
const ITEMS_ARE_LOADING = 'cmlf/categorias/ITEMS_ARE_LOADING'
const ITEMS_HAVE_ERROR = 'cmlf/categorias/ITEMS_HAVE_ERROR'
const CATEGORIAS_LOADED = 'cmlf/categorias/CATEGORIAS_LOADED'


const initialState = {
  categorias: [],
  currentObjectType: 'objectos',
  initialTagsCount: 10,
  moreTags: true,
  filterTagsText: '',
  filteredCategorias: [],
  selectedCategorias: [],
  hasFilteredCategorias: false,
  hasErrored: false,
  isLoading: false,
  categoriasLoaded: false
}

export const setCategorias = (categorias) => ({ type: SET_CATEGORIAS, categorias })
export const toggleMoreTags = () => ({ type: TOGGLE_MORE_TAGS })
export const setFilterTagsText = (text) => ({ type: SET_FILTER_TAGS_TEXT, text })
export const setFilteredCategorias = (categorias) => ({ type: SET_FILTERED_CATEGORIAS, categorias })
export const setHasFilteredCategorias = (value) => ({ type: SET_HAS_FILTERED_CATEGORIAS, value })
export const setCurrentObjType = (objType) => ({ type: SET_CURRENT_OBJ_TYPE, objType })
export const resetSelectedCategorias = () => ({ type: SET_SELECTED_CATEGORIAS, selected: [] })
export const itemsHaveError = (bool) => ({ type: ITEMS_HAVE_ERROR, hasErrored: bool })
export const itemsAreLoading = (bool) => ({ type: ITEMS_ARE_LOADING, isLoading: bool })
export const setCategoriasLoaded = (bool) => ({ type: CATEGORIAS_LOADED, categoriasLoaded: bool })

export function fetchCategoriasFromObjectos (lang, objectList) {
  return (dispatch) => {
    dispatch(itemsAreLoading(true))
    dispatch(itemsHaveError(false))
    if (!objectList) {
      dispatch({
        type: FETCH_CATEGORIAS,
        payload: []
      })
      dispatch(itemsAreLoading(false))
    } else {
      axios.post(host + '/api/public/categorias/fromObjectList?lang=' + lang, objectList)
        .then(res => res.data)
        .then(posts => {
          dispatch({
            type: FETCH_CATEGORIAS,
            payload: posts
          })
          dispatch(itemsAreLoading(false))
        })
    }
  }
}

export function fetchCategorias (lang, objType, online) {
  return (dispatch) => {
    dispatch(itemsAreLoading(true))
    dispatch(itemsHaveError(false))
    if (!objType) {
      dispatch({
        type: FETCH_CATEGORIAS,
        payload: []
      })
      dispatch(itemsAreLoading(false))
    } else {
      axios.get(host + '/api/public/categorias/' + objType + '?lang=' + lang + (online !== null && online !== undefined ? '&online=' + online : ''))
        .then(res => res.data)
        .then(posts => {
          dispatch({
            type: FETCH_CATEGORIAS,
            payload: posts
          })
          dispatch(itemsAreLoading(false))
          dispatch(setCategoriasLoaded(true))
        })
    }
  }
}

export function filterCategoriasFromObjectos (objectos, categorias) {
  return (dispatch) => {
    let filtered = []
    objectos.forEach(obj => {
      obj.categorias.forEach(catId => {
        let inFiltered = false
        for (let index = 0; index < filtered.length; index++) {
          const fcat = filtered[index]
          if (fcat.categoria.id === catId) {
            inFiltered = true
            fcat.score += fcat.categoria.peso
            fcat.objectos++
            break
          }
        }
        if (!inFiltered) {
          for (let index = 0; index < categorias.length; index++) {
            const categoria = categorias[index]
            if (categoria.categoria.id === catId) {
              filtered.push({
                score: categoria.categoria.peso,
                objectos: 1,
                selected: categoria.selected,
                categoria: {
                  id: categoria.categoria.id,
                  nome: categoria.categoria.nome,
                  descricao: categoria.categoria.descricao,
                  peso: categoria.categoria.peso
                }
              })
              break
            }
          }
        }
      })
    })
    dispatch({
      type: SET_FILTERED_CATEGORIAS,
      categorias: filtered
    })
  }
}

export function computeSelectedCategorias (categorias) {
  return (dispatch) => {
    const selected = getSelectedCategorias(categorias)
    dispatch({
      type: SET_SELECTED_CATEGORIAS,
      selected: selected
    })
  }
}

function arrayContainsArray (superset, subset) {
  if (!superset) {
    return false
  }
  return subset.every(function (value) {
    return (superset.indexOf(value) >= 0)
  })
}

export function getCatFilteredObjects (objects, categorias) {
  if (!objects) {
    return []
  }
  const selected = getSelectedCategorias(categorias)
  let result = objects.filter(function (obj) {
    return obj !== null && arrayContainsArray(obj.categorias, selected)
  })
  return result
}

export default function reducer (state = initialState, action = {}) {
  switch (action.type) {
    case ITEMS_HAVE_ERROR:
      return { ...state, hasErrored: action.hasErrored }
    case ITEMS_ARE_LOADING:
      return { ...state, isLoading: action.isLoading }
    case FETCH_CATEGORIAS:
      return { ...state, categorias: action.payload, filteredCategorias: action.payload }
    case SET_CURRENT_OBJ_TYPE:
      return { ...state, currentObjectType: action.objType }
    case SET_CATEGORIAS:
      return { ...state, categorias: action.categorias }
    case TOGGLE_MORE_TAGS:
      return { ...state, moreTags: !state.moreTags }
    case SET_FILTER_TAGS_TEXT:
      return { ...state, filterTagsText: action.text }
    case SET_FILTERED_CATEGORIAS:
      return {
        ...state,
        filteredCategorias: action.categorias,
        hasFilteredCategorias: anyFilteredCategoria(action.categorias)
      }
    case SET_SELECTED_CATEGORIAS:
      return { ...state, selectedCategorias: action.selected }
    case SET_HAS_FILTERED_CATEGORIAS:
      return { ...state, hasFilteredCategorias: action.value }
    case CATEGORIAS_LOADED:
      return { ...state, categoriasLoaded: action.categoriasLoaded }
    default:
      return state
  }
}

function anyFilteredCategoria (array) {
  if (!array || array.length === 0) {
    return false
  }
  for (let index = 0; index < array.length; index++) {
    const element = array[index]
    if (element.selected) {
      return true
    }
  }
  return false
}

function getSelectedCategorias (array) {
  if (!array || array.length === 0) {
    return []
  }
  let sel = []
  array.forEach(cat => {
    if (cat.selected) {
      sel.push(cat.categoria.id)
    }
  })
  return sel
}
