import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { toggleSearchMode, setSelectedButton } from '../../../modules/header'
import { showMenu, toggleMenu } from '../../../modules/menus'
import { setSearchText, doSearch, setShowResults, setCurrentScope } from '../../../modules/search'
import { fetchConfig } from '../../../modules/global'
import styles from './Header.css'
import Search from '../../Search/Search'
import msg from '../../App/messages'

class Header extends Component {
  constructor(props) {
    super(props)
    this.toggleSearchMode = this.toggleSearchMode.bind(this)
    this.toggleSearchMenu = this.toggleSearchMenu.bind(this)
    this.onChange = this.onChange.bind(this)
    this.searchText = this.props.searchText
    this.myInputBox = React.createRef()
  }

  componentDidMount () {
    const { config } = this.props
    if (!config) {
      this.props.fetchConfig()
    }
  }

  render () {
    const { selectedButton, searchMode, mobile, config } = this.props
    if (!config) {
      return null
    }
    let headerStyle = {}
    if (config.imageUrl) {
      headerStyle = { backgroundImage: 'url(' + config.imageUrl + ')', backgroundPositionY: config.positionY }
    }
    return (
      <div className={styles.header} style={headerStyle}>
        <div className={styles.body}>
          {!mobile
            ? <div className={styles.heraldicaGrid}>
              <Link className={styles.heraldica} to='/' />
            </div>
            : null
          }
          {this.buildButtons(selectedButton, searchMode)}
        </div>
      </div>
    )
  }

  getSelectedButton (objName) {
    switch (objName) {
      case 'documentos':
        return 4
      case 'eventos':
        return 6
      default:
        return 0
    }
  }

  getCurrentScope (objName) {
    switch (objName) {
      case 'documentos':
        return 5
      case 'eventos':
        return 7
      default:
        return 0
    }
  }

  buildButtons (selected, searchMode) {
    if (!searchMode) {
      return this.buildExpandedButtons(selected)
    } else {
      return this.buildSearchModeInterface()
    }
  }

  buildSearchModeInterface () {
    const { currentScope, lang, mobile } = this.props
    let placeholderText = msg[lang]['search.whatShort']
    if (!mobile) {
      placeholderText = msg[lang]['search.what']
    } else if (mobile && window.innerWidth < 400) {
      placeholderText = ''
    }
    return (
      <div className={styles.searchGrid}>
        <div className={styles.searchBarContainer}>
          <div className={styles.searchBar}>
            <div className={styles.searchLeftMenu} onClick={this.toggleSearchMenu}>
              <div className={styles.currentScopeDiv + ' ' + this.getCurrentScopeClassname(currentScope)} />
              <div className={styles.openSearchMenuDiv} />
            </div>
            <div className={styles.searchRightDiv}>
              <div className={styles.searchLupa} onClick={this.toggleSearchMenu} />
              <input ref={this.myInputBox}
                className={styles.searchInput}
                type='text'
                onClick={this.toggleSearchMenu}
                onChange={this.onChange}
                placeholder={placeholderText}
                value={this.searchText} />
            </div>
          </div>
          <Search width='580' iconsOnly short />
        </div>
        <div className={styles.menuButtonContainer + ' ' + styles.whiteCircle} onClick={this.toggleSearchMode}>
          {/* <div className={styles.menuButton + ' ' + styles.whiteCircle} onClick={this.toggleSearchMode} /> */}
          <div className={styles.menuButton} />
        </div>
      </div>
    )
  }

  getCurrentScopeClassname (scope) {
    switch (scope) {
      case 1:
        return styles.municipio
      case 2:
        return styles.visitar
      case 3:
        return styles.servicosOnline
      case 4:
        return styles.viver
      case 5:
        return styles.publicacoes
      case 6:
        return styles.noticias
      case 7:
        return styles.eventos
      default:
        return styles.all
    }
  }

  buildExpandedButtons (selected) {
    const { lang } = this.props
    let arr = [
      { contStyle: styles.municipioDivContainer + ' ' + styles.circle, style: styles.municipioDiv, to: '/paginas', title: msg[lang]['header.cityHall'] },
      { contStyle: styles.placeDivContainer + ' ' + styles.circle, style: styles.placeDiv, to: '/locais', title: msg[lang]['body.visit'] },
      { contStyle: styles.onlineServiceDivContainer + ' ' + styles.circle, style: styles.onlineServiceDiv, to: '/servicos-online', title: msg[lang]['body.onlineServices'] },
      { contStyle: styles.viverDivContainer + ' ' + styles.circle, style: styles.viverDiv, to: '/servicos', title: msg[lang]['body.live'] },
      { contStyle: styles.documentsDivContainer + ' ' + styles.circle, style: styles.documentsDiv, to: '/documentos', title: msg[lang]['body.publications'] },
      { contStyle: styles.newsDivContainer + ' ' + styles.circle, style: styles.newsDiv, to: '/noticias', title: msg[lang]['news.news'] },
      { contStyle: styles.eventsDivContainer + ' ' + styles.circle, style: styles.eventsDiv, to: '/eventos', title: msg[lang]['events.events'] }
    ]
    return (
      <div className={styles.navGrid}>
        <div className={styles.searchDivContainer + ' ' + styles.transpCircle} onClick={this.toggleSearchMode}>
          <div className={styles.searchDiv} />
        </div>
        {
          arr.map((item, index) => {
            if (index === selected) {
              return (
                <Link key={index} className={item.contStyle + ' ' + styles.selected} to={item.to} title={item.title}>
                  <div key={index} className={item.style + ' ' + styles.selected} />
                </Link>
              )
            } else {
              return (
                <Link key={index} className={item.contStyle} to={item.to} title={item.title}>
                  <div className={item.style} />
                </Link>
              )
            }
          })
        }
      </div>
    )
  }

  onChange (e) {
    e.stopPropagation()
    let { lang, currentSortFieldOption, currentScope, searchScopes } = this.props
    if (currentScope < 0) {
      currentScope = 0
    }
    let text = e.target.value
    this.searchText = text
    this.props.setSearchText(text)
    if (text.length > 2) {
      this.props.doSearch(text, lang, currentSortFieldOption, searchScopes[currentScope].objSearchType)
    } else {
      this.props.setShowResults(false)
    }
    this.props.showMenu('searchObjects')
  }

  toggleSearchMenu (e) {
    e.stopPropagation()
    this.props.toggleMenu('searchObjects')
    this.myInputBox.current.focus()
  }

  toggleSearchMode (e) {
    this.props.toggleSearchMode()
  }
}

Header.propTypes = {
  doSearch: PropTypes.func.isRequired,
  toggleSearchMode: PropTypes.func.isRequired,
  toggleMenu: PropTypes.func.isRequired,
  showMenu: PropTypes.func.isRequired,
  selectedButton: PropTypes.number.isRequired,
  searchText: PropTypes.string.isRequired,
  setSearchText: PropTypes.func.isRequired,
  setShowResults: PropTypes.func.isRequired,
  searchMode: PropTypes.bool.isRequired,
  currentSortFieldOption: PropTypes.object.isRequired,
  currentScope: PropTypes.number.isRequired,
  searchScopes: PropTypes.array.isRequired,
  setSelectedButton: PropTypes.func.isRequired,
  setCurrentScope: PropTypes.func.isRequired,
  mobile: PropTypes.bool.isRequired,
  config: PropTypes.object,
  fetchConfig: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  lang: state.linguas.lang,
  searchText: state.search.searchText,
  selectedButton: state.header.selectedButton,
  searchMode: state.header.searchMode,
  currentSortFieldOption: state.search.currentSortFieldOption,
  currentScope: state.search.currentScope,
  searchScopes: state.search.searchScopes,
  mobile: state.global.mobile,
  config: state.global.config
})

const mapDispatchToProps = { toggleSearchMode, toggleMenu, showMenu, doSearch, setSearchText, setShowResults, setSelectedButton, setCurrentScope, fetchConfig }

export default connect(mapStateToProps, mapDispatchToProps)(Header)
