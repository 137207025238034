/* eslint-env browser */
import React from 'react'
// import { render } from 'react-dom'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { install, applyUpdate } from 'offline-plugin/runtime'
// import Keycloak from 'keycloak-js'
import axios from 'axios'
import { addLocaleData } from 'react-intl'
import pt from 'react-intl/locale-data/pt'
import en from 'react-intl/locale-data/en'

import store from './store'
import { setLang } from './modules/linguas'
import { setStartDate, fetchEventDays } from './modules/events'
import App from './components/App/App'
import './index.css'
import 'normalize.css'
import { kc, updateLocalStorage, updateState } from './request'
import { closeAllMenus } from './modules/menus'
import { setUser, getCurrentUser } from './modules/auth'
import { addParamToUrl } from './lib/utils'

addLocaleData(pt)
addLocaleData(en)

if (localStorage.cmlfLang) {
  store.dispatch(setLang(localStorage.cmlfLang))
}
const date = new Date()
store.dispatch(setStartDate({ year: date.getFullYear(), month: date.getMonth(), day: date.getDate() }))
// store.dispatch(fetchEventMonths())
store.dispatch(fetchEventDays())

// keycloak - inicio
const app = (
  <Provider store={store}>
    <App />
  </Provider>
)
let token = localStorage.getItem('cmlf_kcToken')
let refreshToken = localStorage.getItem('cmlf_kcRefreshToken')
window.onclick = closeMenus.bind(this)

function closeMenus () {
  store.dispatch(closeAllMenus())
}

token = token === 'null' ? null : token
refreshToken = refreshToken === 'null' ? null : refreshToken

console.log('tipo de login', localStorage.getItem('cmlf_loginIsAuthGov'))

// const loginIsAuthGov = localStorage.getItem('cmlf_loginIsAuthGov')

kc.init({ token, refreshToken, checkLoginIframe: false, onLoad: 'check-sso' })
  .then(authenticated => {
    if (authenticated) {
      store.getState().keycloak = kc
      updateLocalStorage(kc.token, kc.refreshToken)
      updateState(kc.token, kc.refreshToken)
      kc.updateToken(30)
        .then(refreshed => {
          if (refreshed) {
            store.getState().keycloak = kc
            updateLocalStorage(kc.token, kc.refreshToken)
            updateState(kc.token, kc.refreshToken)
          } else {
            // token era válido
          }
          kc.loadUserProfile()
            .success(function (profile) {
              updateUser({
                username: profile.username,
                firstName: profile.firstName,
                lastName: profile.lastName,
                isAuthGov: false,
                email: profile.email,
                fullName: null,
                roles: null,
                authGovToken: null,
                authGovExpires: null
              })
            })
            .error(function () {
              console.log('Falha ao obter dados do utilizador')
              // alert('Falha ao obter dados do utilizador')
            })
        })
        .catch(() => {
          console.log('vou fazer login')
          kc.login()
        })
    } else {
      updateUser(null)
      updateLocalStorage(null, null)
      updateState(null, null)
      // if (loginIsAuthGov === 'true') {
      //   let userStr = localStorage.getItem('cmlf_user')
      //   let myUser = JSON.parse(userStr)
      //   console.log('myUser', myUser)
      //   localStorage.setItem('cmlf_logout', false)
      //   localStorage.setItem('cmlf_login', true)
      //   setUser(myUser)
      // }
    }
    ReactDOM.render(app, document.getElementById('app'))
  })

axios.interceptors.request.use(config => (
  kc.updateToken(5)
    .then(refreshed => {
      if (refreshed) {
        updateLocalStorage(kc.token, kc.refreshToken)
        updateState(kc.token, kc.refreshToken)
        kc.loadUserProfile()
          .success(function (profile) {
            updateUser({
              username: profile.username,
              firstName: profile.firstName,
              lastName: profile.lastName,
              isAuthGov: false,
              email: profile.email,
              fullName: null,
              roles: null,
              authGovToken: null,
              authGovExpires: null
            })
          })
      }
      config.headers.Authorization = 'Bearer ' + kc.token
      return Promise.resolve(config)
    })
    .catch(() => {
      // kc.login()
      let currentUser = getCurrentUser()
      if (currentUser && currentUser.isAuthGov) {
        let url = config.url.replace('/api/user/', '/api/authgov/')
        url = addParamToUrl(url, 'username', currentUser.username)
        url = addParamToUrl(url, 'token', currentUser.authGovToken)
        url = addParamToUrl(url, 'expires', currentUser.authGovExpires)
        url = addParamToUrl(url, 'email', currentUser.email)
        url = addParamToUrl(url, 'nome', currentUser.fullName)
        config.url = url
        return Promise.resolve(config)
      }
      return Promise.resolve(config)
    })
))

const updateUser = (user) => {
  setUser(user)
}
// keycloak - fim
if (process.env.NODE_ENV === 'production') {
  install({
    onInstalled: function () {
      console.log('onInstalled')
    },
    onUpdating: function () {
      console.log('onUpdating')
    },
    onUpdateReady: function () {
      console.log('onUpdateReady')
      applyUpdate()
    },
    onUpdated: function () {
      console.log('onUpdated')
      window.location.reload()
    }
  })
}
window.store = store
