import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { addBreadcrumb, restoreBreadcrumb } from '../../modules/header'
import { fetchModel } from '../../modules/model'

class Formulario extends Component {
  componentDidMount () {
    const { permalink } = this.props.match.params
    this.props.fetchModel('/formulario/' + permalink)
    this.breadcrumbUpdated = false
  }

  componentDidUpdate () {
    const { model, previousLocation, previousBreadcrumb } = this.props
    const { permalink } = this.props.match.params
    if (model !== undefined && model !== null && model.nome !== undefined && permalink === model.permalink) {
      this.breadcrumbUpdated = true
      if (previousLocation !== undefined && previousLocation !== null && previousBreadcrumb !== null) {
        if (previousLocation.indexOf('/servico-online/') === 0) {
          this.props.restoreBreadcrumb()
        }
      }
      this.props.addBreadcrumb({ link: '/formulario/' + permalink, name: model.nome, translate: true })
    }
  }
  
  render () {
    return (
      <div>
        <h2>Página do Formulário</h2>
      </div>
    )
  }
}

Formulario.propTypes = {
  fetchModel: PropTypes.func.isRequired,
  lang: PropTypes.string.isRequired,
  addBreadcrumb: PropTypes.func.isRequired,
  model: PropTypes.object,
  formulario: PropTypes.object,
  previousLocation: PropTypes.string,
  previousBreadcrumb: PropTypes.array,
  restoreBreadcrumb: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  lang: state.linguas.lang,
  model: state.model.model,
  formulario: state.formulario.formulario,
  previousLocation: state.header.previousLocation,
  previousBreadcrumb: state.header.previousBreadcrumb
})

const mapDispatchToProps = { addBreadcrumb, fetchModel, restoreBreadcrumb }

export default connect(mapStateToProps, mapDispatchToProps)(Formulario)
