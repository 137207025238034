const SET_SELECTED_TOOLBAR = 'cmlf/toolbar/SET_SELECTED_TOOLBAR'
const DISABLE_TOOLBAR = 'cmlf/toolbar/DISABLE_TOOLBAR'
const ENABLE_TOOLBAR = 'cmlf/toolbar/ENABLE_TOOLBAR'
const SET_OBJECT_TYPE = 'cmlf/toolbar/SET_OBJECT_TYPE'
const TOGGLE_TOOLBAR = 'cmlf/toolbar/TOGGLE_TOOLBAR'
const CLOSE_TOOLBAR_MENU = 'cmlf/toolbar/CLOSE_TOOLBAR_MENU'

const initialState = {
  menus: [
    { name: 'menu', props: { enabled: true } },
    { name: 'calendar', props: { enabled: true } },
    { name: 'folders', props: { enabled: true } },
    { name: 'tags', props: { enabled: true } }
  ],
  selectedToolbar: 'tags',
  objectType: ''
}
export const toggleToolbar = (toolbar) => ({ type: TOGGLE_TOOLBAR, toolbar })
export const selectToolbar = (toolbar) => ({ type: SET_SELECTED_TOOLBAR, toolbar })
export const disableToolbar = (toolbar) => ({ type: DISABLE_TOOLBAR, toolbar })
export const enableToolbar = (toolbar) => ({ type: ENABLE_TOOLBAR, toolbar })
export const setObjectType = (objectType) => ({ type: SET_OBJECT_TYPE, objectType })
export const closeToolbarMenu = () => ({ type: CLOSE_TOOLBAR_MENU })

export default function reducer (state = initialState, action = {}) {
  let localMenus = null
  let menu = null
  switch (action.type) {
    case SET_SELECTED_TOOLBAR:
      return { ...state, selectedToolbar: action.toolbar }
    case TOGGLE_TOOLBAR:
      if (state.selectedToolbar === action.toolbar) {
        return { ...state, selectedToolbar: '' }
      }
      return { ...state, selectedToolbar: action.toolbar }
    case DISABLE_TOOLBAR:
      localMenus = [...state.menus]
      menu = getMenu(action.toolbar, localMenus)
      menu.props.enabled = false
      return { ...state, menus: localMenus }
    case ENABLE_TOOLBAR:
      localMenus = [...state.menus]
      menu = getMenu(action.toolbar, localMenus)
      menu.props.enabled = true
      return { ...state, menus: localMenus }
    case SET_OBJECT_TYPE:
      return { ...state, objectType: action.objectType }
    case CLOSE_TOOLBAR_MENU:
      return { ...state, selectedToolbar: '' }
    default:
      return state
  }
}

function getMenu (menuName, menus) {
  // const { menus } = this.props
  for (let index = 0; index < menus.length; index++) {
    const element = menus[index]
    if (element.name === menuName) {
      return element
    }
  }
}
