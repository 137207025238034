import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { host, getTranslation } from '../../../request'
import styles from './Content.css'
import { togglePopup } from '../../../modules/popup'

class Content extends Component {
  render () {
    const { content, lang } = this.props
    if (!content || !lang || content.galleryStyle) {
      return null
    }
    return (
      <div className={styles.contentBlock}>
        {this.renderContentText(content, lang)}
        {this.renderContentMediaFile(content, lang)}
        {this.renderContentObject(content, lang)}
      </div>
    )
  }

  renderContentText (content, lang) {
    if (!content || !content.text) {
      return null
    } else if (content.text !== null && !content.embed && content.mediaFile !== null && content.mediaFile.id !== null) {
      return null
    }
    return (
      <div dangerouslySetInnerHTML={{ __html: getTranslation(content.text, lang) }} />
    )
  }

  renderContentMediaFile (content, lang) {
    if (!content || !content.mediaFile || !content.mediaFile.id) {
      return null
    }
    if (content.embed) {
      return (
        <div className={styles.imgContainer}>
          <img className={styles.img}
            style={{ cursor: 'pointer' }}
            src={this.getFile(content.mediaFile)}
            onClick={() => this.props.togglePopup()} />
        </div>
      )
    } else {
      return (
        <div>
          <a target='_blank' href={this.getFile(content.mediaFile)}>
            <div dangerouslySetInnerHTML={{ __html: getTranslation(content.text, lang) }} />
          </a>
        </div>
      )
    }
  }

  // renderiza o objecto base associado ao conteúdo
  renderContentObject (content, lang) {
    if (!content || !content.objectoBase || !content.loaded) {
      return null
    }
    const obj = content.objectoBase
    if (content.embed && obj.type === 'document') {
      return (
        <div className={styles.imgContainer}>
          <img className={styles.img} src={this.getFile(obj.mediaFile)} />
        </div>
      )
    } else {
      return (
        <div>
          <a target='_blank' href={this.getFile(obj.mediaFile)}>
            <div dangerouslySetInnerHTML={{ __html: getTranslation(obj.titulo, lang) }} />
          </a>
        </div>
      )
    }
  }

  getFile (mediaFile) {
    return host + '/api/public/mediaFile/' + mediaFile.id
  }
}

Content.propTypes = {
  lang: PropTypes.string.isRequired,
  togglePopup: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  lang: state.linguas.lang
})

const mapDispatchToProps = { togglePopup }

export default connect(mapStateToProps, mapDispatchToProps)(Content)
