import React, { Component } from 'react'

import styles from '../common/ObjectoBase.css'
import { getTranslation } from '../../../request'

export default class Descricao extends Component {
  render() {
    const { model, lang } = this.props
    return (
      <div className={styles.descricaoDiv} dangerouslySetInnerHTML={{ __html: getTranslation(model.descricao, lang) }} />
    )
  }
}
