import React, { Component } from 'react'

import TreeViewNode from './TreeViewNode'
import styles from './TreeView.css'

export default class TreeView extends Component {
  render () {
    const { data, onSelect } = this.props
    return (
      <div className={styles.containerWrapper}>
        <div className={styles.container}>
          <TreeViewNode data={data} onSelect={onSelect} />
        </div>
      </div>
    )
  }
}
