/* eslint-env browser */
import { host, computeLink, getTranslation } from '../request'
import { getImage } from '../lib/utils'
import axios from 'axios'
import store from '../store'

const FETCH_PASTAS = 'cmlf/pastas/FETCH_PASTAS'
const TOGGLE_EXPANDED_PASTA = 'cmlf/pastas/TOGGLE_EXPANDED_PASTA'
const SET_CURRENT_PASTA = 'cmlf/pastas/SET_CURRENT_PASTA'
const FETCH_CURRENT_PASTA_CONTENT = 'cmlf/pastas/FETCH_CURRENT_PASTA_CONTENT'
const TOGGLE_TREE_EXPANDED_PASTA = 'cmlf/pastas/TOGGLE_TREE_EXPANDED_PASTA'
const SET_MENU_TYPE = 'cmlf/pastas/SET_MENU_TYPE'
const AVAILABLE_MENUS = 'cmlf/pastas/AVAILABLE_MENUS'
const WILL_SELECT_PASTA = 'cmlf/pastas/WILL_SELECT_PASTA'
const EXPAND_PASTA = 'cmlf/pastas/EXPAND_PASTA'
const INIT_PATH = 'cmlf/pastas/INIT_PATH'
const FETCH_DESTINATION_PASTA = 'cmlf/pastas/FETCH_DESTINATION_PASTA'
const SET_PATH_SELECTED_PERMALINK = 'cmlf/pastas/SET_PATH_SELECTED_PERMALINK'
const SET_PATH_DESTINATION_PERMALINK = 'cmlf/pastas/SET_PATH_DESTINATION_PERMALINK'
const SET_DESTINATION_PASTA = 'cmlf/pastas/SET_DESTINATION_PASTA'
const REBUILD_PATH = 'cmlf/pastas/REBUILD_PATH'
const AVAILABLE_MENUS_LOADING = 'cmlf/pastas/AVAILABLE_MENUS_LOADING'
const PASTAS_LOADING = 'cmlf/pastas/PASTAS_LOADING'
const OBJECTOS_TREE_FROM_PASTA_LOADING = 'cmlf/pastas/OBJECTOS_TREE_FROM_PASTA_LOADING'
const CURRENT_PASTA_CONTENT_LOADING = 'cmlf/pastas/CURRENT_PASTA_CONTENT_LOADING'
const DOCUMENTS_TREE_LOADED = 'cmlf/pastas/DOCUMENTS_TREE_LOADED'
const SET_CURRENT_PASTA_CONTENT = 'cmlf/pastas/SET_CURRENT_PASTA_CONTENT'

const initialState = {
  pastas: [],
  expandedPastaId: null,
  currentPasta: null,
  currentPastaContent: null,
  treeSelectedPastaId: null,
  menuType: 0,
  availableMenus: {
    paginas: false,
    servicosOnline: false,
    servicos: false,
    locais: false
  },
  destinationPasta: null,
  willSelectPasta: false,
  path: [],
  pathSelectedPermalink: null,
  pathDestinationPermalink: null,
  pathPermalinkSetted: false,
  availableMenusAreLoading: false,
  pastasAreLoading: false,
  objectosTreeFromPastaAreLoading: false,
  currentPastaContentIsLoading: false,
  documentsTreeLoaded: false,
  documentsTree: null,
  documentsTreeRoot: null
}

export const toggleExpandedPasta = (pasta) => ({ type: TOGGLE_EXPANDED_PASTA, pasta })
export const setCurrentPasta = (pasta) => ({ type: SET_CURRENT_PASTA, pasta })
export const toggleTreeExpandedPasta = (pasta) => ({ type: TOGGLE_TREE_EXPANDED_PASTA, pasta })
export const setMenuType = (menuType) => ({ type: SET_MENU_TYPE, menuType })
export const setWillSelectPasta = (willSelectPasta) => ({ type: WILL_SELECT_PASTA, willSelectPasta })
export const expandPasta = (pastaId) => ({ type: EXPAND_PASTA, pastaId })
export const initPath = () => ({ type: INIT_PATH })
export const setPathSelectedPermalink = (pathSelectedPermalink) => ({ type: SET_PATH_SELECTED_PERMALINK, pathSelectedPermalink })
export const setPathDestinationPermalink = (pathDestinationPermalink) => ({ type: SET_PATH_DESTINATION_PERMALINK, pathDestinationPermalink })
export const setDestinationPasta = (destinationPasta) => ({ type: SET_DESTINATION_PASTA, destinationPasta })
export const rebuildPath = () => ({ type: REBUILD_PATH })
export const availableMenusLoading = (loading) => ({ type: AVAILABLE_MENUS_LOADING, loading })
export const pastasLoading = (loading) => ({ type: PASTAS_LOADING, loading })
export const objectosTreeFromPastaLoading = (loading) => ({ type: OBJECTOS_TREE_FROM_PASTA_LOADING, loading })
export const currentPastaContentLoading = (loading) => ({ type: CURRENT_PASTA_CONTENT_LOADING, loading })
export const setCurrentPastaContent = (content) => ({ type: SET_CURRENT_PASTA_CONTENT, content })

export function fetchPastas(lang, menuType = 0) {
  return (dispatch) => {
    dispatch(pastasLoading(true))
    axios.get(host + '/api/public/pastasTree/' + '?lang=' + lang + '&type=' + menuTypeToString(menuType))
      .then(res => res.data)
      .then(posts => {
        dispatch({
          type: FETCH_PASTAS,
          payload: posts
        })
        dispatch(pastasLoading(false))
      })
  }
}

export function fetchAvailableMenus() {
  return (dispatch) => {
    dispatch(availableMenusLoading(true))
    axios.get(host + '/api/public/availableMenus')
      .then(res => res.data)
      .then(posts => {
        dispatch({
          type: AVAILABLE_MENUS,
          payload: posts
        })
        dispatch(availableMenusLoading(false))
      })
  }
}

export function fetchDestinationPasta(permalink, lang) {
  return (dispatch) => {
    axios.get(host + '/api/public/getObject/pasta/' + permalink)
      .then(res => res.data)
      .then(post => {
        let newObject = {
          id: post.id,
          nome: getTranslation(post.nome, lang),
          descricao: getTranslation(post.descricao, lang),
          permalink: post.permalink,
          subPastas: post.subPastas,
          typeEnum: post.typeEnum,
          listaAleatoria: post.listaAleatoria,
          obj: post
        }
        dispatch({
          type: FETCH_DESTINATION_PASTA,
          payload: newObject
        })
      })
      .catch((error) => {
        console.log(error)
        dispatch({
          type: FETCH_DESTINATION_PASTA,
          payload: null
        })
      })
  }
}

export function setCurrentPastaByObject(lang, pasta, onlyFiles = false, menuType = 0) {
  return (dispatch) => {
    dispatch(objectosTreeFromPastaLoading(true))
    let root = pasta
    if (root) {
      if (root.typeEnum !== menuType) {
        root = null
      }
    }
    axios.get(host + '/api/public/objectosTreeFromPasta' +
      '?lang=' + lang +
      (!root ? '' : '&root=' + root.id) +
      (onlyFiles ? '&docsOnly=' + true : '') +
      '&type=' + menuTypeToString(menuType))
      .then(res => res.data)
      .then(post => {
        post.objectos.map(obj => {
          obj.link = computeLink(obj, getObjType(menuType))
          obj.downloadLink = host + '/api/public/get/mediaFile/' + getMediaTypeString(menuType) + '/' + obj.permalink
          obj.image = getImage(obj.image)
        })
        post.subPastas.map(pasta => (
          computeLinks(pasta)
        ))
        post.menuType = menuType
        post.id = !root ? null : root.id
        dispatch({
          type: FETCH_CURRENT_PASTA_CONTENT,
          payload: post
        })
        dispatch(objectosTreeFromPastaLoading(false))
      })
  }
}

export function fetchCurrentPastaContent(lang, root, onlyFiles = false, menuType = 0) {
  return (dispatch) => {
    if (!root && menuType === 0 && store.getState().pastas.documentsTreeLoaded) {
      dispatch({
        type: FETCH_CURRENT_PASTA_CONTENT,
        payload: store.getState().pastas.documentsTree
      })
    } else {
      dispatch(objectosTreeFromPastaLoading(true))
      dispatch(currentPastaContentLoading(true))
      if (root) {
        if (root.typeEnum !== menuType) {
          root = null
        }
      }
      axios.get(host + '/api/public/objectosTreeFromPasta' +
        '?lang=' + lang +
        (!root ? '' : '&root=' + root.id) +
        (onlyFiles ? '&docsOnly=' + true : '') +
        '&type=' + menuTypeToString(menuType))
        .then(res => res.data)
        .then(post => {
          post.objectos.map(obj => {
            obj.link = computeLink(obj, getObjType(menuType))
            obj.downloadLink = host + '/api/public/get/mediaFile/' + getMediaTypeString(menuType) + '/' + obj.permalink
            obj.image = getImage(obj.image)
          })
          post.subPastas.map(pasta => (
            computeLinks(pasta)
          ))
          post.menuType = menuType
          post.id = !root ? null : root.id
          dispatch({
            type: FETCH_CURRENT_PASTA_CONTENT,
            payload: post
          })
          dispatch(objectosTreeFromPastaLoading(false))
          dispatch(currentPastaContentLoading(false))
          if (!root && menuType === 0) {
            dispatch({
              type: DOCUMENTS_TREE_LOADED,
              payload: post
            })
          }
        })
    }
  }
}

export function setCurrentPastaByPermalink(permalink, pastas, lang) {
  return (dispatch) => {
    const pasta = getPastaByPermalink(permalink, pastas)
    if (pasta) {
      dispatch(objectosTreeFromPastaLoading(true))
      dispatch({ type: SET_CURRENT_PASTA, pasta })
      let parentId = getPastaParentId(null, pasta.id, pastas)
      if (parentId) {
        dispatch({ type: EXPAND_PASTA, pastaId: parentId })
      } else {
        dispatch({ type: EXPAND_PASTA, pastaId: pasta.id })
      }
      const menuType = 0
      axios.get(host + '/api/public/objectosTreeFromPasta' +
        '?lang=' + lang + '&root=' + pasta.id +
        '&type=' + menuTypeToString(menuType))
        .then(res => res.data)
        .then(post => {
          post.objectos.map(obj => {
            obj.link = computeLink(obj, getObjType(menuType))
            obj.downloadLink = host + '/api/public/get/mediaFile/' + getMediaTypeString(menuType) + '/' + obj.permalink
            obj.image = getImage(obj.image)
          })
          post.subPastas.map(pasta => (
            computeLinks(pasta)
          ))
          post.menuType = menuType
          post.id = pasta.id
          dispatch({
            type: FETCH_CURRENT_PASTA_CONTENT,
            payload: post
          })
          dispatch(objectosTreeFromPastaLoading(false))
        })
    }
  }
}

export default function reducer(state = initialState, action = {}) {
  let path = []
  switch (action.type) {
    case FETCH_PASTAS:
      return { ...state, pastas: action.payload }
    case TOGGLE_EXPANDED_PASTA:
      if (state.expandedPastaId === action.pasta.id) {
        return { ...state, expandedPastaId: null }
      }
      return { ...state, expandedPastaId: action.pasta.id }
    case SET_CURRENT_PASTA:
      path = getPath(action.pasta, state.pastas)
      return {
        ...state, currentPasta: action.pasta, path: path,
        treeSelectedPastaId: null, destinationPasta: null, pathDestinationPermalink: null,
        pathSelectedPermalink: action.pasta ? action.pasta.permalink : null,
        documentsTreeRoot: action.pasta
      }
    case FETCH_CURRENT_PASTA_CONTENT:
      return { ...state, currentPastaContent: action.payload }
    case TOGGLE_TREE_EXPANDED_PASTA:
      path = getPath(action.pasta, state.pastas)
      let newDestinationPasta = null
      if (state.treeSelectedPastaId === action.pasta.id) {
        path.splice(-1, 1)
        // actualizar destinationPasta
        let newTreeSelectedPastaId = getPastaParentId(state.currentPasta, action.pasta.id, state.pastas)
        newDestinationPasta = getPastaById(newTreeSelectedPastaId, state.pastas)
        return { ...state, treeSelectedPastaId: newTreeSelectedPastaId, path: path, destinationPasta: newDestinationPasta }
      }
      newDestinationPasta = getPastaById(action.pasta.id, state.pastas)
      return { ...state, treeSelectedPastaId: action.pasta.id, path: path, destinationPasta: newDestinationPasta }
    case SET_MENU_TYPE:
      return { ...state, menuType: action.menuType }
    case AVAILABLE_MENUS:
      let availableMenus = {
        paginas: action.payload.paginas,
        servicosOnline: action.payload.servicosOnline,
        servicos: action.payload.servicos,
        locais: action.payload.locais
      }
      return { ...state, availableMenus: availableMenus }
    case WILL_SELECT_PASTA:
      return { ...state, willSelectPasta: action.willSelectPasta }
    case EXPAND_PASTA:
      return { ...state, expandedPastaId: action.pastaId }
    case INIT_PATH:
      return { ...state, path: [] }
    case FETCH_DESTINATION_PASTA:
      if (!action.payload) {
        return { ...state, destinationPasta: null, treeSelectedPastaId: null }
      }
      path = getPath(action.payload, state.pastas)
      return { ...state, destinationPasta: action.payload, treeSelectedPastaId: action.payload.id, path: path, documentsTreeRoot: action.pasta }
    case SET_PATH_SELECTED_PERMALINK:
      if (!action.pathSelectedPermalink) {
        return { ...state, pathSelectedPermalink: null, currentPasta: null, currentPastaContent: null, path: [] }
      }
      path = state.path
      if (state.currentPasta && state.currentPasta.permalink === action.pathSelectedPermalink) {
        path = getPath(state.currentPasta, state.pastas)
      }
      return { ...state, pathSelectedPermalink: action.pathSelectedPermalink, path: path }
    case SET_PATH_DESTINATION_PERMALINK:
      path = state.path
      if (!action.pathDestinationPermalink) {
        if (state.currentPasta) {
          path = getPath(state.currentPasta, state.pastas)
        }
        return { ...state, pathDestinationPermalink: null, destinationPasta: null, treeSelectedPastaId: null, path: path }
      }
      if (state.destinationPasta) {
        path = getPath(state.destinationPasta, state.pastas)
      }
      return { ...state, pathDestinationPermalink: action.pathDestinationPermalink }
    case AVAILABLE_MENUS_LOADING:
      return { ...state, availableMenusAreLoading: action.loading }
    case PASTAS_LOADING:
      return { ...state, pastasAreLoading: action.loading }
    case OBJECTOS_TREE_FROM_PASTA_LOADING:
      return { ...state, objectosTreeFromPastaAreLoading: action.loading }
    case CURRENT_PASTA_CONTENT_LOADING:
      return { ...state, currentPastaContentIsLoading: action.loading }
    case DOCUMENTS_TREE_LOADED:
      return { ...state, documentsTreeLoaded: true, documentsTree: action.payload, currentPastaContent: action.payload }
    case SET_CURRENT_PASTA_CONTENT:
      return { ...state, currentPastaContent: action.content }
    default:
      return state
  }
}

function getPastaById(id, pastas) {
  if (!id || !pastas) {
    return null
  }
  for (let index = 0; index < pastas.length; index++) {
    const pasta = pastas[index]
    if (pasta.id === id) {
      return pasta
    }
    const subPasta = getPastaById(id, pasta.subPastas)
    if (subPasta) {
      return subPasta
    }
  }
  return null
}

function getPastaByPermalink(permalink, pastas) {
  if (!permalink || !pastas) {
    return null
  }
  for (let index = 0; index < pastas.length; index++) {
    const pasta = pastas[index]
    if (pasta.permalink === permalink) {
      return pasta
    }
    const subPasta = getPastaByPermalink(permalink, pasta.subPastas)
    if (subPasta) {
      return subPasta
    }
  }
  return null
}

function menuTypeToString(menuType) {
  switch (menuType) {
    case 0:
      return 'documento'
    case 1:
      return 'pagina'
    case 2:
      return 'servico_online'
    case 3:
      return 'servico'
    case 4:
      return 'local'
    default:
      return 'documento'
  }
}

function getMediaTypeString(menuType) {
  switch (menuType) {
    case 0:
      return 'documento'
    case 1:
      return 'pagina'
    case 2:
      return 'servico'
    case 3:
      return 'servico'
    case 4:
      return 'local'
    default:
      return 'documento'
  }
}

function getObjType(menuType) {
  switch (menuType) {
    case 0:
      return 'document'
    case 1:
      return 'page'
    case 2:
      return 'service'
    case 3:
      return 'service'
    case 4:
      return 'place'
    default:
      return 'document'
  }
}

function computeLinks(pasta) {
  pasta.objectos.map(doc => {
    doc.link = computeLink(doc, 'document')
    doc.downloadLink = host + '/api/public/get/mediaFile/documento/' + doc.permalink
    // doc.downloadLink = host + '/api/public/mediaFile/' + doc.id
  })
  pasta.subPastas.map(pasta => (
    computeLinks(pasta)
  ))
}

function getPastaParentId(root, pastaId, pastas) {
  let parent = root
  if (!root) {
    for (let index = 0; index < pastas.length; index++) {
      let parentId = getPastaParentId(pastas[index], pastaId)
      if (parentId !== null) {
        return parentId
      }
    }
    return null
  }
  for (let index = 0; index < root.subPastas.length; index++) {
    const element = root.subPastas[index]
    if (pastaId === element.id) {
      return parent.id
    }
    let p = getPastaParentId(element, pastaId, pastas)
    if (p !== null) {
      return p
    }
  }
  return null
}

function getPath(pasta, pastas) {
  if (!pasta) {
    return []
  }
  let path = []
  path.push(pasta)
  let parent = getPastaParent(null, pasta, pastas)
  while (parent !== null) {
    path.splice(0, 0, parent)
    parent = getPastaParent(null, parent, pastas)
  }
  return path
}

function getPastaParent(root, pasta, pastas) {
  let parent = root
  if (!root) {
    for (let index = 0; index < pastas.length; index++) {
      let element = pastas[index]
      if (element.id === pasta.id) {
        return null
      }
      let parent = getPastaParent(element, pasta)
      if (parent !== null) {
        return parent
      }
    }
    return null
  }
  for (let index = 0; index < root.subPastas.length; index++) {
    const element = root.subPastas[index]
    if (pasta.id === element.id) {
      return parent
    }
    let p = getPastaParent(element, pasta)
    if (p !== null) {
      return p
    }
  }
  return null
}
