/* eslint-env browser */
import { host, computeLink } from '../request'
import { getImage, stringArrToCommaSepString } from '../lib/utils'
import axios from 'axios'

const FETCH_DOCUMENTOS = 'cmlf/documentos/FETCH_DOCUMENTOS'
const SET_DOCUMENTOS = 'cmlf/documentos/SET_DOCUMENTOS'
const SET_PASTA_CLICKED = 'cmlf/documentos/SET_PASTA_CLICKED'
const DOCUMENTOS_LOADING = 'cmlf/pastas/DOCUMENTOS_LOADING'
const DOCUMENTOS_LOADED = 'cmlf/pastas/DOCUMENTOS_LOADED'

const initialState = {
  documentos: [],
  pastaClicked: false,
  documentosAreLoading: false,
  documentosLoaded: false
}

export const setDocumentos = (documentos) => ({ type: SET_DOCUMENTOS, documentos })
export const setPastaClicked = (clicked) => ({ type: SET_PASTA_CLICKED, clicked })
export const documentosLoading = (loading) => ({ type: DOCUMENTOS_LOADING, loading })
export const documentosLoaded = (loaded) => ({ type: DOCUMENTOS_LOADED, loaded })

export function fetchDocumentos(lang, categIdArray) {
  return (dispatch) => {
    dispatch(documentosLoading(true))
    dispatch(documentosLoaded(false))
    const cats = stringArrToCommaSepString(categIdArray)
    axios.get(host + '/api/public/documentos?lang=' + lang + (cats !== null ? '&cats=' + cats : ''))
      .then(res => res.data)
      .then(posts => {
        let resp = []
        posts.forEach(post => {
          let item = {
            title: post.titulo,
            categorias: post.categorias,
            fileSizeKb: post.mediaFile.fileSizeKb
          }
          item.image = getImage(post.imagem)
          item.link = computeLink(post)
          item.id = post.id
          item.obj = post
          item.downloadLink = host + '/api/public/get/mediaFile/documento/' + post.permalink
          // item.downloadLink = host + '/api/public/mediaFile/' + post.id
          resp.push(item)
        })
        dispatch({
          type: FETCH_DOCUMENTOS,
          payload: resp
          // payload: initialState.news
        })
        dispatch(documentosLoading(false))
        dispatch(documentosLoaded(true))
      })
  }
}
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case FETCH_DOCUMENTOS:
      return { ...state, documentos: action.payload }
    case SET_DOCUMENTOS:
      return { ...state, documentos: action.documentos }
    case SET_PASTA_CLICKED:
      return { ...state, pastaClicked: action.clicked }
    case DOCUMENTOS_LOADING:
      return { ...state, documentosAreLoading: action.loading }
    case DOCUMENTOS_LOADED:
      return { ...state, documentosLoaded: action.loaded }
    default:
      return state
  }
}
