import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import common from '../common/Body.css'
import spinner from '../../../css/Spinner.css'
import { getCatFilteredObjects, filterCategoriasFromObjectos } from '../../../modules/categorias'
import { setResultSize } from '../../../modules/navigation'
import { ymdToDate } from '../../../lib/utils'
import NavObjects from '../common/NavObjects'
import ModelListItem from '../common/ModelListItem'

class NoticiasList extends Component {
  componentDidMount() {
    this.noShadow = true
  }

  componentDidUpdate() {
    const { categorias, news, filterCategorias, selectedMonth } = this.props
    if (filterCategorias) {
      this.props.filterCategoriasFromObjectos(news, categorias)
    }
    let filteredList = []
    filteredList = getCatFilteredObjects(news, categorias)
    filteredList = this.filterFromSelectedMonth(filteredList, selectedMonth)
    this.props.setResultSize(filteredList.length)
  }

  render() {
    const { short, newsAreLoading } = this.props
    if (newsAreLoading) {
      return (
        <div className={common.listContainer + (short ? ' ' + common.short : '')}>
          <div className={common.listGrid}>
            <div className={spinner.loader} />
          </div>
        </div>
      )
    }
    let objectos = this.filterObjects()
    return (
      <div className={common.listContainer + (short ? ' ' + common.short : '')}>
        <div className={common.listGrid}>
          {this.buildObjectList(objectos, short)}
        </div>
        <NavObjects />
      </div>
    )
  }

  filterObjects() {
    const { news, categorias, currentPage, pageSize, selectedMonth } = this.props
    let objectos = []
    objectos = getCatFilteredObjects(news, categorias)
    objectos = this.filterFromSelectedMonth(objectos, selectedMonth)
    return this.getListSubSet(objectos, currentPage, pageSize)
  }

  buildObjectList(objectos, short) {
    const { pageSize } = this.props
    return (
      <ul className={common.ul}>
        {
          this.buildLines(objectos, pageSize, short)
        }
      </ul>
    )
  }

  buildLines(objectos, pageSize, short) {
    const { model } = this.props
    const fakeLines = 0 // pageSize - objectos.length
    let list = []
    objectos.map((obj, index) => {
      let isModel = model && model.id === obj.id
      list.push(<ModelListItem key={index} obj={obj} index={index} isModel={isModel} short={short} />)
    })
    if (fakeLines > 0) {
      for (let index = 0; index < fakeLines; index++) {
        list.push(
          <li className={!short ? common.liFake : common.liFakeShort} key={'fake-' + index} />
        )
      }
    }
    return list
  }

  filterFromSelectedMonth(objects, selectedMonth) {
    if (!objects) {
      return []
    }
    if (!selectedMonth) {
      return objects
    }
    const selDate = new Date(selectedMonth.year, selectedMonth.month, 0)
    let result = objects.filter(function (obj) {
      return obj !== null && ymdToDate(obj.date) <= selDate
    })
    return result
  }

  getListSubSet(arr, currentPage, pageSize) {
    const start = (currentPage - 1) * pageSize
    const end = start + pageSize
    if (arr.length < end) {
      return arr.slice(start)
    }
    return arr.slice(start, end)
  }
}

NoticiasList.propTypes = {
  news: PropTypes.array.isRequired,
  categorias: PropTypes.array.isRequired,
  currentPage: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  model: PropTypes.object,
  lang: PropTypes.string.isRequired,
  setResultSize: PropTypes.func.isRequired,
  selectedMonth: PropTypes.object,
  filterCategoriasFromObjectos: PropTypes.func.isRequired,
  objectosTreeFromPastaAreLoading: PropTypes.bool.isRequired,
  newsAreLoading: PropTypes.bool.isRequired
}

const mapStateToProps = state => ({
  news: state.news.news,
  categorias: state.categorias.categorias,
  currentPage: state.navigation.currentPage,
  pageSize: state.navigation.pageSize,
  model: state.model.model,
  lang: state.linguas.lang,
  selectedMonth: state.calendar.selectedMonth,
  objectosTreeFromPastaAreLoading: state.pastas.objectosTreeFromPastaAreLoading,
  newsAreLoading: state.news.newsAreLoading
})

const mapDispatchToProps = { setResultSize, filterCategoriasFromObjectos }

export default connect(mapStateToProps, mapDispatchToProps)(NoticiasList)
