import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { FormattedMessage as FM } from 'react-intl'

import SocialNet from '../common/SocialNet'
import FooterLinks from './FooterLinks'

import styles from './Footer.css'
import cx from '../../css/Layout.css'

export default class Footer extends Component {
  render () {
    return (
      <div className={styles.footer}>
        <div className={styles.gridTop}>
          <div className={styles.leftText + ' ' + styles.marginVert}>
            <div><strong><FM id='footer.address' defaultMessage='Morada:' /></strong></div>
            <div>Avenida do Emigrante</div>
            <div>9960-431 Lajes das Flores</div>
            <div>Ilha das Flores, Açores</div>
            <div>Portugal</div>
            <div className={styles.vSpacer}><strong><FM id='footer.contacts' defaultMessage='Contactos:' /></strong></div>
            <div>Tel: +351 292 590 800</div>
            <div>Fax: +351 292 590 826</div>
          </div>
          <div className={styles.middleText + ' ' + styles.marginVert}>
            <div><strong><FM id='footer.email' defaultMessage='Email:' /></strong></div>
            <div>geral@cmlajesdasflores.pt</div>
            <div className={styles.vSpacer}><strong><FM id='footer.schedule' defaultMessage='Horário de funcionamento:' /></strong></div>
            <div>08h30m - 12h30m</div>
            <div>13h30m - 16h30m</div>
          </div>
          <div className={styles.rightText + ' ' + styles.relative + ' ' + styles.marginVert + ' ' + styles.alignContentBottom}>
            <div className={styles.alignBottomRight}>
              <FooterLinks />
              <div className={styles.vDivider} />
              <div className={styles.heraldicaContainer}>
                <a href='https://www.livroreclamacoes.pt/inicio' target='_blank'><div className={styles.livroReclamacoes} /></a>
                <Link to='/'><div className={styles.heraldicaBox + ' ' + cx.heraldica} /></Link>
              </div>
              <div className={styles.socialnetBox}><SocialNet /></div>
            </div>
          </div>
        </div>
        <div className={styles.gridBottomContainer}>
          <div className={styles.gridBottom}>
            <div className={styles.left}>
              <div>&copy; Câmara das Lajes das Flores, Flores</div>
              <div><FM id='footer.reservedRights' defaultMessage='Todos os Direitos Reservados' /></div>
            </div>
            <div />
            <div className={styles.right}><FM id='footer.devBy' defaultMessage='Desenvolvido por: Globaleda' /></div>
          </div>
        </div>
        <div className={styles.gridFederContainer}>
          <div className={styles.gridFeder}>
            <div className={styles.feder} />
          </div>
        </div>
      </div>
    )
  }

  moveTop (e) {
    e.preventDefault()
    e.stopPropagation()
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  }
}
