import React, { Component } from 'react'

import BodyObjecto from './BodyObjecto'

export default class Documento extends Component {
  render () {
    let { permalink } = this.props.match.params
    return (
      <BodyObjecto permalink={permalink} />
    )
  }
}
