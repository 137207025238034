import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { fetchDocumentos } from '../../../modules/documentos'
import DocumentosList from './DocumentosList'

class DocumentosCategorias extends Component {
  constructor(props) {
    super(props)
    const { selectedCategorias, lang } = props
    props.fetchDocumentos(lang, selectedCategorias)
  }

  render() {
    return (
      <DocumentosList filterCategorias />
    )
  }
}

DocumentosCategorias.propTypes = {
  lang: PropTypes.string.isRequired,
  fetchDocumentos: PropTypes.func.isRequired,
  selectedCategorias:PropTypes.array.isRequired
}

const mapStateToProps = state => ({
  lang: state.linguas.lang,
  selectedCategorias: state.categorias.selectedCategorias
})

const mapDispatchToProps = { fetchDocumentos }

export default connect(mapStateToProps, mapDispatchToProps)(DocumentosCategorias)
