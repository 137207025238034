import React, { Component } from 'react'

import Layouts from './Layouts'
import Content from './Content'

export default class Layout extends Component {
  render () {
    const { layout } = this.props
    if (!layout) {
      return null
    }
    return (
      <div>
        {this.buildContent(layout.content)}
        <Layouts parent={layout} />
      </div>
    )
  }

  buildContent (content) {
    if (!content) {
      return null
    }
    return (
      <Content content={content} />
    )
  }
}
